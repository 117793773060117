import styled from "styled-components";

const StyledComponent = styled.form`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--custom-secondary-bg) inset !important;
    -webkit-text-fill-color: var(--custom-white) !important;
  }
  input {
    text-indent: 2rem;
  }
  label {
    color: var(--custom-Black);
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    margin-left: 1rem;
  }
  .formFieldWrapperSubject {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const FormFieldsWrapper = ({ children, ...props }) => {
  return (
    <StyledComponent autocomplete="off" {...props}>
      {children}
    </StyledComponent>
  );
};
