import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {GlobalUserProfileContext} from "../../../App";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {Spinner} from "../../Global/graphical/Spinner";
import {useLocation, useNavigate} from "react-router-dom";
import {fetchUserProfile} from "../../../config/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {globalNavigationReducer} from "../../../config/store/reducers/reducers";
import {CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION} from "../../../config/store/constants";

export const PhoneVerificationForm = () => {
    const [data, setData] = useState({});
    const [processingStates, setProcessingStates] = useState({});
    const [resendingOTPTimeRemaining, setResendingOTPTimeRemaining] = useState(0);
    const {profile} = useContext(GlobalUserProfileContext);
    const redirectTo = useRef(null)
    const OTPSent = profile?.has_pending_phone_number_verification_request
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {redirectRouteAfterPhoneNumberVerification} = useSelector((reducers) => reducers.globalNavigationReducer)

    const handleOnInputChange = (e, dataRef, inputTargetRef = "value") => {
        const tempData = {...data};
        tempData[dataRef] = e.target[inputTargetRef];
        if (inputTargetRef === "value") {
            tempData[dataRef] = tempData[dataRef].trim();
        }
        setData(tempData);
    };

    useEffect(() => {
        if (resendingOTPTimeRemaining > 0) {
            setTimeout(() => setResendingOTPTimeRemaining(resendingOTPTimeRemaining - 1), 1000);
        }
    }, [resendingOTPTimeRemaining]);

    const handleVerifyProceed = () => {
        const endpoint = "/auth/finalize_verify_phone_number/";
        setProcessingStates({
            ...processingStates,
            verifyingPhoneNumber: true,
        });
        axiosServerInstance(profile?.token?.access)
            .post(endpoint, data)
            .then((response) => {
                setProcessingStates({
                    ...processingStates,
                    verifyingPhoneNumber: true,
                });
                dispatch(fetchUserProfile);
                setTimeout(() => {
                    notifySuccess("Phone number verification successful");
                    navigate(redirectRouteAfterPhoneNumberVerification || '/', {replace: true});
                    dispatch({type: CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION})
                }, 2000)
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                setProcessingStates({
                    ...processingStates,
                    verifyingPhoneNumber: true,
                });
            });
    };

    const handleResendOTP = () => {
        const endpoint = "/auth/initialize_verify_phone_number/";
        setProcessingStates({
            ...processingStates,
            resendingOTP: true,
        });
        axiosServerInstance(profile?.token?.access)
            .post(endpoint)
            .then((response) => {
                setProcessingStates({
                    ...processingStates,
                    resendingOTP: false,
                });
                setResendingOTPTimeRemaining(60);
                dispatch(fetchUserProfile);
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                setProcessingStates({
                    ...processingStates,
                    resendingOTP: false,
                });
            });
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("next")) {
            redirectTo.current = query.get("next");
        }
    }, []);
    return (
        <FormComponent>
            <div>
                <label>Verification Code</label>
                <div className="infoWrapper">
                    {OTPSent ? (
                        <span className="info">
                            Enter the OTP sent to{" "}
                            {`${
                                profile?.phone_number
                                    ? profile?.phone_number
                                    : "your registered phone number"
                            }`}
                        </span>
                    ) : (
                        <span className="info">
                            Click{" "}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleResendOTP();
                                }}
                            >
                                here
                            </a>{" "}
                            to request for OTP and verify your phone number.
                        </span>
                    )}
                    {profile?.phone_number &&
                        OTPSent &&
                        (processingStates?.resendingOTP ? (
                            <Spinner size={1}/>
                        ) : (
                            <span>
                                <span className="info">Did not receive OTP, </span>
                                {resendingOTPTimeRemaining ? (
                                    <span>Try again in {resendingOTPTimeRemaining} seconds</span>
                                ) : (
                                    <span>
                                        click{" "}
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleResendOTP();
                                            }}
                                        >
                                            here
                                        </a>{" "}
                                        to send again.
                                    </span>
                                )}
                            </span>
                        ))}
                </div>
                <InputComponent
                    placeholder={"xxxxxx"}
                    type="number"
                    // value={data?.otp}
                    onChange={(e) => handleOnInputChange(e, "otp")}
                />
                <SizedBox height={2.5}/>
            </div>

            <AuthButtonContainer>
                <>
                    <Button
                        fontWeight={700}
                        fontSize={1}
                        maxWidth={15}
                        borderRadius={1.5}
                        paddingTopBottom={0.6}
                        onClick={(e) => {
                            e.preventDefault();
                            handleVerifyProceed();
                        }}
                    >
                        VERIFY
                    </Button>
                </>
            </AuthButtonContainer>
        </FormComponent>
    );
};
