import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {StudentsContext} from "../Container";
import {TableComponent} from "../../../Global/graphical/table/Container";
import {MainWrapper} from "../../../Global/MainWrapper";
import {Button} from "../../../Global/Button";
import {formatQueryDictToQueryParams} from "../../../../helper/formatters/formatDictToParams";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {Spinner} from "../../../Global/graphical/Spinner";

const StyledComponent = styled.div`
  .searchActions {
    display: flex;
    margin-bottom: 2rem;
    gap: 1rem;
    align-items: center;
    @media (max-width: 1250px) {
      flex-wrap: wrap;
    }

    select {
      min-width: 150px;
      height: 2.5rem;
      text-indent: 10px;
      background: var(--custom-btn-bg);
      border-radius: 25px;
      outline: none;
      background-image: none;
      overflow: hidden;
      color: var(--custom-white);

      option {
        border-radius: 25px;
        overflow: hidden;
        width: 32%;
        color: black;
        padding: 0.5rem;
        border-bottom: 1px solid var(--custom-input-bg);
        background: var(--custom-white);
      }
    }

    .actionButtonWrapper {
      button {
        background-color: transparent;
        border: 1px solid var(--custom-btn-bg);
        color: var(--custom-btn-bg);
      }
    }
  }

  .backbutton {
    position: absolute;
    top: 90%;

    button {
      background-color: transparent;
      border: 1px solid var(--custom-btn-bg);
      color: var(--custom-btn-bg);
      padding: 0.6rem 4rem;
    }
  }
`;


const headerData = [
    "FULL NAME",
    "CNIC",
    "GENDER",
    "CITY",
    "FATHER NAME",
    "MOBILE NO.",
    "ADDRESS",
];
export const ViewPersonalInfo = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const [data, setData] = useState(null)
    const navigate = useNavigate();
    const {id: studentID} = useParams()
    const [showSpinnerFetchingData, setShowSpinnerFetchingData] = useState(false)


    const fetchAndParseDataFromAPI = async () => {
        setShowSpinnerFetchingData(true)
        const endpoint = `/regional_head/students/${studentID}/detail/`
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response?.data)
            })
            .catch((err) => {
                notifyFailure("Error in fetching student personal info");
            });
        setShowSpinnerFetchingData(false)
    };

    const handleOnClickBack = () => {
        navigate("/regionalhead/students", {replace: true});
    };
    useEffect(() => {
        if (data) {
            setTableBodyData(
                [{
                    data: [
                        {
                            type: "text",
                            value: data?.full_name,
                        },
                        {
                            type: "text",
                            value: data?.account_data?.cnic,
                        },
                        {
                            type: "text",
                            value: data?.account_data?.gender,
                        },
                        {
                            type: "text",
                            value: data?.account_data?.city,
                        },
                        {
                            type: "text",
                            value: data?.account_data?.father_name,
                        },
                        {
                            type: "text",
                            value: data?.phone_number,
                        },
                        {
                            type: "text",
                            value: data?.account_data?.residential_address,
                        },
                    ],
                }
                ]
            );
        }
    }, [data]);


    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [studentID])

    return (
        <MainWrapper pageTitle={"PERSONAL INFORMATION"}>
            {!showSpinnerFetchingData ?
                (tableBodyData &&
                    <StyledComponent>
                        <TableComponent headerData={headerData} bodyData={tableBodyData}/>
                        <div className="backbutton">
                            <Button
                                fontSize={1}
                                borderRadius={2.4}
                                maxWidth={15}
                                paddingTopBottom={0.6}
                                onClick={handleOnClickBack}
                            >
                                BACK
                            </Button>
                        </div>
                    </StyledComponent>
                ) :
                <div className={'spinnerWrapper'}>
                    <Spinner size={2}/>
                </div>
            }
        </MainWrapper>
    );
};
