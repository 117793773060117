import { axiosServerInstance } from '../config/api/axios'
import moment from 'moment'
import { fetchUserProfile } from '../config/store/actions'
import { ACCOUNT_TYPES } from '../config/store/constants'

const accessTokenCheckExistence = () => {
    const access_token = window.localStorage.getItem('access_token')
    if (access_token === null) {
        window.localStorage.setItem('refresh_token', null)
        return false
    }
    return true
}

const checkIfUserContextIsValid = (userProfileContext) => {
    const { profile, lastTimeFetched, isFetchingProfile } = userProfileContext
    return (
        profile &&
        !isFetchingProfile &&
        lastTimeFetched &&
        moment().diff(lastTimeFetched, 'minutes', true) < 1
    )
}

export const isGuest = async function (userProfileContext, dispatch) {
    if (accessTokenCheckExistence()) {
        return await axiosServerInstance()
            .get('/auth/me/')
            .then((response) => false)
            .catch((err) => true)
    }
    return true
}

export const isAuthenticated = async function (userProfileContext, dispatch) {
    if (checkIfUserContextIsValid(userProfileContext)) {
        return true
    } else {
        if (accessTokenCheckExistence()) {
            return await axiosServerInstance()
                .get('/auth/me/')
                .then((response) => {
                    dispatch(fetchUserProfile)
                    return true
                })
                .catch((err) => false)
        }
        return false
    }
}

export const isEmailVerified = async function (userProfileContext, dispatch) {
    if (checkIfUserContextIsValid(userProfileContext)) {
        if (
            userProfileContext.profile?.account_type !== ACCOUNT_TYPES.STUDENT
        ) {
            return true
        } else {
            if (userProfileContext.profile?.email) {
                return userProfileContext.profile?.is_email_verified
            } else {
                return true
            }
        }
    } else {
        if (accessTokenCheckExistence()) {
            const userData = await axiosServerInstance()
                .get('/auth/me/')
                .then((response) => response.data)
                .catch((err) => null)
            if (userData?.account_type !== ACCOUNT_TYPES.STUDENT) {
                return true
            } else {
                if (userData?.email) {
                    return userData?.is_email_verified
                } else {
                    return true
                }
            }
        }
        return false
    }
}

export const isPhoneNumberVerified = async function (
    userProfileContext,
    dispatch
) {
    if (checkIfUserContextIsValid(userProfileContext)) {
        if (
            userProfileContext.profile?.account_type !== ACCOUNT_TYPES.STUDENT
        ) {
            return true
        } else {
            if (userProfileContext.profile?.phone_number) {
                return userProfileContext.profile?.is_phone_number_verified
            } else {
                return true
            }
        }
    } else {
        if (accessTokenCheckExistence()) {
            const userData = await axiosServerInstance()
                .get('/auth/me/')
                .then((response) => response.data)
                .catch((err) => null)
            if (userData?.account_type !== ACCOUNT_TYPES.STUDENT) {
                return true
            } else {
                if (userData?.phone_number) {
                    return userData?.is_phone_number_verified
                } else {
                    return true
                }
            }
        }
        return false
    }
}

export const studentHasCNICVerified = async function (
    userProfileContext,
    dispatch
) {
    if (checkIfUserContextIsValid(userProfileContext)) {
        if (userProfileContext.profile?.account_data?.is_existing) {
            return true
        }
        return (
            userProfileContext.profile?.account_type !==
                ACCOUNT_TYPES.STUDENT ||
            userProfileContext.profile?.account_data?.is_cnic_verified
        )
    } else {
        if (accessTokenCheckExistence()) {
            const userData = await axiosServerInstance()
                .get('/auth/me/')
                .then((response) => response.data)
                .catch((err) => null)
            if (userData?.account_data?.is_existing) {
                return true
            }
            return (
                userData?.account_type !== ACCOUNT_TYPES.STUDENT ||
                userData?.account_data?.is_cnic_verified
            )
        }
        return false
    }
}

export const authPagesConditions = {
    '/login': isAuthenticated,
    '/verifyphonenumber': isPhoneNumberVerified,
    '/verifyemail': isEmailVerified,
    '/verifycnic': studentHasCNICVerified,
}
