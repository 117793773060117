import { createContext, useEffect, useState } from "react";
import { MainWrapper } from "../../Global/MainWrapper";
import { InterviewListComponents } from "./components/InterviewList";

export const InterviewPROContainer = () => {
    return (
        <MainWrapper pageTitle={"Interview"}>
            <InterviewListComponents />
        </MainWrapper>
    );
};
