import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OneSubject } from "../../../../config/DataList/studentData/StudentData";
import { Button } from "../../../Global/Button";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { MainWrapper } from "../../../Global/MainWrapper";
import search from "../../../../static/images/table/Path 123.svg";
const StyledComponent = styled.div`
    .searchActions {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
    .searchOuter {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        border: 1px solid #ddd;
        border-radius: 2.5rem;
        background-color: #fff;
        height: 3rem;
        width: 100%;
        max-width: 33.9375rem;
        align-items: center;
        padding: 0 1rem;
        input {
            border: none;
            outline: none;
            width: 100%;
            background: transparent;
        }
    }
    .searchIcon {
        cursor: pointer;
    }
    .backbutton {
        position: absolute;
        top: 90%;
        button {
            background-color: transparent;
            border: 1px solid var(--custom-btn-bg);
            color: var(--custom-btn-bg);
            padding: 0.6rem 4rem;
        }
    }
`;
export const ViewCampus = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const [students, setStudents] = useState([]);
    const endpointQueryParam = useRef({});
    const [searchParam, setSearchParam] = useState("");
    const navigate = useNavigate();
    const fetchAndParseDataFromAPI = () => {};
    useEffect(() => {
        setStudents(OneSubject);
    }, []);

    const headerData = ["ID", "NAME", "CITY", "ADDRESS", "PHONE NUMBER"];

    const handleOnClickBack = () => {
        navigate(`/regionalhead/faculity`, { replace: true });
    };

    useEffect(() => {
        if (students) {
            setTableBodyData(
                students.map((data) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: data.SUBJECT,
                            },
                            {
                                type: "text",
                                value: data.TEACHER,
                            },
                            {
                                type: "text",
                                value: data.SUBJECT,
                            },
                            {
                                type: "text",
                                value: data.TEACHER,
                            },
                        ],
                    };
                })
            );
        }
    }, [students]);
    return (
        <MainWrapper pageTitle={"CAMPUS"}>
            <StyledComponent>
                <div className="searchActions">
                    <div className="selectwrapper"></div>
                    <div className="searchOuter">
                        <input
                            type="search"
                            placeholder="Search student by name, CNIC, city..."
                            value={searchParam}
                            onChange={(e) => {
                                setSearchParam(e.target.value);
                            }}
                        />
                        <img
                            src={search}
                            alt="search icon"
                            className={"searchIcon"}
                            onClick={() => {
                                if (searchParam) {
                                    endpointQueryParam.current = {
                                        ...endpointQueryParam,
                                        search: searchParam,
                                    };
                                } else {
                                    const tempData = {
                                        ...endpointQueryParam.current,
                                    };
                                    delete tempData["search"];
                                    endpointQueryParam.current = tempData;
                                }
                                fetchAndParseDataFromAPI();
                            }}
                        />
                    </div>
                </div>
                <TableComponent headerData={headerData} bodyData={tableBodyData} />
                <div className="backbutton">
                    <Button
                        fontSize={1}
                        borderRadius={2.4}
                        maxWidth={15}
                        paddingTopBottom={0.6}
                        onClick={handleOnClickBack}
                    >
                        BACK
                    </Button>
                </div>
            </StyledComponent>
        </MainWrapper>
    );
};
