export const MatricData = [
  {
    Update: "edit",
    ScholarshipMatric: "100",
    Subjects: "7",
    Marks: "90",
  },
  {
    Update: "edit",
    ScholarshipMatric: "100",
    Subjects: "7",
    Marks: "90",
  },
  {
    Update: "edit",
    ScholarshipMatric: "100",
    Subjects: "7",
    Marks: "90",
  },
  {
    Update: "edit",
    ScholarshipMatric: "100",
    Subjects: "7",
    Marks: "90",
  },
];
export const OLevelData = [
  {
    Update: "edit",
    GRADE: "6",
    ScholarshipOlevel: "100",
  },
  {
    Update: "edit",
    GRADE: "6",
    ScholarshipOlevel: "100",
  },
  {
    Update: "edit",
    GRADE: "6",
    ScholarshipOlevel: "100",
  },
  {
    Update: "edit",
    GRADE: "6",
    ScholarshipOlevel: "100",
  },
  {
    Update: "edit",
    GRADE: "6",
    ScholarshipOlevel: "100",
  },
];
