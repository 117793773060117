import styled from 'styled-components'
import { Navigate, useParams } from 'react-router-dom'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { GlobalUserProfileContext } from '../../App'
import { Spinner } from './graphical/Spinner'
import { BlankCenterCanvas } from './graphical/BlankCenterCanvas'
import { PageAccessStatusText } from './textual/PageAccessStatusText'
import { isAuthenticated } from '../../conditions'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserProfile } from '../../config/store/actions'

const StyledContainer = styled.div`
    padding: 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(231, 232, 236);
    align-items: center;
    justify-content: center;
`

export const ProtectedRouteComponent = ({
    conditions,
    elementToRenderOnPass,
    ComponentToRenderOnPass,
    profileRequired = true,
    roleBasedRouting = true,
    roleBasedRoutingFieldName,
    roleBasedRoutingElementMapping,
}) => {
    const [status, setStatus] = useState(null)
    const redirectPath = useRef('')
    const renderedOnce = useRef(false)
    const params = useParams()
    const userProfileContextData = useContext(GlobalUserProfileContext)
    const { isFetchingProfile: isFetching, profile } = userProfileContextData
    const dispatch = useDispatch()
    if (
        roleBasedRouting &&
        roleBasedRoutingFieldName &&
        roleBasedRoutingElementMapping
    ) {
        profileRequired = false
    }

    const { should_verify_phone_number } = useSelector(
        state => state.phone_number
    )
    const [state, updateState] = useState('')
    const forceUpdate = useCallback(() => updateState({}), [])

    useEffect(() => {
        forceUpdate()
    }, [should_verify_phone_number])

    useEffect(() => {
        const evaluateCondition = async () => {
            let tempStatus = true
            for (let key of Object.keys(conditions)) {
                const condition = conditions[key]
                if (typeof condition === 'function') {
                    if (!(await condition(userProfileContextData, dispatch))) {
                        redirectPath.current = key

                        if (key === '/verifyphonenumber') {
                            if (should_verify_phone_number.val) {
                                tempStatus = false
                                break
                            } else {
                                tempStatus = true
                                break
                            }
                        }
                        tempStatus = false
                        break
                    }
                } else if (typeof condition === 'boolean') {
                    if (!condition) {
                        redirectPath.current = key
                        tempStatus = false
                        break
                    }
                }
            }

            setStatus(tempStatus)
        }
        if (!renderedOnce.current) {
            renderedOnce.current = true
            evaluateCondition()
        }
    }, [should_verify_phone_number])

    useEffect(() => {
        if (typeof status === 'boolean') {
            renderedOnce.current = false
        }
    }, [status])

    return typeof status === 'boolean' &&
        (!profileRequired ||
            (profileRequired && profile) ||
            (profileRequired && !profile && !isFetching)) ? (
        status ? (
            profileRequired && !profile ? (
                <BlankCenterCanvas>
                    <PageAccessStatusText>
                        You do not currently have access to this page
                    </PageAccessStatusText>
                </BlankCenterCanvas>
            ) : roleBasedRouting &&
              roleBasedRoutingFieldName &&
              roleBasedRoutingElementMapping ? (
                roleBasedRoutingElementMapping[
                    profile[roleBasedRoutingFieldName]
                ]
            ) : ComponentToRenderOnPass ? (
                <ComponentToRenderOnPass {...params} />
            ) : (
                elementToRenderOnPass
            )
        ) : (
            <Navigate to={redirectPath.current} replace={true} />
        )
    ) : (
        <StyledContainer>
            <Spinner size={2.0} />
        </StyledContainer>
    )
}
