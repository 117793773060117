import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { AcamedinInformationContainer } from './components/academicInformation/Container'
import { ChallanFormContainer } from './components/challanForm/Container'
import { Personalinformation } from './components/personalInformation/PersonalInformation'
import { PreferencesContainer } from './components/preferences/Container'
import { StatusDetailContainer } from './components/StatusDetail/Container'
import { StudentActivityContainer } from './components/studentActivity/Container'
import { GlobalUserProfileContext } from '../../../App'
import { MainWrapper } from '../../Global/MainWrapper'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PRO_SET_STUDENT_CURRENT_PROFILE_STATE } from '../../../config/store/constants'

const StyledComponent = styled.div`
    .DetailRadioMode {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: left;
        @media (max-width: 1360px) {
            ${'' /* justify-content: center; */}
            gap: 1rem;
        }

        .RadioOuter {
            display: flex;
            @media (max-width: 500px) {
                width: 100%;
            }
        }

        input {
            display: none;
        }

        label.Active {
            color: var(--custom-white);
            background-color: var(--custom-btn-bg);
        }

        label {
            border: 1px solid var(--custom-btn-bg);
            background-color: var(--custom-white);
            color: var(--custom-btn-bg);
            border-radius: 2.5rem;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            @media (max-width: 1493px) {
                font-size: 0.85rem;
            }
            @media (max-width: 1446px) {
                font-size: 0.8rem;
            }
            @media (max-width: 500px) {
                font-size: 0.8rem;
            }
        }
    }

    .HiddenContent {
        margin-top: 1rem;

        .helperText {
            text-align: center;
        }
    }
`
export const StudentDetailContainer = () => {
    const { targetPage } = useContext(GlobalUserProfileContext)
    const { id: studentID } = useParams()
    const currentModeStates = useSelector(
        selector => selector.PROStudentCurrentProfileStateReducer?.states
    )
    const [mode, setMode] = useState(
        currentModeStates[studentID] || 'STATUS DETAILS'
    )
    const dispatch = useDispatch()

    let RadioData = [
        { value: 'PERSONAL INFORMATION' },
        { value: 'ACADEMIC INFORMATION' },
        { value: 'PREFERENCES' },
        { value: 'STATUS DETAILS' },
        { value: 'FEE MANAGEMENT' },
        { value: 'STUDENT ACTIVITY' },
    ]
    const onChangeValue = event => {
        setMode(event.target.value)
        const payloadForDispatch = {}
        payloadForDispatch[studentID] = event.target.value
        dispatch({
            type: PRO_SET_STUDENT_CURRENT_PROFILE_STATE,
            payload: payloadForDispatch,
        })
    }
    useEffect(() => {
        targetPage && setMode(targetPage)
    }, [targetPage])

    return (
        <MainWrapper pageTitle={'STUDENT DETAILS'}>
            <StyledComponent>
                <div className='formfieldInner'>
                    <div className='DetailRadioMode' onChange={onChangeValue}>
                        {RadioData.map((item, i) => {
                            return (
                                <div key={i} className='RadioOuter'>
                                    <input
                                        type='radio'
                                        value={item.value}
                                        name={item.value}
                                        id={item.value}
                                        checked={mode === item.value}
                                    />
                                    <label
                                        htmlFor={item.value}
                                        className={
                                            mode === item.value
                                                ? 'Active'
                                                : null
                                        }
                                    >
                                        {item.value}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='HiddenContent'>
                    {mode === 'FEE MANAGEMENT' ? (
                        <ChallanFormContainer />
                    ) : mode === 'STATUS DETAILS' ? (
                        <StatusDetailContainer />
                    ) : mode === 'PERSONAL INFORMATION' ? (
                        <Personalinformation />
                    ) : mode === 'ACADEMIC INFORMATION' ? (
                        <AcamedinInformationContainer />
                    ) : mode === 'PREFERENCES' ? (
                        <PreferencesContainer />
                    ) : mode === 'STUDENT ACTIVITY' ? (
                        <StudentActivityContainer />
                    ) : (
                        <ChallanFormContainer />
                    )}
                </div>
            </StyledComponent>
        </MainWrapper>
    )
}
