import React from "react";
import StepSix from "./Step6";

const StepSixContainer = () => {
  return (
    <>
      <StepSix />
    </>
  );
};

export default StepSixContainer;
