import React from 'react'
import styled from 'styled-components'
import { FormFieldsWrapper } from '../../../graphical/data_input/FormFieldsWrapper'
import Tick from '../../../../../static/images/table/Page-1.svg'
import { Button } from '../../../Button'
import { useNavigate } from 'react-router-dom'

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: 0 10px 10px -3px #cfcfcf;
    padding: 3rem 0 4rem;
    position: relative;
    @media (max-width: 700px) {
        padding: 3rem;
    }
    @media (max-width: 500px) {
        padding: 1rem;
    }
    .formFieldWrapper {
        display: flex;
        justify-content: center;
        gap: 2rem;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;

        .thankYouformfieldInner {
            width: 100%;
            display: flex;

            flex-direction: column;
            gap: 1rem;
            align-items: center;
            .thankYouTitle {
                font-size: 22px;
                display: flex;
                justify-content: center;
                @media (max-width: 500px) {
                    font-size: 18px;
                }
            }
            .thankYouDetail {
                font-size: 1rem;
                text-align: center;
                display: flex;
                justify-content: center;
            }
            .icon {
                width: 60px;
                height: 60px;
                background-color: #91c869;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .thankYouBtn {
                margin-top: 2rem;
                text-align: center;
            }
        }
    }
`
const StepSix = () => {
    const navigate = useNavigate()
    return (
        <StyledComponent>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="thankYouformfieldInner">
                    <div className="icon">
                        <img src={Tick} alt="icon ok" />
                    </div>
                    <h2 className="thankYouTitle">Submitted</h2>
                    <p className="thankYouDetail">
                        Thank you for applying to Beaconhouse College programme.
                        Our school representative will contact your shortly.
                    </p>
                    <div className="thankYouBtn">
                        <Button
                            fontSize={0.9}
                            maxWidth={14}
                            borderRadius={2.4}
                            paddingTopBottom={0.6}
                            paddingLeftRight={2.5}
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/')
                            }}
                        >
                            GO BACK
                        </Button>
                    </div>
                </div>
            </FormFieldsWrapper>
        </StyledComponent>
    )
}
export default StepSix
