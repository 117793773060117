import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import {FormFieldsWrapper} from "../../../Global/graphical/data_input/FormFieldsWrapper";
import {InputComponent} from "../../../Authentication/components/InputELement";
import {Button} from "../../../Global/Button";
import {OLevelTable} from "./OLevelTable";
import {handleOnInputChange} from "../../../../helper/form_inputs/handleOnInputChange";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifySuccess} from "../../../../helper/notifications/notifySuccess";
import {Spinner} from "../../../Global/graphical/Spinner";
import {SizedBox} from "../../../Global/SizedBox";
import {notifyApiErrorMessage} from "../../../../helper/notifications/notifyApiErrorMessage";

const StyledComponent = styled.div`
  width: 50%;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;
const validatorLabels = {
    "total_distinction_count": "Total Grades (A/A*): ",
    "percentage": 'Scholarship (%)'
}
export const OLevel = () => {
    const [newCriteriaData, setNewCriteriaData] = useState({})
    const [showSpinner, setShowSpinner] = useState(false)
    const [showSpinnerIsFetchingData, setShowSpinnerIsFetchingData] = useState(false)
    const [data, setData] = useState([])


    const fetchData = async () => {
        setShowSpinnerIsFetchingData(true)
        const endpoint = `/shared/scholarship_criterias/`
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response?.data?.results)
            })
            .catch((err) => {
                notifyFailure("Error in fetching all students");
            });
        setShowSpinnerIsFetchingData(false)
    };

    useEffect(() => {
        fetchData()
    }, [])

    const validateAddSubject = () => {
        for (let field of Object.keys(validatorLabels)) {
            if (!newCriteriaData[field]) {
                notifyFailure(validatorLabels[field] + ' is required')
                return false
            }
        }
        return true
    }


    const handleOnClickAddSubject = async () => {
        if (validateAddSubject()) {
            setShowSpinner(true)
            const endpoint = `/shared/scholarship_criterias/`
            await axiosServerInstance()
                .post(endpoint, newCriteriaData)
                .then((response) => {
                    notifySuccess('Operation Success')
                    fetchData()
                })
                .catch((err) => {
                    notifyFailure("Error in adding new scholarship criteria");
                    notifyApiErrorMessage(err)
                });
            setShowSpinner(false)
        }
    }

    return (
        <StyledComponent>
            <h2 className="title">O LEVEL</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-32">
                        <label>Total Grades (A/A*): </label>
                        <InputComponent
                            placeholder={""}
                            type="text"
                            fontSize={1}
                            value={newCriteriaData?.total_distinction_count}
                            onChange={(e) => {
                                handleOnInputChange(e, "total_distinction_count", setNewCriteriaData, newCriteriaData);
                            }}
                        />
                    </div>
                    <div className="inputOuter w-32">
                        <label>Scholarship (%)</label>
                        <InputComponent
                            placeholder={""}
                            type="text"
                            fontSize={1}
                            value={newCriteriaData?.percentage}
                            onChange={(e) => {
                                handleOnInputChange(e, "percentage", setNewCriteriaData, newCriteriaData);
                            }}
                        />
                    </div>

                    {!showSpinner ?
                        <Button
                            fontSize={1}
                            borderRadius={2.4}
                            maxWidth={5}
                            height={3.1}
                            paddingTopBottom={0.8}
                            onClick={handleOnClickAddSubject}
                        >
                            +ADD
                        </Button>
                        :
                        <Spinner size={2}/>
                    }
                </div>
            </FormFieldsWrapper>
            {!showSpinnerIsFetchingData ?
                <div className="table">
                    <OLevelTable data={data} fetchData={fetchData}/>
                </div>
                :
                <div className='spinnerWrapper'>
                    <SizedBox height={1}/>
                    <Spinner size={2}/>
                    <SizedBox height={1}/>
                </div>
            }
        </StyledComponent>
    );
};
