import styled from "styled-components";
import {Link} from "react-router-dom";
import logoutImage from "../../../static/images/navigation/_icons.svg";
import {GlobalUserProfileContext} from "../../../App";
import {useContext} from "react";

const StyledComponents = styled.div`
  padding: 1.5rem 0rem;
  font-family: var(--font-1);
  width: 100%;
  background-color: var(--custom-btn-bg);
  display: flex;
  justify-content: center;
  margin-top: auto;
  justify-self: flex-end;

  .footerLogout {
    a {
      letter-spacing: 0.3px;
      color: var(--custom-white);
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      text-transform: uppercase;

      img {
        width: 1.6rem;
        height: 1.7rem;
        object-fit: contain;
      }
    }
  }
`;

export const FooterContainer = () => {

    return (
        <StyledComponents>
            <div className="footerLogout">
                <Link to="/logout" className="logout-button">
                    <img src={logoutImage} alt="logout"/>
                    &nbsp; Log Out
                </Link>
            </div>
        </StyledComponents>
    );
};
