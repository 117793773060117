import { useState } from "react";
import { AuthMainContainer } from "../components/AuthMainContainer";
import { LoginRolesForm } from "./LoginRoles";
import { useLocation } from "react-router-dom";

const LoginRolesContainer = () => {
    const [renderPage, setRenderPage] = useState(true);
    const location = useLocation();

    return (
        <AuthMainContainer mode={renderPage ? "login" : null}>
            <LoginRolesForm renderPage={renderPage} setRenderPage={setRenderPage} />
        </AuthMainContainer>
    );
};

export default LoginRolesContainer;
