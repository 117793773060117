import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useRef,
} from 'react'
import styled from 'styled-components'
import { FormFieldsWrapper } from '../../../graphical/data_input/FormFieldsWrapper'
import { InputComponent } from '../../../../Authentication/components/InputELement'
import { SizedBox } from '../../../SizedBox'
import { ImagePickerComponent } from '../../../graphical/data_input/ProfilePicturePickerComponent'
import { useEffect, useState } from 'react'
import { GlobalUserProfileContext } from '../../../../../App'
import { MultiStepFormContext } from '../../Container'
import { notifyFailure } from '../../../../../helper/notifications/notifyFailure'
import { handleOnInputChange } from '../../../../../helper/form_inputs/handleOnInputChange'
import {
    FETCH_USER_PROFILE_SUCCESS,
    FILE_UPLOAD_PURPOSE,
    GENDER_TYPES,
    SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION,
    VERIFY_PHONE_NUMBER_FALSE,
    VERIFY_PHONE_NUMBER_TRUE,
} from '../../../../../config/store/constants'
import { multiStepFormValidator } from '../../../../../helper/form_validators/multiStepFormValidator'
import { parseEnumToSelectValues } from '../../../../../helper/form_inputs/parseEnumToSelectValues'
import { axiosServerInstance } from '../../../../../config/api/axios'
import { notifySuccess } from '../../../../../helper/notifications/notifySuccess'
import { notifyApiErrorMessage } from '../../../../../helper/notifications/notifyApiErrorMessage'
import { useDispatch } from 'react-redux'
import { fetchUserProfile } from '../../../../../config/store/actions'
import { handleFileUploadSingle } from '../../../../../helper/fileManagement/handler'
import { validatePhoneNumber } from '../../../../../helper/form_validators'
import moment from 'moment'

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: 0 10px 10px -3px #cfcfcf;
    padding: 3rem;
    @media (max-width: 500px) {
        padding: 1rem;
    }

    .disabled {
        cursor: none;
    }

    .profilePicturePickerWrapper {
        padding: 0 1.5rem;
    }

    .profilePicturePickerWrapper > div {
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;

        .controlsWrapperImage .text {
            ${'' /* display: none; */}
            color: var(--custom-btn-bg);
        }

        .controlsWrapperImage button {
            background-color: #fff;
            border: none;
            color: var(--custom-btn-bg);

            padding: 0.5rem 1rem;
            border-radius: 2.5rem;
            border: 1px solid var(--custom-btn-bg);
        }

        .imageWrapper {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            .inputOuter {
                width: 32%;
                @media (max-width: 1445px) {
                    width: 31.5%;
                }
                @media (max-width: 1423px) {
                    width: 48.5%;
                }
                @media (max-width: 1203px) {
                    width: 48%;
                }

                @media (max-width: 1180px) {
                    width: 100%;
                }
            }

            .textarea {
                width: 66%;
                @media (max-width: 1423px) {
                    width: 100%;
                }
            }
        }

        .actionButtonWrapper {
            align-self: end;
            justify-self: end;
        }

        select {
            width: 100%;
            height: 3rem;
            text-indent: 10px;
            background: var(--custom-input-bg);
            border-radius: 25px;
            outline: none;
            background-image: none;
            overflow: hidden;
            border: 1px solid var(--custom-input-border);

            option {
                border-radius: 25px;
                width: 32%;
                background: var(--custom-input-bg);
            }
        }
    }
`

const dataFieldsMapping = {
    full_name: 'Full Name',
    cnic: 'CNIC',
    gender: 'Gender',
    city: 'City',
    father_name: 'Guardian Name',
    parent_cnic: 'Parent CNIC',
    phone_number: 'Student Phone Number',
    parent_phone_number: 'Parent Phone Number',
    residential_address: 'Residential Address',
    dob: 'Date of Birth',
    picture: data => {
        if (
            !data?.picture?.to_be_uploaded_buffer &&
            !data?.picture?.url_on_server
        ) {
            return {
                status: false,
                message: 'Kindly choose a profile picture',
            }
        }
        return {
            status: true,
        }
    },
    phone_numbers: data => {
        if (data?.parent_phone_number && data?.phone_number) {
            let response = validatePhoneNumber(data?.parent_phone_number)
            if (!response[0]) {
                return {
                    status: false,
                    message: 'Parent ' + response[1][0],
                }
            }
            response = validatePhoneNumber(data?.phone_number)
            if (!response[0]) {
                return {
                    status: false,
                    message: 'Student ' + response[1][0],
                }
            }
            if (data.parent_phone_number === data.phone_number) {
                const message =
                    "parent phone number and student phone number can't be the same."
                return {
                    status: false,
                    message,
                }
            }
            return {
                status: true,
            }
        }
    },
}

const StepOneContainer = forwardRef(({ jumpToStep }, ref) => {
    const currentStep = useRef(1)
    const [data, setData] = useState({})
    const [files, setFiles] = useState({})
    const { profile } = useContext(GlobalUserProfileContext)
    const dispatch = useDispatch()
    const fetchAndParseDataFromAPI = () => {
        const tempData = {
            full_name: profile?.full_name,
            cnic: profile?.account_data?.cnic,
            parent_cnic: profile?.account_data?.parent_cnic,
            gender: profile?.account_data?.gender,
            city: profile?.account_data?.city,
            father_name: profile?.account_data?.father_name,
            parent_phone_number: profile?.account_data?.parent_phone_number,
            phone_number: profile?.phone_number,
            residential_address: profile?.account_data?.residential_address,
            dob: profile?.account_data?.dob,
        }
        const pictureData = {
            url_on_server: profile?.picture?.file,
        }
        setData(tempData)
        setFiles({
            ...files,
            picture: pictureData,
        })
    }

    const computeDataAndSendToServer = async () => {
        const profileData = {
            full_name: data?.full_name,
            phone_number: data?.phone_number,
        }
        const profileDataEndpoint = '/auth/me/'
        let profileUpdateStatus = false
        const accountData = {
            cnic: data?.cnic,
            parent_cnic: data?.parent_cnic,
            dob: data?.dob,
            gender: data?.gender,
            city: data?.city,
            father_name: data?.father_name,
            residential_address: data?.residential_address,
            parent_phone_number: data?.parent_phone_number,
        }
        const accountDataEndpoint = '/student/admission/information'
        let accountDataUpdateStatus = false
        await axiosServerInstance()
            .patch(profileDataEndpoint, profileData)
            .then(response => {
                profileUpdateStatus = true
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
        if (profileUpdateStatus) {
            await axiosServerInstance()
                .patch(accountDataEndpoint, accountData)
                .then(response => {
                    accountDataUpdateStatus = true
                })
                .catch(err => {
                    notifyApiErrorMessage(err)
                })
        }
        if (accountDataUpdateStatus && profileUpdateStatus) {
            if (files?.picture) {
                const temp = {
                    ...files?.picture,
                    to_be_deleted_file_id: profile?.picture?.id,
                }
                if (temp?.to_be_uploaded_buffer) {
                    const uploadedFileData = await handleFileUploadSingle(temp)
                    await axiosServerInstance()
                        .patch(profileDataEndpoint, {
                            picture: uploadedFileData?.id,
                        })
                        .then(response => {})
                        .catch(err => {
                            notifyApiErrorMessage(err)
                            profileUpdateStatus = false
                        })
                }
            }
        }
        dispatch(fetchUserProfile)

        /////////////////////////////
        ///////////////////////////////////////////

        if (data.phone_number !== profile?.phone_number) {
            await axiosServerInstance()
                .patch('/student/admission/information', {
                    phone_number: data.phone_number,
                })
                .then(({ data }) => {
                    console.log('updated prof', data)
                })
                .catch(err => {
                    notifyApiErrorMessage(err)
                    profileUpdateStatus = false
                })
        }
        //
        dispatch({
            type: SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION,
            payload: '/admission_information',
        })
        if (
            accountDataUpdateStatus &&
            profileUpdateStatus &&
            profile?.account_data?.admission_application_stage < 6
        ) {
            await axiosServerInstance()
                .patch(accountDataEndpoint, {
                    admission_application_stage: currentStep.current + 1,
                })
                .then(response => {
                    accountDataUpdateStatus = true

                    dispatch({
                        type: VERIFY_PHONE_NUMBER_TRUE,
                    })
                })
                .catch(err => {
                    notifyApiErrorMessage(err)
                    accountDataUpdateStatus = false
                })
        }
        return accountDataUpdateStatus && profileUpdateStatus
    }
    //

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [])

    useImperativeHandle(ref, () => ({
        async isValidated() {
            const { status, message } = multiStepFormValidator(
                dataFieldsMapping,
                data
            )
            if (!status) {
                notifyFailure(message)
            } else {
                return await computeDataAndSendToServer()
            }
            return status
        },
    }))

    useEffect(() => {}, [data.phone_number])

    useEffect(() => {
        if (files?.picture) {
            setData({
                ...data,
                picture: files?.picture,
            })
        }
    }, [files])

    return (
        <StyledComponent>
            <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
                <div className='formfieldInner'>
                    <div className='inputOuter'>
                        <label>Full Name</label>
                        <InputComponent
                            placeholder={'Enter Your Full Name'}
                            type='text'
                            fontSize={1}
                            required={true}
                            value={data?.full_name}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'full_name',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter'>
                        <label>CNIC</label>
                        <InputComponent
                            placeholder={'xxxxx-xxxxxxx-x'}
                            type='text'
                            fontSize={1}
                            required={true}
                            value={data?.cnic}
                            onChange={e => {
                                handleOnInputChange(e, 'cnic', setData, data)
                            }}
                        />
                    </div>
                    <div className='inputOuter'>
                        <label>Gender</label>
                        <select
                            value={data?.gender}
                            onChange={e =>
                                handleOnInputChange(e, 'gender', setData, data)
                            }
                        >
                            {parseEnumToSelectValues(GENDER_TYPES).map(type => (
                                <option
                                    selected={type === data?.gender}
                                    value={type}
                                >
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='inputOuter'>
                        <label>City</label>
                        <InputComponent
                            placeholder={'Enter You City Name'}
                            type='text'
                            fontSize={1}
                            value={data?.city}
                            onChange={e => {
                                handleOnInputChange(e, 'city', setData, data)
                            }}
                        />
                    </div>
                    <div className='inputOuter'>
                        <label>Guardian Name</label>
                        <InputComponent
                            placeholder={'Enter Your Father/Guardian Name '}
                            type='text'
                            fontSize={1}
                            value={data?.father_name}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'father_name',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter'>
                        <label>Parent CNIC</label>
                        <InputComponent
                            placeholder={'xxxxx-xxxxxxx-x'}
                            type='text'
                            fontSize={1}
                            value={data?.parent_cnic}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'parent_cnic',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter'>
                        <label>Student Phone Number</label>
                        <InputComponent
                            placeholder={'92xxxxxxxxxx'}
                            type='tel'
                            fontSize={1}
                            value={data?.phone_number}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'phone_number',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter '>
                        <label>Parent Phone Number</label>
                        <InputComponent
                            placeholder={'92xxxxxxxxxx'}
                            type='tel'
                            fontSize={1}
                            value={data?.parent_phone_number}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'parent_phone_number',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter '>
                        <label>Date of Birth</label>
                        <InputComponent
                            type='date'
                            fontSize={1}
                            value={data?.dob}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'dob',
                                    setData,
                                    data,
                                    'value',
                                    value => moment(value).format('YYYY-MM-DD')
                                )
                            }}
                        />
                    </div>
                    <div className='inputOuter textarea'>
                        <label>Residential Address</label>
                        <InputComponent
                            placeholder={'Enter Your Address Here.'}
                            type='text'
                            fontSize={1}
                            value={data?.residential_address}
                            onChange={e => {
                                handleOnInputChange(
                                    e,
                                    'residential_address',
                                    setData,
                                    data
                                )
                            }}
                        />
                    </div>
                </div>
                <div className='profilePicturePickerWrapper'>
                    <ImagePickerComponent
                        image={files?.picture?.url_on_server}
                        field_name={'picture'}
                        purpose={FILE_UPLOAD_PURPOSE.PROFILE_PICTURE}
                        label={' Profile Image'}
                        setFiles={setFiles}
                    />
                    <SizedBox height={3} />
                </div>
            </FormFieldsWrapper>
        </StyledComponent>
    )
})

export default StepOneContainer
