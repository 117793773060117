import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { axiosServerInstance } from "../../../../config/api/axios";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { Spinner } from "../../../Global/graphical/Spinner";
import { formatQueryDictToQueryParams } from "../../../../helper/formatters/formatDictToParams";

const StyledComponent = styled.div`
  width: 100%;
`;

const headerData = [
  "STUDENT ID",
  "STUDENT NAME",
  "BSS/NON BSS",
  "DEGREE",
  "PERCENTAGE TOTAL A/A*",
  "SCHOLARSHIP OFFERED",
  "SUBJECT/TEACHER",
];
export const DetailTable = ({ filterData, batchFilter }) => {
  const [tableBodyData, setTableBodyData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const navigate = useNavigate();
  const onClickView1 = async (id) => {
    setShowSpinner(true);
    navigate(`/regionalhead/scholarship/${id}/`, { replace: true });
    setShowSpinner(false);
  };
  const [data, setData] = useState([]);
  const fetchAndParseDataFromAPI = async () => {
    const endpoint = `/regional_head/students/all/?${formatQueryDictToQueryParams(
      { ...filterData }
    )}&account_data__batch=${batchFilter.batch}`;
    setShowSpinner(true);
    axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setData(response.data?.results);
        setShowSpinner(false);
      })
      .catch((err) => {
        notifyFailure("Error in fetching students details");
        setShowSpinner(false);
        notifyApiErrorMessage(err);
      });
  };
  useEffect(() => {
    fetchAndParseDataFromAPI();
  }, [filterData, batchFilter]);

  useEffect(() => {
    if (data) {
      setTableBodyData(
        data.map((item, index) => {
          return {
            id: item?.id,
            data: [
              {
                type: "text",
                value: item?.identifier,
              },
              {
                type: "text",
                value: item?.full_name,
              },
              {
                type: "text",
                value: item?.account_data?.is_existing ? "BSS" : "NON BSS",
              },
              {
                type: "text",
                value: item?.academic_degree?.degree_name,
              },

              {
                type: "text",
                value:
                  item?.extra_data?.total_distinction_percentage &&
                  Number.parseInt(
                    item?.extra_data?.total_distinction_percentage
                  ) + "%", // No PERCENTAGE TOTAL from API yet!
              },
              {
                type: "text",
                value:
                  item?.fee_scholarship?.scholarship_percentage &&
                  Number.parseInt(
                    item?.fee_scholarship?.scholarship_percentage
                  ) + "%", // No PERCENTAGE TOTAL from API yet!
              },
              {
                type: "view1",
                value: onClickView1,
              },
            ],
          };
        })
      );
    }
  }, [data]);

  return (
    <StyledComponent>
      {!showSpinner ? (
        <TableComponent
          headerData={headerData}
          bodyData={tableBodyData}
          actionsView1={{
            VIEW: onClickView1,
          }}
        />
      ) : (
        <div className='infoWrapper'>
          <Spinner />
        </div>
      )}
    </StyledComponent>
  );
};
