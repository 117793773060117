
export const TableHeader = ({ HeaderData }) => {
  return (
      <tr>
        {HeaderData.map((key, index) => {
          return <th key={index}>{key.toUpperCase()}</th>;
        })}
      </tr>
  );
};
