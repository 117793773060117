import {Button} from "../Button";
import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {Spinner} from "../graphical/Spinner";


const StyledComponent = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0rem 0 0rem;
    
    .spinnerWrapper{
        width: 150px;
        text-align: center;
    }
`;

export const RefreshAuthUserAffiliatedObjectsButtonComponent = ({callBackOnComplete}) => {
    const [isProcessing, setIsProcessing] = useState(null)
    const handleOnClickRefresh = useCallback(async () => {
        setIsProcessing(true)
        await axiosServerInstance()
            .post(`/shared/campuses/subject_teachers_record/refresh/`)
            .then(response => {
                notifySuccess('Records refreshed/updated successfully')
                callBackOnComplete()
            })
            .catch((err) => {
                notifyFailure("Error in refreshing records");
            });
        setIsProcessing(false)
    }, [isProcessing]);
    return (
        <StyledComponent>
            {isProcessing === true ?
                <div className='spinnerWrapper'><Spinner size={1.5}/></div>
                :
                <Button
                    fontSize={1}
                    borderRadius={2.4}
                    maxWidth={10}
                    paddingTopBottom={0.6}
                    paddingLeftRight={1.5}
                    onClick={handleOnClickRefresh}
                >
                    REFRESH
                </Button>
            }
        </StyledComponent>
    )
}