export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawOnChartArea: false,
        color: "#3f3f3f",
        borderColor: "#3f3f3f",
      },
      backdropColor: "rgba(255, 255, 255, 0.75)",
    },

    y: {
      grid: {
        display: false,
      },
    },
  },
};
