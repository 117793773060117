import styled from "styled-components";

const Wrapper = styled.button`
    margin-top: ${({ marginTop }) => marginTop}rem;
    background-color: var(
        --custom-${({ BgColor, inverted }) => (inverted ? "none" : BgColor || "btn-bg")}
    );
    text-align: center;
    font-size: ${({ fontSize }) => fontSize}rem;
    padding: ${({ paddingTopBottom }) => paddingTopBottom || 0.2}rem
        ${({ paddingLeftRight }) => paddingLeftRight || 1}rem;
    border-radius: ${({ borderRadius }) => borderRadius}rem;
    text-transform: ${(props) => (props.textTransform ? `${props.textTransform}` : "inherit")};
    outline: none;
    border: 2px solid var(--custom-${({ border }) => border || "btn-bg"});
    width: 100%;
    font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : "500")};
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : "initial")};
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}rem` : "initial")};
    height: ${(props) => (props.height ? `${props.height}rem` : "initial")};
    transition: all 0.25s ease-out;
    color: var(--custom-${({ inverted }) => (inverted ? "Black" : "white")});
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    @media (max-width: 500px) {
        font-size: 0.85rem;
        padding: 0.4rem 1rem;
        height: auto;
    }

    a {
        color: white;
        display: inline-block;
        width: 100%;
    }

    :hover {
        box-shadow: ${({ addEffect }) => (addEffect ? "0 0 5px var(--custom-orange)" : "null")};
    }
`;

export const Button = ({
    marginTop = 0,
    fontSize = 1,
    addEffect = false,
    paddingTopBottom,
    paddingLeftRight,
    textTransform,
    fontWeight,
    minWidth,
    maxWidth,
    borderRadius,
    height,
    border,
    children,
    onClick,
    BgColor,
    inverted = false,
    disabled = false,
}) => (
    <Wrapper
        inverted={inverted || disabled}
        paddingTopBottom={paddingTopBottom}
        paddingLeftRight={paddingLeftRight}
        disabled={disabled}
        marginTop={marginTop}
        fontSize={fontSize}
        BgColor={BgColor}
        border={border}
        borderRadius={borderRadius}
        textTransform={textTransform}
        onClick={(e) => {
            if (disabled) {
                e.preventDefault();
            } else {
                onClick(e);
            }
        }}
        minWidth={minWidth}
        fontWeight={fontWeight}
        height={height}
        maxWidth={maxWidth}
        addEffect={addEffect}
    >
        {children}
    </Wrapper>
);
