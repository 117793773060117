import styled from "styled-components";
import { useEffect, useState } from "react";
import { TableComponent } from "../../Global/graphical/table/Container";
import { useContext } from "react";
import { GlobalUserProfileContext } from "../../../App";
import { formatBatch } from "../../../helper/formatters/formatBatch";

const StyledComponent = styled.div`
  .extra-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tablevms thead th:last-child {
    border-radius: 2.5rem !important;
  }
`;
export const BatchList = () => {
  const [tableBodyData, setTableBodyData] = useState([]);
  const headerData = ["Batch"];

  const { profile } = useContext(GlobalUserProfileContext);

  useEffect(() => {
    setTableBodyData([
      {
        data: [
          {
            type: "text",
            value: formatBatch(profile?.account_data?.batch?.first_year),
          },
        ],
      },
    ]);
  }, [profile]);

  return (
    <StyledComponent>
      <TableComponent headerData={headerData} bodyData={tableBodyData} />
    </StyledComponent>
  );
};
