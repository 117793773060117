import { useContext, useEffect, useState } from "react";
import { TableComponent } from "../../Global/graphical/table/Container";
import { EditSAcademic } from "./EditAcademic";
import { ModalComponent } from "../../Global/graphical/Modal";
import { AcademicInformationContext } from "../Container";
import { STUDENT_DEGREE_TYPES } from "../../../config/store/constants";

export const AcademicList = ({ handleEdit }) => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const { data, files } = useContext(AcademicInformationContext);

    const headerData = [
        "Degree Name",
        "Institution",
        "Subject Name",
        "Grade",
        "Degree Result",
        "",
    ];

    useEffect(() => {
        if (data) {
            setTableBodyData(
                [data].map((item) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value:
                                    data.degree_name ===
                                    STUDENT_DEGREE_TYPES.OTHERS
                                        ? data.degree_label
                                        : data.degree_name,
                            },
                            {
                                type: "list",
                                value: (data?.institutions || []).map(
                                    (item) => item?.name
                                ),
                            },
                            {
                                type: "list",
                                value: (
                                    data?.result_announced?.subjects || []
                                ).map((item) => item?.name),
                            },

                            {
                                type: "list",
                                value: (
                                    data?.result_announced?.subjects || []
                                ).map((item) => item?.grade),
                            },
                            {
                                type: "file",
                                value:
                                    data?.result_announced?.uploaded_result ||
                                    [],
                            },
                            {
                                type: "edit",
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);
    return (
        <TableComponent
            headerData={headerData}
            bodyData={tableBodyData}
            actionEdit={{
                edit: (id) => {
                    handleEdit();
                },
            }}
        />
    );
};
