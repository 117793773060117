import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import {useLocation, useNavigate} from "react-router-dom";

import {Spinner} from "../../Global/graphical/Spinner";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {fetchUserProfile} from "../../../config/store/actions";
import {notifyApiSuccessMessage} from "../../../helper/notifications/notifyApiSuccessMessage";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import React, {useEffect, useState} from "react";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {useDispatch} from "react-redux";

export const LoginBssForm = ({renderPage, setRenderPage}) => {
    const [data, setData] = useState({});
    const [bssToken, setBssToken] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const validateFields = () => {
        let state = true;
        const fields = ["bss_email"];
        for (let field of fields) {
            if (!data[field]) {
                notifyFailure(`${field} is required`);
                state = false;
            }
        }
        return state;
    };

    const handleProceedLoginStep1 = async () => {
        if (!validateFields()) {
            return;
        }
        const endpoint = "/auth/existing_bss_student_login_step_1/";
        const requestData = {
            ...data,
            existing_student_login_page_url: window.location.href,
        };
        setShowSpinner(true);
        axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                notifyApiSuccessMessage(response.data);
                setShowSpinner(false);
            })
            .catch((err) => {
                setShowSpinner(false);
                notifyApiErrorMessage(err);
            });
    };

    const handleProceedLoginFinalStep = async () => {
        const endpoint = "/auth/existing_bss_student_login_final_step/";
        const requestData = {
            token: bssToken,
        };
        setShowSpinner(true);
        axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                notifySuccess("Login success");
                const {refresh, access} = response.data;
                window.localStorage.setItem("access_token", access);
                window.localStorage.setItem("refresh_token", refresh);
                dispatch(fetchUserProfile);
                navigate("/", {replace: true});
            })
            .catch((err) => {
                setShowSpinner(false);
                setRenderPage(true);
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        if (bssToken) {
            handleProceedLoginFinalStep();
        }
    }, [bssToken]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("bss_token")) {
            setBssToken(query.get("bss_token"));
        }
    }, []);

    if (renderPage) {
        return (
            <FormComponent>
                <>
                    <label>Enter Your BSS Email</label>
                    <InputComponent
                        placeholder={""}
                        height={2.5}
                        type="email"
                        value={data?.bss_email}
                        onChange={(e) => handleOnInputChange(e, "bss_email", setData, data)}
                    />
                    <SizedBox height={1.5}/>
                </>
                <AuthButtonContainer>
                    {!showSpinner ? (
                        <>
                            <>
                                <SizedBox height={1.0}/>
                                <Button
                                    fontWeight={700}
                                    fontSize={1}
                                    maxWidth={15}
                                    borderRadius={1.5}
                                    paddingTopBottom={0.6}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleProceedLoginStep1();
                                    }}
                                >
                                    Log in
                                </Button>
                                <SizedBox height={1.0}/>
                            </>
                        </>
                    ) : (
                        <Spinner size={1.5}/>
                    )}
                </AuthButtonContainer>
            </FormComponent>
        );
    }
};
