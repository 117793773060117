import React, {useEffect, useState} from "react";
import {BarChartContainer} from "../../../Global/graphical/charts/bar_chart/Container";
import {LineChartContainer} from "../../../Global/graphical/charts/line_chart/Container";
import {MultiTypeChartContainer} from "../../../Global/graphical/charts/multitypeChart/Container";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {SizedBox} from "../../../Global/SizedBox";
import {Spinner} from "../../../Global/graphical/Spinner";
import {formatQueryDictToQueryParams} from "../../../../helper/formatters/formatDictToParams";

const Revenue = () => {
    const [monthlyRevenueLabels, setMonthlyRevenueLabels] = useState([])
    const [monthlyRevenueData, setMonthlyRevenueData] = useState([])
    const [monthlyGrossMarginData, setMonthlyGrossMarginData] = useState([])
    const [isFetchingMonthlyRevenueData, setIsFetchingMonthlyRevenueData] = useState(true)
    const [revenuePerSubjectChartLabels, setRevenuePerSubjectChartLabels] = useState([])
    const [revenuePerSubjectChartData, setRevenuePerSubjectChartData] = useState([])
    const [isFetchingRevenuePerSubjectData, setIsFetchingRevenuePerSubjectData] = useState(true)
    const [grossMarginPerSubjectChartLabels, setGrossMarginPerSubjectChartLabels] = useState([])
    const [grossMarginPerSubjectChartData, setGrossMarginPerSubjectChartData] = useState([])
    const [isFetchingGrossMarginPerSubjectData, setIsFetchingGrossMarginPerSubjectData] = useState(true)
    const [revenuePerFacultyChartLabels, setRevenuePerFacultyChartLabels] = useState([])
    const [revenuePerFacultyChartData, setRevenuePerFacultyChartData] = useState([])
    const [isFetchingRevenuePerFacultyData, setIsFetchingRevenuePerFacultyData] = useState(true)

    const fetchRevenuePerSubjectData = async () => {
        let endpoint = `/shared/campuses/my_campus/subject_revenue/`;
        setIsFetchingRevenuePerSubjectData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setRevenuePerSubjectChartData(Object.values(response?.data));
                setRevenuePerSubjectChartLabels(Object.keys(response?.data));
            })
            .catch((err) => {
                notifyFailure("Error in fetching revenue per subjects");
            });
        setIsFetchingRevenuePerSubjectData(false)
    }


    const fetchGrossMarginPerSubjectData = async () => {
        let endpoint = `/shared/campuses/my_campus/subjects_gross_margin/`;
        setIsFetchingGrossMarginPerSubjectData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setGrossMarginPerSubjectChartData(Object.values(response?.data));
                setGrossMarginPerSubjectChartLabels(Object.keys(response?.data));
            })
            .catch((err) => {
                notifyFailure("Error in fetching gross margin per subjects");
            });
        setIsFetchingGrossMarginPerSubjectData(false)
    }


    const fetchMonthlyRevenueData = async () => {
        let endpoint = `/shared/campuses/my_campus/monthly_revenue/`;
        setIsFetchingMonthlyRevenueData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setMonthlyRevenueData(Object.values(response?.data));
                setMonthlyRevenueLabels(Object.keys(response?.data));
            })
            .catch((err) => {
                notifyFailure("Error in fetching monthly revenue");
            });
        setIsFetchingMonthlyRevenueData(false)
    }


    const fetchMonthlyGrossMarginData = async () => {
        let endpoint = `/shared/campuses/my_campus/monthly_gross_margin/`;
        setIsFetchingMonthlyRevenueData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setMonthlyGrossMarginData(Object.values(response?.data));
            })
            .catch((err) => {
                notifyFailure("Error in fetching monthly gross margin");
            });
        setIsFetchingMonthlyRevenueData(false)
    }

    const fetchRevenuePerFacultyData = async () => {
        let endpoint = `/shared/campuses/my_campus/teacher_revenue/`;
        setIsFetchingRevenuePerFacultyData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setRevenuePerFacultyChartData(Object.values(response?.data));
                setRevenuePerFacultyChartLabels(Object.keys(response?.data));
            })
            .catch((err) => {
                notifyFailure("Error in fetching revenue per faculty");
            });
        setIsFetchingRevenuePerFacultyData(false)
    }

    useEffect(() => {
        fetchMonthlyRevenueData()
        fetchMonthlyGrossMarginData()
        fetchRevenuePerSubjectData()
        fetchRevenuePerFacultyData()
        fetchGrossMarginPerSubjectData()
    }, [])
    return (<>
        {!isFetchingMonthlyRevenueData ?
            (<div className=" wrapperParent  ">
                    <h2>Monthly Revenue & Gross Margins</h2>
                    <MultiTypeChartContainer lineData={monthlyGrossMarginData} barData={monthlyRevenueData}
                                             labels={monthlyRevenueLabels}/>
                </div>
            ) :
            <div className='spinnerWrapper'>
                <SizedBox height={1}/>
                <Spinner size={1}/>
                <SizedBox height={1}/>
            </div>
        }
        <div className=" wrapper">
            {!isFetchingRevenuePerSubjectData ? <>
                {/*<div className="wrapperchild">*/}
                <h2>Revenue per subject</h2>
                <BarChartContainer labels={revenuePerSubjectChartLabels} data={revenuePerSubjectChartData}/>
            </> : <div className='spinnerWrapper'>
                <SizedBox height={1}/>
                <Spinner size={1}/>
                <SizedBox height={1}/>
            </div>}
            {/*</div>*/}
            {/*<div className="wrapperchild">*/}
            {/*    <h2>Gross Margin per subject</h2>*/}
            {/*    <LineChartContainer />*/}
            {/*</div>*/}
        </div>

        <div className=" wrapper">
            {!isFetchingGrossMarginPerSubjectData ? <>
                {/*<div className="wrapperchild">*/}
                <h2>Gross margin per subject</h2>
                <BarChartContainer labels={grossMarginPerSubjectChartLabels} data={grossMarginPerSubjectChartData}/>
            </> : <div className='spinnerWrapper'>
                <SizedBox height={1}/>
                <Spinner size={1}/>
                <SizedBox height={1}/>
            </div>}
            {/*</div>*/}
            {/*<div className="wrapperchild">*/}
            {/*    <h2>Gross Margin per subject</h2>*/}
            {/*    <LineChartContainer />*/}
            {/*</div>*/}
        </div>
        <div className=" wrapper">

            {!isFetchingRevenuePerFacultyData ? // <div className="wrapperchild">
                <>
                    <h2>Revenue Per Faculty</h2>
                    <BarChartContainer labels={revenuePerFacultyChartLabels} data={revenuePerFacultyChartData}/>
                </>
                // </div>
                : <div className='spinnerWrapper'>
                    <SizedBox height={1}/>
                    <Spinner size={1}/>
                    <SizedBox height={1}/>
                </div>}
            <div></div>
        </div>
    </>);
};

export default Revenue;
