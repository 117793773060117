import styled from "styled-components";

const StyledContainer = styled.div`
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
    background-color: rgb(231, 232, 236);
  align-items: center;
  justify-content: center;
`;

export const BlankCenterCanvas = ({children}) => (<StyledContainer>{children}</StyledContainer>)
