import styled from "styled-components";

const StyledComponent = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const TableBodyPicture = ({ picture }) => {
  return (
    <StyledComponent>
      <img src={picture} />
    </StyledComponent>
  );
};
