import React, { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { HomeWidgetContainer } from "../Global/homeComponents/Container";
import { HorizontalRule } from "../Global/HorizontalRule";
import { MainWrapper } from "../Global/MainWrapper";
import { AcademicList } from "./components/AcademicList";
import { axiosServerInstance } from "../../config/api/axios";
import { notifyFailure } from "../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../helper/notifications/notifyApiErrorMessage";
import { ModalComponent } from "../Global/graphical/Modal";
import { EditSAcademic } from "./components/EditAcademic";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../Global/graphical/Spinner";
import { SizedBox } from "../Global/SizedBox";
import styled from "styled-components";
// const Wrapper = styled.div`
//     .tableOuter {
//         border-radius: 1.5rem;
//         .heading {
//             margin-bottom: 0px;
//         }
//         thead {
//             tr {
//                 th {
//                     :first-child {
//                         border-radius: 10px 0 0 10px;
//                     }
//                     :last-child {
//                         border-radius: 0px 10px 10px 0px;
//                     }
//                 }
//             }
//         }

//         tbody {
//             tr {
//                 border-bottom: 0.1px solid var(--custom-border-color);
//                 :last-child {
//                     border-bottom: none;
//                 }
//                 td {
//                     padding: 0rem 0rem 0 1rem;

//                     button {
//                         width: 5rem;
//                         font-weight: 600;
//                         font-size: 1rem;
//                         background-color: transparent;
//                         letter-spacing: 0.5px;
//                         outline: none;
//                         border: none;
//                         img {
//                             filter: invert(0);
//                             width: 1.2rem;
//                             height: 1.2rem;
//                             object-fit: cover;
//                         }
//                         ::focus-visible {
//                             border: none;
//                             outline: none;
//                         }
//                     }
//                 }
//             }

//             td:nth-last-child(2) {
//                 width: 7vw !important;
//                 padding: 0.4rem 0;
//             }

//             td:last-child {
//                 padding: 0.4rem 0;
//                 width: 7vw;
//             }
//         }
//     }
// `;
export const AcademicInformationContext = createContext({});

export const AcademicInfoContainer = () => {
    const [data, setData] = useState(null);
    const [files, setFiles] = useState({});
    const [showEditAcademicInfo, setShowEditAcademicInfo] = useState(false);
    const [campusResultAnnouncedStatus, setCampusResultAnnouncedStatus] = useState(false);
    const [showSpinnerResultAnnouncedBoard, setShowSpinnerResultAnnouncedBoard] = useState(false);

    const fetchResultAnnouncedStatus = async () => {
        let endpoint = `/student/admission/preference/city_campus`;
        setShowSpinnerResultAnnouncedBoard(true);
        await axiosServerInstance()
            .get(endpoint)
            .then(async (response) => {
                if (response?.data?.results?.length) {
                    const campusData = response.data.results[0];
                    endpoint = `/shared/campuses?campus_id=${campusData.campus_id}`;
                    await axiosServerInstance()
                        .get(endpoint)
                        .then((response) => {
                            if (response?.data?.results?.length) {
                                setCampusResultAnnouncedStatus(
                                    response.data.results[0].result_announced
                                );
                            }
                        })
                        .catch((err) => {
                            notifyFailure("Error: Could not get result announced status");
                        });
                }
            })
            .catch((err) => {
                notifyFailure("Error: Could not get result announced status");
            });
        setShowSpinnerResultAnnouncedBoard(false);
    };
    const navigate = useNavigate();

    const initiateEditAcademicInfo = () => {
        setShowEditAcademicInfo(true);
    };

    const fetchAndParseDataFromAPI = () => {
        const dataEndpoint = "/student/admission/degree";
        axiosServerInstance()
            .get(dataEndpoint)
            .then((response) => {
                setData(response.data);
                const resultAnnouncedUploadResultData = {
                    url_on_server: response.data?.result_announced?.uploaded_result?.file,
                };
                setFiles({
                    ...files,
                    resultAnnouncedUploadedResult: resultAnnouncedUploadResultData,
                });
            })
            .catch((err) => {
                notifyFailure("Error in fetching academic information");
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        fetchAndParseDataFromAPI();
        fetchResultAnnouncedStatus();
    }, []);
    return (
        <MainWrapper pageTitle={"Academic Information"}>
            {!showSpinnerResultAnnouncedBoard ? (
                campusResultAnnouncedStatus && (
                    <HomeWidgetContainer
                        handleOnClickButton={() => {
                            navigate("/admission_information");
                        }}
                        WidgetButtonName={"+ ADD"}
                        widgetTitle={"RESULT ANNOUNCED, PLEASE UPLOAD DETAILS"}
                        WidgetButtonColor={"secondary-bg"}
                        WidgetBgColor={"btn-bg"}
                        widgetTitleColor={"white"}
                    />
                )
            ) : (
                <div className="spinnerWrapper">
                    <SizedBox height={1} />
                    <Spinner size={1.5} />
                    <SizedBox height={1} />
                </div>
            )}
            <AcademicInformationContext.Provider
                value={{
                    data,
                    files,
                }}
            >
                {data && <AcademicList handleEdit={initiateEditAcademicInfo} />}
            </AcademicInformationContext.Provider>
            <ModalComponent
                modalLabel={"Edit Academic Info"}
                isOpen={showEditAcademicInfo}
                callbackCloseModal={() => {
                    setShowEditAcademicInfo(false);
                    fetchAndParseDataFromAPI();
                }}
            >
                <EditSAcademic
                    callbackOnComplete={() => {
                        setShowEditAcademicInfo(false);
                        fetchAndParseDataFromAPI();
                    }}
                />
            </ModalComponent>
        </MainWrapper>
    );
};
