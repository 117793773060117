import React, {useEffect, useState} from "react";
import {TableComponent} from "../../../Global/graphical/table/Container";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {formatQueryDictToQueryParams} from "../../../../helper/formatters/formatDictToParams";
import moment from "moment";
import search from "../../../../static/images/table/Path 123.svg";
import {SizedBox} from "../../../Global/SizedBox";
import {Spinner} from "../../../Global/graphical/Spinner";


const headerData = ["ID", "NAME", "TYPE", "ACTIVITY", "Date/Time"];
export const LogsList = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const [data, setData] = useState(null)
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [searchParam, setSearchParam] = useState('')


    const fetchData = async () => {
        const queryParams = {
            search: searchParam
        }
        let endpoint = `/super_admin/activity/logs/?ordering=-date_added&${formatQueryDictToQueryParams(queryParams)}`;
        setIsFetchingData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response?.data.results);
            })
            .catch((err) => {
                notifyFailure("Error in fetching activity logs");
            });
        setIsFetchingData(false)
    };

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item, index) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: index + 1,
                            },
                            {
                                type: "text",
                                value: item?.owner?.name,
                            },
                            {
                                type: "text",
                                value: item?.owner?.account_type,
                            },
                            {
                                type: "text",
                                value: `The ${item?.owner?.account_type?.toLowerCase()} ` + item?.description,
                            },
                            {
                                type: "text",
                                value: moment(item?.date_added).format('D-M-YYYY, h:mmA'),
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);
    return (
        <>
            <div className="searchActions">
                <div></div>
                <div className="searchOuter">
                    <input
                        type="search"
                        placeholder="Search here..."
                        value={searchParam}
                        onChange={(e) => {
                            setSearchParam(e.target.value);
                        }}
                    />
                    <img
                        src={search}
                        alt="search icon"
                        className={"searchIcon"}
                        onClick={() => {
                            fetchData()
                        }}
                    />
                </div>
            </div>
            {!isFetchingData ?
                <TableComponent
                    headerData={headerData}
                    bodyData={tableBodyData}
                />
                :
                <div className='spinnerWrapper'>
                    <SizedBox height={1}/>
                    <Spinner size={2}/>
                    <SizedBox height={1}/>
                </div>
            }
        </>
    );
};
