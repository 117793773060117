import React from "react";
import styled from "styled-components";
import { Button } from "../../Button";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    margin: 1.5rem 0;
    border-radius: 20px;
    box-shadow: 0 10px 10px -3px #cfcfcf;
    background-color: var(
        --custom-${({ WidgetBgColor }) => WidgetBgColor || "white"}
    );
    .HomeWidgetTitle {
        color: ${({ widgetTitleColor }) => widgetTitleColor || "Black"};
        font-size: 1rem;
    }
    @media (max-width: 780px) {
        flex-wrap: wrap;
        gap: 1rem;
    }
`;
const WidgetHome = ({
    widgetTitle,
    widgetTitleColor,
    WidgetButtonColor,
    WidgetButtonName,
    WidgetBgColor,
    handleOnClickButton,
}) => {
    return (
        <Wrapper
            WidgetBgColor={WidgetBgColor}
            widgetTitleColor={widgetTitleColor}
        >
            <div className="HomeWidgetTitle">{widgetTitle}</div>
            <div className="HomeWidgetButton">
                <Button
                    fontSize={1}
                    fontWeight={400}
                    maxWidth={15}
                    borderRadius={1.5}
                    onClick={() => handleOnClickButton(WidgetButtonName)}
                    BgColor={WidgetButtonColor}
                    border={WidgetButtonColor}
                >
                    {WidgetButtonName}
                </Button>
            </div>
        </Wrapper>
    );
};

export default WidgetHome;
