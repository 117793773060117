import { createContext, useEffect, useState } from "react";
import { FaculityData } from "../../../config/DataList/faculity/FaculityData";
import { MainWrapper } from "../../Global/MainWrapper";
import { FaculityListComponents } from "./components/FaculityList";

export const StudentsContext = createContext({});

export const FacultyContainer = () => {
    const [students, setStudents] = useState([]);
    useEffect(() => {
        setStudents(FaculityData);
    }, []);
    return (
        <MainWrapper pageTitle={"Faculty"}>
            <StudentsContext.Provider
                value={{
                    students,
                }}
            >
                <FaculityListComponents />
            </StudentsContext.Provider>
        </MainWrapper>
    );
};
