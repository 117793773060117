import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  p {
    margin-bottom: .5rem;
  }
`;
const Stroke = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  padding: 0.3rem;
  border-radius: 2.5rem;
  margin-bottom: 1rem;
  background-color: ${(props) =>
          props.strokeBg ? `${props.strokeBg}` : "#ddd"};
`;
const Trail = styled.div`
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) => (props.TrailBg ? `${props.TrailBg}` : "#3CB4E5")};

  padding: 0 1rem;
  width: ${(props) => `${props.TrailText}%`};
  color: ${(props) => (props.TrailTextColor ? `${props.TrailTextColor}` : "#fff")};

  .TrailText {
    font-weight: 500;
  }
}

`;

export const LinearProgressBarContainer = ({
                                               strokeBg,
                                               TrailBg,
                                               TrailText,
                                               label,
                                               TrailTextColor,
                                               percentage
                                           }) => {
    return (
        <Wrapper>
            <p>{label}</p>
            <Stroke strokeBg={strokeBg}>
                <Trail TrailBg={TrailBg} TrailTextColor={TrailTextColor} TrailText={percentage}>
                    <div className="TrailText">{TrailText}</div>
                </Trail>
            </Stroke>
        </Wrapper>
    );
};
