import React from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import styled from "styled-components";
import { options } from "./component/Option";
import faker from "faker";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

const Wrapper = styled.div`
    min-width: 300px;
    overflow: auto;
    canvas {
        min-width: 800px;
        overflow: auto;
        min-height: 400px;
        height: 100%!important;
        @media (max-width: 1300px) {
            height: 100%!important;
        }
    }
`;

export const MultiTypeChartContainer = ({ lineData, barData, labels }) => {
    const dataset = {
        labels,
        datasets: [
            {
                type: "line",
                label: "Dataset 1",
                borderColor: "rgb(28, 68, 137 )",
                borderWidth: 2,
                fill: false,
                data: lineData,
                datalabels: {
                    display: false,
                    color: "#FFCfef",
                    anchor: "end",
                    align: "end",
                    labels: {
                        value: {
                            color: "black",
                        },
                    },
                },
            },
            {
                type: "bar",
                label: "Dataset 2",
                backgroundColor: "rgba(60, 180, 290, 1)",
                data: barData,
                borderColor: "white",
                borderWidth: 2,
                datalabels: {
                    color: "#FFCE56",
                    anchor: "end",
                    align: "end",
                    labels: {
                        value: {
                            color: "black",
                        },
                    },
                },
            },
        ],
    };
    return (
        <Wrapper>
            <Chart type="bar" options={options} data={dataset} />
        </Wrapper>
    );
};
