import styled from "styled-components";

const StyledComponent = styled.div`
    min-height: 100vh;
    height: 100vh;
    background-color: var(--custom-main-bg);
    width: calc(100vw - 17.5rem);
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    overflow: auto;
    
    @media screen and (max-width: 800px) {
        width: 100vw;
    }
    @media screen and (max-width: 500px) {
        padding: 1rem 1.5rem;

    }
`;

export const MainContainer = (props) => {
    return <StyledComponent {...props}>{props.children}</StyledComponent>;
};
