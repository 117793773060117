export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    font: {
      size: 14,
  },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawOnChartArea: false,
        color: "#3f3f3f",
        borderColor: "#3f3f3f",
      },
      backdropColor: "rgba(255, 255, 255, 0.75)",
    },

    y: {
      grid: {
        display: false,
        color: "#3f3f3f",
        borderColor: "#3f3f3f",
      },
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 5,
        stepSize: Math.ceil(250 / 5),
        max: 250,
      },
    },
  },
  
};
