import React, { createContext, useContext, useState } from 'react'
import StepZilla from 'react-stepzilla'
import { MainWrapper } from '../MainWrapper'
import styled from 'styled-components'
import StepOneContainer from './components/step1/Container'
import StepTwoContainer from './components/step2/Container'
import StepThreeContainer from './components/step3/Container'
import StepFourContainer from './components/step4/Container'
import StepFiveContainer from './components/step5/Container'
import StepSixContainer from './components/step6/Container'
import { GlobalUserProfileContext } from '../../../App'
import StepZeroContainer from './components/step0/Container'

const MultiStepWrapper = styled.div`
    ${'' /* height: 100%; */}
    padding: 0 2rem;
    @media (max-width: 500px) {
        padding: 0 0rem;
    }
    .multi-step {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
    .footer-buttons {
        margin-top: 2rem;
        button {
            background-color: #1c4489;
            border-radius: 2.5rem;
            color: #fff;
            padding: 0.6rem 2rem;
            border: 2px solid #1c4489;
            :hover {
                background-color: #91c869;
                color: #ffffff;
                border: 2px solid #91c869;
            }
        }
        #next-button {
            float: right;
        }
        #prev-button {
            float: left;
        }
    }
`
export const MultiStepFormContext = createContext({})
export const MultiStepFormContainer = () => {
    const [step0, setStep0] = useState({})
    const [step1, setStep1] = useState({})
    const [step2, setStep2] = useState({})
    const [step3, setStep3] = useState({})
    const [step4, setStep4] = useState({})
    const [step5, setStep5] = useState({})
    const { profile } = useContext(GlobalUserProfileContext)
    const [PageTitleAdd, setPageTitleAdd] = useState('')

    const steps = [
        {
            name: 'Batch',
            component: <StepZeroContainer />,
        },
        {
            name: 'Personal Information',
            component: <StepOneContainer />,
        },
        { name: 'Academic Information', component: <StepTwoContainer /> },
        { name: 'City & Campus', component: <StepThreeContainer /> },
        { name: 'Select Subjects', component: <StepFourContainer /> },
        { name: 'Disclaimer', component: <StepFiveContainer /> },
        { name: 'Submit', component: <StepSixContainer /> },
    ]

    const stepChange = async step => {
        step === 1
            ? setPageTitleAdd(steps[1].name)
            : step === 2
            ? setPageTitleAdd(steps[2].name)
            : step === 3
            ? setPageTitleAdd(steps[3].name)
            : step === 4
            ? setPageTitleAdd(steps[4].name)
            : step === 5
            ? setPageTitleAdd(steps[5].name)
            : step === 5
            ? setPageTitleAdd(steps[6].name)
            : step === 6
            ? setPageTitleAdd(steps[7].name)
            : setPageTitleAdd(steps[0].name)
    }
    return (
        <MainWrapper pageTitle={PageTitleAdd}>
            <MultiStepFormContext.Provider
                value={{
                    step0,
                    setStep0,
                    step1,
                    setStep1,
                    step2,
                    setStep2,
                    step3,
                    setStep3,
                    step4,
                    setStep4,
                    step5,
                    setStep5,
                }}
            >
                <MultiStepWrapper>
                    <StepZilla
                        steps={steps}
                        preventEnterSubmission={true}
                        stepsNavigation={false}
                        prevBtnOnLastStep={false}
                        startAtStep={
                            (profile?.account_data
                                ?.admission_application_stage || 1) - 1
                        }
                        nextButtonCls={'nextBtnFetch'}
                        nextButtonText={`SAVE & CONTINUE`}
                        backButtonText={'BACK'}
                        nextTextOnFinalActionStep={'Save'}
                        onStepChange={step => stepChange(step)}
                    />
                </MultiStepWrapper>
            </MultiStepFormContext.Provider>
        </MainWrapper>
    )
}
