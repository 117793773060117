import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import search from '../../../../static/images/table/Path 123.svg'
import { axiosServerInstance } from '../../../../config/api/axios'
import { notifyFailure } from '../../../../helper/notifications/notifyFailure'

import { TableComponent } from '../../../Global/graphical/table/Container'
import { formatQueryDictToQueryParams } from '../../../../helper/formatters/formatDictToParams'
import { Spinner } from '../../../Global/graphical/Spinner'
import { SizedBox } from '../../../Global/SizedBox'
import Pagination from '../../../Global/pagination/Pagination'
import FilterByBatch from '../../../Global/filters/FilterByBatch'
import { formatBatch } from '../../../../helper/formatters/formatBatch'

const StyledComponent = styled.div`
    .searchActions {
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    .tableOuter {
        max-height: 560px;
        overflow: scroll;
        overflow-x: auto;
    }

    .infoWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`
const headerData = [
    'STUDENT ID',
    'STUDENT Name',
    'GENDER',
    'PERSONAL INFO',
    'Region',
    'City',
    'Branch/CAMPUS',
    'SUBJECTS',
    'BATCH',
]
export const StudentsListComponents = () => {
    const [tableBodyData, setTableBodyData] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const [batchFilter, setBatchFilter] = useState({
        batch: '',
    })
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [showSpinnerFetchingData, setShowSpinnerFetchingData] =
        useState(false)
    const [count, setCount] = useState(0)

    const [pagination, setPagination] = useState(1)
    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [pagination, batchFilter])

    const [limit] = useState(100)

    const fetchAndParseDataFromAPI = async empty => {
        setShowSpinnerFetchingData(true)

        const endpoint = `/school_head/students/all/?${formatQueryDictToQueryParams(
            { search: empty ? '' : searchParam }
        )}&account_data__batch=${batchFilter.batch}&limit=${limit}&offset=${
            (pagination - 1) * limit
        }`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setData(response?.data?.results)
                setCount(response.data?.count)
            })
            .catch(err => {
                notifyFailure('Error in fetching all students')
            })
        setShowSpinnerFetchingData(false)
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [])

    const onClickView1 = async id => {
        navigate(`/schoolhead/students/${id}/personalinfo`)
    }
    const onClickView2 = async id => {
        navigate(`/schoolhead/students/${id}/subject`)
    }

    const handleSearchInputChange = e => {
        setSearchParam(e.target.value)
        if (!e.target.value) {
            fetchAndParseDataFromAPI(true)
        }
    }

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item, index) => {
                    return {
                        id: item.id,
                        data: [
                            {
                                type: 'text',
                                value: item?.identifier,
                            },
                            {
                                type: 'text',
                                value: item?.full_name,
                            },
                            {
                                type: 'text',
                                value: item?.account_data?.gender,
                            },
                            {
                                type: 'view1',
                                value: onClickView1,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.region_name,
                            },
                            {
                                type: 'text',
                                value: item?.account_data?.city,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.campus_name,
                            },

                            {
                                type: 'view2',
                                value: onClickView2,
                            },
                            {
                                type: 'text',
                                value: formatBatch(
                                    item?.account_data?.batch?.first_year
                                ),
                            },
                        ],
                    }
                })
            )
        }
    }, [data])

    return (
        <StyledComponent>
            <div className='searchActions'>
                <div className='selectOuter'>
                    <FilterByBatch setBatchFilter={setBatchFilter} />
                </div>

                <div className='searchOuter'>
                    <input
                        type='search'
                        placeholder='Search student by name, CNIC, city...'
                        value={searchParam}
                        onChange={handleSearchInputChange}
                    />
                    <img
                        src={search}
                        alt='search icon'
                        className={'searchIcon'}
                        onClick={() => {
                            fetchAndParseDataFromAPI()
                        }}
                    />
                </div>
            </div>
            <SizedBox height={1} />
            {showSpinnerFetchingData ? (
                <div className='infoWrapper'>
                    <Spinner size={1.5} />
                </div>
            ) : (
                <>
                    <TableComponent
                        headerData={headerData}
                        bodyData={tableBodyData}
                        actionsView1={{
                            VIEW: onClickView1,
                        }}
                        actionsView2={{
                            VIEW: onClickView2,
                        }}
                    />
                    <Pagination
                        className='pagination-bar'
                        currentPage={pagination}
                        totalCount={count}
                        pageSize={limit}
                        onPageChange={setPagination}
                    />
                </>
            )}
        </StyledComponent>
    )
}
