import { axiosServerInstance } from "../../config/api/axios";
import { notifyApiErrorMessage } from "../notifications/notifyApiErrorMessage";

export const singleFileUpload = async ({ fileBuffer, purpose, owner }) => {
  const endpoint = "/upload/";
  const formData = new FormData();
  formData.append("file", fileBuffer);
  formData.append("purpose", purpose);

  purpose === "Result Announced" && formData.append("owner", owner);

  return await axiosServerInstance()
    .post(endpoint, formData)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      notifyApiErrorMessage(err);
    });
};

export const batchFileUpload = async ({ fileBuffers, purpose }) => {
  const endpoint = "/upload/batch_upload/";
  const formData = new FormData();
  formData.append("files", fileBuffers);
  formData.append("purpose", purpose);
  return await axiosServerInstance()
    .post(endpoint, formData)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      notifyApiErrorMessage(err);
    });
};

export const deleteUploadedFile = async ({ fileID }) => {
  const endpoint = `/upload/${fileID}/`;
  return await axiosServerInstance()
    .delete(endpoint)
    .then(response => {
      return true;
    })
    .catch(err => {
      notifyApiErrorMessage(err);
    });
};
