import styled from "styled-components";
import { AuthenticationHeader } from "./Header";
import { CompanyLogo } from "../../Global/CompanyLogo";
import LogoBg from "../../../static/images/logo/1.svg";
import AuthenicationContentbg from "../../../static/images/logo/SL_042620_30310_15.svg";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
    height: 100vh;
    background-image: ${`url(${AuthenicationContentbg})`};
    background-size: cover;
    background-position: center;
`;

const AuthMainOuter = styled.div`
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    .BrndLogin {
        max-width: 28rem;
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: ${`url(${LogoBg})`};
        background-size: cover;
        background-position: center;
        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            height: 17rem;
        }
        & > div {
            padding: 1rem;

            img {
                max-width: 20rem;
                width: 24rem;
                height: 100%;
                object-fit: cover;
            }
        }
        .signUpForNonBss {
            text-align: center;
            margin-top: 2rem;
            @media (max-width: 768px) {
                margin-top: 0rem;
                padding: 0px;
            }
            p {
                font-size: 1.2rem;
                color: #fff;
                margin-bottom: 2rem;
            }
            button {
                background-color: transparent;
                padding: 0.4rem 2rem;
                font-size: 1rem;
                text-transform: uppercase;
                border: 1px solid #fff;
                border-radius: 2.5rem;
                color: #fff;
            }
        }
    }

    .AuthenicationContent {
        margin: 0 auto;
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    p {
        font-size: 0.8rem;
        text-align: center;
    }
    .AuthRadioMode {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        input {
            margin-left: 1rem;
        }
    }
    .inputOuter {
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .recaptcha {
        display: flex;
        margin: 1rem 0 3rem;
        justify-content: center;
    }
    .googleLoginBtn {
        margin: 1rem 0 3rem;
        display: flex;

        justify-content: center;
    }
`;

const FormWrapper = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    max-width: 560px;
    width: 90%;
`;

export const AuthMainContainer = ({ isBssActive, mode, children }) => {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate("/signup", { replace: true });
    };
    return (
        <StyledContainer>
            <AuthMainOuter>
                <div className="BrndLogin">
                    <CompanyLogo />
                    {isBssActive === "NonBss" && (
                        <div className="signUpForNonBss">
                            <p>New here? Sign up now!</p>
                            <button onClick={handleSignUp}>SIGN UP</button>
                        </div>
                    )}
                </div>
                <div className="AuthenicationContent">
                    {mode === "register" ? (
                        <>
                            <AuthenticationHeader mode={mode} />
                            {children}
                        </>
                    ) : (
                        <FormWrapper>
                            <AuthenticationHeader mode={mode} />
                            {children}
                        </FormWrapper>
                    )}
                </div>
            </AuthMainOuter>
        </StyledContainer>
    );
};
