import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {HorizontalRule} from "./../../../../Global/HorizontalRule";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {useParams} from "react-router-dom";
import {SizedBox} from "../../../../Global/SizedBox";
import {Spinner} from "../../../../Global/graphical/Spinner";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";

const StyledComponent = styled.div`
  max-height: 600px;
  overflow: auto;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 20px -3px #ddd;
  margin-top: 5rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
  }

  .heading {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .activity {
    .activityContent {
      display: flex;

      .content {
        font-size: 1.2rem;
      }

      .dot {
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--custom-btn-bg);
        margin: 0 2rem;
      }
    }

    hr {
      margin: 1.5rem 0;
    }
  }
`;
const ActivityData = [
    {
        content: "The student has signed in",
    },
    {
        content: "The student made changes in subjects",
    },
    {
        content: "The student has logged out",
    },
];
export const StudentActivityContainer = () => {
    const [data, setData] = useState([])
    const [showSpinner, setShowSpinner] = useState(false)
    const {id: studentID} = useParams()

    const fetchDataFromServer = async () => {
        setShowSpinner(true);
        const endpoint = `/pro/students/${studentID}/activity/logs?ordering=-date_added`;
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data.results)
            })
            .catch((err) => {
                notifyFailure('Error in fetching student activity logs')
            });
        setShowSpinner(false)
    }

    useEffect(() => {
        fetchDataFromServer()
    }, [studentID])


    return (
        <StyledComponent>
            <div className="heading">STUDENT ACTIVITY</div>
            {!showSpinner ?
                (
                    data.map((item, i) => (
                        <div className="activity" key={i}>
                            <div className="activityContent">
                                <div className="dot"></div>
                                <div className="content">The student {item.description}</div>
                            </div>
                            <HorizontalRule/>
                        </div>
                    ))
                ) :
                <div className={'spinnerWrapper'}>
                    <SizedBox height={1}/>
                    <Spinner size={1.5}/>
                </div>

            }
        </StyledComponent>
    );
};
