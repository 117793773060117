import {TableComponent} from "../../../../graphical/table/Container";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";
import {notifySuccess} from "../../../../../../helper/notifications/notifySuccess";
import {
    STUDENT_ADMISSION_STATUS_TYPES,
    STUDENT_RESULT_TYPES,
    STUDENT_SUBJECT_GRADES_TYPES
} from "../../../../../../config/store/constants";
import {useContext} from "react";
import {GlobalUserProfileContext} from "../../../../../../App";

export const ResultAnnouncedSubjectsTable = ({data, callBackFetchData, handleEdit}) => {
    const headerData = ['subject', 'grade', '', '']
    const {profile} = useContext(GlobalUserProfileContext);
    const onClickDelete = (subject) => {
        const endpoint = `/student/admission/degree/result_announced/subjects/${subject?.id}/`
        axiosServerInstance()
            .delete(endpoint)
            .then((response) => {
                notifySuccess("Success")
                callBackFetchData()
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const onClickEdit = (subject) => {
        handleEdit(subject?.id, subject?.grade === STUDENT_SUBJECT_GRADES_TYPES.RESULT_AWAITED ? STUDENT_RESULT_TYPES.AWAITED : STUDENT_RESULT_TYPES.ANNOUNCED)
    }
    const fetchResultAnnouncedSubjectTableData = () => {
        return (
            ([...data?.result_announced?.subjects, ...data?.result_awaited?.subjects]).map((subject, i) => {
                return {
                    id: subject,
                    data: [
                        {
                            type: "text",
                            value: subject.name,
                        },
                        {
                            type: "text",
                            value: subject.grade,
                        },
                        ...((![STUDENT_ADMISSION_STATUS_TYPES.ENROLLED].includes(profile?.account_data?.admission_status?.admission_status)) ? [{
                            type: "edit"
                        },
                            {
                                type: "delete"
                            },
                        ] : [])
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchResultAnnouncedSubjectTableData()}
            actionEdit={{
                edit: onClickEdit,
            }}
            actionDelete={{
                delete: onClickDelete,
            }}
        />
    )
}