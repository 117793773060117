import React, { useState } from "react";
import { Button } from "../../../Global/Button";
import { FormFieldsWrapper } from "../../../Global/graphical/data_input/FormFieldsWrapper";
import { Spinner } from "../../../Global/graphical/Spinner";
import styled from "styled-components";
const StyledComponent = styled.div`
    margin: 1rem 1.5rem;
    .formTitle {
        font-size: 2rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        .formfieldInner {
            .inputOuter {
                width: 32%;
            }

            .inputOuter.w-100 {
                width: 100%;
            }
            .inputOuter.w-38 {
                width: 45%;
                @media screen and (max-width: 45rem) {
                    width: 100%;
                }
            }
            .RadioMode {
                margin-top: 2rem;
                input {
                    margin: 0 0.5rem 0 1rem;
                }
            }
            textarea {
                min-width: 40rem;
                display: flex;

                height: 9rem;
                text-indent: 10px;
                background: var(--custom-input-bg);
                border-radius: 10px;
                outline: none;
                background-image: none;
                overflow: hidden;
                border: 1px solid var(--custom-input-border);
                @media (max-width: 900px) {
                    min-width: 100%;
                }
            }
        }
    }

    .formFieldWrapperSubject {
        align-items: flex-start;
        .helperText {
            font-size: 12px;
            margin-left: 1rem;
            line-height: 1.5rem;
        }
    }
    .actionButtonWrapper {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 900px) {
            margin-top: 2rem;
        }
    }
`;

export const EditReminder = ({}) => {
    const [data, setData] = useState({});
    const [mode, setMode] = useState("via Email");
    const [showSpinner, setShowSpinner] = useState(false);

    const handleOnChangeInput = (e, field) => {
        let tempData = { ...data };
        tempData[field] = e.target.value;
        setData(tempData);
    };

    const handleOnClickProceed = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (let field in data) {
            formData.append(field, data[field]);
        }
        proceedAddSubUser(formData);
    };

    const proceedAddSubUser = (data) => {};

    const cleanState = () => {
        let tempData = {};
        for (let field in data) {
            tempData[field] = "";
        }
        setData(tempData);
    };
    let RadioData = [{ value: "via Email" }, { value: "via SMS" }, { value: "Both" }];
    const onChangeValue = (event) => {
        setMode(event.target.value);
        console.log(event.target.value);
    };

    return (
        <StyledComponent>
            <h2 className="formTitle">REMINDERS</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-100">
                        <label>Duration</label>
                        <textarea value={data.textArea} onChange={handleOnChangeInput} />
                    </div>
                    <div className="RadioMode" onChange={onChangeValue}>
                        <span>Mode:</span>
                        {RadioData.map((item, i) => {
                            return (
                                <>
                                    <input
                                        key={i}
                                        type="radio"
                                        value={item.value}
                                        name={item.value}
                                        checked={mode === item.value}
                                    />
                                    {item.value}
                                </>
                            );
                        })}
                    </div>
                </div>
            </FormFieldsWrapper>

            <div className="actionButtonWrapper">
                {showSpinner ? (
                    <Spinner size={1.5} />
                ) : (
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={handleOnClickProceed}
                    >
                        SEND
                    </Button>
                )}
            </div>
        </StyledComponent>
    );
};
