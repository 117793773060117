import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StudentsContext } from "../Container";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { MainWrapper } from "../../../Global/MainWrapper";
import { Button } from "../../../Global/Button";
import { Spinner } from "../../../Global/graphical/Spinner";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { axiosServerInstance } from "../../../../config/api/axios";
import { notifyInfo } from "../../../../helper/notifications/notifySuccess";

const StyledComponent = styled.div`
    padding-top: 4rem;
    .extra-actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .backBtn {
        margin-top: 6rem;
        Button {
            color: var(--custom-btn-bg);
            padding: 0.6rem 2rem;
        }
    }
    .infoWrapper {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export const ViewSubject = () => {
    const { id } = useParams();

    const [tableBodyData, setTableBodyData] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const { matric } = useContext(StudentsContext);

    const navigate = useNavigate();
    const headerData = ["SUBJECT", "TEACHER"];

    const handleOnClickBack = () => {
        // setTargetPage("STATUS DETAILS");
        navigate(`/pro/scholarship`, { replace: true });
    };

    const [data, setData] = useState();

    const fetchSubjects = async () => {
        const endpoint = `/pro/students/${id}/admission/preference/subjects`;
        setShowSpinner(true);
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data?.results);
                if (response.data?.count == 0) {
                    notifyInfo("No subject");
                }
                setShowSpinner(false);
            })
            .catch((err) => {
                notifyFailure("Error in fetching subjects and teachers");
                setShowSpinner(false);
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        fetchSubjects();
    }, []);

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((data) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: data.subject_name,
                            },
                            {
                                type: "text",
                                value: data.teacher_name,
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);

    return (
        <MainWrapper pageTitle={"SCHOLARSHIP"}>
            <StyledComponent>
                {!showSpinner ? (
                    <>
                        <TableComponent headerData={headerData} bodyData={tableBodyData} />
                        <div className="backBtn">
                            <Button
                                fontSize={1}
                                borderRadius={2.4}
                                maxWidth={10}
                                BgColor="transparent"
                                height={2.6}
                                paddingTopBottom={0.6}
                                onClick={handleOnClickBack}
                            >
                                BACK
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="infoWrapper">
                        <Spinner />
                    </div>
                )}
            </StyledComponent>
        </MainWrapper>
    );
};
