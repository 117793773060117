import styled from "styled-components";
import {FormFieldsWrapper} from "../../../graphical/data_input/FormFieldsWrapper";
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState,} from "react";
import {multiStepFormValidator} from "../../../../../helper/form_validators/multiStepFormValidator";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {GlobalUserProfileContext} from "../../../../../App";

const StyledComponent = styled.div`
  border-radius: 20px;
  box-shadow: 0 10px 10px -3px #cfcfcf;
  padding: 3rem;
  @media (max-width: 500px) {
    padding: 3rem 1rem;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (max-width: 45rem) {
      grid-template-columns: repeat(1, 1fr);
    }

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      .inputOuter.w-38 {
        width: 90%;
        @media screen and (max-width: 45rem) {
          width: 100%;
        }

        label {
          display: flex;

          font-size: 1rem;
        }

        input {
          margin-right: 1rem;
          width: 20px;
          height: 20px;
          margin-top: 0.5rem;
          padding-bottom: 0;
        }
      }
    }
  }
`;
const dataFieldsMapping = {
    disclaimer: (data) => {
        const message = "You need to accept the disclaimer";
        return {
            status: data?.has_confirmed_admission_disclaimer_notice,
            message,
        };
    },
};
const StepFiveContainer = forwardRef(({jumpToStep}, ref) => {
    const currentStep = useRef(5);
    const [data, setData] = useState([]);
    const {profile} = useContext(GlobalUserProfileContext);

    const fetchAndParseDataFromAPI = () => {
        const endpoint = "/student/admission/information";
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching admission data");
                notifyApiErrorMessage(err);
            });
    };

    const handleChange = () => {
        const tempData = {...data};
        tempData["has_confirmed_admission_disclaimer_notice"] =
            !tempData["has_confirmed_admission_disclaimer_notice"];
        setData(tempData);
    };

    const computeDataAndSendToServer = async () => {
        let status = true;
        const accountData = {
            has_confirmed_admission_disclaimer_notice:
            data?.has_confirmed_admission_disclaimer_notice,
        };
        const accountDataEndpoint = "/student/admission/information";
        await axiosServerInstance()
            .patch(accountDataEndpoint, accountData)
            .then((response) => {
                status = true;
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                status = false;
            });
        if (status && profile?.account_data?.admission_application_stage < 6) {
            let endpoint = "/student/admission/information";
            await axiosServerInstance()
                .patch(endpoint, {
                    admission_application_stage: currentStep.current + 1,
                })
                .then((response) => {
                    status = true;
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                    status = false;
                });
            endpoint = "/student/admission/set_admission_status_to_applied/";
            await axiosServerInstance()
                .post(endpoint)
                .then((response) => {
                    status = true;
                })
                .catch((err) => {
                    notifyApiErrorMessage(err)
                    notifyFailure("Error encountered");
                    status = false;
                });
        }
        return status;
    };

    useImperativeHandle(ref, () => ({
        async isValidated() {
            const {status, message} = multiStepFormValidator(dataFieldsMapping, data);
            if (!status) {
                notifyFailure(message);
            } else {
                return await computeDataAndSendToServer();
            }
            return status;
        },
    }));

    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);

    return (
        <StyledComponent>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-38">
                        <label>
                            <input
                                type="checkbox"
                                checked={data?.has_confirmed_admission_disclaimer_notice}
                                onChange={handleChange}
                            />
                            I hereby, confirm that the information provided by me is correct to the
                            best of my knowledge.
                            Please note that you will not be able to update or edit the information once saved
                        </label>
                    </div>
                </div>
            </FormFieldsWrapper>
        </StyledComponent>
    );
});
export default StepFiveContainer;
