export const ActionsView2 = ({ actionsView2, dataID }) => {
  return (
    <>
      {actionsView2 && (
        <div className="view">
          {Object.keys(actionsView2).map((actionName) => (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionsView2[actionName](dataID)
              }}
            >
              {actionName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
