import styled from 'styled-components'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import search from '../../../../static/images/table/Path 123.svg'
import { axiosServerInstance } from '../../../../config/api/axios'
import { notifyFailure } from '../../../../helper/notifications/notifyFailure'
import { notifyApiErrorMessage } from '../../../../helper/notifications/notifyApiErrorMessage'
import {
    STUDENT_ADMISSION_STATUS_TYPES,
    STUDENT_DEGREE_TYPES,
} from '../../../../config/store/constants'
import { TableComponent } from '../../../Global/graphical/table/Container'
import { formatQueryDictToQueryParams } from '../../../../helper/formatters/formatDictToParams'
import { Spinner } from '../../../Global/graphical/Spinner'
import { SizedBox } from '../../../Global/SizedBox'
import { SearchBar } from '../../../Global/searchBar/SearchBar'
import Pagination from '../../../Global/pagination/Pagination'
import FilterByBatch from '../../../Global/filters/FilterByBatch'
import { batch } from 'react-redux'

const StyledComponent = styled.div`
    .searchActions {
        display: flex;
        gap: 2rem;
        align-items: center;
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .helperText {
            flex: auto;
        }

        .selectOuter {
            select {
                min-width: 140px;
                max-width: 170px;
                width: 100%;
                padding: 0.6rem;
                text-indent: 10px;
                background: var(--custom-btn-bg);
                border-radius: 25px;
                outline: none;
                background-image: none;
                overflow: hidden;
                color: var(--custom-white);

                option {
                    color: var(--custom-btn-bg);
                    border-radius: 25px;
                    width: 32%;
                    background: var(--custom-white);
                }
            }
        }
    }

    .searchOuter {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        border: 1px solid #ddd;
        border-radius: 2.5rem;
        background-color: #fff;
        height: 3rem;
        width: 100%;
        max-width: 33.9375rem;
        align-items: center;
        padding: 0 1rem;

        input {
            border: none;
            outline: none;
            width: 100%;
            background: transparent;
        }
    }

    .searchIcon {
        cursor: pointer;
        width: 20px;
    }

    .tableOuter {
        max-height: 560px;
        overflow: auto;
        ${
            '' /* overflow: scroll;
    overflow-x: auto; */
        }
    }

    .infoWrapper {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
export const StudentsListComponents = () => {
    const [tableBodyData, setTableBodyData] = useState([])
    const [currentlySelectedSortByStatus, setCurrentlySelectedSortByStatus] =
        useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const [count, setCount] = useState(0)
    const [batchFilter, setBatchFilter] = useState({ batch: '' })
    const [pagination, setPagination] = useState(1)
    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [pagination])

    const [limit] = useState(100)
    const navigate = useNavigate()
    const headerData = [
        'ID',
        'Name',
        'CNIC',
        'Phone Number',
        'Region',
        'City',
        'Campus',
        'BSS/Non-Bss',
        '',
    ]
    const sortStatusList = useRef([
        {
            label: 'All',
            parameter: {},
        },
        {
            label: 'BSS',
            parameter: {
                account_data__is_existing: true,
            },
        },
        {
            label: 'Non-BSS',
            parameter: {
                account_data__is_existing: false,
            },
        },
    ])
    const [data, setData] = useState([])
    const endpointQueryParam = useRef({})

    const fetchAndParseDataFromAPI = () => {
        setIsProcessing(true)
        const endpoint = `/pro/students${
            formatQueryDictToQueryParams(endpointQueryParam.current, '?')
                ? formatQueryDictToQueryParams(endpointQueryParam.current, '?')
                : '?'
        }&account_data__batch=${batchFilter.batch}&limit=${limit}&offset=${
            (pagination - 1) * limit
        }`
        axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setData(response.data?.results)
                setCount(response.data?.count)
                setIsProcessing(false)
            })
            .catch(err => {
                notifyFailure('Error in fetching data')
                notifyApiErrorMessage(err)
                setIsProcessing(false)
            })
    }

    useEffect(() => {
        if (currentlySelectedSortByStatus) {
            endpointQueryParam.current = sortStatusList.current.find(
                item => item.label === currentlySelectedSortByStatus
            )?.parameter
            fetchAndParseDataFromAPI()
        }
    }, [currentlySelectedSortByStatus])

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [batchFilter])

    const onClickEdit = async id => {
        navigate(`/pro/students/${id}/`)
    }

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item, index) => {
                    return {
                        id: item.id,
                        data: [
                            {
                                type: 'text',
                                value: limit * (pagination - 1) + index + 1,
                            },
                            {
                                type: 'text',
                                value: item?.full_name,
                            },
                            {
                                type: 'text',
                                value: item?.account_data?.cnic,
                            },
                            {
                                type: 'text',
                                value: item?.phone_number,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.region_name,
                            },

                            {
                                type: 'text',
                                value: item?.city_campus[0]?.city_name,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.campus_name,
                            },
                            {
                                type: 'badge-custom',
                                value: item?.account_data?.is_existing
                                    ? 'BSS'
                                    : 'Non-BSS',
                            },
                            {
                                type: 'edit',
                                value: onClickEdit,
                            },
                        ],
                    }
                })
            )
        }
    }, [data])

    return (
        <StyledComponent>
            <div className='searchActions'>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <div className='selectOuter'>
                        <select
                            value={currentlySelectedSortByStatus?.label}
                            onChange={e => {
                                setCurrentlySelectedSortByStatus(e.target.value)
                            }}
                        >
                            {sortStatusList.current.map(status => (
                                <option
                                    selected={
                                        status.label ===
                                        currentlySelectedSortByStatus
                                    }
                                    value={status.label}
                                >
                                    {status.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='selectOuter'>
                        <FilterByBatch setBatchFilter={setBatchFilter} />
                    </div>
                    <div>
                        <p className='helperText'>
                            <strong>BSS</strong>: Existing BSS Students
                        </p>
                        <p className='helperText'>
                            <strong>Non-BSS</strong>: Newly Registered Students
                        </p>
                    </div>
                </div>
                <div className='searchOuter'>
                    <SearchBar
                        endpointQueryParam={endpointQueryParam}
                        fetchAndParseDataFromAPI={fetchAndParseDataFromAPI}
                    />
                </div>
            </div>
            <SizedBox height={3} />
            {isProcessing ? (
                <div className='infoWrapper'>
                    <Spinner />
                </div>
            ) : (
                <>
                    <TableComponent
                        headerData={headerData}
                        bodyData={tableBodyData}
                        actionEdit={{
                            edit: onClickEdit,
                        }}
                    />
                    <Pagination
                        className='pagination-bar'
                        currentPage={pagination}
                        totalCount={count}
                        pageSize={limit}
                        onPageChange={setPagination}
                    />
                </>
            )}
        </StyledComponent>
    )
}
