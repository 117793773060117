import styled from 'styled-components'
import { TableComponent } from '../../../Global/graphical/table/Container'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import search from '../../../../static/images/table/Path 123.svg'
import { formatQueryDictToQueryParams } from '../../../../helper/formatters/formatDictToParams'
import { axiosServerInstance } from '../../../../config/api/axios'
import { notifyFailure } from '../../../../helper/notifications/notifyFailure'
import { Spinner } from '../../../Global/graphical/Spinner'
import { ListFilterComponentByRegionCityCampus } from '../../global/ListFilterComponentByRegionCityCampus'
import { StudentsCount } from '../StudentsCount'
import Pagination from '../../../Global/pagination/Pagination'

import FilterByBatch from '../../../Global/filters/FilterByBatch'
import { formatBatch } from '../../../../helper/formatters/formatBatch'

const StyledComponent = styled.div`
    .SuperAdminDataOuter {
        .actionButtonWrapperSubject {
            margin-bottom: 2rem;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            button {
                width: 170px;
                color: var(--custom-white);
            }
        }
    }

    .searchActions {
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
    .students-count {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-bottom: 2rem;
    }
`

const headerData = [
    'STUDENT ID',
    'STUDENT Name',
    'GENDER',
    'PERSONAL INFO',
    'Region',
    'City',
    'Branch/CAMPUS',
    'SUBJECTS',
    'BATCH',
]
export const StudentsListComponents = () => {
    const [tableBodyData, setTableBodyData] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [appliedStudentsCount, setAppliedStudentsCount] = useState('')
    const [showSpinnerFetchingData, setShowSpinnerFetchingData] =
        useState(false)
    const [filterData, setFilterData] = useState({})
    const [batchFilter, setBatchFilter] = useState({ batch: '' })
    const [pagination, setPagination] = useState(1)
    const [limit] = useState(100)

    const fetchAndParseDataFromAPI = async empty => {
        setShowSpinnerFetchingData(true)
        const formattedFilterData = {}
        if (filterData['selectedRegion']) {
            formattedFilterData['city_campus__region_id'] =
                filterData['selectedRegion']
        }

        if (filterData['selectedCity']) {
            formattedFilterData['city_campus__city_id'] =
                filterData['selectedCity']
        }

        if (filterData['selectedCampus']) {
            formattedFilterData['city_campus__campus_id'] =
                filterData['selectedCampus']
        }
        const endpoint = `/super_admin/students/all/?${formatQueryDictToQueryParams(
            { search: empty ? '' : searchParam, ...formattedFilterData }
        )}&account_data__batch=${batchFilter.batch}&limit=${limit}&offset=${
            (pagination - 1) * limit
        }`

        const endpointAppliedStudent = `/super_admin/dashboard/university_application_stats/?${formatQueryDictToQueryParams(
            {
                region_id: formattedFilterData.city_campus__region_id
                    ? formattedFilterData.city_campus__region_id
                    : '',
                city_id: formattedFilterData.city_campus__city_id
                    ? formattedFilterData.city_campus__city_id
                    : '',
                campus_id: formattedFilterData.city_campus__campus_id
                    ? formattedFilterData.city_campus__campus_id
                    : '',
            }
        )}${batchFilter?.batch && `&batch=${batchFilter.batch}`}`

        await axiosServerInstance()
            .get(endpointAppliedStudent)
            .then(response => {
                setAppliedStudentsCount(
                    response.data.successful_applications_count
                )
            })

        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                axiosServerInstance()
                    .get(
                        `/super_admin/students/all/?account_data__admission_status__admission_status=Applied&count=True&${formatQueryDictToQueryParams(
                            {
                                search: empty ? '' : searchParam,
                                ...formattedFilterData,
                            }
                        )}${
                            batchFilter?.batch && `&batch=${batchFilter.batch}`
                        }`
                    )
                    .then(resp => {
                        setData(response?.data)
                        //setAppliedStudentsCount(resp.data.count)
                    })
                    .catch(err => {
                        notifyFailure('Error in fetching all students')
                    })
            })
            .catch(err => {
                notifyFailure('Error in fetching all students')
            })
        setShowSpinnerFetchingData(false)
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [])

    useEffect(() => {
        if (Object.values(filterData).length) {
            fetchAndParseDataFromAPI()
        }
    }, [filterData])

    const onClickView1 = async id => {
        navigate(`/superadmin/students/${id}/personalinfo`)
    }
    const onClickView2 = async id => {
        navigate(`/superadmin/students/${id}/subject`)
    }
    const handleOnClickAddStudent = () => {
        navigate(`/superadmin/students/add`)
    }

    const handleSearchInputChange = e => {
        setSearchParam(e.target.value)
        if (!e.target.value) {
            fetchAndParseDataFromAPI(true)
        }
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [pagination, filterData, batchFilter])

    useEffect(() => {
        if (data.results) {
            setTableBodyData(
                data.results.map((item, index) => {
                    return {
                        id: item.id,
                        data: [
                            {
                                type: 'text',
                                value: item?.identifier,
                            },
                            {
                                type: 'text',
                                value: item?.full_name,
                            },
                            {
                                type: 'text',
                                value: item?.account_data?.gender,
                            },
                            {
                                type: 'view1',
                                value: onClickView1,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.region_name,
                            },
                            {
                                type: 'text',
                                value: item?.account_data?.city,
                            },
                            {
                                type: 'text',
                                value: item?.city_campus[0]?.campus_name,
                            },

                            {
                                type: 'view2',
                                value: onClickView2,
                            },
                            {
                                type: 'text',
                                value: formatBatch(
                                    item?.account_data?.batch?.first_year
                                ),
                            },
                        ],
                    }
                })
            )
        }
    }, [data])

    return (
        <StyledComponent>
            <div className='searchActions'>
                <div className='selectOuter'>
                    <FilterByBatch setBatchFilter={setBatchFilter} />
                </div>
            </div>
            <div className='searchActions'>
                <ListFilterComponentByRegionCityCampus
                    setterCallback={setFilterData}
                />
                <div className='searchOuter'>
                    <input
                        type='search'
                        placeholder='Search student by name, CNIC, city...'
                        value={searchParam}
                        onChange={handleSearchInputChange}
                    />
                    <img
                        src={search}
                        alt='search icon'
                        className={'searchIcon'}
                        onClick={() => {
                            fetchAndParseDataFromAPI()
                        }}
                    />
                </div>
            </div>
            <div className='SuperAdminDataOuter'>
                {showSpinnerFetchingData ? (
                    <div className='infoWrapper'>
                        <Spinner size={1.5} />
                    </div>
                ) : (
                    <div>
                        <div className='students-count'>
                            <StudentsCount
                                count={data.count}
                                head={'Total Students'}
                            />
                            <StudentsCount
                                count={appliedStudentsCount}
                                head={'Total Applied Students'}
                            />
                        </div>

                        <TableComponent
                            headerData={headerData}
                            bodyData={tableBodyData}
                            actionsView1={{
                                VIEW: onClickView1,
                            }}
                            actionsView2={{
                                VIEW: onClickView2,
                            }}
                        />
                        {tableBodyData.length === 0 ? (
                            'No record found.'
                        ) : (
                            <Pagination
                                className='pagination-bar'
                                currentPage={pagination}
                                totalCount={data.count}
                                pageSize={limit}
                                onPageChange={setPagination}
                            />
                        )}
                    </div>
                )}
            </div>
        </StyledComponent>
    )
}
