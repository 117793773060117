import React, { useState } from "react";
import { InputComponent } from "../../Authentication/components/InputELement";
import { Button } from "../../Global/Button";
import { FormFieldsWrapper } from "../../Global/graphical/data_input/FormFieldsWrapper";
import { Spinner } from "../../Global/graphical/Spinner";
import styled from "styled-components";
import { AddDegree } from "./AddDegree";
import { AddSubject } from "./AddSubject";
import StepTwoContainer from "../../Global/multiStepForm/components/step2/Container";

const StyledComponent = styled.div`
    margin: 1rem 1.5rem;
    @media (max-width: 1180px) {
        margin: 0rem;
    }
    .ReactModal__Content > div > div {
        @media (max-width: 1180px) {
            margin: 0rem;
        }
    }
    .formTitle {
        font-size: 2rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
        @media (max-width: 720px) {
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
        @media (max-width: 500px) {
            margin-bottom: 0rem;
            font-size: 1.42rem;
        }
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .inputOuter {
                width: 32%;
                @media (max-width: 1180px) {
                    width: 48%;
                }
                @media (max-width: 45rem) {
                    width: 100%;
                }
            }
            .ResultRadioMode {
                width: 100%;
                input {
                    margin: 0 1rem;
                }
            }
            .inputOuter.w-22 {
                width: 22%;
                @media (max-width: 1468px) {
                    width: 20%;
                }
                @media (max-width: 1353px) {
                    width: 30%;
                }
                @media (max-width: 1311px) {
                    width: 30%;
                }
                @media (max-width: 1277px) {
                    width: 30%;
                }
                @media (max-width: 1170px) {
                    width: 49%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-38 {
                width: 40%;
                @media (max-width: 1358px) {
                    width: 38%;
                }
                @media (max-width: 1180px) {
                    width: 48%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }
            .textarea {
                width: 100%;
            }
        }
    }

    .inputOuter.profilePicturePickerWrapper {
        height: auto !important;
        width: 100% !important;
        margin-bottom: 5rem !important;
        position: relative;
        input {
            position: absolute;
            opacity: 0;
            left: 0;
            bottom: -3.5rem !important;
        }
        .inputFile {
            position: absolute;
            bottom: -3.5rem !important;
            left: 0;
            width: 100%;
            height: 50px;
            border: 1px solid var(--custom-input-border);
            background-color: var(--custom-input-bg);
            border-radius: 2.5rem;
        }
    }
    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);
        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }
    .formFieldWrapperSubject.degree {
        @media (max-width: 1277px) {
            justify-content: flex-end;
        }
        @media (max-width: 1170px) {
            justify-content: space-between;
        }
        .actionButtonWrapper.degree {
            margin-top: -1rem;
            @media (max-width: 1444px) {
                margin-top: -2.7rem;
            }
            @media (max-width: 1277px) {
                margin-top: 0rem;
            }
            @media (max-width: 1170px) {
                margin-top: 0.8rem;
            }
            @media (max-width: 1000px) {
                margin-top: 0rem;
            }
        }
    }
    .formFieldWrapperSubject.marks {
        @media (max-width: 1180px) {
            justify-content: space-between;
        }
    }
    .formFieldWrapperSubject {
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
        @media (max-width: 1180px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
        .helperText {
            font-size: 12px;
            margin-left: 1rem;
            line-height: 1.5rem;
        }
    }
    .actionButtonWrapper {
        ${"" /* margin-top: 2rem; */}
        display: flex;
        justify-content: flex-end;
        @media (max-width: 500px) {
            margin-top: 1rem;
        }
    }
`;

export const EditSAcademic = ({ callbackOnComplete }) => {
    return (
        <StyledComponent>
            <h2 className="formTitle">UPDATE ACADEMIC INFORMATION</h2>
            <StepTwoContainer
                asSubComponent={true}
                callBackCloseAsSubComponent={callbackOnComplete}
            />
        </StyledComponent>
    );
};
