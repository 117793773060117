import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import GoogleBtn from "../../../static/images/logo/GoogleIcon.svg";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import React, {useState} from "react";
import {useCheckTokenFromGoogle} from "../../../helper/google/oauth_hooks";
import {useNavigate} from "react-router-dom";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {axiosServerInstance} from "../../../config/api/axios";
import {useEffect} from "react";
import styled from "styled-components";
import {formatCNIC} from "../../../helper/formatters/formatCNIC";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {useDispatch} from "react-redux";
import {fetchUserProfile} from "../../../config/store/actions";
import {Spinner} from "../../Global/graphical/Spinner";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";

const Wrapper = styled.div`
  .ReactModal__Content {
    width: 100%;
  }
  .BoxOuter {
    width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #1c4489;
    }
    .inputOuter {
      width: 65%;

      text-align: center;
    }
    .googleLoginBtn {
      width: 100%;
      margin: 0rem;
      padding: 0.3rem;
      Button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.1px;
        padding-right: 1rem;
        border: 5px solid #fff;
        gap: 1rem;
        color: #fff;
        background-color: #4f86ec;
      }
      .imgOuter {
        background-color: #fff;
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          padding: 0.2rem;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

export const GoogleSignUpForm = () => {
    const [data, setData] = useState({})
    const navigate = useNavigate();
    const [processingStates, setProcessingStates] = useState({})
    const [googleOauthRedirectURI, setGoogleOauthRedirectURI] = useState("")
    const dispatch = useDispatch()


    const handleOnClickProceed = async () => {
        setProcessingStates({
            ...processingStates,
            signingUp: true
        })
        axiosServerInstance()
            .post("/utils/student/check_if_exists_by_cnic/", data)
            .then((response) => {
                if (response.data.status === false) {
                    initiateGoogleOauth()
                } else {
                    notifyFailure("The CNIC is already registered with an account")

                    setProcessingStates({
                        ...processingStates,
                        signingUp: false
                    })
                }
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
                setProcessingStates({
                    ...processingStates,
                    signingUp: false
                })
            });
    };
    const initiateGoogleOauth = () => {
        let requestData = {
            ...data,
            redirect_uri: `${window.location.protocol + "//" + window.location.host}/signup`,
        };
        axiosServerInstance()
            .post("/auth/initialize_google_signup/", requestData)
            .then((response) => {
                window.location.href = response.data.oauth_url;
            })
            .catch((err) => {
                notifyApiErrorMessage(err)

                setProcessingStates({
                    ...processingStates,
                    signingUp: false
                })
            });
    };
    useEffect(() => {
        if (googleOauthRedirectURI) {
            let dataOath = {
                redirect_uri_content: googleOauthRedirectURI,
            };

            setProcessingStates({
                ...processingStates,
                signingUp: true
            })
            axiosServerInstance()
                .post("/auth/finalize_google_signup/", dataOath)
                .then((response) => {
                    const {refresh, access} = response.data.token;
                    window.localStorage.setItem("access_token", access);
                    window.localStorage.setItem("refresh_token", refresh);
                    dispatch(fetchUserProfile)
                    notifySuccess("Success")
                    navigate("/", {replace: true});
                })
                .catch((err) => {
                    notifyApiErrorMessage(err)
                    setProcessingStates({
                        ...processingStates,
                        signingUp: false
                    })
                });
        }
    }, [googleOauthRedirectURI]);

    useCheckTokenFromGoogle(setGoogleOauthRedirectURI);
    return (
        <Wrapper>
            <div className="BoxOuter">
                <label>CNIC/CRC/B-Form</label>
                <InputComponent
                    placeholder={""}
                    type="text"
                    value={data?.cnic}
                    onChange={(e) => handleOnInputChange(e, "cnic", setData, data)}
                />
                <SizedBox height={2.5}/>

                <div className="googleLoginBtn">
                    {processingStates?.signingUp ? <Spinner size={1.5}/> : <Button
                        fontWeight={500}
                        fontSize={1}
                        maxWidth={16}
                        height={4}
                        BgColor="#ddd"
                        border={"border-color"}
                        borderRadius={0}
                        paddingTopBottom={0.8}
                        onClick={(e) => {
                            e.preventDefault()
                            handleOnClickProceed()
                        }}
                    >
                        <div className="imgOuter">
                            <img src={GoogleBtn} alt="google btn"/>
                        </div>
                        Sign Up with Google
                    </Button>
                    }
                </div>
            </div>
        </Wrapper>
    );
};
