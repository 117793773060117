import React, { useEffect, useState } from "react";
import { InputComponent } from "../../../Authentication/components/InputELement";
import { Button } from "../../../Global/Button";
import { FormFieldsWrapper } from "../../../Global/graphical/data_input/FormFieldsWrapper";
import { Spinner } from "../../../Global/graphical/Spinner";
import styled from "styled-components";
import { ImagePickerComponent } from "../../../Global/graphical/data_input/ProfilePicturePickerComponent";
import { SizedBox } from "../../../Global/SizedBox";
import { MainWrapper } from "../../../Global/MainWrapper";
const StyledComponent = styled.div`
    .formTitle {
        font-size: 1.5rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin: 2rem 0;
    }
    .profilePicturePickerWrapper {
        padding: 0 1.5rem;
    }
    .profilePicturePickerWrapper > div {
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        .controlsWrapperImage .text {
            display: none;
        }
        .controlsWrapperImage button {
            background-color: #fff;
            border: none;
            color: black;
        }
        .imageWrapper {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .formFieldWrapperOuter {
        padding: 2rem;
        box-shadow: 0 10px 10px -3px #cecece;
        border-radius: 20px;
    }
    .formFieldWrapper.subjects {
        @media (max-width: 960px) {
            flex-direction: column;
        }
    }
    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
        }
        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .inputOuter.w-50 {
                width: 49.1%;
                @media screen and (max-width: 45rem) {
                    width: 100%;
                }
            }
            .inputOuter.w-32 {
                width: 32.2%;

                @media (max-width: 1450px) {
                    width: 49%;
                }
                @media (max-width: 1350px) {
                    width: 48%;
                }
                @media (max-width: 945px) {
                    width: 100%;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            .inputOuter.w-28 {
                width: 28.9%;

                @media (max-width: 1250px) {
                    width: 48%;
                }
                @media (max-width: 945px) {
                    width: 100%;
                }
            }
            .inputOuter {
                @media (max-width: 945px) {
                    width: 100%;
                }
            }
            .inputOuter.w-38 {
                width: 41%;
                @media screen and (max-width: 45rem) {
                    width: 100%;
                }
            }
            .textarea {
                width: 100%;
            }
        }
    }
    .actionButtonWrapper {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
    .actionButtonWrapperSubject {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
`;

export const AddStudent = () => {
    const [data, setData] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [profilePicture, setProfilePicture] = useState(null);

    const handleOnChangeInput = (e, field) => {
        let tempData = { ...data };
        tempData[field] = e.target.value;
        setData(tempData);
    };

    const handleOnClickProceed = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (let field in data) {
            formData.append(field, data[field]);
        }
        proceedAddSubUser(formData);
    };

    const proceedAddSubUser = (data) => {
        // axiosServerInstance().post('/auth/login', validatedData).then((response) => {
        //
        // }).catch((err) => {
        //     setShowSpinner(false)
        // });
    };

    const cleanState = () => {
        setProfilePicture(null);
        let tempData = {};
        for (let field in data) {
            tempData[field] = "";
        }
        setData(tempData);
    };

    useEffect(() => {
        if (profilePicture) {
            if (typeof profilePicture != "string") {
                let tempData = { ...data };
                tempData["picture"] = profilePicture;
                setData(tempData);
            }
        }
    }, [profilePicture]);
    return (
        <MainWrapper pageTitle={"ADD STUDENT"}>
            <StyledComponent>
                <FormFieldsWrapper className="formFieldWrapperOuter" autocomplete="off">
                    <div className="formFieldWrapper">
                        <div className="formfieldInner">
                            <div className="inputOuter w-28">
                                <label>Student ID</label>
                                <InputComponent
                                    placeholder={""}
                                    type="text"
                                    fontSize={1}
                                    value={data?.student_id}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "student_id");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-28">
                                <label>Student Name</label>
                                <InputComponent
                                    placeholder={"Enter Your Name"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.student_name}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "student_name");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-28">
                                <label>Gender</label>
                                <InputComponent
                                    placeholder={"Male/Female"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.gender}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "gender");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-28">
                                <label>City</label>
                                <InputComponent
                                    placeholder={"Enter Your City"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.city}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "city");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-28">
                                <label>Campus</label>
                                <InputComponent
                                    placeholder={"Enter Any Campus"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.branch_campus}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "branch_campus");
                                    }}
                                />
                            </div>
                        </div>
                        <div className="profilePicturePickerWrapper">
                            <SizedBox height={3} />
                        </div>
                    </div>
                    <h2 className="formTitle">Personal Information</h2>
                    <div className="formFieldWrapper">
                        <div className="formfieldInner">
                            <div className="inputOuter w-32">
                                <label>Full Name</label>
                                <InputComponent
                                    placeholder={"Enter Your Full Name"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.full_name}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "full_name");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-32">
                                <label>CNIC</label>
                                <InputComponent
                                    placeholder={"xxxxx-xxxxxxx-x"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.CNIC}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "CNIC");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-32">
                                <label>Gender</label>
                                <InputComponent
                                    placeholder={"Male/Female"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.gender}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "gender");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-32">
                                <label>City</label>
                                <InputComponent
                                    placeholder={"Enter Your City"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.city}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "city");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-32">
                                <label>Father Name</label>
                                <InputComponent
                                    placeholder={"Enter Your Father/Guardian Name"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.father_name}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "father_name");
                                    }}
                                />
                            </div>
                            <div className="inputOuter w-32">
                                <label>Mobile Number</label>
                                <InputComponent
                                    placeholder={"92xxxxxxxxxx"}
                                    type="text"
                                    fontSize={1}
                                    value={data?.mobile_number}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "mobile_number");
                                    }}
                                />
                            </div>
                            <div className="inputOuter textarea">
                                <label>Residential Address</label>
                                <InputComponent
                                    placeholder={"Enter Your Address here."}
                                    type="text"
                                    fontSize={1}
                                    value={data?.residential_address}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "residential_address");
                                    }}
                                />
                            </div>
                        </div>
                        <div className="profilePicturePickerWrapper">
                            <ImagePickerComponent
                                image={profilePicture}
                                setImage={setProfilePicture}
                            />
                            <SizedBox height={3} />
                        </div>
                    </div>
                    <h2 className="formTitle">SUBJECTS</h2>
                    <div className="formFieldWrapper subjects">
                        <div className="formfieldInner">
                            <div className="inputOuter w-28">
                                <label>Subject Name</label>
                                <InputComponent
                                    type="text"
                                    fontSize={1}
                                    value={data?.subject_name}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "subject_name");
                                    }}
                                />
                            </div>
                            <div className="inputOuter ">
                                <label>Grade</label>
                                <InputComponent
                                    type="text"
                                    fontSize={1}
                                    value={data?.Grade}
                                    onChange={(e) => {
                                        handleOnChangeInput(e, "Grade");
                                    }}
                                />
                            </div>
                            <div className="actionButtonWrapperSubject">
                                <Button
                                    fontSize={0.9}
                                    maxWidth={14}
                                    borderRadius={2.4}
                                    paddingTopBottom={1}
                                    paddingLeftRight={2}
                                    onClick={handleOnClickProceed}
                                >
                                    + ADD SUBJECT
                                </Button>
                            </div>
                        </div>
                        <div className="actionButtonWrapper">
                            {showSpinner ? (
                                <Spinner size={1.5} />
                            ) : (
                                <Button
                                    fontSize={0.9}
                                    maxWidth={14}
                                    height={3.3}
                                    borderRadius={2.4}
                                    paddingTopBottom={0.2}
                                    paddingLeftRight={3}
                                    onClick={handleOnClickProceed}
                                >
                                    UPDATE
                                </Button>
                            )}
                        </div>
                    </div>
                </FormFieldsWrapper>
            </StyledComponent>
        </MainWrapper>
    );
};
