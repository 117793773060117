import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Button} from "../../../../../Global/Button";
import {FormFieldsWrapper} from "../../../../../Global/graphical/data_input/FormFieldsWrapper";
import {Spinner} from "../../../../../Global/graphical/Spinner";
import {useParams} from "react-router-dom";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {notifyFailure} from "../../../../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";
import {notifySuccess} from "../../../../../../helper/notifications/notifySuccess";
import {PROStudentPreferencesContext} from "../Container";
import {GlobalUserProfileContext} from "../../../../../../App";
import {parseArrayToSelectValues} from "../../../../../../helper/form_inputs/parseEnumToSelectValues";

const StyledComponent = styled.div`
  margin: 1rem 1.5rem;

  .formTitle {
    font-size: 1.4rem;
    margin-left: 1rem;
    color: var(--custom-btn-bg);
    margin-bottom: 2rem;
  }

  .formFieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (max-width: 45rem) {
      grid-template-columns: repeat(1, 1fr);
    }

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: 1rem;

      .inputOuter {
        flex: auto;
        width: 32%;
      }

      label {
        display: inline-block;
      }

      .Seats {
        display: inline-block;
        color: var(--custom-btn-bg);
        margin-left: 1rem;
        font-weight: 700;
        font-size: 18px;
      }

      .ResultRadioMode {
        width: 100%;

        input {
          margin: 0 1rem;
        }
      }

      .inputOuter.w-38 {
        width: 300px;
        max-width: 100%;
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }
    }

    .actionButtonWrapper {
      button {
        @media (max-width: 500px) {
          padding: 0.7rem 2rem;
        }
      }
    }
  }

  .spinnerWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  select {
    width: 100%;
    height: 3rem;
    text-indent: 10px;
    background: var(--custom-input-bg);
    border-radius: 25px;
    outline: none;
    background-image: none;
    overflow: hidden;
    border: 1px solid var(--custom-input-border);

    option {
      border-radius: 25px;
      width: 32%;
      background: var(--custom-input-bg);
    }
  }

`;
export const AddSubjectPreference = ({callbackCancel, callbackProceed, subjectPreferenceID}) => {
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [selectedTeacher, setSelectedTeacher] = useState(null)
    const [selectedClass, setSelectedClass] = useState(null)
    const [availableSubjects, setAvailableSubjects] = useState([])
    const [availableTeachers, setAvailableTeachers] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessingAvailableSubjects, setIsProcessingAvailableSubjects] = useState(false)
    const [isProcessingAvailableTeachers, setIsProcessingAvailableTeachers] = useState(false)
    const [currentAvailableSeats, setCurrentAvailableSeats] = useState(null)
    const {id: studentID} = useParams()
    const {getAvailableSeat} = useContext(PROStudentPreferencesContext)
    const {profile} = useContext(GlobalUserProfileContext)

    const saveDataToServer = async () => {
        const dataEndpoint = `/pro/students/${studentID}/admission/preference/subjects`
        const data = {
            subject_name: availableSubjects.find((item) => item.id.toString() === selectedSubject)?.name,
            subject_id: selectedSubject ? Number.parseInt(selectedSubject) : null,
            teacher_name: availableTeachers.find((item) => item.teacher_id.toString() === selectedTeacher)?.teacher_name,
            teacher_id: selectedTeacher ? Number.parseInt(selectedTeacher) : null,
            class_name: availableTeachers.find((item) => item.class_id.toString() === selectedClass)?.class_name,
            class_id: selectedClass ? Number.parseInt(selectedClass) : null
        }
        await axiosServerInstance()
            .post(dataEndpoint, data)
            .then((response) => {
                notifySuccess("Success")
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const fetchAvailableSubjects = async () => {
        const endpoint = `/pro/students/${studentID}/admission/preference/city_campus`;
        setIsProcessingAvailableSubjects(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                let selectedCampus = response.data?.results[0]?.campus_id;
                if (selectedCampus) {
                    let endpoint = `/utils/student/preference/fetch_subjects_available_in_campus/?campus_id=${selectedCampus}`;
                    axiosServerInstance()
                        .get(endpoint)
                        .then((response) => {
                            setAvailableSubjects(response.data);
                            setIsProcessingAvailableSubjects(false)
                        })
                        .catch((err) => {
                            notifyFailure("Error in fetching available subjects");
                            notifyApiErrorMessage(err);
                            setIsProcessingAvailableSubjects(false)
                        });
                } else {
                    setIsProcessingAvailableSubjects(false)
                }
            })
            .catch((err) => {
                notifyFailure("Error in fetching available subjects");
                notifyApiErrorMessage(err);
                setIsProcessingAvailableSubjects(false)
            });
    };

    useEffect(() => {
        fetchAvailableSubjects()
    }, [subjectPreferenceID])

    useEffect(() => {
        const fetchAvailableTeachers = async () => {
            let endpoint = `/utils/student/preference/fetch_teachers_teaching_a_subject/?subject_id=${selectedSubject}&campus_id=${profile?.pro_account_data?.campus_id}`;
            setIsProcessingAvailableTeachers(true)
            await axiosServerInstance()
                .get(endpoint)
                .then((response) => {
                    setAvailableTeachers(response.data);
                })
                .catch((err) => {
                    notifyFailure(`Error in fetching available teachers to take selected subject`);
                    notifyApiErrorMessage(err);
                });
            setIsProcessingAvailableTeachers(false)
        }
        if (selectedSubject) {
            fetchAvailableTeachers()
        }
    }, [selectedSubject])

    useEffect(() => {
        const execute = async () => {
            setCurrentAvailableSeats(await getAvailableSeat(selectedSubject, selectedTeacher, selectedClass))
        }
        execute()
    }, [selectedSubject, selectedTeacher, selectedClass])
    return (
        <StyledComponent>
            <h2 className="formTitle">ADD SUBJECT</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-38">
                        <label>Subject</label>
                        {!isProcessingAvailableSubjects ? <select
                            value={selectedSubject}
                            onChange={(e) => {
                                setSelectedSubject(e.target.value)
                            }}
                        >
                            {parseArrayToSelectValues(availableSubjects).map((subject) => <option
                                selected={subject?.id?.toString() === selectedSubject}
                                value={subject?.id?.toString()}>{subject?.name}</option>)}
                        </select> : <div className="spinnerWrapper"><Spinner size={1.5}/></div>}
                    </div>
                    <div className="inputOuter w-38">
                        <label>Teacher</label>
                        {!isProcessingAvailableTeachers ? <select
                            value={selectedTeacher}
                            onChange={(e) => {
                                setSelectedTeacher(e.target.value)
                            }}
                        >
                            {parseArrayToSelectValues(availableTeachers.map((item) => ({
                                teacher_id: item?.teacher_id,
                                teacher_name: item?.teacher_name
                            })).filter((item, index, array) => {
                                return array.map((i) => i.teacher_id).indexOf(item.teacher_id) === index;
                            })).map((item) => <option
                                selected={item?.teacher_id?.toString() === selectedTeacher}
                                value={item?.teacher_id?.toString()}>{item?.teacher_name}</option>)}
                        </select> : <div className="spinnerWrapper"><Spinner size={1.5}/></div>}
                    </div>
                    <div className="inputOuter w-38">
                        <label>Class</label>
                        {!isProcessingAvailableTeachers ? <select
                            value={selectedClass}
                            onChange={(e) => {
                                setSelectedClass(e.target.value)
                            }}
                        >
                            {(selectedTeacher ? parseArrayToSelectValues(availableTeachers.filter((item) => item?.teacher_id.toString() === selectedTeacher.toString())) : []).map((item) =>
                                <option
                                    selected={item?.class_id?.toString() === selectedClass}
                                    value={item?.class_id?.toString()}>{item?.class_name}</option>)}
                        </select> : <div className="spinnerWrapper"><Spinner size={1.5}/></div>}
                    </div>
                </div>
                <div className="formfieldInner">
                    <div className="inputOuter w-38 SeatsOuter">
                        <label>Available seats:</label>
                        <div className="Seats">{currentAvailableSeats || 'Nil'}</div>
                    </div>
                    <div className="actionButtonWrapper">
                        {isProcessing ? (
                            <Spinner size={1.5}/>
                        ) : (
                             currentAvailableSeats && <Button
                                fontSize={0.9}
                                maxWidth={23}
                                borderRadius={2.4}
                                paddingTopBottom={0.85}
                                paddingLeftRight={7}
                                onClick={async (e) => {
                                    e.preventDefault()
                                    await saveDataToServer()
                                    callbackProceed()
                                }}
                            >
                                SAVE
                            </Button>
                        )}
                    </div>
                </div>
            </FormFieldsWrapper>
        </StyledComponent>
    );
};
