import {AuthMainContainer} from "../components/AuthMainContainer";
import {EmailVerificationForm} from "./Form";

export const EmailVerificationContainer = () => {
    return (
        <AuthMainContainer mode={"email_verification"}>
            <EmailVerificationForm/>
        </AuthMainContainer>
    );
};
