import styled from 'styled-components'
import { ProfilePictureBoard } from './ProfilePictureBoard'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import Notification from '../../static/images/navigation/Group 33.svg'
import profileImage from '../../static/images/dropdownMenu/icons.svg'
import React from 'react'
import { GlobalUserProfileContext } from '../../App'
import { SET_ACTIVE_NAVIGATION } from '../../config/store/constants'
import { useDispatch } from 'react-redux'
import { BellNotification } from './BellNotification'

const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    .img {
        width: 30px;
        height: 30px;
        box-shadow: 0 0 10px -3px #8c8c8c;
        border-radius: 50%;
    }
    .name {
        margin-right: 10px;
        font-size: 0.9rem;
        font-family: var(--font-1);

        .first {
            color: var(--custom-Black);
        }
        .last {
            color: var(--custom-Black);
            margin-left: 0.3rem;
        }
    }
    .notification {
        position: relative;
        .badgeNotify {
            color: var(--custom-secondary-bg);
            background-color: var(--custom-secondary-bg);
            position: absolute;
            top: 0;
            right: -5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
`

export const AccountBoard = () => {
    const { profile } = useContext(GlobalUserProfileContext)

    const [isNotificationOpen, setIsNotificationOpen] = useState(false)
    const [isNotificationShow, setIsNotificationShow] = useState(false)

    useEffect(() => {
        isNotificationOpen === true && setIsNotificationShow(true)
    }, [isNotificationOpen])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleOnClickProfileBoard = e => {
        e.preventDefault()
    }
    const handleNotification = () => {
        setIsNotificationOpen(!isNotificationOpen)
    }

    return (
        <Wrapper>
            <ProfilePictureBoard size={2.5}>
                <img
                    className='img'
                    src={profile?.picture?.file || profileImage}
                    alt=''
                    onClick={handleOnClickProfileBoard}
                />
            </ProfilePictureBoard>
            <div className='name'>
                <span className='first'>{profile?.full_name}</span>
            </div>
            <div
                className='notification'
                onClick={() => {
                    handleNotification()
                }}
            >
                {isNotificationShow === false && (
                    <span className='badgeNotify'></span>
                )}
                <img src={Notification} alt='notification' />
            </div>
            {isNotificationOpen && <BellNotification />}
        </Wrapper>
    )
}
