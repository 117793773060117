import React from "react";
import styled from "styled-components";
// import Edit from "../../../../../static/images/table/Group 46.svg";
// import Delete from "../../../../../static/images/table/Path 39.png";
import { TableBodyPicture } from "./TableBodyPicture";
import { ActionsView1 } from "./actions/ActionView1";
import { ActionsEdit } from "./actions/ActionEdit";
import { ActionsView2 } from "./actions/ActionView2";
import { ActionsView3 } from "./actions/ActionView3";
import { ActionPrint } from "./actions/ActionPrint";
import { ActionUpdate } from "./actions/ActionUpdate";
import { ActionsDelete } from "./actions/ActionDelete";

const Badge = styled.div`
  background-color: var(--custom-${({ color }) => color});
  padding: 0.4rem 0.6rem;
  font-size: 0.9rem !important;
  color: #fff;
  overflow: auto;
  min-width: 110px;
  text-indent: 0px;
  max-width: min-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2rem;
  border-radius: 2.5rem;
`;

// const actionIconsMapping = {
//   edit: Edit,
//   delete: Delete,
//   update: "Update",
// };
export const TableBody = ({
  bodyData,
  actionsView1,
  actionsUpdate,
  actionsView2,
  actionsView3,
  actionEdit,
  actionDelete,
  actionsPrint,
}) => {
  return (
    bodyData &&
    bodyData?.map((data) => {
      return (
        <tr key={data.id}>
          {data?.data?.map((item) => (
            <td>
              {item.type === "image" ? (
                <TableBodyPicture picture={item.value} />
              ) : item.type.startsWith("view1") ? (
                <ActionsView1 actionsView1={actionsView1} dataID={data.id} />
              ) : item.type.startsWith("delete") ? (
                <ActionsDelete actionDelete={actionDelete} dataID={data.id} />
              ) : item.type.startsWith("edit") ? (
                <ActionsEdit actionEdit={actionEdit} dataID={data.id} />
              ) : item.type.startsWith("update") ? (
                <ActionUpdate actionsUpdate={actionsUpdate} dataID={data.id} />
              ) : item.type.startsWith("view2") ? (
                <ActionsView2 actionsView2={actionsView2} dataID={data.id} />
              ) : item.type.startsWith("view3") ? (
                <ActionsView3 actionsView3={actionsView3} dataID={data.id} />
              ) : item.type.startsWith("print") ? (
                <ActionPrint actionsPrint={actionsPrint} dataID={data.id} />
              ) : item.type.startsWith("badge") ? (
                <Badge color={item.type}> {item.value} </Badge>
              ) : item.type.startsWith("list") ? (
                <div className='list'>
                  {item.value?.map((x) => (
                    <span>{x}</span>
                  ))}
                </div>
              ) : item.type.startsWith("file") ? (
                <div className='file'>
                  {item?.value ? (
                    <a href={item?.value?.file} target={"_blank"}>
                      Click to view
                    </a>
                  ) : null}
                </div>
              ) : (
                item.value
              )}
            </td>
          ))}
        </tr>
      );
    })
  );
};
