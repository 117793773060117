import { AuthMainContainer } from '../components/AuthMainContainer'
import { PhoneVerificationForm } from './Form'

export const PhoneVerificationContainer = () => {
    return (
        <AuthMainContainer mode={'phone_verification'}>
            <PhoneVerificationForm />
        </AuthMainContainer>
    )
}
