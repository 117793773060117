import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import { FormFieldsWrapper } from "../../../graphical/data_input/FormFieldsWrapper";

import { useEffect, useState } from "react";
import { GlobalUserProfileContext } from "../../../../../App";
import { notifyFailure } from "../../../../../helper/notifications/notifyFailure";
import { axiosServerInstance } from "../../../../../config/api/axios";
import { notifyApiErrorMessage } from "../../../../../helper/notifications/notifyApiErrorMessage";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../../../config/store/actions";

import { formatBatch } from "../../../../../helper/formatters/formatBatch";

const StyledComponent = styled.div`
  border-radius: 20px;
  box-shadow: 0 10px 10px -3px #cfcfcf;
  padding: 3rem;
  @media (max-width: 500px) {
    padding: 1rem;
  }

  .disabled {
    cursor: none;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .inputOuter {
        width: 32%;
        @media (max-width: 1445px) {
          width: 31.5%;
        }
        @media (max-width: 1423px) {
          width: 48.5%;
        }
        @media (max-width: 1203px) {
          width: 48%;
        }

        @media (max-width: 1180px) {
          width: 100%;
        }
      }

      .textarea {
        width: 66%;
        @media (max-width: 1423px) {
          width: 100%;
        }
      }
    }

    select {
      width: 100%;
      height: 3rem;
      text-indent: 10px;
      background: var(--custom-input-bg);
      border-radius: 25px;
      outline: none;
      background-image: none;
      overflow: hidden;
      border: 1px solid var(--custom-input-border);

      option {
        border-radius: 25px;
        width: 32%;
        background: var(--custom-input-bg);
      }
    }
  }
`;

const StepZeroContainer = forwardRef(({ jumpToStep }, ref) => {
  const currentStep = useRef(1);
  const [data, setData] = useState("");

  const selectArray = ["Batch"];
  const [selectedOptions, setSelectedOptions] = useState({
    Batch: "",
  });

  const [optionData, setOptionData] = useState({
    Batch: {
      data: [],
      currentPage: 1,
      hasMore: true,
    },
  });

  const { profile } = useContext(GlobalUserProfileContext);
  const dispatch = useDispatch();

  const fetchAndParseDataFromAPI = async (optionType, page = 1) => {
    let batch_id = profile?.account_data?.batch?.id;
    if (batch_id) {
      setSelectedOptions({
        Batch: batch_id,
      });
      setOptionData({
        Batch: {
          data: [
            {
              academic_id: batch_id,
              academic_year: formatBatch(
                profile?.account_data?.batch?.first_year
              ),
            },
          ],
          currentPage: 1,
          hasMore: false,
        },
      });
      setData({
        batch: batch_id,
      });
    }

    if (optionData[optionType].hasMore) {
      let endpoint = "";
      let pagination = `limit=${20}&offset=${(page - 1) * 20}`;
      if (optionType === "Batch") {
        endpoint = `/student/batch?${pagination}`;
      }

      const response = await axiosServerInstance().get(endpoint);
      const mappedData = response.data.results.map((item) => ({
        academic_id: item?.id,
        academic_year: formatBatch(item?.first_year),
      }));

      setOptionData((prevData) => {
        // Check for duplicates before adding data
        const newData = mappedData.filter(
          (newItem) =>
            !prevData[optionType].data.some(
              (prevItem) => prevItem.academic_id === newItem.academic_id
            )
        );
        return {
          [optionType]: {
            data: [...prevData[optionType].data, ...newData],
            currentPage: page,
            hasMore: response.data.next !== null,
          },
        };
      });
    }
  };

  const computeDataAndSendToServer = async () => {
    const accountDataEndpoint = "/student/admission/information";
    let accountDataUpdateStatus = false;

    await axiosServerInstance()
      .patch(accountDataEndpoint, data)
      .then((response) => {
        accountDataUpdateStatus = true;
      })
      .catch((err) => {
        notifyApiErrorMessage(err);
      });

    dispatch(fetchUserProfile);

    if (
      accountDataUpdateStatus &&
      profile?.account_data?.admission_application_stage < 7
    ) {
      await axiosServerInstance()
        .patch(accountDataEndpoint, {
          admission_application_stage: currentStep.current + 1,
        })
        .then((response) => {
          accountDataUpdateStatus = true;
        })
        .catch((err) => {
          notifyApiErrorMessage(err);
          accountDataUpdateStatus = false;
        });
    }
    return accountDataUpdateStatus;
  };

  useImperativeHandle(ref, () => ({
    async isValidated() {
      if (selectedOptions["Batch"] !== "") {
        const success = await computeDataAndSendToServer();
        if (success) {
          return true;
        } else {
          notifyFailure("Failed to update data");
          return false;
        }
      } else {
        notifyFailure("Batch is required");
        return false;
      }
    },
  }));

  useEffect(() => {
    fetchAndParseDataFromAPI("Batch");
  }, []);

  const handleOptionChange = (optionType, value) => {
    if (value !== "loadMore") {
      setSelectedOptions((prevData) => ({
        ...prevData,
        Batch: value,
      }));
      setData({ batch: value });
    } else {
      handleScroll(optionType);
    }
  };

  const handleScroll = (optionType) => {
    // Load the next page of data
    fetchAndParseDataFromAPI(
      optionType,
      optionData[optionType].currentPage + 1
    );
  };

  console.log(optionData);

  return (
    <StyledComponent>
      <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
        <div className='formfieldInner'>
          <div className='inputOuter'>
            <label>Please select your preferred admission batch:</label>

            {selectArray.map((optionType, index) => (
              <select
                key={index}
                value={selectedOptions[optionType]}
                onChange={(e) => handleOptionChange(optionType, e.target.value)}
              >
                <option value=''>{optionType}</option>
                {optionData[optionType].data.map((item, index) => (
                  <option key={index} value={item.academic_id}>
                    {item.academic_year}
                  </option>
                ))}
                {optionData[optionType].hasMore &&
                  optionData[optionType].data.length > 0 && (
                    <option value='loadMore'>load more</option>
                  )}
              </select>
            ))}
          </div>
        </div>
      </FormFieldsWrapper>
    </StyledComponent>
  );
});

export default StepZeroContainer;
