import styled from "styled-components"
import { HomeWidgetContainer } from "../../Global/homeComponents/Container"
import { SizedBox } from "../../Global/SizedBox"
import { useNavigate } from "react-router-dom"
import { HorizontalRule } from "../../Global/HorizontalRule"
import { useContext, useEffect, useState } from "react"
import { GlobalUserProfileContext } from "../../../App"
import { axiosServerInstance } from "../../../config/api/axios"
import { notifyApiErrorMessage } from "../../../helper/notifications/notifyApiErrorMessage"
import { notifyFailure } from "../../../helper/notifications/notifyFailure"
import { STUDENT_ADMISSION_STATUS_TYPES } from "../../../config/store/constants"

const Wrapper = styled.div`
    width: 100%;
    min-height: fit-content;
    height: 100%;
    margin-top: 1rem;
    padding: 0 2rem;
    @media (max-width: 500px) {
        padding: 0;
    }
`

export const HomeboardingHeader = () => {
    const navigate = useNavigate()
    const { profile } = useContext(GlobalUserProfileContext)
    const [allAdmissionStatusDetails, setAllAdmissionStatusDetails] = useState(
        []
    )

    const fetchAllAdmissionStatusDetails = async () => {
        const endpoint = `/student/admission/status/all`
        return await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setAllAdmissionStatusDetails(response.data?.results || [])
            })
            .catch((err) => {
                notifyFailure("Error in fetching current admission status")
            })
    }

    useEffect(() => {
        fetchAllAdmissionStatusDetails()
    }, [])

    return (
        <Wrapper>
            <h2>WELCOME {profile?.full_name?.toUpperCase()}</h2>
            <SizedBox height={2} />
            <HomeWidgetContainer
                handleOnClickButton={(e) => {
                    if (
                        allAdmissionStatusDetails.find(
                            (item) =>
                                item.admission_status ===
                                STUDENT_ADMISSION_STATUS_TYPES.APPLIED
                        )
                    ) {
                        notifyFailure(
                            "You can't access the page you're trying at the moment as your admission is currently under processing."
                        )
                    } else {
                        navigate("/admission_information")
                    }
                }}
                WidgetButtonName={
                    allAdmissionStatusDetails.find(
                        (item) =>
                            item.admission_status ===
                            STUDENT_ADMISSION_STATUS_TYPES.APPLIED
                    )
                        ? "APPLIED"
                        : allAdmissionStatusDetails.find(
                              (item) =>
                                  item.admission_status ===
                                  STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                          )
                        ? "CANCELLED"
                        : "APPLY NOW"
                }
                widgetTitle={"ADMISSION FORM"}
                WidgetButtonColor={
                    allAdmissionStatusDetails.find(
                        (item) =>
                            item.admission_status ===
                            STUDENT_ADMISSION_STATUS_TYPES.APPLIED
                    )
                        ? "badge-success"
                        : allAdmissionStatusDetails.find(
                              (item) =>
                                  item.admission_status ===
                                  STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                          ) && "badge-danger"
                }
            />
            <HorizontalRule />
            <HomeWidgetContainer
                handleOnClickButton={() => {}}
                WidgetButtonName={
                    allAdmissionStatusDetails.find(
                        (item) =>
                            item.admission_status ===
                            STUDENT_ADMISSION_STATUS_TYPES.INTERVIEWED
                    )
                        ? "Completed"
                        : allAdmissionStatusDetails.find(
                              (item) =>
                                  item.admission_status ===
                                  STUDENT_ADMISSION_STATUS_TYPES.INTERVIEW_SCHEDULED
                          )
                        ? "Active"
                        : "Pending"
                }
                widgetTitle={"INTERVIEW CALL"}
                WidgetButtonColor={"home-btn-bg1"}
            />
            <HomeWidgetContainer
                handleOnClickButton={() => {}}
                WidgetButtonName={
                    allAdmissionStatusDetails.find(
                        (item) =>
                            item.admission_status ===
                            STUDENT_ADMISSION_STATUS_TYPES.RESERVED
                    )
                        ? allAdmissionStatusDetails.find(
                              (item) =>
                                  item.admission_status ===
                                  STUDENT_ADMISSION_STATUS_TYPES.CONFIRMED
                          )
                            ? "Completed"
                            : "In Progress"
                        : "Pending"
                }
                widgetTitle={"FEE SUBMISSION"}
                WidgetButtonColor={"home-btn-bg2"}
            />
            <HomeWidgetContainer
                handleOnClickButton={() => {}}
                WidgetButtonName={
                    allAdmissionStatusDetails.find(
                        (item) =>
                            item.admission_status ===
                            STUDENT_ADMISSION_STATUS_TYPES.CONFIRMED
                    )
                        ? "Confirmed"
                        : "Pending"
                }
                widgetTitle={"SEAT CONFIRMATION"}
                WidgetButtonColor={"home-btn-bg3"}
            />
        </Wrapper>
    )
}
