import styled from "styled-components";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../../../../static/images/table/Path 123.svg";
import { Button } from "../../../Global/Button";
import { ModalComponent } from "../../../Global/graphical/Modal";
import { AddReminder } from "./AddReminder";
import { EditReminder } from "./EditReminder";
import { StudentPROData } from "../../../../config/DataList/studentsPROData/StudentsPROData";
import { STUDENT_ADMISSION_STATUS_TYPES } from "../../../../config/store/constants";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { axiosServerInstance } from "../../../../config/api/axios";
import { formatQueryDictToQueryParams } from "../../../../helper/formatters/formatDictToParams";
import { Spinner } from "../../../Global/graphical/Spinner";
import { SizedBox } from "../../../Global/SizedBox";

const StyledComponent = styled.div`
    .ReactModalPortal {
        .ReactModal__Overlay {
            .ReactModal__Content.ReactModal__Content--after-open {
                @media (max-width: 500px) {
                    padding: 2rem 0.5rem;
                }
            }
        }
    }

    .searchActions {
        @media (max-width: 1250px) {
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        .reminderBtn {
            flex: auto;
            display: flex;
            justify-content: flex-end;

            button {
                @media (max-width: 500px) {
                    padding: 0.6rem 0.6rem;
                    max-width: 10rem;
                }
            }
        }
    }

    .tableOuter {
        max-height: 560px;
        overflow: auto;
        ${"" /* overflow: scroll; */}
        ${"" /* overflow-x: auto; */}
    }

    .infoWrapper {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export const ReminderContext = createContext({});
export const RemindersListComponents = () => {
    const [addReminderModal, setAddReminderModal] = useState(false);
    const [currentlyInSingleMode, setCurrentlyInSingleMode] = useState(false);
    const studentToRemind = useRef(null);
    const [tableBodyData, setTableBodyData] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedClient, setSelectedClient] = useState([]);
    const [searchParam, setSearchParam] = useState("");
    const endpointQueryParam = useRef({});
    const navigate = useNavigate();
    const headerData = [
        "ID",
        "BSS ID",
        "Name",
        "Phone Number",
        "Region",
        "City",
        "Campus",
        "INTERVIEW STATUS",
        "",
    ];
    const handleCancelReminder = () => {
        addReminderModal && setAddReminderModal(false);
    };

    const handleOnClickButton = () => {
        setCurrentlyInSingleMode(false);
        setAddReminderModal(true);
    };
    const onClickNotifyStudent = async (id) => {
        studentToRemind.current = id;
        console.log(id);
        setCurrentlyInSingleMode(true);
        setAddReminderModal(true);
    };

    const handleSearchInputChange = (e) => {
        let query = e.target.value;
        setSearchParam(e.target.value);
        handleSearch(query);
    };

    const handleSearch = (query) => {
        if (query) {
            endpointQueryParam.current = {
                ...endpointQueryParam,
                search: query,
            };
        } else {
            const tempData = {
                ...endpointQueryParam.current,
            };
            delete tempData["search"];
            endpointQueryParam.current = tempData;
        }
        fetchAndParseDataFromAPI();
    };

    const [data, setData] = useState([]);
    const fetchAndParseDataFromAPI = async () => {
        const endpoint = `/pro/students${formatQueryDictToQueryParams(
            endpointQueryParam.current,
            "?"
        )}`;
        setShowSpinner(true);
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data?.results);
                setShowSpinner(false);
            })
            .catch((err) => {
                notifyFailure("Error in fetching students");
                setShowSpinner(false);
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        if (selectedClient) {
            endpointQueryParam.current =
                selectedClient === "all"
                    ? {}
                    : {
                          account_data__admission_status__admission_status: selectedClient,
                      };
            fetchAndParseDataFromAPI();
        }
    }, [selectedClient]);

    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item, index) => {
                    return {
                        id: item.id,
                        data: [
                            {
                                type: "text",
                                value: index + 1,
                            },
                            {
                                type: "text",
                                value: item?.id,
                            },
                            {
                                type: "text",
                                value: item?.full_name,
                            },
                            {
                                type: "text",
                                value: item?.phone_number,
                            },
                            {
                                type: "text",
                                value: item?.city_campus[0]?.region_name,
                            },

                            {
                                type: "text",
                                value: item?.city_campus[0]?.city_name,
                            },
                            {
                                type: "text",
                                value: item?.city_campus[0]?.campus_name,
                            },
                            {
                                type:
                                    item?.account_data?.admission_status?.admission_status &&
                                    "badge-custom",
                                value: item?.account_data?.admission_status?.admission_status,
                            },
                            {
                                type: "view1",
                                value: onClickNotifyStudent,
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);

    return (
        <StyledComponent>
            <div className="searchActions">
                <div className="selectOuter">
                    <select
                        value={selectedClient}
                        onChange={(e) => {
                            setSelectedClient(e.target.value);
                        }}
                    >
                        <option value="all">All</option>
                        {Object.values(STUDENT_ADMISSION_STATUS_TYPES).map((status) => (
                            <option selected={status === ""} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="reminderBtn">
                    <Button
                        fontWeight={700}
                        fontSize={1}
                        maxWidth={12}
                        borderRadius={2.5}
                        paddingTopBottom={0.6}
                        onClick={handleOnClickButton}
                    >
                        Add Reminders
                    </Button>
                </div>
                <div className="searchOuter">
                    <input
                        type="search"
                        placeholder="Search student by name, city..."
                        value={searchParam}
                        onChange={handleSearchInputChange}
                    />
                    <img
                        src={search}
                        alt="search icon"
                        className={"searchIcon"}
                        onClick={(searchParam) => handleSearch}
                    />
                </div>
            </div>
            <SizedBox height={1} />
            {!showSpinner ? (
                <TableComponent
                    headerData={headerData}
                    bodyData={tableBodyData}
                    actionsView1={{
                        NOTIFY: onClickNotifyStudent,
                    }}
                />
            ) : (
                <div className="infoWrapper">
                    <Spinner />
                </div>
            )}
            <ReminderContext.Provider
                value={{
                    currentlyInSingleMode,
                    studentToRemind: studentToRemind.current,
                }}
            >
                <ModalComponent
                    modalLabel={"Add reminder"}
                    isOpen={addReminderModal}
                    callbackCloseModal={() => {
                        setAddReminderModal(false);
                    }}
                >
                    <AddReminder
                        callbackCancel={handleCancelReminder}
                        callbackProceed={() => {}}
                        showSpinner={showSpinner}
                    />
                </ModalComponent>
            </ReminderContext.Provider>
        </StyledComponent>
    );
};
