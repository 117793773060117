import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { axiosServerInstance } from '../../../../../../config/api/axios'
import { notifyFailure } from '../../../../../../helper/notifications/notifyFailure'
import { notifyApiErrorMessage } from '../../../../../../helper/notifications/notifyApiErrorMessage'
import {
    STUDENT_RESULT_TYPES,
    STUDENT_SUBJECT_GRADES_TYPES,
    STUDENT_SUBJECT_NAMES,
} from '../../../../../../config/store/constants'
import { handleOnInputChange } from '../../../../../../helper/form_inputs/handleOnInputChange'
import { parseEnumToSelectValues } from '../../../../../../helper/form_inputs/parseEnumToSelectValues'
import { FormFieldsWrapper } from '../../../../graphical/data_input/FormFieldsWrapper'
import { InputComponent } from '../../../../../Authentication/components/InputELement'
import { Button } from '../../../../Button'

const StyledComponent = styled.div`
    margin: 1rem 1.5rem;
    .formTitle {
        font-size: 2rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
    }
    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        .inputOuter.w-38 {
            width: 45%;
            @media screen and (max-width: 45rem) {
                width: 100%;
            }
        }
    }
    .formFieldWrapperSubject {
        align-items: flex-start;
        .helperText {
            font-size: 12px;
            margin-left: 1rem;
            line-height: 1.5rem;
        }
    }
    .actionButtonWrapper {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        & > button:first-child {
            background-color: transparent;
            color: var(--custom-btn-bg);
            border: 1px solid var(--custom-btn-bg);
        }
        @media (max-width: 900px) {
            margin-top: 2rem;
        }
    }
    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);
        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }
`

export const EditSubject = ({
    callbackCancel,
    callbackProceed,
    subjectID,
    resultType,
    degree_name,
}) => {
    const [data, setData] = useState({})
    const neededDataFields = useRef(['name', 'grade'])

    const fetchAndParseDataFromAPI = () => {
        let endpoint
        if (resultType === STUDENT_RESULT_TYPES.ANNOUNCED) {
            endpoint = `/student/admission/degree/result_announced/subjects/${subjectID}/`
        } else {
            endpoint = `/student/admission/degree/result_awaited/subjects/${subjectID}/`
        }
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                const tempData = {}
                for (let field of neededDataFields.current) {
                    tempData[field] = response.data[field]
                }
                setData(tempData)
            })
            .catch((err) => {
                notifyFailure('Error in fetching subject data')
                notifyApiErrorMessage(err)
            })
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [])

    return (
        <StyledComponent>
            <h2 className='formTitle'>EDIT SUBJECT</h2>
            <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
                <div className='inputOuter w-38'>
                    <label>Subject Name</label>
                    {degree_name === 'O Level' ? (
                        <select
                            value={data?.name}
                            onChange={(e) => {
                                handleOnInputChange(e, 'name', setData, data)
                            }}
                        >
                            {parseEnumToSelectValues(STUDENT_SUBJECT_NAMES).map(
                                (type) => (
                                    <option
                                        selected={type === data?.name}
                                        value={type}
                                    >
                                        {type}
                                    </option>
                                )
                            )}
                        </select>
                    ) : (
                        <InputComponent
                            placeholder={''}
                            type='text'
                            fontSize={0.7}
                            value={data?.name}
                            onChange={(e) => {
                                handleOnInputChange(e, 'name', setData, data)
                            }}
                        />
                    )}
                </div>
                {
                    <div className='inputOuter w-38'>
                        <label>Grade</label>
                        <select
                            value={data?.grade}
                            onChange={(e) => {
                                handleOnInputChange(e, 'grade', setData, data)
                            }}
                        >
                            {parseEnumToSelectValues(
                                STUDENT_SUBJECT_GRADES_TYPES
                            ).map((type) => (
                                <option
                                    selected={type === data?.grade}
                                    value={type}
                                >
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                }
            </FormFieldsWrapper>

            <div className='actionButtonWrapper'>
                <>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        Bg_color='transparent'
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={callbackCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={() => {
                            callbackProceed(data)
                        }}
                    >
                        SEND
                    </Button>
                </>
            </div>
        </StyledComponent>
    )
}
