import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    FETCH_USER_PROFILE_SUCCESS,
    SET_ACTIVE_NAVIGATION,
} from '../../../../config/store/constants'
import { useContext } from 'react'
import { GlobalUserProfileContext } from '../../../../App'

const StyledComponents = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.9rem;

    .mainLink,
    a {
        font-family: var(--font-1);
        color: var(--custom-white);
        cursor: pointer;
        text-decoration: none;
        transition: all 0.25s ease-in-out;
        font-size: 1rem;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        font-weight: 400;
        padding: 0.5rem 1rem;

        :hover,
        &.active {
            color: var(--custom-primary-bg);
            font-weight: 600;
            border-radius: 1.4rem;
            background-color: var(--custom-btn-bg);
        }

        &:hover .navIcon img,
        :focus .navIcon img,
        :active .navIcon img {
            filter: invert(0);
        }

        .navIcon {
            margin-right: 1rem;
            img {
                filter: invert(0);
                width: 16px;
                height: 16px;
                object-fit: contain;
            }
        }
    }

    .subNavIndicatorWrapper {
        padding: 0 1rem;
        margin-top: 0.5rem;
    }
`

export const NavigationLink = ({ config }) => {
    const { activeNavigationKey } = useSelector(
        state => state.globalNavigationReducer
    )
    const { profile } = useContext(GlobalUserProfileContext)
    const dispatch = useDispatch()
    return (
        <StyledComponents>
            <Link
                className={`mainLink ${
                    activeNavigationKey === config.key && 'active'
                }`}
                to={config.condition(profile) ? config.location : '#'}
                onClick={() => {
                    dispatch({
                        type: SET_ACTIVE_NAVIGATION,
                        payload: config.key,
                    })
                }}
            >
                <span className='navIcon'>
                    <img src={config.icon} alt='' />
                </span>
                {config.label}
            </Link>
        </StyledComponents>
    )
}
