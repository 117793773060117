import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {InputComponent} from "../components/InputELement";
import {FormComponent} from "../components/FormElement";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {axiosServerInstance} from "../../../config/api/axios";
import GoogleBtn from "../../../static/images/logo/GoogleIcon.svg";
import {AppRecaptchaWrapper} from "../components/AppRecaptchaElement";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {Spinner} from "../../Global/graphical/Spinner";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {useDispatch} from "react-redux";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import {fetchUserProfile} from "../../../config/store/actions";
import {useCheckTokenFromGoogle} from "../../../helper/google/oauth_hooks";
import {validatePassword, validatePhoneNumber} from "../../../helper/form_validators";
import moment from "moment";

export const RegistrationForm = () => {
    const [data, setData] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [processingStates, setProcessingStates] = useState({});
    const [googleOauthRedirectURI, setGoogleOauthRedirectURI] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateData = () => {
        const logValidationMessages = (messages) => {
            for (let message of messages) {
                notifyFailure(message);
            }
        };
        let genState = true;
        let response = validatePhoneNumber(data?.phone_number);
        if (!response[0]) {
            logValidationMessages(response[1]);
            genState = false;
        }
        response = validatePassword(data?.password);
        if (!response[0]) {
            logValidationMessages(response[1]);
            genState = false;
        }
        return genState;
    };

    const handleOnClickProceedButton = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!recaptchaToken) {
            notifyFailure("Kindly solve the recaptcha verification to proceed");
            return;
        }
        if (validateData()) {
            handleRegister();
        }
    };

    const handleRegister = async () => {
        const endpoint = "/auth/register_as_a_student/";
        const modifiedData = {
            recaptcha_token: recaptchaToken,
            ...data,
        };
        setShowSpinner(true);
        axiosServerInstance()
            .post(endpoint, modifiedData)
            .then((response) => {
                notifySuccess("Registration Successful");
                navigate("/login", {replace: true});
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                setShowSpinner(false);
            });
    };

    const initiateGoogleOauth = () => {
        let requestData = {
            redirect_uri: `${window.location.protocol + "//" + window.location.host}/signup`,
        };
        setProcessingStates({
            ...processingStates,
            signingUpGoogle: true,
        });
        axiosServerInstance()
            .post("/auth/initialize_google_signup/", requestData)
            .then((response) => {
                window.location.href = response.data.oauth_url;
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                setProcessingStates({
                    ...processingStates,
                    signingUpGoogle: false,
                });
            });
    };

    useEffect(() => {
        if (googleOauthRedirectURI) {
            let dataOath = {
                redirect_uri_content: googleOauthRedirectURI,
            };

            setProcessingStates({
                ...processingStates,
                signingUp: true,
            });
            axiosServerInstance()
                .post("/auth/finalize_google_signup/", dataOath)
                .then(async (response) => {
                    const {refresh, access} = response.data.token;
                    const accountData = {
                        parent_cnic: data?.parent_cnic,
                        dob: data?.dob,
                    };
                    const accountDataEndpoint = "/student/admission/information";
                    await axiosServerInstance(access)
                        .patch(accountDataEndpoint, accountData)
                        .then((response) => {
                            window.localStorage.setItem("access_token", access);
                            window.localStorage.setItem("refresh_token", refresh);
                            dispatch(fetchUserProfile);
                            notifySuccess("Success");
                            navigate("/", {replace: true});
                        })
                        .catch((err) => {
                            throw err
                        });
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                    setProcessingStates({
                        ...processingStates,
                        signingUp: false,
                    });
                });
        }
    }, [googleOauthRedirectURI]);

    useCheckTokenFromGoogle(setGoogleOauthRedirectURI);

    return (
        <>
            <div className="googleLoginBtn">
                {processingStates?.signingUpGoogle ? (
                    <Spinner size={1.5}/>
                ) : (
                    <Button
                        fontWeight={500}
                        fontSize={1}
                        maxWidth={15}
                        height={4}
                        BgColor="#ddd"
                        border={"border-color"}
                        borderRadius={0}
                        paddingTopBottom={0.6}
                        onClick={initiateGoogleOauth}
                    >
                        <div className="imgOuter">
                            <img src={GoogleBtn} alt="google btn"/>
                        </div>
                        Sign Up With Google
                    </Button>
                )}
            </div>

            <div className="seperationOR"></div>
            <h3 className="headingInfo">All fields are mandatory</h3>
            <FormComponent>
                <div className="inputOuter">
                    <label>Full Name</label>
                    <InputComponent
                        placeholder={"Please Enter Your Full Name"}
                        type="text"
                        height={2.5}
                        value={data?.full_name}
                        onChange={(e) => handleOnInputChange(e, "full_name", setData, data)}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>Email </label>
                    <InputComponent
                        placeholder={"Example@gmail.com"}
                        type="email"
                        height={2.5}
                        value={data?.email}
                        onChange={(e) => handleOnInputChange(e, "email", setData, data)}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>Phone Number</label>
                    <InputComponent
                        placeholder={"92xxxxxxxxxx"}
                        type="tel"
                        height={2.5}
                        value={data?.phone_number}
                        onChange={(e) => {
                            handleOnInputChange(e, "phone_number", setData, data);
                        }}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>CNIC/CRC/B-Form</label>
                    <InputComponent
                        placeholder={"xxxxx-xxxxxxx-x"}
                        type="text"
                        height={2.5}
                        value={data?.cnic}
                        onChange={(e) => handleOnInputChange(e, "cnic", setData, data)}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>Date Of Birth</label>
                    <InputComponent
                        placeholder={"DD/MM/YYYY"}
                        type="date"
                        name="date"
                        min="1950-01-01"
                        max="2030-12-31"
                        fontSize={0.7}
                        value={data?.dob}
                        onChange={(e) => {
                            handleOnInputChange(e, "dob", setData, data, 'value', (value) => (
                                moment(value).format('YYYY-MM-DD')
                            ));
                        }}
                    />
                </div>
                <div className="inputOuter">
                    <label>Parent CNIC/CRC/B-Form</label>
                    <InputComponent
                        placeholder={"xxxxx-xxxxxxx-x"}
                        type="text"
                        height={2.5}
                        value={data?.parent_cnic}
                        onChange={(e) => handleOnInputChange(e, "parent_cnic", setData, data)}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>Password</label>
                    <InputComponent
                        placeholder={"************"}
                        type="password"
                        height={2.5}
                        value={data?.password}
                        onChange={(e) => handleOnInputChange(e, "password", setData, data)}
                    />
                    <SizedBox height={1.0}/>
                </div>
                <div className="inputOuter">
                    <label>Confirm Password</label>
                    <InputComponent
                        placeholder={"************"}
                        type="password"
                        height={2.5}
                        value={data?.confirm_password}
                        onChange={(e) => handleOnInputChange(e, "confirm_password", setData, data)}
                    />
                    <SizedBox height={1.5}/>
                </div>

                <div className="recaptcha">
                    <AppRecaptchaWrapper onChange={(value) => setRecaptchaToken(value)}/>
                </div>
                <AuthButtonContainer>
                    {!showSpinner ? (
                        <>
                            <Button
                                fontWeight={700}
                                fontSize={1}
                                maxWidth={15}
                                borderRadius={1.5}
                                paddingTopBottom={0.6}
                                onClick={handleOnClickProceedButton}
                            >
                                Sign Up
                            </Button>
                            <SizedBox height={1.0}/>
                        </>
                    ) : (
                        <Spinner size={1.5}/>
                    )}
                </AuthButtonContainer>
            </FormComponent>
        </>
    );
};
