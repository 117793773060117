import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { FormFieldsWrapper } from '../../../graphical/data_input/FormFieldsWrapper'
import { InputComponent } from '../../../../Authentication/components/InputELement'
import { Button } from '../../../Button'
import { axiosServerInstance } from '../../../../../config/api/axios'
import { GlobalUserProfileContext } from '../../../../../App'
import { handleOnInputChange } from '../../../../../helper/form_inputs/handleOnInputChange'
import {
    parseArrayToSelectValues,
    parseEnumToSelectValues,
} from '../../../../../helper/form_inputs/parseEnumToSelectValues'
import {
    FILE_UPLOAD_PURPOSE,
    STUDENT_DEGREE_TYPES,
    STUDENT_RESULT_TYPES,
    STUDENT_SUBJECT_GRADES_TYPES,
    STUDENT_SUBJECT_NAMES,
} from '../../../../../config/store/constants'
import { notifyApiErrorMessage } from '../../../../../helper/notifications/notifyApiErrorMessage'
import { notifyFailure } from '../../../../../helper/notifications/notifyFailure'
import { handleFileUploadSingle } from '../../../../../helper/fileManagement/handler'
import { multiStepFormValidator } from '../../../../../helper/form_validators/multiStepFormValidator'
import { ResultAnnouncedSubjectsTable } from './components/ResultAnouncedSubjectsTable'
import { ResultAnwaitedSubjectsTable } from './components/ResultAwaitedSubjectsTable'
import { DegreeInstitutionsTable } from './components/DegreeInstitutionsTable'
import {
    extractDataFromObject,
    setDataInObject,
} from '../../../../../helper/misc'
import { EditInstitution } from './components/EditInstitutionModal'
import { ModalComponent } from '../../../graphical/Modal'
import { EditSubject } from './components/EditSubjectModal'
import { Spinner } from '../../../graphical/Spinner'
import { APIUtils } from '../../../../../helper/api'

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: ${({ asSubComponent }) =>
        asSubComponent ? '' : '0 10px 10px -3px #cfcfcf'};
    padding: 3rem;
    @media (max-width: 500px) {
        padding: 3rem 1rem;
    }

    .formFieldWrapperSubjectEdit {
        width: 100%;

        > div {
            padding: 0rem;
            border-radius: 0px;
            box-shadow: none;
        }

        .tableOuter {
            padding: 0rem;
            border-radius: 1.5rem;
            border: 1px solid var(--custom-input-border);
            .heading {
                margin-bottom: 0px;
            }
            thead {
                display: none;
            }

            tbody {
                padding: 0.5rem;
                background-color: var(--custom-input-bg);

                tr {
                    border-bottom: 0.1px solid var(--custom-border-color);
                    :last-child {
                        border-bottom: none;
                    }
                    td {
                        padding: 0rem 0rem 0 1rem;

                        button {
                            width: 5rem;
                            font-weight: 600;
                            font-size: 1rem;
                            background-color: transparent;
                            letter-spacing: 0.5px;
                            outline: none;
                            border: none;
                            img {
                                filter: invert(0);
                                width: 1.2rem;
                                height: 1.2rem;
                                object-fit: cover;
                            }
                            ::focus-visible {
                                border: none;
                                outline: none;
                            }
                        }
                    }
                }

                td:nth-last-child(2) {
                    width: 7vw !important;
                    padding: 0.4rem 0;
                }

                td:last-child {
                    padding: 0.4rem 0;
                    width: 7vw;
                }
            }
        }
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            .formFieldWrapperSubject {
                width: 100%;
                gap: 1rem;
                display: flex;
                flex-wrap: wrap;
                @media (max-width: 1000px) {
                    justify-content: flex-end;
                }
            }

            .inputOuter.w-50 {
                width: 49.1%;
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .inputOuter.w-60 {
                width: 64%;
                @media (max-width: 1265px) {
                    width: 48%;
                }

                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .inputOuter.w-90 {
                width: 80%;

                @media (max-width: 1405px) {
                    width: 78%;
                }
                @media (max-width: 1323px) {
                    width: 76%;
                }
                @media (max-width: 1256px) {
                    width: 100%;
                }
            }

            .inputOuter.w-32 {
                width: 32%;
                @media (max-width: 1265px) {
                    width: 48%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .inputOuter.w-22 {
                width: 22%;
                @media (max-width: 1565px) {
                    width: 20%;
                }
                @media (max-width: 1510px) {
                    width: 20%;
                }
                @media (max-width: 1405px) {
                    width: 48%;
                }
                @media (max-width: 1405px) {
                    width: 48%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .inputOuter.w-34 {
                width: 34%;
                @media (max-width: 1265px) {
                    width: 48%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .inputOuter.w-38 {
                width: 39%;

                @media (max-width: 1350px) {
                    width: 38%;
                }
                @media (max-width: 1289px) {
                    width: 37%;
                }
                @media (max-width: 1265px) {
                    width: 48%;
                }
                @media (max-width: 1000px) {
                    width: 100%;
                }
            }

            .ResultRadioMode {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                input {
                    display: none;
                    margin: 0 1rem;
                }

                label {
                    border: 2px solid var(--custom-secondary-bg);
                    border-radius: 2.5rem;
                    padding: 0.3rem 1.5rem;
                    @media (max-width: 500px) {
                        padding: 0.2rem 2rem;
                    }
                }

                label.active {
                    background-color: var(--custom-secondary-bg);
                    color: #fff;
                }
            }

            .textarea {
                width: 100%;
            }

            .helperText {
                font-size: 0.8rem;
            }
        }

        select {
            width: 100%;
            height: 3rem;
            text-indent: 10px;
            background: var(--custom-input-bg);
            border-radius: 25px;
            outline: none;
            background-image: none;
            overflow: hidden;
            border: 1px solid var(--custom-input-border);

            option {
                border-radius: 25px;
                width: 32%;
                background: var(--custom-input-bg);
            }
        }

        .inputOuter.profilePicturePickerWrapper {
            position: relative;
            margin-bottom: 2rem;

            input {
                position: absolute;
                opacity: 0;
                left: 0;
                bottom: -2.5rem;
            }

            .inputFile {
                position: absolute;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 1rem;
                bottom: -2.5rem;
                left: 0;
                width: 100%;
                height: 50px;
                border: 1px solid var(--custom-input-border);
                background-color: var(--custom-input-bg);
                border-radius: 2.5rem;

                :after {
                    content: 'Choose File';
                    position: absolute;
                    border: 1px solid var(--custom-btn-bg);
                    right: 10px;
                    color: var(--custom-btn-bg);
                    background-color: #f3f3f2;
                    padding: 0.4rem 1rem;
                    width: 90px;
                    bottom: 14%;
                    font-size: 1rem;
                    border-radius: 2.5rem;
                }
            }
        }

        .profilePicturePickerWrapper {
            height: 3rem;
            margin-top: 0.24rem;
            width: 100%;
        }

        .profilePicturePickerWrapper > div {
            justify-content: space-around;
            gap: 0.5rem;

            .controlsWrapperImage .text {
                color: var(--custom-txt-color);
                font-size: 1rem;
                font-weight: 400;
            }

            .controlsWrapperImage {
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 1rem;
            }

            .chooseImageButtonWrapper {
                width: 100%;
                border-radius: 2.5rem;
                padding: 0.2rem;
                border: 1px solid var(--custom-input-border);
                justify-content: flex-end;
                display: flex;
                background-color: var(--custom-input-bg);
            }

            .chooseImageButtonWrapper input {
                height: 46px;
            }

            .chooseImageButtonWrapper button {
                background-color: #f3f3f2;
                color: black;
                width: 150px;
                font-size: 1rem;
                border-radius: 2.5rem;
            }

            .imageWrapper {
                display: none;
            }
        }

        .actionButtonWrapper.olevel {
            margin-bottom: 1.3rem;
            @media (max-width: 1291px) {
                margin-bottom: 1.9rem;
            }
        }

        .actionButtonWrapper.degree {
            align-self: center;

            margin-top: -0.5rem;
            @media (max-width: 1405px) {
                margin-top: 0.5rem;
            }
            @media (max-width: 1123px) {
                margin-top: 0rem;
            }
        }

        .actionButtonWrapper {
            align-self: end;
            justify-self: end;

            button {
                @media (max-width: 500px) {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }
`

const dataFieldsMapping = {
    BASE: {
        degree_name: 'Degree Type',
        institutions: (data) => {
            const message = 'You need to add at least one institution'
            return {
                status: !!data?.institutions?.length,
                message,
            }
        },
        institution_matriculation: (data) => {
            const message =
                "'Matriculation' degree type supports only one institution"
            return {
                status:
                    (data?.institutions?.length > 1 &&
                        data?.degree_name !==
                            STUDENT_DEGREE_TYPES.MATRICULATION) ||
                    data?.institutions?.length === 1,
                message,
            }
        },
        total_marks_mark_obtained_1: (data) => {
            const message =
                "Total Marks and marks obtained are both needed for 'Matriculation' degree type"
            return {
                status:
                    data?.degree_name !== STUDENT_DEGREE_TYPES.MATRICULATION ||
                    (data?.degree_name === STUDENT_DEGREE_TYPES.MATRICULATION &&
                        data?.total_marks &&
                        data?.marks_obtained),
                message,
            }
        },
        total_marks_mark_obtained_2: (data) => {
            const message =
                "Marks obtained cannot be greater than total marks for 'Matriculation' degree type"
            return {
                status:
                    data?.degree_name !== STUDENT_DEGREE_TYPES.MATRICULATION ||
                    Number.parseFloat(data?.total_marks) >=
                        Number.parseFloat(data?.marks_obtained),
                message,
            }
        },
        result_announced: (data) => {
            const message = 'You need to add at least 1 result subjects'
            return {
                status: !!(
                    !data?.result_announced?.subjects?.length ||
                    (data?.result_announced?.subjects?.length &&
                        data?.result_announced?.subjects?.length >= 1)
                ),
                message,
            }
        },
    },
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.MATRICULATION] = {
    ...dataFieldsMapping.BASE,
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.O_LEVEL] = {
    ...dataFieldsMapping.BASE,
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.OTHERS] = {
    ...dataFieldsMapping.BASE,
    degree_label: 'Degree Name',
}

const StepTwoContainer = forwardRef(
    ({ jumpToStep, asSubComponent, callBackCloseAsSubComponent }, ref) => {
        const currentStep = useRef(2)
        const [showSpinner, setShowSpinner] = useState(true)
        const [data, setData] = useState({})
        console.log(data.degree_name)
        const [files, setFiles] = useState({})
        const [currentDegreeResultType, setCurrentDegreeResultType] = useState(
            STUDENT_RESULT_TYPES.ANNOUNCED
        )
        const { profile } = useContext(GlobalUserProfileContext)
        const [feederCampuses, setFeederCampuses] = useState({
            data: [
                {
                    _id: '647f1521f86d47c490bf2472',
                    main_branch: {
                        _id: '647f1382f86d47c490bf2471',
                        date_added: '2023-06-06T16:07:46.262000+05:00',
                        date_last_modified: '2023-06-06T23:46:40.949000+05:00',
                        branch_id: 299,
                        branch_name: 'BCP Defence Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:14:41.023000+05:00',
                    date_last_modified: '2023-06-06T16:14:41.023000+05:00',
                    branch_id: 212,
                    branch_name: 'Defence Campus',
                },
                {
                    _id: '647f1653f86d47c490bf2473',
                    main_branch: {
                        _id: '647f1382f86d47c490bf2471',
                        date_added: '2023-06-06T16:07:46.262000+05:00',
                        date_last_modified: '2023-06-06T23:46:40.949000+05:00',
                        branch_id: 299,
                        branch_name: 'BCP Defence Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:19:47.936000+05:00',
                    date_last_modified: '2023-06-06T16:19:47.936000+05:00',
                    branch_id: 278,
                    branch_name: 'Ring Road Campus',
                },
                {
                    _id: '647f16b1f86d47c490bf2474',
                    main_branch: {
                        _id: '647f1382f86d47c490bf2471',
                        date_added: '2023-06-06T16:07:46.262000+05:00',
                        date_last_modified: '2023-06-06T23:46:40.949000+05:00',
                        branch_id: 299,
                        branch_name: 'BCP Defence Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:21:21.676000+05:00',
                    date_last_modified: '2023-06-06T16:21:21.676000+05:00',
                    branch_id: 239,
                    branch_name: 'Newlands Lahore',
                },
                {
                    _id: '647f1803f86d47c490bf2476',
                    main_branch: {
                        _id: '647f17c5f86d47c490bf2475',
                        date_added: '2023-06-06T16:25:57.078000+05:00',
                        date_last_modified: '2023-06-06T23:52:30.004000+05:00',
                        branch_id: 254,
                        branch_name: 'BCP Faisalabad Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 8,
                        city_name: 'Faisalabad',
                    },
                    date_added: '2023-06-06T16:26:59.800000+05:00',
                    date_last_modified: '2023-06-06T16:26:59.800000+05:00',
                    branch_id: 232,
                    branch_name: 'Main Campus Canal Side',
                },
                {
                    _id: '647f1a23f86d47c490bf2478',
                    main_branch: {
                        _id: '647f19e0f86d47c490bf2477',
                        date_added: '2023-06-06T16:34:56.098000+05:00',
                        date_last_modified: '2023-06-06T23:50:51.765000+05:00',
                        branch_id: 285,
                        branch_name: 'BCP Johar Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:36:03.161000+05:00',
                    date_last_modified: '2023-06-06T16:36:03.161000+05:00',
                    branch_id: 666,
                    branch_name: 'Johar Town Boys',
                },
                {
                    _id: '647f1a49f86d47c490bf2479',
                    main_branch: {
                        _id: '647f19e0f86d47c490bf2477',
                        date_added: '2023-06-06T16:34:56.098000+05:00',
                        date_last_modified: '2023-06-06T23:50:51.765000+05:00',
                        branch_id: 285,
                        branch_name: 'BCP Johar Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:36:41.859000+05:00',
                    date_last_modified: '2023-06-06T16:36:41.859000+05:00',
                    branch_id: 667,
                    branch_name: 'Johar Town Girls',
                },
                {
                    _id: '647f1a5bf86d47c490bf247a',
                    main_branch: {
                        _id: '647f19e0f86d47c490bf2477',
                        date_added: '2023-06-06T16:34:56.098000+05:00',
                        date_last_modified: '2023-06-06T23:50:51.765000+05:00',
                        branch_id: 285,
                        branch_name: 'BCP Johar Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:36:59.498000+05:00',
                    date_last_modified: '2023-06-06T16:36:59.498000+05:00',
                    branch_id: 660,
                    branch_name: 'Canal Side Girls, Lhr',
                },
                {
                    _id: '647f1a6df86d47c490bf247b',
                    main_branch: {
                        _id: '647f19e0f86d47c490bf2477',
                        date_added: '2023-06-06T16:34:56.098000+05:00',
                        date_last_modified: '2023-06-06T23:50:51.765000+05:00',
                        branch_id: 285,
                        branch_name: 'BCP Johar Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:37:17.373000+05:00',
                    date_last_modified: '2023-06-06T16:37:17.373000+05:00',
                    branch_id: 661,
                    branch_name: 'Canal Side Boys, Lhr',
                },
                {
                    _id: '647f1a82f86d47c490bf247c',
                    main_branch: {
                        _id: '647f19e0f86d47c490bf2477',
                        date_added: '2023-06-06T16:34:56.098000+05:00',
                        date_last_modified: '2023-06-06T23:50:51.765000+05:00',
                        branch_id: 285,
                        branch_name: 'BCP Johar Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:37:38.053000+05:00',
                    date_last_modified: '2023-06-06T16:37:38.053000+05:00',
                    branch_id: 217,
                    branch_name: 'Valencia',
                },
                {
                    _id: '647f1af7f86d47c490bf247e',
                    main_branch: {
                        _id: '647f1ac9f86d47c490bf247d',
                        date_added: '2023-06-06T16:38:49.247000+05:00',
                        date_last_modified: '2023-06-06T23:47:39.324000+05:00',
                        branch_id: 225,
                        branch_name: 'BCP Gulberg Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:39:35.056000+05:00',
                    date_last_modified: '2023-06-06T16:39:35.056000+05:00',
                    branch_id: 292,
                    branch_name: 'Cantt Branch, Lahore',
                },
                {
                    _id: '647f1b0af86d47c490bf247f',
                    main_branch: {
                        _id: '647f1ac9f86d47c490bf247d',
                        date_added: '2023-06-06T16:38:49.247000+05:00',
                        date_last_modified: '2023-06-06T23:47:39.324000+05:00',
                        branch_id: 225,
                        branch_name: 'BCP Gulberg Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:39:54.505000+05:00',
                    date_last_modified: '2023-06-06T16:39:54.505000+05:00',
                    branch_id: 618,
                    branch_name: '45-B/1 PECO Road',
                },
                {
                    _id: '647f1b21f86d47c490bf2480',
                    main_branch: {
                        _id: '647f1ac9f86d47c490bf247d',
                        date_added: '2023-06-06T16:38:49.247000+05:00',
                        date_last_modified: '2023-06-06T23:47:39.324000+05:00',
                        branch_id: 225,
                        branch_name: 'BCP Gulberg Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:40:17.497000+05:00',
                    date_last_modified: '2023-06-06T16:40:17.497000+05:00',
                    branch_id: 619,
                    branch_name: '78-A/1 Township Girls',
                },
                {
                    _id: '647f1b5af86d47c490bf2481',
                    main_branch: {
                        _id: '647f1ac9f86d47c490bf247d',
                        date_added: '2023-06-06T16:38:49.247000+05:00',
                        date_last_modified: '2023-06-06T23:47:39.324000+05:00',
                        branch_id: 225,
                        branch_name: 'BCP Gulberg Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:41:14.715000+05:00',
                    date_last_modified: '2023-06-06T16:41:14.715000+05:00',
                    branch_id: 284,
                    branch_name: 'AITC Senior, Lhr',
                },
                {
                    _id: '647f1b76f86d47c490bf2482',
                    main_branch: {
                        _id: '647f1ac9f86d47c490bf247d',
                        date_added: '2023-06-06T16:38:49.247000+05:00',
                        date_last_modified: '2023-06-06T23:47:39.324000+05:00',
                        branch_id: 225,
                        branch_name: 'BCP Gulberg Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T16:41:42.491000+05:00',
                    date_last_modified: '2023-06-06T16:41:42.491000+05:00',
                    branch_id: 211,
                    branch_name: 'Garden Town, Lhr',
                },
                {
                    _id: '647f1c20f86d47c490bf2484',
                    main_branch: {
                        _id: '647f1b93f86d47c490bf2483',
                        date_added: '2023-06-06T16:42:11.678000+05:00',
                        date_last_modified: '2023-06-06T23:53:19.428000+05:00',
                        branch_id: 294,
                        branch_name: 'BCP Gujranwala Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 11,
                        city_name: 'Gujranwala',
                    },
                    date_added: '2023-06-06T16:44:32.043000+05:00',
                    date_last_modified: '2023-06-06T16:44:32.043000+05:00',
                    branch_id: 244,
                    branch_name: 'Palm Tree Campus, Guj',
                },
                {
                    _id: '647f1c32f86d47c490bf2485',
                    main_branch: {
                        _id: '647f1b93f86d47c490bf2483',
                        date_added: '2023-06-06T16:42:11.678000+05:00',
                        date_last_modified: '2023-06-06T23:53:19.428000+05:00',
                        branch_id: 294,
                        branch_name: 'BCP Gujranwala Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 11,
                        city_name: 'Gujranwala',
                    },
                    date_added: '2023-06-06T16:44:50.155000+05:00',
                    date_last_modified: '2023-06-06T16:44:50.155000+05:00',
                    branch_id: 269,
                    branch_name: 'Citi Housing, Guj',
                },
                {
                    _id: '647f1e00f86d47c490bf2487',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:52:32.530000+05:00',
                    date_last_modified: '2023-06-06T16:52:32.530000+05:00',
                    branch_id: 221,
                    branch_name: 'Main Campus, Multan',
                },
                {
                    _id: '647f1e12f86d47c490bf2488',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:52:50.703000+05:00',
                    date_last_modified: '2023-06-06T16:52:50.703000+05:00',
                    branch_id: 226,
                    branch_name: 'Cantt Campus, Multan',
                },
                {
                    _id: '647f1e27f86d47c490bf2489',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:53:11.775000+05:00',
                    date_last_modified: '2023-06-06T16:53:11.775000+05:00',
                    branch_id: 279,
                    branch_name: 'Officers Colony Senior',
                },
                {
                    _id: '647f1e37f86d47c490bf248a',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:53:27.679000+05:00',
                    date_last_modified: '2023-06-06T16:53:27.679000+05:00',
                    branch_id: 680,
                    branch_name: 'Rahim Yarr Khan Campus',
                },
                {
                    _id: '647f1e47f86d47c490bf248b',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:53:43.241000+05:00',
                    date_last_modified: '2023-06-06T16:53:43.241000+05:00',
                    branch_id: 684,
                    branch_name: 'Bahawalpur Campus',
                },
                {
                    _id: '647f1e58f86d47c490bf248c',
                    main_branch: {
                        _id: '647f1cb6f86d47c490bf2486',
                        date_added: '2023-06-06T16:47:02.788000+05:00',
                        date_last_modified: '2023-06-06T23:56:19.873000+05:00',
                        branch_id: 224,
                        branch_name: 'BCP Multan Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 17,
                        city_name: 'Multan',
                    },
                    date_added: '2023-06-06T16:54:00.353000+05:00',
                    date_last_modified: '2023-06-06T16:54:00.353000+05:00',
                    branch_id: 476,
                    branch_name: 'Sadaqabad Campus',
                },
                {
                    _id: '647f1ff0f86d47c490bf248e',
                    main_branch: {
                        _id: '647f1fc8f86d47c490bf248d',
                        date_added: '2023-06-06T17:00:08.037000+05:00',
                        date_last_modified: '2023-06-06T23:50:03.197000+05:00',
                        branch_id: 298,
                        branch_name: 'BCP Liberty Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T17:00:48.531000+05:00',
                    date_last_modified: '2023-06-06T17:00:48.531000+05:00',
                    branch_id: 213,
                    branch_name: 'Liberty Campus, Lahore',
                },
                {
                    _id: '647f2085f86d47c490bf2490',
                    main_branch: {
                        _id: '647f2024f86d47c490bf248f',
                        date_added: '2023-06-06T17:01:40.699000+05:00',
                        date_last_modified: '2023-06-06T23:51:40.273000+05:00',
                        branch_id: 288,
                        branch_name: 'BCP Bahria Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T17:03:17.830000+05:00',
                    date_last_modified: '2023-06-06T17:03:17.830000+05:00',
                    branch_id: 287,
                    branch_name: 'Bahria Town Senior Campus',
                },
                {
                    _id: '647f209df86d47c490bf2491',
                    main_branch: {
                        _id: '647f2024f86d47c490bf248f',
                        date_added: '2023-06-06T17:01:40.699000+05:00',
                        date_last_modified: '2023-06-06T23:51:40.273000+05:00',
                        branch_id: 288,
                        branch_name: 'BCP Bahria Town Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T17:03:41.292000+05:00',
                    date_last_modified: '2023-06-06T17:03:41.292000+05:00',
                    branch_id: 262,
                    branch_name: 'Sheikhpura',
                },
                {
                    _id: '647f20d3f86d47c490bf2493',
                    main_branch: {
                        _id: '647f20bcf86d47c490bf2492',
                        date_added: '2023-06-06T17:04:12.262000+05:00',
                        date_last_modified: '2023-06-06T23:54:45.275000+05:00',
                        branch_id: 293,
                        branch_name: 'BCP Sialkot Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 24,
                        city_name: 'Sialkot',
                    },
                    date_added: '2023-06-06T17:04:35.651000+05:00',
                    date_last_modified: '2023-06-06T17:04:35.651000+05:00',
                    branch_id: 271,
                    branch_name: 'Sialkot Campus',
                },
                {
                    _id: '647f2181f86d47c490bf2495',
                    main_branch: {
                        _id: '647f215bf86d47c490bf2494',
                        date_added: '2023-06-06T17:06:51.047000+05:00',
                        date_last_modified: '2023-06-06T23:55:28.710000+05:00',
                        branch_id: 289,
                        branch_name: 'BCP Sargodha Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 21,
                        city_name: 'Sargodha',
                    },
                    date_added: '2023-06-06T17:07:29.920000+05:00',
                    date_last_modified: '2023-06-06T17:07:29.920000+05:00',
                    branch_id: 624,
                    branch_name: 'Sargodha Cantt',
                },
                {
                    _id: '647f21d2f86d47c490bf2497',
                    main_branch: {
                        _id: '647f21abf86d47c490bf2496',
                        date_added: '2023-06-06T17:08:11.614000+05:00',
                        date_last_modified: '2023-06-06T23:58:41.928000+05:00',
                        branch_id: 627,
                        branch_name: 'BCP Okara Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 26,
                        city_name: 'Okara',
                    },
                    date_added: '2023-06-06T17:08:50.213000+05:00',
                    date_last_modified: '2023-06-06T17:08:50.213000+05:00',
                    branch_id: 296,
                    branch_name: 'Okara Girls',
                },
                {
                    _id: '647f21e3f86d47c490bf2498',
                    main_branch: {
                        _id: '647f21abf86d47c490bf2496',
                        date_added: '2023-06-06T17:08:11.614000+05:00',
                        date_last_modified: '2023-06-06T23:58:41.928000+05:00',
                        branch_id: 627,
                        branch_name: 'BCP Okara Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 26,
                        city_name: 'Okara',
                    },
                    date_added: '2023-06-06T17:09:07.831000+05:00',
                    date_last_modified: '2023-06-06T17:09:07.831000+05:00',
                    branch_id: 297,
                    branch_name: 'Okara Boys',
                },
                {
                    _id: '647f21f7f86d47c490bf2499',
                    main_branch: {
                        _id: '647f21abf86d47c490bf2496',
                        date_added: '2023-06-06T17:08:11.614000+05:00',
                        date_last_modified: '2023-06-06T23:58:41.928000+05:00',
                        branch_id: 627,
                        branch_name: 'BCP Okara Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 26,
                        city_name: 'Okara',
                    },
                    date_added: '2023-06-06T17:09:27.104000+05:00',
                    date_last_modified: '2023-06-06T17:09:27.104000+05:00',
                    branch_id: 264,
                    branch_name: 'Sahiwal Boys',
                },
                {
                    _id: '647f2205f86d47c490bf249a',
                    main_branch: {
                        _id: '647f21abf86d47c490bf2496',
                        date_added: '2023-06-06T17:08:11.614000+05:00',
                        date_last_modified: '2023-06-06T23:58:41.928000+05:00',
                        branch_id: 627,
                        branch_name: 'BCP Okara Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 26,
                        city_name: 'Okara',
                    },
                    date_added: '2023-06-06T17:09:41.493000+05:00',
                    date_last_modified: '2023-06-06T17:09:41.493000+05:00',
                    branch_id: 257,
                    branch_name: 'Sahiwal Girls',
                },
                {
                    _id: '647f24d2f86d47c490bf249b',
                    main_branch: {
                        _id: '647f1382f86d47c490bf2471',
                        date_added: '2023-06-06T16:07:46.262000+05:00',
                        date_last_modified: '2023-06-06T23:46:40.949000+05:00',
                        branch_id: 299,
                        branch_name: 'BCP Defence Campus, Lahore',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 3,
                        city_name: 'Lahore',
                    },
                    date_added: '2023-06-06T17:21:38.180000+05:00',
                    date_last_modified: '2023-06-06T17:21:38.180000+05:00',
                    branch_id: 253,
                    branch_name: 'Hafizabad Campus',
                },
                {
                    _id: '647f2547f86d47c490bf249c',
                    main_branch: {
                        _id: '647f17c5f86d47c490bf2475',
                        date_added: '2023-06-06T16:25:57.078000+05:00',
                        date_last_modified: '2023-06-06T23:52:30.004000+05:00',
                        branch_id: 254,
                        branch_name: 'BCP Faisalabad Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 8,
                        city_name: 'Faisalabad',
                    },
                    date_added: '2023-06-06T17:23:35.653000+05:00',
                    date_last_modified: '2023-06-06T17:23:35.653000+05:00',
                    branch_id: 238,
                    branch_name: 'Canal Campus Girls Branch',
                },
                {
                    _id: '647f2575f86d47c490bf249d',
                    main_branch: {
                        _id: '647f17c5f86d47c490bf2475',
                        date_added: '2023-06-06T16:25:57.078000+05:00',
                        date_last_modified: '2023-06-06T23:52:30.004000+05:00',
                        branch_id: 254,
                        branch_name: 'BCP Faisalabad Campus',
                        region_id: 2,
                        region_name: 'Centre',
                        city_id: 8,
                        city_name: 'Faisalabad',
                    },
                    date_added: '2023-06-06T17:24:21.673000+05:00',
                    date_last_modified: '2023-06-06T17:24:21.673000+05:00',
                    branch_id: 258,
                    branch_name: 'Jhang Campus',
                },
                {
                    _id: '647f2724f86d47c490bf24a4',
                    main_branch: {
                        _id: '647f26f8f86d47c490bf24a3',
                        date_added: '2023-06-06T17:30:48.788000+05:00',
                        date_last_modified: '2023-06-07T00:16:52.456000+05:00',
                        branch_id: 444,
                        branch_name: 'BCP Juniper Campus, Quetta',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 4,
                        city_name: 'Quetta',
                    },
                    date_added: '2023-06-06T17:31:32.005000+05:00',
                    date_last_modified: '2023-06-06T17:31:32.006000+05:00',
                    branch_id: 471,
                    branch_name:
                        'Beaconhouse College Campus Juniper (BCCJ), Quetta',
                },
                {
                    _id: '647f27b1f86d47c490bf24a5',
                    main_branch: {
                        _id: '647f26e8f86d47c490bf24a2',
                        date_added: '2023-06-06T17:30:32.175000+05:00',
                        date_last_modified: '2023-06-07T00:15:26.090000+05:00',
                        branch_id: 443,
                        branch_name: 'BCP Qasimabad Campus, Hyderabad',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 7,
                        city_name: 'Hyderabad',
                    },
                    date_added: '2023-06-06T17:33:52.999000+05:00',
                    date_last_modified: '2023-06-06T17:33:52.999000+05:00',
                    branch_id: 453,
                    branch_name: 'Latifabad Secondary Branch, Hyderabad',
                },
                {
                    _id: '647f27cbf86d47c490bf24a6',
                    main_branch: {
                        _id: '647f26e8f86d47c490bf24a2',
                        date_added: '2023-06-06T17:30:32.175000+05:00',
                        date_last_modified: '2023-06-07T00:15:26.090000+05:00',
                        branch_id: 443,
                        branch_name: 'BCP Qasimabad Campus, Hyderabad',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 7,
                        city_name: 'Hyderabad',
                    },
                    date_added: '2023-06-06T17:34:19.566000+05:00',
                    date_last_modified: '2023-06-06T17:34:19.566000+05:00',
                    branch_id: 451,
                    branch_name: 'Qasimabad Campus',
                },
                {
                    _id: '647f2840f86d47c490bf24a7',
                    main_branch: {
                        _id: '647f26cef86d47c490bf24a1',
                        date_added: '2023-06-06T17:30:06.273000+05:00',
                        date_last_modified: '2023-06-07T00:14:24.959000+05:00',
                        branch_id: 430,
                        branch_name: 'BCP North Nazimabad Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:36:16.398000+05:00',
                    date_last_modified: '2023-06-06T17:36:16.398000+05:00',
                    branch_id: 480,
                    branch_name: 'North Nazimabad O Level - Commerce',
                },
                {
                    _id: '647f2863f86d47c490bf24a8',
                    main_branch: {
                        _id: '647f26cef86d47c490bf24a1',
                        date_added: '2023-06-06T17:30:06.273000+05:00',
                        date_last_modified: '2023-06-07T00:14:24.959000+05:00',
                        branch_id: 430,
                        branch_name: 'BCP North Nazimabad Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:36:51.317000+05:00',
                    date_last_modified: '2023-06-06T17:36:51.317000+05:00',
                    branch_id: 654,
                    branch_name: 'North Nazimabad Cambridge, Karachi',
                },
                {
                    _id: '647f2889f86d47c490bf24a9',
                    main_branch: {
                        _id: '647f26bdf86d47c490bf24a0',
                        date_added: '2023-06-06T17:29:49.255000+05:00',
                        date_last_modified: '2023-06-07T00:12:42.423000+05:00',
                        branch_id: 442,
                        branch_name: 'BCP PECHS Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:37:29.056000+05:00',
                    date_last_modified: '2023-06-06T17:37:29.056000+05:00',
                    branch_id: 436,
                    branch_name: 'PECHS Campus, Karachi',
                },
                {
                    _id: '647f289bf86d47c490bf24aa',
                    main_branch: {
                        _id: '647f26bdf86d47c490bf24a0',
                        date_added: '2023-06-06T17:29:49.255000+05:00',
                        date_last_modified: '2023-06-07T00:12:42.423000+05:00',
                        branch_id: 442,
                        branch_name: 'BCP PECHS Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:37:47.594000+05:00',
                    date_last_modified: '2023-06-06T17:37:47.594000+05:00',
                    branch_id: 445,
                    branch_name: 'Jubilee Campus, Karachi',
                },
                {
                    _id: '647f28e4f86d47c490bf24ab',
                    main_branch: {
                        _id: '647f26a7f86d47c490bf249f',
                        date_added: '2023-06-06T17:29:27.025000+05:00',
                        date_last_modified: '2023-06-07T00:13:53.234000+05:00',
                        branch_id: 466,
                        branch_name: 'BCP Gulshan Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:39:00.150000+05:00',
                    date_last_modified: '2023-06-06T17:39:00.151000+05:00',
                    branch_id: 465,
                    branch_name: 'Gulshan Cambridge, Karachi',
                },
                {
                    _id: '647f2917f86d47c490bf24ac',
                    main_branch: {
                        _id: '647f26a7f86d47c490bf249f',
                        date_added: '2023-06-06T17:29:27.025000+05:00',
                        date_last_modified: '2023-06-07T00:13:53.234000+05:00',
                        branch_id: 466,
                        branch_name: 'BCP Gulshan Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:39:51.646000+05:00',
                    date_last_modified: '2023-06-06T17:39:51.646000+05:00',
                    branch_id: 440,
                    branch_name: 'Gulistan-e-Jauhar Campus',
                },
                {
                    _id: '647f293ef86d47c490bf24ad',
                    main_branch: {
                        _id: '647f268df86d47c490bf249e',
                        date_added: '2023-06-06T17:29:01.741000+05:00',
                        date_last_modified: '2023-06-07T00:13:16.323000+05:00',
                        branch_id: 438,
                        branch_name: 'BCP Defence Campus, Karachi',
                        region_id: 4,
                        region_name: 'South',
                        city_id: 2,
                        city_name: 'Karachi',
                    },
                    date_added: '2023-06-06T17:40:30.492000+05:00',
                    date_last_modified: '2023-06-06T17:40:30.492000+05:00',
                    branch_id: 433,
                    branch_name: 'Defence Campus, Karachi',
                },
                {
                    _id: '647f2c58f86d47c490bf24ba',
                    main_branch: {
                        _id: '647f2974f86d47c490bf24ae',
                        date_added: '2023-06-06T17:41:24.108000+05:00',
                        date_last_modified: '2023-06-07T00:11:08.470000+05:00',
                        branch_id: 362,
                        branch_name: 'BCP Abbottabad Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 9,
                        city_name: 'Abbotabad',
                    },
                    date_added: '2023-06-06T17:53:44.926000+05:00',
                    date_last_modified: '2023-06-06T17:53:44.926000+05:00',
                    branch_id: 351,
                    branch_name: 'Abbottabad Campus',
                },
                {
                    _id: '647f2c6af86d47c490bf24bb',
                    main_branch: {
                        _id: '647f2974f86d47c490bf24ae',
                        date_added: '2023-06-06T17:41:24.108000+05:00',
                        date_last_modified: '2023-06-07T00:11:08.470000+05:00',
                        branch_id: 362,
                        branch_name: 'BCP Abbottabad Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 9,
                        city_name: 'Abbotabad',
                    },
                    date_added: '2023-06-06T17:54:02.320000+05:00',
                    date_last_modified: '2023-06-06T17:54:02.320000+05:00',
                    branch_id: 387,
                    branch_name: 'Beaconhouse Muzaffarabad Branch',
                },
                {
                    _id: '647f2c99f86d47c490bf24bc',
                    main_branch: {
                        _id: '647f29a4f86d47c490bf24af',
                        date_added: '2023-06-06T17:42:12.029000+05:00',
                        date_last_modified: '2023-06-07T00:01:48.202000+05:00',
                        branch_id: 302,
                        branch_name: 'BCP Margalla Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T17:54:49.368000+05:00',
                    date_last_modified: '2023-06-06T17:54:49.368000+05:00',
                    branch_id: 311,
                    branch_name: 'BMI Campus, Islamabad',
                },
                {
                    _id: '647f2ce9f86d47c490bf24bd',
                    main_branch: {
                        _id: '647f29b8f86d47c490bf24b0',
                        date_added: '2023-06-06T17:42:32.060000+05:00',
                        date_last_modified: '2023-06-07T00:03:09.530000+05:00',
                        branch_id: 330,
                        branch_name: 'BCP Karakoram Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T17:56:09.797000+05:00',
                    date_last_modified: '2023-06-06T17:56:09.797000+05:00',
                    branch_id: 334,
                    branch_name: 'Satellite Town Boys` Branch, Rawalpindi',
                },
                {
                    _id: '647f2cf7f86d47c490bf24be',
                    main_branch: {
                        _id: '647f29b8f86d47c490bf24b0',
                        date_added: '2023-06-06T17:42:32.060000+05:00',
                        date_last_modified: '2023-06-07T00:03:09.530000+05:00',
                        branch_id: 330,
                        branch_name: 'BCP Karakoram Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T17:56:23.693000+05:00',
                    date_last_modified: '2023-06-06T17:56:23.693000+05:00',
                    branch_id: 331,
                    branch_name: 'Satellite Town Girls` Branch, Rawalpindi',
                },
                {
                    _id: '647f2d06f86d47c490bf24bf',
                    main_branch: {
                        _id: '647f29b8f86d47c490bf24b0',
                        date_added: '2023-06-06T17:42:32.060000+05:00',
                        date_last_modified: '2023-06-07T00:03:09.530000+05:00',
                        branch_id: 330,
                        branch_name: 'BCP Karakoram Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T17:56:38.519000+05:00',
                    date_last_modified: '2023-06-06T17:56:38.519000+05:00',
                    branch_id: 379,
                    branch_name: 'Karakoram Campus, Islamabad',
                },
                {
                    _id: '647f2d16f86d47c490bf24c0',
                    main_branch: {
                        _id: '647f29b8f86d47c490bf24b0',
                        date_added: '2023-06-06T17:42:32.060000+05:00',
                        date_last_modified: '2023-06-07T00:03:09.530000+05:00',
                        branch_id: 330,
                        branch_name: 'BCP Karakoram Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T17:56:54.171000+05:00',
                    date_last_modified: '2023-06-06T17:56:54.171000+05:00',
                    branch_id: 345,
                    branch_name: 'Frontier Campus, Peshawar',
                },
                {
                    _id: '647f2d38f86d47c490bf24c1',
                    main_branch: {
                        _id: '647f29cff86d47c490bf24b1',
                        date_added: '2023-06-06T17:42:55.627000+05:00',
                        date_last_modified: '2023-06-07T00:05:54.155000+05:00',
                        branch_id: 359,
                        branch_name: 'BCP Frontier Campus, Peshawar',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 5,
                        city_name: 'Peshawar',
                    },
                    date_added: '2023-06-06T17:57:28.447000+05:00',
                    date_last_modified: '2023-06-06T17:57:28.447000+05:00',
                    branch_id: 372,
                    branch_name: 'Jalala Campus, Mardan',
                },
                {
                    _id: '647f2d46f86d47c490bf24c2',
                    main_branch: {
                        _id: '647f29cff86d47c490bf24b1',
                        date_added: '2023-06-06T17:42:55.627000+05:00',
                        date_last_modified: '2023-06-07T00:05:54.155000+05:00',
                        branch_id: 359,
                        branch_name: 'BCP Frontier Campus, Peshawar',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 5,
                        city_name: 'Peshawar',
                    },
                    date_added: '2023-06-06T17:57:42.007000+05:00',
                    date_last_modified: '2023-06-06T17:57:42.007000+05:00',
                    branch_id: 381,
                    branch_name: 'Nowshera Campus',
                },
                {
                    _id: '647f2d51f86d47c490bf24c3',
                    main_branch: {
                        _id: '647f29cff86d47c490bf24b1',
                        date_added: '2023-06-06T17:42:55.627000+05:00',
                        date_last_modified: '2023-06-07T00:05:54.155000+05:00',
                        branch_id: 359,
                        branch_name: 'BCP Frontier Campus, Peshawar',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 5,
                        city_name: 'Peshawar',
                    },
                    date_added: '2023-06-06T17:57:53.074000+05:00',
                    date_last_modified: '2023-06-06T17:57:53.074000+05:00',
                    branch_id: 391,
                    branch_name: 'Mandi Bahauddin Campus',
                },
                {
                    _id: '647f2d70f86d47c490bf24c4',
                    main_branch: {
                        _id: '647f29f7f86d47c490bf24b2',
                        date_added: '2023-06-06T17:43:35.590000+05:00',
                        date_last_modified: '2023-06-07T00:11:49.996000+05:00',
                        branch_id: 395,
                        branch_name: 'BCP Mandi Bahauddin Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 347,
                        city_name: 'Mandi Bahauddin',
                    },
                    date_added: '2023-06-06T17:58:24.232000+05:00',
                    date_last_modified: '2023-06-06T17:58:24.232000+05:00',
                    branch_id: 364,
                    branch_name: 'Gujrat Campus',
                },
                {
                    _id: '647f2d9af86d47c490bf24c5',
                    main_branch: {
                        _id: '647f2a14f86d47c490bf24b3',
                        date_added: '2023-06-06T17:44:04.040000+05:00',
                        date_last_modified: '2023-06-07T00:07:15.183000+05:00',
                        branch_id: 367,
                        branch_name: 'BCP Gujrat Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 18,
                        city_name: 'Gujrat',
                    },
                    date_added: '2023-06-06T17:59:06.332000+05:00',
                    date_last_modified: '2023-06-06T17:59:06.332000+05:00',
                    branch_id: 385,
                    branch_name: 'Mirpur Campus',
                },
                {
                    _id: '647f2dcff86d47c490bf24c6',
                    main_branch: {
                        _id: '647f2a33f86d47c490bf24b4',
                        date_added: '2023-06-06T17:44:35.746000+05:00',
                        date_last_modified: '2023-06-07T00:08:03.331000+05:00',
                        branch_id: 370,
                        branch_name: 'BCP Jhelum Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 12,
                        city_name: 'Jhelum',
                    },
                    date_added: '2023-06-06T17:59:59.095000+05:00',
                    date_last_modified: '2023-06-06T17:59:59.095000+05:00',
                    branch_id: 631,
                    branch_name: 'Jhelum Campus',
                },
                {
                    _id: '647f2ddcf86d47c490bf24c7',
                    main_branch: {
                        _id: '647f2a33f86d47c490bf24b4',
                        date_added: '2023-06-06T17:44:35.746000+05:00',
                        date_last_modified: '2023-06-07T00:08:03.331000+05:00',
                        branch_id: 370,
                        branch_name: 'BCP Jhelum Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 12,
                        city_name: 'Jhelum',
                    },
                    date_added: '2023-06-06T18:00:12.944000+05:00',
                    date_last_modified: '2023-06-06T18:00:12.944000+05:00',
                    branch_id: 368,
                    branch_name: 'Kharian Campus',
                },
                {
                    _id: '647f2e06f86d47c490bf24c8',
                    main_branch: {
                        _id: '647f2a49f86d47c490bf24b5',
                        date_added: '2023-06-06T17:44:57.839000+05:00',
                        date_last_modified: '2023-06-07T00:10:14.910000+05:00',
                        branch_id: 380,
                        branch_name: 'BCP Kharian Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 22,
                        city_name: 'Kharian',
                    },
                    date_added: '2023-06-06T18:00:54.667000+05:00',
                    date_last_modified: '2023-06-06T18:00:54.667000+05:00',
                    branch_id: 352,
                    branch_name: 'Potohar Campus, Rawalpindi',
                },
                {
                    _id: '647f2e41f86d47c490bf24c9',
                    main_branch: {
                        _id: '647f2a6af86d47c490bf24b6',
                        date_added: '2023-06-06T17:45:30.990000+05:00',
                        date_last_modified: '2023-06-07T00:03:58.502000+05:00',
                        branch_id: 363,
                        branch_name: 'BCP Potohar Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T18:01:53.895000+05:00',
                    date_last_modified: '2023-06-06T18:01:53.895000+05:00',
                    branch_id: 636,
                    branch_name: 'Civil Lines Branch Rawalpindi',
                },
                {
                    _id: '647f2e51f86d47c490bf24ca',
                    main_branch: {
                        _id: '647f2a6af86d47c490bf24b6',
                        date_added: '2023-06-06T17:45:30.990000+05:00',
                        date_last_modified: '2023-06-07T00:03:58.502000+05:00',
                        branch_id: 363,
                        branch_name: 'BCP Potohar Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T18:02:09.355000+05:00',
                    date_last_modified: '2023-06-06T18:02:09.355000+05:00',
                    branch_id: 326,
                    branch_name: 'Harley Street Campus, Rawalpindi',
                },
                {
                    _id: '647f2e60f86d47c490bf24cb',
                    main_branch: {
                        _id: '647f2a6af86d47c490bf24b6',
                        date_added: '2023-06-06T17:45:30.990000+05:00',
                        date_last_modified: '2023-06-07T00:03:58.502000+05:00',
                        branch_id: 363,
                        branch_name: 'BCP Potohar Campus, Islamabad',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T18:02:24.702000+05:00',
                    date_last_modified: '2023-06-06T18:02:24.702000+05:00',
                    branch_id: 321,
                    branch_name: 'Tipu Sultan Campus, Rawalpindi',
                },
                {
                    _id: '647f2e88f86d47c490bf24cc',
                    main_branch: {
                        _id: '647f2a8af86d47c490bf24b7',
                        date_added: '2023-06-06T17:46:02.622000+05:00',
                        date_last_modified: '2023-06-07T00:02:27.813000+05:00',
                        branch_id: 360,
                        branch_name: 'BCP Tipu Sultan Campus, Rawalpindi',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T18:03:04.482000+05:00',
                    date_last_modified: '2023-06-06T18:03:04.482000+05:00',
                    branch_id: 328,
                    branch_name: 'Beaconhouse Educational Complex, Rawalpindi',
                },
                {
                    _id: '647f2e99f86d47c490bf24cd',
                    main_branch: {
                        _id: '647f2a8af86d47c490bf24b7',
                        date_added: '2023-06-06T17:46:02.622000+05:00',
                        date_last_modified: '2023-06-07T00:02:27.813000+05:00',
                        branch_id: 360,
                        branch_name: 'BCP Tipu Sultan Campus, Rawalpindi',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 1,
                        city_name: 'Islamabad',
                    },
                    date_added: '2023-06-06T18:03:21.218000+05:00',
                    date_last_modified: '2023-06-06T18:03:21.218000+05:00',
                    branch_id: 361,
                    branch_name: 'Wah Cantt Campus',
                },
                {
                    _id: '647f6f7df86d47c490bf24d1',
                    main_branch: {
                        _id: '647f6ce5f86d47c490bf24cf',
                        date_added: '2023-06-06T22:29:09.567000+05:00',
                        date_last_modified: '2023-06-07T00:06:29.293000+05:00',
                        branch_id: 366,
                        branch_name: 'BCP Wah Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 19,
                        city_name: 'Wah',
                    },
                    date_added: '2023-06-06T22:40:13.241000+05:00',
                    date_last_modified: '2023-06-06T22:40:13.241000+05:00',
                    branch_id: 366,
                    branch_name: 'BCP Wah Campus',
                },
                {
                    _id: '647f8ab6f86d47c490bf24d2',
                    main_branch: {
                        _id: '647f6ce5f86d47c490bf24cf',
                        date_added: '2023-06-06T22:29:09.567000+05:00',
                        date_last_modified: '2023-06-07T00:06:29.293000+05:00',
                        branch_id: 366,
                        branch_name: 'BCP Wah Campus',
                        region_id: 3,
                        region_name: 'North',
                        city_id: 19,
                        city_name: 'Wah',
                    },
                    date_added: '2023-06-07T00:36:22.400000+05:00',
                    date_last_modified: '2023-06-07T00:36:22.400000+05:00',
                    branch_id: 358,
                    branch_name: 'Beaconhouse Bahria Enclave Campus',
                },
            ],
        })
        const [showModalEditInstitution, setShowModalEditInstitution] =
            useState(false)
        const institutionToEdit = useRef(null)
        const [showModalEditSubject, setShowModalEditSubject] = useState(false)
        const subjectToEdit = useRef(null)
        const resultTypeSubjectToEdit = useRef(null)

        const fetchAndParseDataFromAPI = (fieldSpecified) => {
            // setShowSpinner(true)
            const dataEndpoint = '/student/admission/degree'
            axiosServerInstance()
                .get(dataEndpoint)
                .then((response) => {
                    if (fieldSpecified) {
                        let tempData = {
                            ...data,
                        }
                        let mutatedData
                        if (
                            fieldSpecified === 'result_announced.subjects' ||
                            fieldSpecified === 'result_awaited.subjects'
                        ) {
                            const extractedData1 = extractDataFromObject(
                                response.data,
                                'result_announced.subjects'
                            )
                            const extractedData2 = extractDataFromObject(
                                response.data,
                                'result_awaited.subjects'
                            )
                            mutatedData = setDataInObject(
                                tempData,
                                extractedData1,
                                'result_announced.subjects'
                            )
                            mutatedData = setDataInObject(
                                { ...mutatedData },
                                extractedData2,
                                'result_awaited.subjects'
                            )
                        } else {
                            const extractedData = extractDataFromObject(
                                response.data,
                                fieldSpecified
                            )
                            mutatedData = setDataInObject(
                                tempData,
                                extractedData,
                                fieldSpecified
                            )
                        }
                        if (
                            fieldSpecified === 'result_announced.subjects' ||
                            fieldSpecified === 'result_awaited.subjects'
                        ) {
                            mutatedData[
                                `subject_name_${currentDegreeResultType.toLowerCase()}`
                            ] = ''
                        }
                        setData(mutatedData)
                    } else {
                        const tempData = {
                            ...data,
                            ...response.data,
                        }
                        tempData.total_marks =
                            tempData?.result_announced?.total_marks
                        tempData.marks_obtained =
                            tempData?.result_announced?.marks_obtained
                        tempData.percentage =
                            tempData?.result_announced?.percentage
                        setData(tempData)
                    }
                    const resultAnnouncedUploadResultData = {
                        url_on_server:
                            response.data?.result_announced?.uploaded_result
                                ?.file,
                    }
                    setFiles({
                        ...files,
                        resultAnnouncedUploadedResult:
                            resultAnnouncedUploadResultData,
                    })
                    setShowSpinner(false)
                })
                .catch((err) => {
                    notifyFailure('Error in fetching data')
                    notifyApiErrorMessage(err)
                    setShowSpinner(false)
                })
        }

        const handleAddInstitution = async (requestData) => {
            const endpoint = '/student/admission/degree/institutions'
            return await axiosServerInstance()
                .post(endpoint, requestData)
                .then((response) => {
                    return response?.data
                })
                .catch((err) => {
                    notifyApiErrorMessage(err)
                })
        }
        useEffect(() => {
            // setData([...data, data[institution] === ""]);
        }, [])

        const handleAddSubject = async ({ name, grade, owner }) => {
            const requestData = {
                name,
                grade,
            }
            let endpoint
            if (owner === STUDENT_RESULT_TYPES.ANNOUNCED) {
                endpoint =
                    data.degree_name === 'O Level'
                        ? '/student/admission/degree/result_announced/subjects'
                        : '/student/admission/degree/result_announced/subjects?remove_subject_validator=True'
            } else {
                endpoint =
                    data.degree_name === 'O Level'
                        ? '/student/admission/degree/result_awaited/subjects'
                        : '/student/admission/degree/result_awaited/subjects?remove_subject_validator=True'
            }
            return await axiosServerInstance()
                .post(endpoint, requestData)
                .then((response) => {
                    return response?.data
                })
                .catch((err) => {
                    notifyApiErrorMessage(err)
                })
        }
        const handleAddSubjectButton = async (e) => {
            e.preventDefault()
            if (await callAddSubject()) {
                if (
                    currentDegreeResultType === STUDENT_RESULT_TYPES.ANNOUNCED
                ) {
                    fetchAndParseDataFromAPI('result_announced.subjects')
                } else {
                    fetchAndParseDataFromAPI('result_awaited.subjects')
                }
            }

            // handleOnInputChange(
            //     "",
            //     "",
            //     setData,
            //     data
            // );

            // handleOnInputChange(
            //     "",
            //     "subject_grade",
            //     setData,
            //     data
            // );
        }

        const callAddSubject = async () => {
            let status = true
            if (data[`subject_name`]) {
                if (
                    currentDegreeResultType ===
                        STUDENT_RESULT_TYPES.ANNOUNCED &&
                    !data?.subject_grade
                ) {
                    notifyFailure(
                        "Please 'Enter the subject grade for the result announced'"
                    )
                    status = false
                } else {
                    status = !!(await handleAddSubject({
                        name: data[`subject_name`],
                        owner: currentDegreeResultType,
                        grade:
                            currentDegreeResultType ===
                            STUDENT_RESULT_TYPES.AWAITED
                                ? 'Result Awaited'
                                : data?.subject_grade,
                    }))
                    if (status) {
                        const tempData = { ...data }
                        tempData[`subject_name`] = ''
                        tempData['subject_grade'] = ''
                        setData({
                            ...tempData,
                        })
                    }
                }
            }
            return status
        }

        const callAddInstitution = async () => {
            let status = true
            if (data?.institution) {
                let payload
                if (
                    data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL &&
                    profile?.account_data?.is_existing === true
                ) {
                    const institutionData = (feederCampuses?.data || []).find(
                        (item) => item?._id === data.institution
                    )
                    payload = {
                        name: institutionData?.branch_name,
                        identifier: institutionData?.branch_id,
                    }
                } else {
                    payload = {
                        name: data?.institution,
                    }
                }
                status = !!(await handleAddInstitution(payload))
                if (status) {
                    const tempData = { ...data }
                    tempData['institution'] = null
                    setData(tempData)
                }
            }
            return status
        }

        const initiateEditInstitution = (id) => {
            institutionToEdit.current = id
            setShowModalEditInstitution(true)
        }

        const finalizeEditInstitution = (data) => {
            const endpoint = `/student/admission/degree/institutions/${institutionToEdit.current}/`
            axiosServerInstance()
                .patch(endpoint, data)
                .then((response) => {
                    fetchAndParseDataFromAPI('institutions')
                    setShowModalEditInstitution(false)
                })
                .catch((err) => {
                    notifyFailure('Error in editing institution')
                    notifyApiErrorMessage(err)
                })
        }

        const initiateEditSubject = (id, type) => {
            subjectToEdit.current = id
            resultTypeSubjectToEdit.current = type
            setShowModalEditSubject(true)
        }

        const finalizeEditSubject = (data) => {
            let endpoint
            if (
                resultTypeSubjectToEdit.current ===
                STUDENT_RESULT_TYPES.ANNOUNCED
            ) {
                endpoint =
                    data.degree_name === 'O Level'
                        ? `/student/admission/degree/result_announced/subjects/${subjectToEdit.current}/`
                        : `/student/admission/degree/result_announced/subjects/${subjectToEdit.current}/?remove_subject_validator=True`
            } else {
                endpoint =
                    data.degree_name === 'O Level'
                        ? `/student/admission/degree/result_awaited/subjects/${subjectToEdit.current}/`
                        : `/student/admission/degree/result_awaited/subjects/${subjectToEdit.current}/?remove_subject_validator=True`
            }
            axiosServerInstance()
                .patch(endpoint, data)
                .then((response) => {
                    if (
                        resultTypeSubjectToEdit.current ===
                        STUDENT_RESULT_TYPES.ANNOUNCED
                    ) {
                        fetchAndParseDataFromAPI('result_announced.subjects')
                    } else {
                        fetchAndParseDataFromAPI('result_awaited.subjects')
                    }
                    setShowModalEditSubject(false)
                })
                .catch((err) => {
                    notifyFailure('Error in editing subject')
                    notifyApiErrorMessage(err)
                })
        }

        const computeDataAndSendToServer = async () => {
            let status = true
            const requestConfig = [
                {
                    requestData: {
                        degree_name: data?.degree_name,
                        degree_label: data?.degree_label,
                    },
                    endpoint: '/student/admission/degree',
                },
                {
                    requestData: {
                        total_marks:
                            data?.degree_name ===
                                STUDENT_DEGREE_TYPES.MATRICULATION &&
                            data?.total_marks,
                        marks_obtained:
                            data?.degree_name ===
                                STUDENT_DEGREE_TYPES.MATRICULATION &&
                            data?.marks_obtained,
                        percentage:
                            data?.degree_name ===
                                STUDENT_DEGREE_TYPES.MATRICULATION &&
                            data?.percentage,
                    },
                    endpoint: '/student/admission/degree/result_announced',
                },
            ]
            for (let config of requestConfig) {
                if (status) {
                    const { requestData, endpoint } = config
                    await axiosServerInstance()
                        .patch(endpoint, requestData)
                        .then((response) => {
                            status = true
                        })
                        .catch((err) => {
                            notifyApiErrorMessage(err)
                            status = false
                        })
                }
            }
            if (status) {
                if (files?.resultAnnouncedUploadedResult) {
                    const temp = {
                        ...files?.resultAnnouncedUploadedResult,
                        to_be_deleted_file_id:
                            data?.result_announced?.uploaded_result?.id,
                    }
                    if (temp?.to_be_uploaded_buffer) {
                        const uploadedFileData = await handleFileUploadSingle(
                            temp
                        )
                        const endpoint =
                            '/student/admission/degree/result_announced'
                        await axiosServerInstance()
                            .patch(endpoint, {
                                uploaded_result: uploadedFileData?.id,
                            })
                            .then((response) => {
                                status = true
                            })
                            .catch((err) => {
                                notifyApiErrorMessage(err)
                                status = false
                            })
                    }
                }
            }

            if (
                status &&
                profile?.account_data?.admission_application_stage < 6
            ) {
                let endpoint = '/student/admission/information'
                await axiosServerInstance()
                    .patch(endpoint, {
                        admission_application_stage: currentStep.current + 1,
                    })
                    .then((response) => {
                        status = true
                    })
                    .catch((err) => {
                        notifyApiErrorMessage(err)
                        status = false
                    })
            }
            fetchAndParseDataFromAPI()
            return status
        }

        const performValidationAndSave = async () => {
            const { status, message } = multiStepFormValidator(
                data?.degree_name
                    ? dataFieldsMapping[data?.degree_name]
                    : dataFieldsMapping.BASE,
                data
            )
            if (!status) {
                notifyFailure(message)
            } else {
                return await computeDataAndSendToServer()
            }
            return status
        }

        useImperativeHandle(ref, () => ({
            async isValidated() {
                return await performValidationAndSave()
            },
        }))

        useEffect(() => {
            fetchAndParseDataFromAPI()
        }, [])

        useEffect(() => {
            if (
                data &&
                data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL &&
                profile?.account_data?.is_existing === true
            ) {
                if (typeof feederCampuses?.isFetching !== 'boolean') {
                    APIUtils.fetchItemData({
                        endpoint: '/shared/population_data/campuses/feeder/',
                        item: feederCampuses,
                        setItem: setFeederCampuses,
                    })
                }
            }
        }, [data])

        if (data) {
            return (
                <StyledComponent
                    asSubComponent={asSubComponent}
                    tableLength={data?.institutions?.length}
                >
                    <FormFieldsWrapper
                        className='formFieldWrapper'
                        autocomplete='off'
                    >
                        <div className='formfieldInner'>
                            <div
                                className='formFieldWrapperSubject degree'
                                autocomplete='off'
                            >
                                <div className='inputOuter w-34'>
                                    <label>Degree Name</label>
                                    <select
                                        value={data?.degree_name}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                data['institution'] = null
                                            }
                                            handleOnInputChange(
                                                e,
                                                'degree_name',
                                                setData,
                                                data
                                            )
                                        }}
                                    >
                                        {parseEnumToSelectValues(
                                            STUDENT_DEGREE_TYPES
                                        ).map((type) => (
                                            <option
                                                selected={type === data?.gender}
                                                value={type}
                                            >
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {data?.degree_name && (
                                    <>
                                        {data?.degree_name ===
                                            STUDENT_DEGREE_TYPES.OTHERS && (
                                            <div className='inputOuter w-22'>
                                                <label>Degree Name</label>
                                                <InputComponent
                                                    placeholder={''}
                                                    type='text'
                                                    fontSize={1}
                                                    value={data?.degree_label}
                                                    onChange={(e) => {
                                                        handleOnInputChange(
                                                            e,
                                                            'degree_label',
                                                            setData,
                                                            data
                                                        )
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={`inputOuter ${
                                                data?.degree_name ===
                                                STUDENT_DEGREE_TYPES.OTHERS
                                                    ? 'w-22'
                                                    : data?.degree_name ===
                                                      STUDENT_DEGREE_TYPES.O_LEVEL
                                                    ? 'w-38'
                                                    : data?.institutions?.length
                                                    ? 'w-60'
                                                    : 'w-38'
                                            }`}
                                        >
                                            <label>Institution</label>
                                            {data?.degree_name ===
                                                STUDENT_DEGREE_TYPES.O_LEVEL &&
                                            profile?.account_data
                                                ?.is_existing === true ? (
                                                feederCampuses?.isFetching ===
                                                false ? (
                                                    <select
                                                        value={
                                                            data?.institution
                                                        }
                                                        onChange={(e) => {
                                                            handleOnInputChange(
                                                                e,
                                                                'institution',
                                                                setData,
                                                                data
                                                            )
                                                        }}
                                                    >
                                                        {parseArrayToSelectValues(
                                                            (
                                                                feederCampuses?.data ||
                                                                []
                                                            ).filter(
                                                                (item) =>
                                                                    !data?.institutions
                                                                        .map(
                                                                            (
                                                                                item_
                                                                            ) =>
                                                                                item_?.identifier
                                                                        )
                                                                        .includes(
                                                                            item?.branch_id
                                                                        )
                                                            )
                                                        ).map((item) => (
                                                            <option
                                                                selected={
                                                                    data?.institution ===
                                                                    item?._id
                                                                }
                                                                value={
                                                                    item?._id
                                                                }
                                                            >
                                                                {
                                                                    item?.branch_name
                                                                }
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    feederCampuses?.isFetching ===
                                                        true && (
                                                        <Spinner size={1.5} />
                                                    )
                                                )
                                            ) : (
                                                <InputComponent
                                                    placeholder={''}
                                                    type='text'
                                                    fontSize={1}
                                                    value={data?.institution}
                                                    onChange={(e) => {
                                                        handleOnInputChange(
                                                            e,
                                                            'institution',
                                                            setData,
                                                            data
                                                        )
                                                    }}
                                                />
                                            )}
                                            {data?.degree_name !==
                                                STUDENT_DEGREE_TYPES.MATRICULATION && (
                                                <p className='helperText'>
                                                    *If you have multiple
                                                    institutions, please provide
                                                    all.
                                                </p>
                                            )}
                                        </div>
                                        {data?.institutions?.length &&
                                        data?.degree_name ===
                                            STUDENT_DEGREE_TYPES.MATRICULATION ? null : (
                                            <div
                                                className={`actionButtonWrapper ${
                                                    data?.degree_name ===
                                                    STUDENT_DEGREE_TYPES.OTHERS
                                                        ? 'degree'
                                                        : data?.degree_name ===
                                                          STUDENT_DEGREE_TYPES.O_LEVEL
                                                        ? 'olevel'
                                                        : 'matric'
                                                }`}
                                            >
                                                <Button
                                                    fontSize={1}
                                                    borderRadius={2.4}
                                                    paddingTopBottom={0.6}
                                                    paddingLeftRight={1.5}
                                                    onClick={async (e) => {
                                                        e.preventDefault()
                                                        if (
                                                            await callAddInstitution()
                                                        ) {
                                                            fetchAndParseDataFromAPI(
                                                                'institutions'
                                                            )
                                                        }
                                                    }}
                                                >
                                                    + ADD INSTITUTION
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            {data?.institutions?.length ? (
                                <div className='formFieldWrapperSubjectEdit'>
                                    <DegreeInstitutionsTable
                                        data={data}
                                        callBackFetchData={() =>
                                            fetchAndParseDataFromAPI(
                                                'institutions'
                                            )
                                        }
                                        handleEdit={initiateEditInstitution}
                                    />
                                </div>
                            ) : null}
                            {data?.degree_name && (
                                <div className='ResultRadioMode '>
                                    <input
                                        type='radio'
                                        id='ResultAnnounced'
                                        value={STUDENT_RESULT_TYPES.ANNOUNCED}
                                        name='degreeResultTypes'
                                        checked={
                                            currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.ANNOUNCED
                                        }
                                        onChange={(e) =>
                                            setCurrentDegreeResultType(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                    <label
                                        htmlFor='ResultAnnounced'
                                        className={`${
                                            currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.ANNOUNCED
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        Result Announced
                                    </label>
                                    <input
                                        type='radio'
                                        id='ResultAwaited'
                                        value={STUDENT_RESULT_TYPES.AWAITED}
                                        name='degreeResultTypes'
                                        checked={
                                            currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.AWAITED
                                        }
                                        onChange={(e) =>
                                            setCurrentDegreeResultType(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                    <label
                                        htmlFor='ResultAwaited'
                                        className={`${
                                            currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.AWAITED
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        Result Awaited
                                    </label>
                                </div>
                            )}
                            {data?.degree_name && (
                                <>
                                    {currentDegreeResultType ===
                                        STUDENT_RESULT_TYPES.ANNOUNCED &&
                                        data?.degree_name !==
                                            STUDENT_DEGREE_TYPES.O_LEVEL && (
                                            <div className='formFieldWrapperSubject marks'>
                                                <div className='inputOuter w-32'>
                                                    <label>Total Marks</label>
                                                    <InputComponent
                                                        placeholder={''}
                                                        type='number'
                                                        fontSize={1}
                                                        value={
                                                            data?.total_marks
                                                        }
                                                        onChange={(e) => {
                                                            handleOnInputChange(
                                                                e,
                                                                'total_marks',
                                                                setData,
                                                                data
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className='inputOuter w-32'>
                                                    <label>
                                                        Marks Obtained
                                                    </label>
                                                    <InputComponent
                                                        placeholder={''}
                                                        type='number'
                                                        fontSize={1}
                                                        value={
                                                            data?.marks_obtained
                                                        }
                                                        onChange={(e) => {
                                                            handleOnInputChange(
                                                                e,
                                                                'marks_obtained',
                                                                setData,
                                                                data
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className='inputOuter w-32'>
                                                    <label>Percentage</label>
                                                    <InputComponent
                                                        placeholder={''}
                                                        type='text'
                                                        fontSize={1}
                                                        value={data?.percentage}
                                                        onChange={(e) => {
                                                            handleOnInputChange(
                                                                e,
                                                                'percentage',
                                                                setData,
                                                                data
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    <div className='formFieldWrapperSubject'>
                                        <div
                                            className={`inputOuter ${
                                                currentDegreeResultType ===
                                                STUDENT_RESULT_TYPES.ANNOUNCED
                                                    ? 'w-38'
                                                    : 'w-90'
                                            } `}
                                        >
                                            <label>Subject Name</label>
                                            {data?.degree_name === 'O Level' ? (
                                                <select
                                                    value={data?.subject_name}
                                                    onChange={(e) => {
                                                        handleOnInputChange(
                                                            e,
                                                            'subject_name',
                                                            setData,
                                                            data
                                                        )
                                                    }}
                                                >
                                                    {parseEnumToSelectValues(
                                                        STUDENT_SUBJECT_NAMES
                                                    ).map((type) => (
                                                        <option
                                                            selected={
                                                                type ===
                                                                data?.grade
                                                            }
                                                            value={type}
                                                        >
                                                            {type}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <InputComponent
                                                    placeholder={''}
                                                    type='input'
                                                    fontSize={1}
                                                    value={
                                                        data[`subject_name}`]
                                                    }
                                                    onChange={(e) => {
                                                        handleOnInputChange(
                                                            e,
                                                            `subject_name`,
                                                            setData,
                                                            data
                                                        )
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.ANNOUNCED && (
                                            <div
                                                className={`inputOuter ${
                                                    currentDegreeResultType ===
                                                        STUDENT_RESULT_TYPES.ANNOUNCED &&
                                                    data?.degree_name !==
                                                        STUDENT_DEGREE_TYPES.MATRICULATION
                                                        ? 'w-38'
                                                        : 'w-34'
                                                } `}
                                            >
                                                <label>Grade</label>
                                                <select
                                                    value={data?.subject_grade}
                                                    onChange={(e) => {
                                                        handleOnInputChange(
                                                            e,
                                                            'subject_grade',
                                                            setData,
                                                            data
                                                        )
                                                    }}
                                                >
                                                    {parseEnumToSelectValues(
                                                        STUDENT_SUBJECT_GRADES_TYPES
                                                    ).map((type) => (
                                                        <option
                                                            selected={
                                                                type ===
                                                                data?.grade
                                                            }
                                                            value={type}
                                                        >
                                                            {type}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        <div className='actionButtonWrapper'>
                                            <Button
                                                fontSize={1}
                                                borderRadius={2.4}
                                                paddingTopBottom={0.6}
                                                paddingLeftRight={1.5}
                                                onClick={handleAddSubjectButton}
                                            >
                                                + ADD SUBJECT
                                            </Button>
                                        </div>
                                    </div>

                                    {currentDegreeResultType ===
                                        STUDENT_RESULT_TYPES.ANNOUNCED &&
                                    data?.result_announced?.subjects?.length ? (
                                        <div className='formFieldWrapperSubjectEdit'>
                                            <ResultAnnouncedSubjectsTable
                                                data={data}
                                                callBackFetchData={() => {
                                                    fetchAndParseDataFromAPI(
                                                        'result_announced.subjects'
                                                    )
                                                }}
                                                handleEdit={initiateEditSubject}
                                            />
                                        </div>
                                    ) : null}
                                    {currentDegreeResultType ===
                                        STUDENT_RESULT_TYPES.AWAITED &&
                                    data?.result_awaited?.subjects?.length ? (
                                        <div className='formFieldWrapperSubjectEdit'>
                                            <ResultAnwaitedSubjectsTable
                                                data={data}
                                                callBackFetchData={() => {
                                                    fetchAndParseDataFromAPI(
                                                        'result_awaited.subjects'
                                                    )
                                                }}
                                                handleEdit={initiateEditSubject}
                                            />
                                        </div>
                                    ) : null}
                                    {currentDegreeResultType ===
                                        STUDENT_RESULT_TYPES.ANNOUNCED && (
                                        <div className='inputOuter profilePicturePickerWrapper'>
                                            <label>Upload Degree/Result</label>
                                            {files
                                                ?.resultAnnouncedUploadedResult
                                                ?.url_on_server && (
                                                <a
                                                    href={
                                                        files
                                                            ?.resultAnnouncedUploadedResult
                                                            ?.url_on_server
                                                    }
                                                    target={'_blank'}
                                                >
                                                    View
                                                </a>
                                            )}
                                            <div className='inputFile'>
                                                {files
                                                    .resultAnnouncedUploadedResult
                                                    ?.to_be_uploaded_buffer
                                                    ?.name ??
                                                    'No file selected'}
                                            </div>
                                            <InputComponent
                                                placeholder={''}
                                                type='file'
                                                fontSize={1}
                                                onChange={(e) => {
                                                    setFiles((files) => {
                                                        const temp = {
                                                            ...files,
                                                        }
                                                        temp[
                                                            'resultAnnouncedUploadedResult'
                                                        ] = {
                                                            ...(temp[
                                                                'resultAnnouncedUploadedResult'
                                                            ] || {}),
                                                            to_be_uploaded_buffer:
                                                                e.target
                                                                    .files[0],
                                                            purpose:
                                                                FILE_UPLOAD_PURPOSE.RESULT_ANNOUNCED,
                                                        }
                                                        return temp
                                                    })
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </FormFieldsWrapper>
                    <ModalComponent
                        modalLabel={'Edit Institution'}
                        isOpen={showModalEditInstitution}
                        callbackCloseModal={() => {
                            setShowModalEditInstitution(false)
                        }}
                    >
                        <EditInstitution
                            callbackCancel={() => {
                                setShowModalEditInstitution(false)
                            }}
                            callbackProceed={finalizeEditInstitution}
                            institutionID={institutionToEdit.current}
                        />
                    </ModalComponent>
                    <ModalComponent
                        modalLabel={'Edit Subject'}
                        isOpen={showModalEditSubject}
                        callbackCloseModal={() => {
                            setShowModalEditSubject(false)
                        }}
                    >
                        <EditSubject
                            callbackCancel={() => {
                                setShowModalEditSubject(false)
                            }}
                            callbackProceed={finalizeEditSubject}
                            subjectID={subjectToEdit.current}
                            resultType={resultTypeSubjectToEdit.current}
                            degree_name={data.degree_name}
                        />
                    </ModalComponent>
                    {asSubComponent && (
                        <div className='actionButtonWrapper'>
                            <Button
                                fontSize={1}
                                maxWidth={10}
                                borderRadius={2.4}
                                paddingTopBottom={0.6}
                                paddingLeftRight={1.5}
                                onClick={async () => {
                                    const response =
                                        await performValidationAndSave()
                                    if (response) {
                                        callBackCloseAsSubComponent()
                                    }
                                }}
                            >
                                UPDATE
                            </Button>
                        </div>
                    )}
                </StyledComponent>
            )
        } else {
            return (
                <SpinnerWrapper className='infoWrapper'>
                    <Spinner />
                </SpinnerWrapper>
            )
        }
    }
)

export default StepTwoContainer
