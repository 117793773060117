import {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

export const useCheckTokenFromGoogle = (setGoogleOauthRedirectContent) => {
    const location = useLocation();
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let state = query.get("state");
        if (state !== null && state.startsWith("google")) {
            setGoogleOauthRedirectContent(window.location.href);
        }
    }, []);
};
