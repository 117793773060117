import React, { useState } from 'react'
import search from '../../../static/images/table/Path 123.svg'

export const SearchBar = ({ fetchAndParseDataFromAPI, endpointQueryParam }) => {
    const [searchParam, setSearchParam] = useState('')
    const handleSearchInputChange = (e) => {
        setSearchParam(e.target.value)
        if (!e.target.value) {
            handleSearch(true)
        }
    }

    const handleSearch = (query) => {
        if (searchParam) {
            endpointQueryParam.current = {
                ...endpointQueryParam.current,
                search: query ? '' : searchParam,
            }
        } else {
            const tempData = {
                ...endpointQueryParam.current,
            }
            delete tempData['search']
            endpointQueryParam.current = tempData
        }

        fetchAndParseDataFromAPI()
    }

    return (
        <>
            <input
                type='search'
                placeholder='Search student by name, city...'
                value={searchParam}
                onChange={handleSearchInputChange}
            />
            <img
                src={search}
                alt='search icon'
                className={'searchIcon'}
                onClick={() => handleSearch()}
            />
        </>
    )
}
