import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormFieldsWrapper } from '../../Global/graphical/data_input/FormFieldsWrapper'
import { InputComponent } from '../../Authentication/components/InputELement'
import { SizedBox } from '../../Global/SizedBox'
import { ImagePickerComponent } from '../../Global/graphical/data_input/ProfilePicturePickerComponent'
import { GlobalUserProfileContext } from '../../../App'
import moment from 'moment'

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: 0 10px 10px -3px #cfcfcf;
    padding: 3rem;
    @media (max-width: 500px) {
        padding: 3rem 1rem;
    }
    .profilePicturePickerWrapper {
        padding: 0 1.5rem;
    }
    .profilePicturePickerWrapper > div {
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
        .controlsWrapperImage .text {
            display: none;
        }
        .controlsWrapperImage button {
            background-color: #fff;
            border: none;
            color: black;
        }
        .imageWrapper {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media (max-width: 900px) {
            flex-direction: column-reverse;
        }
        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .inputOuter {
                width: 32.5%;
                @media (max-width: 1917px) {
                    width: 32%;
                }
                @media (max-width: 1469px) {
                    width: 31.5%;
                }
                @media (max-width: 1405px) {
                    width: 48%;
                }
                @media (max-width: 1093px) {
                    width: 100%;
                }
            }
            .textarea {
                width: 100%;
            }
        }
        .actionButtonWrapper {
            align-self: end;
            justify-self: end;
        }
    }
    .helperText {
        font-size: 14px;
        margin: 3rem 0 2rem;
    }
`
export const EditPersonalinformation = () => {
    const { profile } = useContext(GlobalUserProfileContext)

    return (
        <StyledComponent>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter">
                        <label>Full Name</label>
                        <InputComponent
                            placeholder={'Enter Your Full Name'}
                            type="text"
                            fontSize={1}
                            value={profile?.full_name}
                            disabled
                        />
                    </div>
                    <div className="inputOuter">
                        <label>CNIC</label>
                        <InputComponent
                            placeholder={'xxxxx-xxxxxxx-x'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.account_data?.cnic}
                        />
                    </div>
                    <div className="inputOuter">
                        <label>Date Of Birth</label>
                        <InputComponent
                            placeholder={'DD/MM/YYYY'}
                            type="date"
                            name="date"
                            min="1950-01-01"
                            max="2030-12-31"
                            fontSize={1}
                            disabled
                            value={moment(profile?.account_data?.dob).format(
                                'YYYY-MM-DD'
                            )}
                        />
                    </div>
                    <div className="inputOuter">
                        <label>Parent CNIC/CRC/B-Form</label>
                        <InputComponent
                            placeholder={'xxxxx-xxxxxxx-x'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.account_data?.parent_cnic}
                        />
                        <SizedBox height={1.0} />
                    </div>
                    <div className="inputOuter">
                        <label>Gender</label>
                        <InputComponent
                            placeholder={'Male / Female'}
                            type="text"
                            disabled
                            fontSize={1}
                            value={profile?.account_data?.gender}
                        />
                    </div>
                    <div className="inputOuter">
                        <label>City</label>
                        <InputComponent
                            placeholder={'Enter Your City'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.account_data?.city}
                        />
                    </div>
                    <div className="inputOuter">
                        <label>Father Name</label>
                        <InputComponent
                            placeholder={'Enter Your Father/Guardian Name'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.account_data?.father_name}
                        />
                    </div>
                    <div className="inputOuter">
                        <label>Mobile Number</label>
                        <InputComponent
                            placeholder={'92xxxxxxxxxx'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.phone_number}
                        />
                    </div>
                    <div className="inputOuter textarea">
                        <label>Residential Address</label>
                        <InputComponent
                            placeholder={'Enter Your Address Here.'}
                            type="text"
                            fontSize={1}
                            disabled
                            value={profile?.account_data?.residential_address}
                        />
                    </div>
                </div>
                <div className="profilePicturePickerWrapper">
                    <ImagePickerComponent image={profile?.picture?.file} />
                    <SizedBox height={3} />
                </div>
            </FormFieldsWrapper>
            {profile?.account_data
                ?.has_confirmed_admission_disclaimer_notice === true ? (
                ''
            ) : (
                <p className="helperText">
                    <strong>Disclaimer</strong>: Please contact your campus head
                    if you wish to make any changes.
                </p>
            )}
        </StyledComponent>
    )
}
