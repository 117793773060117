import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { FormFieldsWrapper } from "../../../../Global/graphical/data_input/FormFieldsWrapper";
import { InputComponent } from "../../../../Authentication/components/InputELement";
import { Spinner } from "../../../../Global/graphical/Spinner";
import { Button } from "../../../../Global/Button";
import { handleOnInputChange } from "../../../../../helper/form_inputs/handleOnInputChange";
import { axiosServerInstance } from "../../../../../config/api/axios";
import { notifyApiErrorMessage } from "../../../../../helper/notifications/notifyApiErrorMessage";
import { useParams } from "react-router-dom";
import { notifySuccess } from "../../../../../helper/notifications/notifySuccess";
import { parseEnumToSelectValues } from "../../../../../helper/form_inputs/parseEnumToSelectValues";
import { GENDER_TYPES } from "../../../../../config/store/constants";

const StyledComponent = styled.div`
    .formFieldWrapper {
        border-radius: 20px;
        box-shadow: 0 10px 10px -3px #cfcfcf;
        padding: 3rem;
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media screen and (max-width: 500px) {
            padding: 2rem 1rem;
        }
        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .inputOuter {
                width: 32%;
            }
            .inputOuter.w-38 {
                width: 32.3%;
                @media (max-width: 1380px) {
                    width: 48%;
                }
                @media (max-width: 861px) {
                    width: 100%;
                }
            }
            .textarea {
                width: 100%;
            }
        }
        .actionButtonWrapper {
            align-self: end;
            justify-self: end;
        }
    }
    .textarea {
        width: 100%;
    }

    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);
        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }

    .actionButtonWrapper {
        margin-top: 3rem;
        display: flex;
        justify-content: flex-end;
    }
    .helperText {
        font-size: 14px;
        margin: 3rem 0 2rem;
    }
`;

const mainAccFields = ["full_name", "phone_number"];
const accDataFields = ["cnic", "gender", "city", "father_name", "residential_address"];
export const Personalinformation = () => {
    const [data, setData] = useState({});
    const [accountData, setAccountData] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const { id: studentID } = useParams();

    const fetchDataFromServer = async () => {
        const endpoint = `/pro/students/${studentID}/`;
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data);
                setAccountData(response.data?.account_data);
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
    };

    const saveDataToServer = async () => {
        // Personal Data
        let endpoint = `/pro/students/${studentID}/`;
        let requestData = {};
        for (let field of mainAccFields) {
            requestData[field] = data[field];
        }
        let status;
        setIsProcessing(true);
        await axiosServerInstance()
            .patch(endpoint, requestData)
            .then((response) => {
                status = true;
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                status = false;
            });
        if (status) endpoint = `/pro/students/${studentID}/admission/information`;
        requestData = {};
        for (let field of accDataFields) {
            requestData[field] = accountData[field];
        }
        await axiosServerInstance()
            .patch(endpoint, requestData)
            .then((response) => {
                status = true;
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                status = false;
            });
        setIsProcessing(false);
        if (status) {
            notifySuccess("Success");
            fetchDataFromServer();
        }
    };

    useEffect(() => {
        fetchDataFromServer();
    }, [studentID]);

    return (
        <StyledComponent>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-38">
                        <label>Full Name</label>
                        <InputComponent
                            placeholder={"Please Enter Your Full Name"}
                            type="text"
                            fontSize={0.7}
                            value={data?.full_name}
                            onChange={(e) => {
                                handleOnInputChange(e, "full_name", setData, data);
                            }}
                        />
                    </div>
                    <div className="inputOuter w-38">
                        <label>CNIC</label>
                        <InputComponent
                            placeholder={"xxxxx-xxxxxxx-x"}
                            type="text"
                            fontSize={0.7}
                            value={accountData?.cnic}
                            onChange={(e) => {
                                handleOnInputChange(e, "cnic", setAccountData, accountData);
                            }}
                        />
                    </div>
                    <div className="inputOuter w-38">
                        <label>Gender</label>
                        <select
                            value={accountData?.gender}
                            onChange={(e) =>
                                handleOnInputChange(e, "gender", setAccountData, accountData)
                            }
                        >
                            {parseEnumToSelectValues(GENDER_TYPES).map((type) => (
                                <option selected={type === accountData?.gender} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="inputOuter w-38">
                        <label>City</label>
                        <InputComponent
                            placeholder={"Enter Your City "}
                            type="text"
                            fontSize={0.7}
                            value={accountData?.city}
                            onChange={(e) => {
                                handleOnInputChange(e, "city", setAccountData, accountData);
                            }}
                        />
                    </div>
                    <div className="inputOuter w-38">
                        <label>Father Name</label>
                        <InputComponent
                            placeholder={"Enter Your Father/Guadrian Name "}
                            type="text"
                            fontSize={0.7}
                            value={accountData?.father_name}
                            onChange={(e) => {
                                handleOnInputChange(e, "father_name", setAccountData, accountData);
                            }}
                        />
                    </div>
                    <div className="inputOuter w-38">
                        <label>Mobile Number</label>
                        <InputComponent
                            placeholder={"92xxxxxxxx"}
                            type="text"
                            fontSize={0.7}
                            value={data?.phone_number}
                            onChange={(e) => {
                                handleOnInputChange(e, "phone_number", setAccountData, accountData);
                            }}
                        />
                    </div>
                    <div className="inputOuter textarea">
                        <label>Residential Address</label>
                        <InputComponent
                            placeholder={"Enter Your Address here."}
                            type="text"
                            fontSize={0.7}
                            value={accountData?.residential_address}
                            onChange={(e) => {
                                handleOnInputChange(
                                    e,
                                    "residential_address",
                                    setAccountData,
                                    accountData
                                );
                            }}
                        />
                    </div>
                </div>
            </FormFieldsWrapper>
            <div className="actionButtonWrapper">
                {isProcessing ? (
                    <Spinner size={1.5} />
                ) : (
                    <Button
                        fontSize={1}
                        borderRadius={2.4}
                        maxWidth={10}
                        paddingTopBottom={0.6}
                        paddingLeftRight={3}
                        onClick={(e) => {
                            e.preventDefault();
                            saveDataToServer();
                        }}
                    >
                        SAVE
                    </Button>
                )}
            </div>
        </StyledComponent>
    );
};
