import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {TableComponent} from "./../../../../Global/graphical/table/Container";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {useParams} from "react-router-dom";
import {ChallanFormContext} from "./Container";
import {Spinner} from "../../../../Global/graphical/Spinner";
import {SizedBox} from "../../../../Global/SizedBox";

const StyledComponent = styled.div`
  width: inherit;

  .formTitle {
    font-size: 1.2rem;
    margin-left: 1rem;
    color: var(--custom-Black);
    margin: 1rem 0;
  }

  .feeManagement {
    display: flex;
    justify-content: left;

    .ColoredCard {
      border-radius: 2.5rem 0rem 0rem 2.5rem;
      padding: 1rem 0 1rem 1rem;
      border: 1px solid var(--custom-border-color);
      background-color: var(--custom-secondary-bg);

      p {
        font-weight: 500;
      }
    }

    .ColoredCard,
    .PlaneCard {
      width: 46%;

      p {
        padding-left: 1rem;
        color: var(--custom-white);
        line-height: 2.5rem;
        border-bottom: 1px solid var(--custom-border-color);
      }
    }

    .PlaneCard {
      border: 1px solid var(--custom-border-color);
      border-left: 0px;
      border-radius: 0rem 2.5rem 2.5rem 0rem;
      padding: 1rem 1rem 1rem 0rem;

      p {
        color: var(--custom-Black);

        padding-left: 2rem;
      }
    }
  }
`;


const headerData = [
    "ID",
    "BSS ID",
    "Name",
    "REGISTRATION FEE",
    "CHALLAN AND SECURITY FORM",
    "CONDITIONAL OFFER LETTER",
];
export const BeforeResults = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const {id: studentID} = useParams();
    const [data, setData] = useState(null)
    const {studentAccountData} = useContext(ChallanFormContext)
    const [showSpinner, setShowSpinner] = useState(false)
    const [beforeResultData, setBeforeResultData] = useState([])

    const fetchBeforeResultFeeStatus = async () => {
        const endpoint = `/pro/students/action/${studentID}/fetch_before_result_fees_status`
        setShowSpinner(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                console.log(response.data)
                setData(response.data)
            })
            .catch((err) => {
                notifyFailure("Error in before result fee status data")
            });
        setShowSpinner(false)
    }

    useEffect(() => {
        if (studentID) {
            fetchBeforeResultFeeStatus()
        }
    }, [studentID])

    useEffect(() => {
        setBeforeResultData([
                {
                    id: studentAccountData?.id,
                    Bss_id: studentAccountData?.account_data?.system_id_on_bss_portal || studentAccountData?.account_data?.registration_id_on_bss_portal || studentAccountData?.account_data?.visitor_id_on_bss_portal,
                    name: studentAccountData?.full_name,
                    RegistrationFee: data?.registration ? "DONE" : "PENDING",
                    CHALLAN_AND_SECURITY_FORM: data?.challan_and_security ? "DONE" : "PENDING",
                    CONDITIONAL_OFFER_LETTER: "PRINT",
                },
            ]
        )
    }, [data, studentAccountData])
    useEffect(() => {
        if (beforeResultData) {
            setTableBodyData(
                beforeResultData.map((data) => {
                    return {
                        id: data.id,
                        data: [
                            {
                                type: "text",
                                value: data.id,
                            },
                            {
                                type: "text",
                                value: data.Bss_id,
                            },
                            {
                                type: "text",
                                value: data.name,
                            },
                            {
                                type: "text",
                                value: data.RegistrationFee,
                            },
                            {
                                type: "text",
                                value: data.CHALLAN_AND_SECURITY_FORM,
                            },
                            {
                                type: "text",
                                value: data.CONDITIONAL_OFFER_LETTER,
                            },
                        ],
                    };
                })
            );
        }
    }, [beforeResultData]);
    return (
        <StyledComponent>
            {
                showSpinner ? (
                    <div className="spinnerWrapper">
                        <SizedBox height={3}/>
                        <Spinner size={1.5}/>
                        <SizedBox height={1}/>
                    </div>
                ) : (
                    <TableComponent
                        heading="BEFORE RESULT"
                        headerData={headerData}
                        bodyData={tableBodyData}
                    />
                )
            }
        </StyledComponent>
    );
};
