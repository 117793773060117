import React, { useState } from "react";
import { Button } from "../../Global/Button";
import { SizedBox } from "../../Global/SizedBox";
import { AuthButtonContainer } from "../components/AuthButtonContainer";
import { FormComponent } from "../components/FormElement";
import { InputComponent } from "../components/InputELement";
import { useLocation, useNavigate } from "react-router-dom";
import { handleOnInputChange } from "../../../helper/form_inputs/handleOnInputChange";
import { Spinner } from "../../Global/graphical/Spinner";

export const CreatePasswordForm = () => {
    const [data, setData] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClickButton = (e) => {
        e.preventDefault();
        handleProceedCreatePassword();
    };

    const handleProceedCreatePassword = () => {};

    return (
        <FormComponent>
            <label>Enter Password</label>
            <InputComponent
                placeholder={""}
                type="password"
                value={data?.password}
                onChange={(e) => handleOnInputChange(e, "password", setData, data)}
            />
            <SizedBox height={1.0} />
            <label>Confirm Password</label>
            <InputComponent
                placeholder={""}
                type="password"
                value={data?.confirm_password}
                onChange={(e) => handleOnInputChange(e, "confirm_password", setData, data)}
            />
            <SizedBox height={1.5} />
            <AuthButtonContainer>
                {!showSpinner ? (
                    <>
                        <Button
                            fontWeight={300}
                            fontSize={1}
                            maxWidth={15}
                            borderRadius={1.5}
                            paddingTopBottom={0.6}
                            onClick={handleOnClickButton}
                        >
                            NEXT
                        </Button>
                    </>
                ) : (
                    <Spinner size={2} />
                )}
            </AuthButtonContainer>
        </FormComponent>
    );
};
