import { useEffect, useState } from "react";
import { AuthMainContainer } from "../components/AuthMainContainer";
import { LoginBssForm } from "./BssForm";
import { LoginNonBssForm } from "./NonBssForm";
import { useLocation } from "react-router-dom";
import { Spinner } from "../../Global/graphical/Spinner";
import { SizedBox } from "../../Global/SizedBox";

const LoginContainer = () => {
    const [isBss, setIsBss] = useState("Bss");
    const [renderPage, setRenderPage] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("bss_token")) {
            setRenderPage(false);
            setIsBss("Bss");
        }
    }, []);

    return (
        <AuthMainContainer isBssActive={isBss} mode={renderPage ? "login" : null}>
            {renderPage ? (
                <>
                    <p>
                        <strong>Disclaimer</strong>: For existing BSS students, please use your
                        existing Beaconhouse email address to login If you are not a Beaconhouse
                        student, please use your Gmail credentials
                    </p>
                    <SizedBox height={2} />
                    <div className="AuthRadioMode">
                        <input
                            type="radio"
                            value="Bss"
                            name="Bss"
                            id="bss"
                            checked={isBss === "Bss"}
                            onChange={(e) => setIsBss(e.currentTarget.value)}
                        />
                        <label htmlFor="bss">BSS Student</label>
                        <input
                            type="radio"
                            value="NonBss"
                            name="NonBss"
                            id="nonbss"
                            checked={isBss === "NonBss"}
                            onChange={(e) => setIsBss(e.currentTarget.value)}
                        />
                        <label htmlFor="nonbss">Non BSS Student</label>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
            {isBss === "NonBss" ? (
                <LoginNonBssForm />
            ) : (
                <LoginBssForm renderPage={renderPage} setRenderPage={setRenderPage} />
            )}
        </AuthMainContainer>
    );
};

export default LoginContainer;
