import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainWrapper } from "../../Global/MainWrapper";
import { DetailTable } from "./components/DetailTable";
import { ListFilterComponentByCityCampus } from "../global/ListFilterComponentByCityCampus";
import FilterByBatch from "../../Global/filters/FilterByBatch";

const StyledComponent = styled.div`
  width: 100%;

  .component {
    width: 100%;
    border-radius: 20px;
    display: flex;
    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      gap: 1rem;

      .inputOuter.w-32 {
        width: 39.2%;
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }

      .inputOuter.w-22 {
        width: 25%;
        @media screen and (max-width: 1545px) {
          width: 30%;
        }
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }
    }

    .actionButtonWrapper {
      align-self: end;
      justify-self: end;
    }
  }

  .ActionButtons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  .actionButtonSave {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 0rem;
  }

  .table {
    margin: 1rem 0;

    .tableOuter {
      padding: 1rem 0px;
    }
  }

  .table > div > div {
    box-shadow: none;
    padding: 1rem 0px;
  }

  .title {
    margin-bottom: 3rem;
  }

  .searchActions {
    @media (max-width: 1250px) {
      flex-wrap: wrap;
    }
  }
`;

export const ScholarshipContainer = () => {
  const [filterData, setFilterData] = useState({});
  const [formattedFilterData, setFormattedFilterData] = useState({});
  const [batchFilter, setBatchFilter] = useState({ batch: "" });

  useEffect(() => {
    if (Object.values(filterData).length) {
      const tempFormattedFilterData = {};
      if (filterData["selectedCity"]) {
        tempFormattedFilterData["city_campus__city_id"] =
          filterData["selectedCity"];
      }

      if (filterData["selectedCampus"]) {
        tempFormattedFilterData["city_campus__campus_id"] =
          filterData["selectedCampus"];
      }
      setFormattedFilterData(tempFormattedFilterData);
    }
  }, [filterData]);

  return (
    <MainWrapper pageTitle={"SCHOLARSHIP"}>
      <StyledComponent>
        <div className='searchActions'>
          <div className='selectOuter'>
            <FilterByBatch setBatchFilter={setBatchFilter} />
          </div>
        </div>
        <div className='searchActions'>
          <ListFilterComponentByCityCampus setterCallback={setFilterData} />
        </div>

        <div className='component'>
          <DetailTable
            filterData={formattedFilterData}
            batchFilter={batchFilter}
          />
        </div>
      </StyledComponent>
    </MainWrapper>
  );
};
