import { createContext, useEffect, useState } from "react";
import { MainWrapper } from "../../Global/MainWrapper";
import { StudentData } from "../../../config/DataList/studentData/StudentData";
import { StudentsListComponents } from "./components/StudentsList";
import styled from "styled-components";

export const StudentsContext = createContext({});

const StyledComponent = styled.div`
    .searchActions {
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-bottom: 2rem;
        .helperText {
            flex: auto;
        }
    }
    .searchOuter {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        border: 1px solid #ddd;
        border-radius: 2.5rem;
        background-color: #fff;
        height: 3rem;
        width: 100%;
        max-width: 33.9375rem;
        align-items: center;
        padding: 0 1rem;
        input {
            border: none;
            outline: none;
            width: 100%;
            background: transparent;
        }
    }
    .badge-info {
        color: #1c70c8;
    }
  
`;
export const StudentsContainer = () => {
    const [students, setStudents] = useState([]);
    const [studentPRO, setStudentPRO] = useState(true);
    const [studentSuperAdmin, setStudentSuperAdmin] = useState(false);
    const [studentSchoolHead, setStudentSchoolHead] = useState(false);
    const [studentRegionalHead, setStudentRegionalHead] = useState(false);
    useEffect(() => {
        // axiosServerInstance().post('/auth/login', validatedData).then((response) => {
        //
        setStudents(StudentData);
        // }).catch((err) => {

        //     setShowSpinner(false)
        // });
    }, []);

    return (
        <MainWrapper pageTitle={"STUDENTS"}>
            <StyledComponent>
                <StudentsContext.Provider
                    value={{
                        students,
                        studentSuperAdmin,
                        studentSchoolHead,
                        studentRegionalHead,
                    }}
                >
                    <StudentsListComponents />
                </StudentsContext.Provider>
            </StyledComponent>
        </MainWrapper>
    );
};
