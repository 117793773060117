import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { options } from "./component/Option";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";

ChartJS.register(ChartDataLabels, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Wrapper = styled.div`
    min-width: 300px;
    overflow: auto;
    canvas {
        min-width: 800px;
        height: 100%;
        overflow: auto;
        min-height: 400px !important;
        @media (max-width: 1300px) {
            min-height: 100% !important;
        }
    }
`;

export const BarChartContainer = ({ labels, data }) => {
    const dataset = {
        labels,
        datasets: [
            {
                label: "Dataset 1",
                data,
                backgroundColor: "rgba(60, 180, 290, 1)",
                borderColor: "rgb(255, 99, 132)",
                pointHoverBackgroundColor: "",
                fill: true,
                datalabels: {
                    color: "#FFCE56",
                    anchor: "end",
                    align: "end",
                    labels: {
                        value: {
                            color: "black",
                        },
                        font: {
                            size: 14,
                        },
                    },
                },
            },
        ],
    };
    return (
        <Wrapper>
            <Bar options={options} data={dataset} />
        </Wrapper>
    );
};
