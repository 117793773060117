import Delete from "../../../../../../static/images/table/Path 39.png";

export const ActionsDelete = ({actionDelete, dataID}) => {
    return (
        <>
            {actionDelete && (
                <div className="editBtn">
                    {Object.keys(actionDelete).map((actionName) => (
                        <button
                            className="button"
                            onClick={(e) => {
                                e.preventDefault()
                                actionDelete[actionName](dataID)
                            }}
                        >
                            <img src={Delete} alt=""/>
                        </button>
                    ))}
                </div>
            )}
        </>
    );
};
