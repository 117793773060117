import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../../Global/Button";
import { FormFieldsWrapper } from "../../../Global/graphical/data_input/FormFieldsWrapper";
import { Spinner } from "../../../Global/graphical/Spinner";
import styled from "styled-components";
import { ToggleButton } from "../../../Global/graphical/toggleBtn/ToggleButton";
import {
    INTERVEIW_STATUS_TYPES,
    STUDENT_ADMISSION_STATUS_TYPES,
} from "../../../../config/store/constants";
import { handleOnInputChange } from "../../../../helper/form_inputs/handleOnInputChange";
import { formatQueryDictToQueryParams } from "../../../../helper/formatters/formatDictToParams";
import { axiosServerInstance } from "../../../../config/api/axios";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { GlobalUserProfileContext } from "../../../../App";
import { notifySuccess } from "../../../../helper/notifications/notifySuccess";
import { ReminderContext } from "./RemindersList";

const StyledComponent = styled.div`
    margin: 1rem 1.5rem;
    @media (max-width: 500px) {
        margin: 1rem 0.5rem;
    }

    .headingReminder {
        display: flex;
        justify-content: space-between;
        @media (max-width: 640px) {
            flex-wrap: wrap;
        }
    }

    .formTitle {
        font-size: 2rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;

            .inputOuter {
                width: 32%;
            }

            .ResultRadioMode {
                width: 100%;

                input {
                    margin: 0 1rem;
                }
            }

            .inputOuter.w-100 {
                width: 100%;
            }

            .inputOuter.w-38 {
                width: 45%;
                @media screen and (max-width: 45rem) {
                    width: 100%;
                }
            }

            textarea {
                width: 100%;
                display: flex;
                height: 9rem;
                text-indent: 10px;
                background: var(--custom-input-bg);
                border-radius: 10px;
                outline: none;
                background-image: none;
                overflow: hidden;
                border: 1px solid var(--custom-input-border);
            }
        }
    }

    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);

        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }

    .RadioMode {
        input {
            margin: 0 0.5rem 0 1rem;
        }
    }

    .formFieldWrapperSubject {
        align-items: flex-start;

        .helperText {
            font-size: 12px;
            margin-left: 1rem;
            line-height: 1.5rem;
        }
    }

    .actionButtonWrapper {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
    }
`;

const optionsDuration = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31,
];
let reminderChannelMode = [
    ["EMAIL", "Via Email"],
    ["SMS", "via SMS"],
    ["SMS_EMAIL", "Both"],
];

export const AddReminder = ({}) => {
    const [data, setData] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [campusResultAnnouncedStatus, setCampusResultAnnouncedStatus] = useState(false);
    const currentCampusID = useRef(null);
    const { profile } = useContext(GlobalUserProfileContext);
    const { currentlyInSingleMode, studentToRemind } = useContext(ReminderContext);
    const fetchResultAnnouncedStatus = async () => {
        if (profile?.pro_account_data?.campus_id) {
            const endpoint = `/shared/campuses?campus_id=${profile?.pro_account_data?.campus_id}`;
            await axiosServerInstance()
                .get(endpoint)
                .then((response) => {
                    if (response?.data?.results?.length) {
                        const campusData = response.data.results[0];
                        currentCampusID.current = campusData.id;
                        setCampusResultAnnouncedStatus(campusData.result_announced);
                    }
                })
                .catch((err) => {
                    notifyFailure("Error: Could not get result announced status");
                });
        }
    };

    const handleSetResultAnnouncedStatus = async (newStatus) => {
        if (!currentCampusID) return;
        const endpoint = `/shared/campuses/${currentCampusID.current}/`;
        await axiosServerInstance()
            .patch(endpoint, { result_announced: newStatus })
            .then((response) => {
                setCampusResultAnnouncedStatus(newStatus);
            })
            .catch((err) => {
                notifyFailure("Error: Could not set result announced status");
            });
    };

    const handleProceedToAddReminder = async (e) => {
        setShowSpinner(true);
        const endpoint = `/pro/students/action/send_reminder/`;
        let requestData = { ...data };
        if (currentlyInSingleMode) {
            requestData.admission_status_category = "SINGLE";
            requestData.student_id = studentToRemind;
        }
        console.log(requestData);
        await axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                notifySuccess("Operation Successful");
                const tempData = {};
                for (let field in data) {
                    tempData[field] = "";
                }
                setData(tempData);
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
        setShowSpinner(false);
    };

    useEffect(() => {
        fetchResultAnnouncedStatus();
    }, [profile]);

    return (
        <StyledComponent>
            <div className="headingReminder">
                <h2 className="formTitle">
                    {currentlyInSingleMode ? "NOTIFY STUDENT" : "REMINDERS"}
                </h2>
                {!currentlyInSingleMode && (
                    <ToggleButton
                        label={"Result Announced"}
                        checked={campusResultAnnouncedStatus}
                        callBackOnChange={handleSetResultAnnouncedStatus}
                    />
                )}
            </div>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    {!currentlyInSingleMode && (
                        <div className="inputOuter w-38">
                            <label>Student List</label>
                            <select
                                value={data?.admission_status_category}
                                onChange={(e) =>
                                    handleOnInputChange(
                                        e,
                                        "admission_status_category",
                                        setData,
                                        data
                                    )
                                }
                            >
                                <option value=""></option>
                                <option
                                    value="ALL"
                                    selected={data?.admission_status_category === "ALL"}
                                >
                                    All Students
                                </option>
                                {Object.values(STUDENT_ADMISSION_STATUS_TYPES).map((status) => (
                                    <option
                                        selected={data?.admission_status_category === status}
                                        value={status}
                                    >
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {!currentlyInSingleMode &&
                        data?.admission_status_category ===
                            STUDENT_ADMISSION_STATUS_TYPES.INTERVIEW_SCHEDULED && (
                            <div className="inputOuter w-38">
                                <label>Duration</label>
                                <select
                                    value={data?.duration}
                                    onChange={(e) =>
                                        handleOnInputChange(e, "duration", setData, data)
                                    }
                                >
                                    {optionsDuration.map((item, i) => (
                                        <option
                                            key={i}
                                            value={item}
                                            selected={data?.duration === item}
                                        >
                                            {item} days
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    <div className="inputOuter w-100">
                        <label>Message</label>
                        <textarea
                            value={data?.message}
                            onChange={(e) => handleOnInputChange(e, "message", setData, data)}
                        />
                    </div>
                    <div
                        className="RadioMode"
                        onChange={(e) => handleOnInputChange(e, "mode", setData, data)}
                    >
                        <span>Mode:</span>
                        {reminderChannelMode.map((item, i) => {
                            return (
                                <>
                                    <input
                                        key={i}
                                        type="radio"
                                        value={item[0]}
                                        name={"reminderChannelMode"}
                                        checked={item[0] === data?.mode}
                                    />
                                    {item[1]}
                                </>
                            );
                        })}
                    </div>
                </div>
            </FormFieldsWrapper>

            <div className="actionButtonWrapper">
                {showSpinner ? (
                    <Spinner size={1.5} />
                ) : (
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={handleProceedToAddReminder}
                    >
                        SEND
                    </Button>
                )}
            </div>
        </StyledComponent>
    );
};
