import styled from "styled-components";
import {useContext, useEffect, useRef, useState} from "react";
import {TableComponent} from "../../../Global/graphical/table/Container";
import {ModalComponent} from "../../../Global/graphical/Modal";
import {EditMatric} from "./EditMatricModal";
import {EditOlevel} from "./EditOlevelModal";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifySuccess} from "../../../../helper/notifications/notifySuccess";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../../helper/notifications/notifyApiErrorMessage";

const StyledComponent = styled.div``;


const headerData = ["Total Grades (A/A*): ", "Scholarship (%)", ""];
export const OLevelTable = ({data, fetchData}) => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const [showModalEditScholarshipCriteria, setShowModalEditScholarshipCriteria] = useState(false)
    const currentSelectedCriteria = useRef(null)

    const handleDeleteData = async () => {
        const dataEndpoint = `/shared/scholarship_criterias/${currentSelectedCriteria.current}/`;
        return await axiosServerInstance()
            .delete(dataEndpoint)
            .then((response) => {
                notifySuccess('Operation success')
                return true
            })
            .catch((err) => {
                notifyFailure("Error in deleting scholarship criteria");
                notifyApiErrorMessage(err)
                return false
            });
    };

    useEffect(() => {
        if (data) {
            setTableBodyData(data.map((item) => {
                return {
                    id: item?.id,
                    data: [
                        {
                            type: "text",
                            value: item.total_distinction_count,
                        },
                        {
                            type: "text",
                            value: Math.round(item.percentage) + "%",
                        },
                        {
                            type: "edit",
                            value: null
                        },
                        {
                            type: "delete",
                            value: null
                        }
                    ],
                }
            }))
        }
    }, [data])

    return (
        <StyledComponent>
            <TableComponent
                headerData={headerData}
                bodyData={tableBodyData}
                actionEdit={{
                    edit: (id) => {
                        currentSelectedCriteria.current = id
                        setShowModalEditScholarshipCriteria(true)
                    },
                }}
                actionDelete={{
                    delete: async (id) => {
                        currentSelectedCriteria.current = id
                        if (await handleDeleteData()) {
                            fetchData()
                        }
                    },
                }}
            />
            <ModalComponent
                modalLabel={"Edit Scholarship Criteria"}
                isOpen={showModalEditScholarshipCriteria}
                callbackCloseModal={() => {
                    setShowModalEditScholarshipCriteria(false);
                }}
            >
                <EditOlevel
                    callbackCancel={() => {
                        setShowModalEditScholarshipCriteria(false);
                    }}
                    callbackProceed={() => {
                        fetchData()
                        setShowModalEditScholarshipCriteria(false);
                    }}
                    criteriaID={currentSelectedCriteria.current}

                />
            </ModalComponent>
        </StyledComponent>
    );
};
