export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START'
export const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED'
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'
export const ERROR_MODE_SERVER = 'ERROR_MODE_SERVER'
export const ERROR_MODE_EXTERNAL = 'ERROR_MODE_EXTERNAL'
export const ERROR_MODE_CUSTOM = 'ERROR_MODE_CUSTOM'
export const SET_ACTIVE_NAVIGATION = 'SET_ACTIVE_NAVIGATION'
export const CLEAR_ACTIVE_NAVIGATION = 'CLEAR_ACTIVE_NAVIGATION'
export const SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION =
    'SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION'
export const CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION =
    'CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION'
export const PRO_SET_STUDENT_CURRENT_PROFILE_STATE =
    'PRO_SET_STUDENT_CURRENT_PROFILE_STATE'
export const PRO_CLEAR_STUDENT_CURRENT_PROFILE_STATE =
    'PRO_CLEAR_STUDENT_CURRENT_PROFILE_STATE'
export const VERIFY_PHONE_NUMBER_TRUE = 'VERIFY_PHONE_NUMBER_TRUE'

export const VERIFY_PHONE_NUMBER_FALSE = 'VERIFY_PHONE_NUMBER_FALSE'

export const ACCOUNT_TYPES = {
    STUDENT: 'Student',
    PRO: 'PRO',
    REGIONAL_HEAD: 'Regional Head',
    SCHOOL_HEAD: 'School Head',
    SUPERADMIN: 'Super Admin',
}
export const GENDER_TYPES = {
    MALE: 'Male',
    FEMALE: 'Female',
}

export const STUDENT_DEGREE_TYPES = {
    MATRICULATION: 'Matriculation',
    O_LEVEL: 'O Level',
    OTHERS: 'Others',
}

export const FILE_UPLOAD_PURPOSE = {
    PROFILE_PICTURE: 'Profile Picture',
    RESULT_ANNOUNCED: 'Result Announced',
}

export const SCHOLARSHIP_TYPES = {
    CRITERIA: 'Result Announced',
    DETAILS: 'Result Awaited',
}

export const INTERVEIW_STATUS_TYPES = {
    APPLIED: 'Applied',
    SCHEDULED: 'Schedule Interview',
}
export const FINANCE_STATUS_TYPES = {
    RESERVED: 'Reserved',
    CONFIRMED: 'Confirmed',
}
export const STUDENT_RESULT_TYPES = {
    ANNOUNCED: 'Result Announced',
    AWAITED: 'Result Awaited',
}

export const STUDENT_SUBJECT_GRADES_TYPES = {
    RESULT_AWAITED: 'Result Awaited',
    A_PLUS: 'A*',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    X: 'X',
}
export const STUDENT_SUBJECT_NAMES = [
    'Accounting 7707',
    'Agriculture 5038',
    'Arabic 3180',
    'Art and Design 6090',
    'Biology 5090',
    'Business Studies 7115',
    'Chemistry 5070',
    'Commerce 7100',
    'Computer Science 2210',
    'Design and Technology 6043',
    'Economics 2281',
    'English Language 1123',
    'Environmental Management 5014',
    'Fashion and Textiles 6130',
    'Food and Nutrition 6065',
    'French 3015',
    'Geography 2217',
    'Global Perspectives 2069',
    'History 2147',
    'Islamic Studies 2068',
    'Islamiyat 2058',
    'Literature in English 2010',
    'Marine Science 5180',
    'Mathematics Additional 4037',
    'Mathematics 4024',
    'Pakistan Studies 2059',
    'Physics 5054',
    'Science Combined 5129',
    'Sociology 2251',
    'Statistics 4040',
    'Travel and Tourism 7096',
    'Urdu First Language 3247',
    'Urdu Second Language 3248',
]

export const STUDENT_ADMISSION_STATUS_TYPES = {
    NOT_APPLIED: 'Not Applied',
    APPLIED: 'Applied',
    INTERVIEW_SCHEDULED: 'Schedule Interview',
    INTERVIEWED: 'Interviewed',
    RESERVED: 'Reserved',
    CONFIRMED: 'Confirmed',
    ENROLLED: 'Enrolled',
    CANCELLED: 'Cancelled',
}

export const ANALYTICS_STATUS_TYPES = {
    APPLIED: 'Applied',
    INTERVIEW_SCHEDULED: 'Schedule Interview',
    FEE_PAID: 'Fee Paid',
    CONFIRMED: 'Confirmed',
}
