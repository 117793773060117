import {Route, Routes} from "react-router-dom";
import {ProtectedRouteComponent} from "../../components/Global/ProtectedRouteComponent";
import {authPagesConditions} from "../../conditions";
import {StudentsPROContainer} from "../../components/PRO/students/Container";
import {StudentDetailContainer} from "../../components/PRO/student_details/Container";
import {InterviewPROContainer} from "../../components/PRO/interview/Container";
import {SubjectConfirmationPROContainer} from "../../components/PRO/subjectConfirmation/Container";
import {FinancePROContainer} from "../../components/PRO/finance/Container";
import {RemindersPROContainer} from "../../components/PRO/reminders/Container";
import {ScholarshipContainer} from "../../components/PRO/scholarship/Container";
import {AnalyticsProContainer} from "../../components/PRO/analytics/Container";
import {ViewSubject} from "../../components/PRO/scholarship/viewSubject/ViewSubject";
import {StudentsBSSNONBSSPROContainer} from "../../components/PRO/students_bss_non_bss/Container";

export const PRORoutes = () => {
    return (
        <Routes>
            <Route
                path="/pro/students"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<StudentsPROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/students/bss-non-bss"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<StudentsBSSNONBSSPROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/students/:id"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<StudentDetailContainer/>}
                    />
                }
            />
            <Route
                path="/pro/interview"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<InterviewPROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/subjectconfirmation"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<SubjectConfirmationPROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/finance"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<FinancePROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/reminder"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<RemindersPROContainer/>}
                    />
                }
            />
            <Route
                path="/pro/scholarship"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ScholarshipContainer/>}
                    />
                }
            />
            <Route
                path="/pro/scholarship/:id"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ViewSubject/>}
                    />
                }
            />
            <Route
                path="/pro/analytics"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<AnalyticsProContainer/>}
                    />
                }
            />
        </Routes>
    );
};
