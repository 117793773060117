import {StudentsListComponents} from "./components/StudentsList";
import {MainWrapper} from "../../Global/MainWrapper";


export const StudentsBSSNONBSSPROContainer = () => {
    return (
        <MainWrapper pageTitle={"BSS / Non-BSS"}>
            <StudentsListComponents/>
        </MainWrapper>
    );
};
