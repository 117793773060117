import ChartDataLabels from "chartjs-plugin-datalabels";
export const options = {
    responsive: true,

    plugins: {
        legend: {
            position: "bottom",
        },

        labels: {
            // This more specific font property overrides the global property
            font: {
                size: 14,
            },
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawOnChartArea: false,
                color: "#3f3f3f",
                borderColor: "#3f3f3f",
            },
            barPercentage: 0.4,
            backdropColor: "rgba(255, 255, 255, 0.75)",
        },

        y: {
            grid: {
                display: false,
            },
        },
    },
    plugins: [ChartDataLabels],
};
