import { createContext, useEffect, useState } from "react";
import { FaculityData } from "../../../config/DataList/faculity/FaculityData";
import { MainWrapper } from "../../Global/MainWrapper";
import { FeeManagementListComponents } from "./components/FeeManagementList";

export const StudentsContext = createContext({});

export const FeeManagementContainer = () => {
    const [students, setStudents] = useState([]);
    useEffect(() => {
        setStudents(FaculityData);
    }, []);
    return (
        <MainWrapper pageTitle={"FEE MANAGEMENT"}>
            <StudentsContext.Provider
                value={{
                    students,
                }}
            >
                <FeeManagementListComponents />
            </StudentsContext.Provider>
        </MainWrapper>
    );
};
