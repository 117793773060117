import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {TableComponent} from "../../../../Global/graphical/table/Container";
import {SizedBox} from "../../../../Global/SizedBox";
import {useParams} from "react-router-dom";
import moment from 'moment';
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {StudentStatusDetailsContext} from "./Container";

const StyledComponent = styled.div`

  width: inherit;
  .formTitle {
    font-size: 1.2rem;
    margin-left: 1rem;
    color: var(--custom-Black);
    margin: 1rem 0;
  }
`;
export const StatusDetailHistory = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const {studentAdmissionStatusHistoryData} = useContext(StudentStatusDetailsContext)
    const headerData = ["STATUS NAME", "REMARKS", "TIME/DATE"];

    useEffect(() => {
        if (studentAdmissionStatusHistoryData) {
            setTableBodyData(
                studentAdmissionStatusHistoryData.map((item) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: item.name,
                            },
                            {
                                type: "text",
                                value: item?.description_or_reason,
                            },
                            {
                                type: "text",
                                value: moment(item.date).format('h:mmA - D-M-YYYY'),
                            },
                        ],
                    };
                })
            );
        }
    }, [studentAdmissionStatusHistoryData]);
    return (
        <StyledComponent>
            <SizedBox height={3}/>
            <TableComponent
                heading="STATUS HISTORY"
                headerData={headerData}
                bodyData={tableBodyData}
            />
        </StyledComponent>
    );
};
