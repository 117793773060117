import React from 'react'
import { useEffect } from 'react'

import { useState } from 'react'
import { axiosServerInstance } from '../../../config/api/axios'
import { formatBatch } from '../../../helper/formatters/formatBatch'

const FilterByBatch = ({ setBatchFilter }) => {
    const selectArray = ['Batch']
    const [selectedOptions, setSelectedOptions] = useState({
        Batch: '',
    })

    const [optionData, setOptionData] = useState({
        Batch: {
            data: [],
            currentPage: 1,
            hasMore: true,
        },
    })
    const fetchAndParseDataFromAPI = async (optionType, page = 1) => {
        // Update the API endpoint to include pagination parameters
        if (optionData[optionType].hasMore) {
            let endpoint = ''
            let pagination = `limit=${100}&offset=${(page - 1) * 100}`
            if (optionType === 'Batch') {
                endpoint = `/student/batch?${pagination}`
            }

            const response = await axiosServerInstance().get(endpoint)
            const mappedData = response.data.results.map(item => ({
                academic_id: item?.id,
                academicYear: formatBatch(item?.first_year),
            }))

            setOptionData(prevData => {
                // Check for duplicates before adding data
                const newData = mappedData.filter(
                    newItem =>
                        !prevData[optionType].data.some(
                            prevItem =>
                                prevItem.academic_id === newItem.academic_id
                        )
                )
                return {
                    [optionType]: {
                        data: [...prevData[optionType].data, ...newData],
                        currentPage: page,
                        hasMore: response.data.next !== null,
                    },
                }
            })
        }
    }

    useEffect(() => {
        fetchAndParseDataFromAPI('Batch')
    }, [])

    const handleOptionChange = (optionType, value) => {
        if (value !== 'loadMore') {
            setSelectedOptions(prevData => ({
                ...prevData,
                Batch: value,
            }))
            setBatchFilter(value === '' ? { batch: '' } : { batch: value })
        } else {
            handleScroll(optionType)
        }
    }

    const handleScroll = optionType => {
        // Load the next page of data
        fetchAndParseDataFromAPI(
            optionType,
            selectedOptions.Batch,
            optionData[optionType].currentPage + 1
        )
    }

    return (
        <>
            {selectArray.map((optionType, index) => (
                <select
                    key={index}
                    defaultValue={selectedOptions[optionType]}
                    onChange={e =>
                        handleOptionChange(optionType, e.target.value)
                    }
                >
                    <option
                        value=''
                        defaultValue={selectedOptions[optionType] === ''}
                    >
                        {optionType}
                    </option>
                    {optionData[optionType].data.map((item, index) => (
                        <option key={index} value={item.academic_id}>
                            {item.academicYear}
                        </option>
                    ))}
                    {optionData[optionType].hasMore &&
                        optionData[optionType].data.length > 0 && (
                            <option value='loadMore'>load more</option>
                        )}
                </select>
            ))}
        </>
    )
}

export default FilterByBatch
