export const formatCNIC = (cnic) => {
    cnic = cnic.replace(/-/g, "")
    const segments = []
    segments.push(cnic.substring(0, 5))
    segments.push(cnic.substring(5, 12))
    segments.push(cnic.substring(12, 13))
    let joinedSegments = segments.filter((x) => x).join("-");
    if ([5, 12].includes(cnic.length)) {
        return joinedSegments + "-"
    }
    return joinedSegments
}