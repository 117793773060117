import styled from 'styled-components'
import { TableComponent } from '../../../Global/graphical/table/Container'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StudentsContext } from '../Container'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../Global/Button'
import search from '../../../../static/images/table/Path 123.svg'
import { SizedBox } from '../../../Global/SizedBox'
import { SearchBar } from '../../../Global/searchBar/SearchBar'
import { formatQueryDictToQueryParams } from '../../../../helper/formatters/formatDictToParams'
import { axiosServerInstance } from '../../../../config/api/axios'
import { notifyFailure } from '../../../../helper/notifications/notifyFailure'
import { notifySuccess } from '../../../../helper/notifications/notifySuccess'
import { notifyApiErrorMessage } from '../../../../helper/notifications/notifyApiErrorMessage'
import Edit from '../../../../static/images/table/Group 46.svg'
import { ListFilterComponentByCityCampus } from '../../global/ListFilterComponentByCityCampus'
import { Spinner } from '../../../Global/graphical/Spinner'
import { ModalComponent } from '../../../Global/graphical/Modal'
import { EditRemarks } from '../../../school_head/faculity/components/EditRemarks'
import { batch } from 'react-redux'
import FilterByBatch from '../../../Global/filters/FilterByBatch'

const StyledComponent = styled.div`
    .tablevms tbody td,
    .tablevms thead th {
        min-width: 60px;
    }

    .searchActions {
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
`

const headerData = [
    'ID',
    'NAME',
    'REGISTRATION FEE',
    'CHALAN AND SECURITY FORM',
    'CONDITIONAL OFFER LETTER',
    'TOTAL FEE',
    'SCHOLARSHIP',
    'REMAINING FEE',
    'REMARKS',
    '',
]
export const FeeManagementListComponents = () => {
    const [tableBodyData, setTableBodyData] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const [showEditRemarksModal, setShowEditRemarksModal] = useState(false)
    const itemInQuestion = useRef(null)
    const inputValRemarks = useRef(null)
    const [data, setData] = useState([])
    const [showSpinnerFetchingData, setShowSpinnerFetchingData] =
        useState(false)
    const [filterData, setFilterData] = useState({})
    const [batchFilter, setBatchFilter] = useState({ batch: '' })

    const fetchAndParseDataFromAPI = async () => {
        setShowSpinnerFetchingData(true)
        const formattedFilterData = {}
        if (filterData['selectedCity']) {
            formattedFilterData['city_campus__city_id'] =
                filterData['selectedCity']
        }

        if (filterData['selectedCampus']) {
            formattedFilterData['city_campus__campus_id'] =
                filterData['selectedCampus']
        }
        const endpoint = `regional_head/students/fee_management/all/?${formatQueryDictToQueryParams(
            { search: searchParam, ...formattedFilterData }
        )}&account_data__batch=${batchFilter.batch}`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setData(response?.data?.results)
            })
            .catch(err => {
                notifyFailure('Error in fetching students')
            })
        setShowSpinnerFetchingData(false)
    }

    const handleSaveEditRemarks = async value => {
        const endpoint = `/shared/student/${itemInQuestion.current}/fee_scholarship/record/remark/`
        let requestData = { value }
        await axiosServerInstance()
            .post(endpoint, requestData)
            .then(response => {
                fetchAndParseDataFromAPI()
                notifySuccess('Operation Success')
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
        setShowEditRemarksModal(false)
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [batchFilter])

    useEffect(() => {
        if (Object.values(filterData).length) {
            fetchAndParseDataFromAPI()
        }
    }, [filterData])

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item, i) => {
                    return {
                        id: item.id,
                        data: [
                            {
                                type: 'text',
                                value: item.identifier,
                            },
                            {
                                type: 'text',
                                value: item.full_name,
                            },
                            {
                                type: 'text',
                                value: item?.before_result_fee?.registration_fee
                                    ? item?.before_result_fee
                                          ?.registration_fee + '/-'
                                    : 'Nil',
                            },
                            {
                                type: 'badge-custom',
                                value: item?.before_result_fee
                                    ?.challan_and_security
                                    ? 'DONE'
                                    : '',
                            },
                            {
                                type: 'badge-custom',
                                value: item?.before_result_fee?.registration
                                    ? 'DONE'
                                    : '',
                            },

                            {
                                type: 'text',
                                value: item?.fee_scholarship?.total_fee
                                    ? item?.fee_scholarship?.total_fee + '/-'
                                    : 'Nil',
                            },
                            {
                                type: 'text',
                                value: item?.fee_scholarship
                                    ?.scholarship_percentage
                                    ? item?.fee_scholarship
                                          ?.scholarship_percentage + '%'
                                    : 'Nil',
                            },
                            {
                                type: 'text',
                                value: item?.fee_scholarship?.remaining_fee
                                    ? item?.fee_scholarship?.remaining_fee +
                                      '/-'
                                    : 'Nil',
                            },
                            {
                                type: 'text',
                                value: item.remarks || 'Nil',
                            },
                            {
                                type: 'text',
                                value: (
                                    <div>
                                        <img
                                            onClick={() => {
                                                itemInQuestion.current = item.id
                                                inputValRemarks.current =
                                                    item.remarks
                                                setShowEditRemarksModal(true)
                                            }}
                                            src={Edit}
                                            style={{ cursor: 'pointer' }}
                                            alt='edit'
                                        />
                                    </div>
                                ),
                            },
                        ],
                    }
                })
            )
        }
    }, [data])

    return (
        <StyledComponent>
            <div className='searchActions'>
                <div className='selectOuter'>
                    <FilterByBatch setBatchFilter={setBatchFilter} />
                </div>
            </div>
            <div className='searchActions'>
                <ListFilterComponentByCityCampus
                    setterCallback={setFilterData}
                />
                <div className='searchOuter'>
                    <input
                        type='search'
                        placeholder='Search by teacher name, subject name, city, region...'
                        value={searchParam}
                        onChange={e => {
                            setSearchParam(e.target.value)
                        }}
                    />
                    <img
                        src={search}
                        alt='search icon'
                        className={'searchIcon'}
                        onClick={() => {
                            fetchAndParseDataFromAPI()
                        }}
                    />
                </div>
            </div>

            {!showSpinnerFetchingData ? (
                <TableComponent
                    headerData={headerData}
                    bodyData={tableBodyData}
                    actionEdit={{
                        edit: id => {
                            itemInQuestion.current = id
                            setShowEditRemarksModal(true)
                        },
                    }}
                />
            ) : (
                <div className={'spinnerWrapper'}>
                    <SizedBox height={1} />
                    <Spinner size={1.5} />
                    <SizedBox height={1} />
                </div>
            )}
            <ModalComponent
                modalLabel={'REMARKS'}
                isOpen={showEditRemarksModal}
                callbackCloseModal={() => {
                    setShowEditRemarksModal(false)
                }}
            >
                <EditRemarks
                    label={''}
                    inputVal={inputValRemarks.current}
                    callbackCancel={() => {
                        setShowEditRemarksModal(false)
                    }}
                    callbackProceed={handleSaveEditRemarks}
                />
            </ModalComponent>
        </StyledComponent>
    )
}
