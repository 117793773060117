import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CircleProgressBarContainer } from '../../Global/graphical/progressbar/circleBar/Container'
import { HorizontalRule } from '../../Global/HorizontalRule'
import { MainWrapper } from '../../Global/MainWrapper'
import { notifyFailure } from '../../../helper/notifications/notifyFailure'
import { notifyApiErrorMessage } from '../../../helper/notifications/notifyApiErrorMessage'
import { formatQueryDictToQueryParams } from '../../../helper/formatters/formatDictToParams'
import { axiosServerInstance } from '../../../config/api/axios'
// import ProAnalytics from "./components.js/ProAnalytics";
import {
    ANALYTICS_STATUS_TYPES,
    STUDENT_ADMISSION_STATUS_TYPES,
} from '../../../config/store/constants'
import { Spinner } from '../../Global/graphical/Spinner'
import ProAnalytics from './components.js/ProAnalytics'
import FilterByBatch from '../../Global/filters/FilterByBatch'

const Wrapper = styled.div`
    h2 {
        margin-bottom: 1rem;
    }

    .dropdownOuter {
        margin: 2rem 0;
    }

    .wrapper {
        display: flex;
        gap: 1rem;
        margin: 1rem 0;
        width: 100%;
        @media (max-width: 1300px) {
            width: 100%;
            flex-direction: column;
        }
    }

    hr {
        margin: 2rem 0;
    }

    .wrapperchild {
        border-radius: 2.5rem;
        box-shadow: 0 10px 20px -3px #ddd;
        padding: 2rem;
        width: 50%;
        @media (max-width: 1300px) {
            width: 100%;
        }
    }

    .wrapperParent {
        border-radius: 2.5rem;
        box-shadow: 0 10px 20px -3px #ddd;
        padding: 2rem;
    }

    .circleBarsOuter {
        display: flex;
        gap: 0;
        gap: 2rem;
        margin-bottom: 2rem;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 1070px) {
            display: flex;
            flex-wrap: wrap;
        }
        @media (max-width: 500px) {
            gap: 1rem;
            margin-bottom: 1rem;
        }

        .progressbar {
            padding: 1rem 3.5rem;
            border-radius: 2.5rem;
            box-shadow: 0 10px 20px -3px #ddd;
            width: 23%;
            margin-bottom: 1rem;
            box-shadow: 0 10px 13px -3px #ddd;
            @media (max-width: 1399px) {
                width: 30%;
                height: 30%;
            }

            @media (max-width: 1100px) {
                width: 46%;
                height: 46%;
                padding: 1rem 3.5rem;
            }
            @media (max-width: 500px) {
                padding: 1rem 1rem;
            }
        }
    }

    .headingProgressbar {
        margin-bottom: 2rem;
    }

    .LinearBarsOuter {
        display: flex;
        justify-content: space-around;
        gap: 0;
        flex-wrap: wrap;
        @media (max-width: 1100px) {
            flex-wrap: wrap;
        }

        .progressbar {
            margin: 1rem 0;
            width: 31%;
            padding: 2rem;
            border-radius: 2.5rem;
            box-shadow: 0 10px 20px -3px #ddd;
            @media (max-width: 1100px) {
                width: 46%;
            }
            @media (max-width: 700px) {
                width: 100%;
            }
        }
    }

    .circleContent {
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        justify-content: 'center';

        .detail {
            @media (min-width: 1350px) {
                font-size: 1rem !important;
                line-height: 1rem;
            }

            @media (max-width: 1070px) {
                font-size: 1.4vw !important;
                line-height: 1.4vw;
            }
            @media (max-width: 800px) {
                font-size: 1rem !important;
                line-height: 1rem;
            }
            @media (max-width: 570px) {
                font-size: 0.95rem !important;
                line-height: 0.95rem;
            }
        }

        .counter {
            @media (max-width: 1070px) {
                font-size: 1.8rem !important;
                line-height: 1.8rem;
            }
            @media (max-width: 570px) {
                font-size: 1.2rem !important;
                line-height: 1.2rem;
            }
            @media (max-width: 500px) {
                font-size: 1.4rem !important;
                line-height: 1.4rem;
            }
        }
    }

    .infoWrapper {
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const AnalyticsProContainer = () => {
    const [toggle, setToggle] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [campusAdmissionStatusStats, setCampusAdmissionStatusStats] =
        useState()

    const [batchFilter, setBatchFilter] = useState({ batch: '' })

    const fetchCampusAdmissionStatusStats = async () => {
        let endpoint = `/shared/campuses/my_campus/admission_status_stats/${
            batchFilter?.batch && `?batch=${batchFilter.batch}`
        }`
        setShowSpinner(true)
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setCampusAdmissionStatusStats(response?.data)
            })
            .catch(err => {
                notifyFailure('Error in fetching campus admission statistics')
            })
        setShowSpinner(false)
    }

    useEffect(() => {
        fetchCampusAdmissionStatusStats()
    }, [batchFilter])

    return (
        <MainWrapper pageTitle={'Analytics'}>
            <Wrapper>
                <div className='searchActions'>
                    <div className='selectOuter'>
                        <FilterByBatch setBatchFilter={setBatchFilter} />
                    </div>
                </div>
                {!showSpinner ? (
                    <>
                        <div className='circleBarsOuter'>
                            <div
                                className='progressbar'
                                onClick={() => {
                                    setToggle(!toggle)
                                }}
                            >
                                <CircleProgressBarContainer
                                    isClickable={true}
                                    counterSize='2.9'
                                    detailSize='1.2'
                                    counterColor='btn-bg'
                                    color2='#DFDFDF'
                                    color1='#3CB4E5'
                                    detail='Submitted Applications'
                                    value={
                                        campusAdmissionStatusStats?.successful_applications_count ||
                                        0
                                    }
                                    total={
                                        campusAdmissionStatusStats?.total_student_count ||
                                        0
                                    }
                                />
                            </div>
                            <div
                                className='progressbar'
                                onClick={() => {
                                    setToggle(!toggle)
                                }}
                            >
                                <CircleProgressBarContainer
                                    counterSize='2.9'
                                    detailSize='1.2'
                                    counterColor='btn-bg'
                                    color2='#DFDFDF'
                                    color1='#3CB4E5'
                                    detail='Interview Call'
                                    value={
                                        campusAdmissionStatusStats?.successful_interview_count ||
                                        0
                                    }
                                    total={
                                        campusAdmissionStatusStats?.total_student_count ||
                                        0
                                    }
                                />
                            </div>
                            <div
                                className='progressbar'
                                onClick={() => {
                                    setToggle(!toggle)
                                }}
                            >
                                <CircleProgressBarContainer
                                    counterSize='2.9'
                                    detailSize='1.2'
                                    counterColor='btn-bg'
                                    color2='#DFDFDF'
                                    color1='#3CB4E5'
                                    detail='Fees Submission'
                                    value={
                                        campusAdmissionStatusStats?.fee_submission_count ||
                                        0
                                    }
                                    total={
                                        campusAdmissionStatusStats?.total_student_count ||
                                        0
                                    }
                                />
                            </div>
                            <div
                                className='progressbar'
                                onClick={() => {
                                    setToggle(!toggle)
                                }}
                            >
                                <CircleProgressBarContainer
                                    counterSize='2.9'
                                    detailSize='1.2'
                                    counterColor='btn-bg'
                                    color2='#DFDFDF'
                                    color1='#3CB4E5'
                                    detail='Seat Confirmation'
                                    value={
                                        campusAdmissionStatusStats?.seat_confirmation_count ||
                                        0
                                    }
                                    total={
                                        campusAdmissionStatusStats?.total_student_count ||
                                        0
                                    }
                                />
                            </div>
                        </div>
                        {toggle ? <HorizontalRule /> : null}
                        {toggle && <ProAnalytics />}
                    </>
                ) : (
                    <div className='spinnerWrapper'>
                        <Spinner />
                    </div>
                )}
            </Wrapper>
        </MainWrapper>
    )
}
