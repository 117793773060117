import { Route, Routes } from 'react-router-dom'
import { MultiStepFormContainer } from '../../components/Global/multiStepForm/Container'
import { ProtectedRouteComponent } from '../../components/Global/ProtectedRouteComponent'
import { authPagesConditions } from '../../conditions'
import { PersonalInfoContainer } from '../../components/personalInformation/Container'
import { AcademicInfoContainer } from '../../components/academicInformation/Container'
import { PreferenceContainer } from '../../components/preferences/Container'
import { BatchContainer } from '../../components/batchInfo/Container'
import { PersonalInfoUpdateContainer } from '../../components/personalInformationUpdate/Container'

export const StudentRoutes = () => {
    return (
        <Routes>
            <Route
                path='/admission_information'
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<MultiStepFormContainer />}
                    />
                }
            />

            <Route
                path='/personalinformation'
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<PersonalInfoContainer />}
                    />
                }
            />
            {/* 
            <Route
                path="/update"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<PersonalInfoUpdateContainer />}
                    />
                }
            /> */}

            <Route
                path='/academicinformation'
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<AcademicInfoContainer />}
                    />
                }
            />

            <Route
                path='/preferences'
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<PreferenceContainer />}
                    />
                }
            />
            <Route
                path='/batchInfo'
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<BatchContainer />}
                    />
                }
            />
        </Routes>
    )
}
