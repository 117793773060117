import React, {createContext, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button} from "./../../../../Global/Button";
import {Spinner} from "./../../../../Global/graphical/Spinner";
import {SizedBox} from "./../../../../Global/SizedBox";
import {AfterResults} from "./AfterResults";
import {BeforeResults} from "./BeforeResults";
import {useParams} from "react-router-dom";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {GlobalUserProfileContext} from "../../../../../App";

const StyledComponent = styled.div`
  .formComponentOuter {
    border-radius: 20px;
    box-shadow: 0 10px 20px -3px #ddd;
    display: flex;

    .feeManagementOuter {
      border-right: 1px solid #ddd;
      width: 100%;
    }

    .challanFormOuter {
      width: 100%;
    }
  }

  .actionButtonWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ChallanFormContext = createContext({})
export const ChallanFormContainer = () => {
        const [studentAccountData, setStudentAccountData] = useState(null)
        const {id: studentID} = useParams();
        const [campusResultAnnouncedStatus, setCampusResultAnnouncedStatus] = useState(false);
        const [showSpinnerFetchingCampusResultAnnounced, setShowSpinnerFetchingCampusResultAnnounced] = useState(false);
        const {profile} = useContext(GlobalUserProfileContext);

        const fetchDataFromServer = async () => {
            const endpoint = `/pro/students/${studentID}/`;
            await axiosServerInstance()
                .get(endpoint)
                .then((response) => {
                    setStudentAccountData(response.data);
                })
                .catch((err) => {
                    notifyFailure("Error in fetching student account data from server")
                });
        };

        const fetchCampusResultAnnouncedStatus = async () => {
                setShowSpinnerFetchingCampusResultAnnounced(true);
                let endpoint = `/shared/campuses?campus_id=${profile?.pro_account_data?.campus_id}`;
                await axiosServerInstance()
                    .get(endpoint)
                    .then((response) => {
                        if (response?.data?.results?.length) {
                            setCampusResultAnnouncedStatus(
                                response.data.results[0].result_announced
                            );
                        }
                    })
                    .catch((err) => {
                        notifyFailure("Error: Could not get campus result announced status");
                    });
                setShowSpinnerFetchingCampusResultAnnounced(false);
            }
        ;

        useEffect(() => {
            fetchDataFromServer()
            fetchCampusResultAnnouncedStatus()
        }, [])


        return (
            <>
                <StyledComponent>
                    <ChallanFormContext.Provider value={{
                        studentAccountData
                    }}>
                        <SizedBox height={0.8}/>
                        <BeforeResults/>
                        <SizedBox height={2}/>
                        {!showSpinnerFetchingCampusResultAnnounced ?
                            (campusResultAnnouncedStatus && <AfterResults/>)
                            :
                            <div className={'spinnerWrapper'}>
                                <SizedBox height={1}/>
                                <Spinner size={2}/>
                                <SizedBox height={1}/>
                            </div>
                        }
                    </ChallanFormContext.Provider>
                </StyledComponent>
            </>
        );
    }
;
