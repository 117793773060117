import {formatCNIC} from "../formatters/formatCNIC";
import {formatPhoneNumber} from "../formatters/formatPhoneNumber";

export const handleOnInputChange = (e, dataRef, setData, currentData, inputTargetRef = "value", extraFormatter) => {
    const tempData = {...currentData}
    if (typeof (e) === "string") {
        tempData[dataRef] = e
    } else {
        // tempData[dataRef] = e.target[inputTargetRef];
        tempData[dataRef] = e.target[inputTargetRef]
    }
    if (dataRef === "cnic" || dataRef === "parent_cnic") {
        tempData[dataRef] = formatCNIC(tempData[dataRef])
    } else if (dataRef.includes('number', 'Number')) {
        tempData[dataRef] = formatPhoneNumber(tempData[dataRef])
    }
    if (extraFormatter) {
        tempData[dataRef] = extraFormatter(tempData[dataRef])
    }
    setData(tempData)
}