import React from 'react'
import { MainWrapper } from '../Global/MainWrapper'
import { BatchList } from './components/BatchList'
import styled from 'styled-components'

const StyledComponent = styled.div`
    max-width: 100%;
`
export const BatchContainer = () => {
    return (
        <MainWrapper pageTitle={'BATCH'}>
            <StyledComponent>
                <BatchList />
            </StyledComponent>
        </MainWrapper>
    )
}
