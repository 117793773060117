import styled from "styled-components";
import { AccountBoard } from "../AccountBoard";
import { useContext } from "react";

import { GlobalUserProfileContext } from "../../../App";

const StyledComponent = styled.div`
    display: flex;
    align-items: center;
    border-radius: 7px;

    background-color: var(--custom-primary-bg);
    z-index: initial;
    justify-content: space-between;
    flex-grow: 0;
    margin-bottom: 1rem;

    .authMenuBarTitle {
        padding: 1rem 0;
        margin: 0;
        text-transform: uppercase;
        color: var(--custom-Black);
        font-weight: 500;
        display: flex;
        gap: 1rem;
        span {
            font-size: 30px;
            text-align: left;
            @media (max-width: 1200px) {
                font-size: 22px;
            }
            @media (max-width: 1200px) {
                font-size: 22px;
            }
            @media (max-width: 900px) {
                font-size: 18px;
            }
            @media (max-width: 500px) {
                font-size: 16px;
            }
            @media (max-width: 395px) {
                font-size: 14px;
            }
        }
        a {
            color: var(--custom-cream-color);
            text-decoration: none;
            cursor: pointer;
        }
    }
`;

export const NavigationContainer = ({ pageTitle }) => {
    const { parentProfile, setParentProfile } = useContext(GlobalUserProfileContext);

    return (
        <StyledComponent>
            <h2 className="authMenuBarTitle">
                <span>{pageTitle}</span>
            </h2>
            <AccountBoard />
        </StyledComponent>
    );
};
