import styled from "styled-components";
import { useEffect, useState } from "react";
import { TableComponent } from "../../Global/graphical/table/Container";
import { axiosServerInstance } from "../../../config/api/axios";
import { notifyFailure } from "../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../helper/notifications/notifyApiErrorMessage";

const StyledComponent = styled.div`
    .extra-actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tablevms thead th:last-child {
        border-radius: 2.5rem !important;
    }
`;
export const SubjectList = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const headerData = ["Subject"];
    const [data, setData] = useState([]);

    const fetchAndParseDataFromAPI = () => {
        const endpoint = "/student/admission/preference/subjects";
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data?.results);
            })
            .catch((err) => {
                notifyFailure("Error in fetching available subjects");
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);
    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: item?.subject_name,
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);

    return (
        <StyledComponent>
            <TableComponent headerData={headerData} bodyData={tableBodyData} />
        </StyledComponent>
    );
};
