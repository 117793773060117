import React, { useEffect } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar'
import styled from 'styled-components'

const Wrapper = styled.div`
    cursor: ${({ cursor }) => (cursor ? 'pointer' : 'initial')};

    .circleContent {
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
        justify-content: 'center';

        .detail {
            font-size: ${props =>
                props.detailSize ? `${props.detailSize}vw` : '1'};
            max-width: 80%;
            font-weight: 500;
            margin: auto;
            line-height: ${props =>
                props.detailSize ? `${props.detailSize}vw` : '1'};
            text-align: center;

            @media (max-width: 1030px) {
                font-size: 0.8rem !important;
            }
            @media (max-width: 800px) {
                font-size: 0.8rem !important;
                line-height: 0.8rem;
            }
        }

        .counter {
            text-align: center;
            line-height: ${props =>
                props.counterSize ? `${props.counterSize}vw` + '5px' : '1'};
            font-size: ${props =>
                props.counterSize ? `${props.counterSize}vw` : '1'};
            margin-top: 5px;
            color: var(
                --custom-${({ counterColor }) => counterColor || 'btn-bg'}
            );
            @media (max-width: 1030px) {
                font-size: 1.4rem !important;
                line-height: 1.4rem;
            }
            @media (max-width: 800px) {
                line-height: 1rem;
                font-size: 1.4rem !important;
            }
        }
    }
`
export const CircleProgressBarContainer = ({
    value,
    total,
    percentage,
    detail,
    detailSize,
    color1,
    color2,
    counterColor,
    counterSize,
    isClickable,
}) => {
    return (
        <Wrapper
            counterColor={counterColor}
            counterSize={counterSize}
            detailSize={detailSize}
            cursor={isClickable}
        >
            <div id='progressbarContainer'>
                <CircularProgressbarWithChildren
                    value={
                        value !== undefined && total !== undefined
                            ? (value / total) * 100
                            : percentage
                    }
                    strokeWidth={10}
                    styles={buildStyles({
                        trailWidth: 1,
                        pathColor: color1,
                        trailColor: color2,
                        strokeLinecap: 'butt',
                    })}
                >
                    <div className='circleContent'>
                        <p className='detail'>{detail}</p>
                        <h3 className='counter'>
                            {value !== undefined ? value : percentage}
                        </h3>
                    </div>
                </CircularProgressbarWithChildren>
            </div>
        </Wrapper>
    )
}
