import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {FormFieldsWrapper} from "../../../graphical/data_input/FormFieldsWrapper";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {multiStepFormValidator} from "../../../../../helper/form_validators/multiStepFormValidator";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {Button} from "../../../Button";
import {CityCampusTable} from "./components/CityCampusTable";
import {GlobalUserProfileContext} from "../../../../../App";
import {Spinner} from "../../../graphical/Spinner";
import {parseArrayToSelectValues} from "../../../../../helper/form_inputs/parseEnumToSelectValues";
import {APIUtils} from "../../../../../helper/api";
import {STUDENT_DEGREE_TYPES} from "../../../../../config/store/constants";

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: ${({inEditMode}) => (inEditMode ? "0 10px 10px -3px #cfcfcf " : "none")};
    padding: ${({inEditMode}) => (inEditMode ? "3rem" : "0rem")};

    @media (max-width: 500px) {
        padding: 3rem 1rem;
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            @media (max-width: 1405px) {
                justify-content: flex-end;
            }

            .inputOuter.w-38 {
                width: 41%;
                @media (max-width: 1456px) {
                    width: 40%;
                }
                @media (max-width: 1405px) {
                    width: 48%;
                }
                @media (max-width: 900px) {
                    width: 100%;
                }

                .spinnerWrapper {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                }
            }
        }
        
        
      .formfieldInnerDisclaimerText {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      .inputOuter.w-38 {
        width: 100%;

        label {
          display: flex;
          justify-content: center;
          font-size: 1rem;
        }

        input {
          margin-right: 1rem;
          width: 20px;
          height: 20px;
          margin-top: 0.5rem;
          padding-bottom: 0;
        }
      }
    }

        select {
            width: 100%;
            height: 3rem;
            text-indent: 10px;
            background: var(--custom-input-bg);
            border-radius: 25px;
            outline: none;
            background-image: none;
            overflow: hidden;
            border: 1px solid var(--custom-input-border);
            font-size: 1rem;

            option {
                border-radius: 25px;
                width: 32%;
                background: var(--custom-input-bg);
            }
        }

        .actionButtonWrapper {
            align-self: end;
            justify-self: end;

            button {
                @media (max-width: 500px) {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }

    .tableOuter {
        max-height: 560px;
        overflow: auto;
        thead {
            border-radius: 10px;
            tr {
                border-radius: 10px;
                th {
                    :first-child {
                        border-radius: 10px 0 0 10px;
                    }
                    :last-child {
                        border-radius: 0px 10px 10px 0px;
                    }
                }
            }
        }
        .editBtn {
            button {
                background: transparent;
                border: 0px;
            }
        }
    }
    
`;

const dataFieldsMapping = {
    city_name: "City",
    city_id: "City",
    campus_name: "Campus",
    campus_id: "Campus",
};

const StepThreeContainer = forwardRef(({jumpToStep}, ref) => {
    const currentStep = useRef(3);
    const [data, setData] = useState({});
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCampus, setSelectedCampus] = useState(null);
    const [availableCities, setAvailableCities] = useState([]);
    const [availableCampuses, setAvailableCampuses] = useState([]);
    const [inEditMode, setInEditMode] = useState(false);
    const [isFetchingCity, setIsFetchingCity] = useState(false);
    const [isFetchingCampus, setIsFetchingCampus] = useState(false);
    const {profile} = useContext(GlobalUserProfileContext);
    const [checked, setChecked] = useState(false);
    const [isDisclaimerConfirmed, setIsDisclaimerConfirmed] = useState(false);
    const [degreeData, setDegreeData] = useState(null)
    const [selectedFeederCampuses, setSelectedFeederCampuses] = useState(null)
    const [selectedFeederCampusesMainCampuses, setSelectedFeederCampusesMainCampuses] = useState(null)

    const fetchAndParseDataFromAPI = () => {
        const dataEndpoint = "/student/admission/preference/city_campus";
        axiosServerInstance()
            .get(dataEndpoint)
            .then((response) => {
                setData(response.data?.results);
                setSelectedCampus(
                    response.data?.results?.length && response.data?.results[0]?.campus_id
                );
                setSelectedCity(
                    response.data?.results?.length && response.data?.results[0]?.city_id
                );
                if (response.data?.results[0]?.campus_id && response.data?.results[0]?.city_id) {
                    setInEditMode(false);
                }
            })
            .catch((err) => {
                notifyFailure("Error in fetching data");
                notifyApiErrorMessage(err);
            });
    };

    const fetchAvailableCities = async () => {
        if (degreeData?.data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL && profile?.account_data?.is_existing === true) {
            setAvailableCities(
                parseArrayToSelectValues((selectedFeederCampusesMainCampuses || []).map((item) => ({
                    name: item?.city_name,
                    id: item?.city_id
                })).filter((value, index, array) => {
                    return array.map(item => item?.name).indexOf(value?.name) === index;
                }).sort((a, b) => (a?.name === b?.name ? 0 : (a?.name < b?.name ? -1 : 1))))
            )
        } else {
            const dataEndpoint = "/utils/student/preference/fetch_all_available_cities/";
            setIsFetchingCity(true);
            await axiosServerInstance()
                .get(dataEndpoint)
                .then((response) => {
                    setAvailableCities(parseArrayToSelectValues(response.data));
                })
                .catch((err) => {
                    notifyFailure("Error in fetching available cities");
                    notifyApiErrorMessage(err);
                });
            setIsFetchingCity(false);
        }
    };

    const fetchAvailableCampuses = async () => {
        if (degreeData?.data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL && profile?.account_data?.is_existing === true) {
            const matchingCampuses = (selectedFeederCampusesMainCampuses || []).filter((item) => item?.city_id === selectedCity
            ).map((item) => ({
                name: item?.branch_name,
                id: item?.branch_id
            })).filter((value, index, array) => {
                return array.map(item => item?.name).indexOf(value?.name) === index;
            }).sort((a, b) => (a?.name === b?.name ? 0 : (a?.name < b?.name ? -1 : 1)))
            setAvailableCampuses(parseArrayToSelectValues(matchingCampuses))
        } else {
            const dataEndpoint = `/utils/student/preference/fetch_campuses_available_in_city/?city_id=${selectedCity}`;
            setIsFetchingCampus(true);
            await axiosServerInstance()
                .get(dataEndpoint)
                .then((response) => {
                    setAvailableCampuses(parseArrayToSelectValues(response.data));
                })
                .catch((err) => {
                    notifyFailure("Error in fetching available cities");
                    notifyApiErrorMessage(err);
                });
            setIsFetchingCampus(false);
        }
    };

    const handleAddCityCampus = () => {
        if (!selectedCity) {
            notifyFailure("Kindly select a city");
            return;
        }
        if (!selectedCampus) {
            notifyFailure("Kindly select a campus");
            return;
        }
        const requestData = {
            city_name: availableCities.find((data) => data?.id === Number.parseInt(selectedCity))
                ?.name,
            city_id: Number.parseInt(selectedCity),
            campus_name: availableCampuses.find(
                (data) => data?.id === Number.parseInt(selectedCampus)
            )?.name,
            campus_id: Number.parseInt(selectedCampus),
        };
        if (data?.length && inEditMode) {
            const dataEndpoint = `/student/admission/preference/city_campus/${data[0]?.id}/`;
            axiosServerInstance()
                .patch(dataEndpoint, requestData)
                .then((response) => {
                    setSelectedCity(null);
                    setSelectedCampus(null);
                    setInEditMode(false);
                    fetchAndParseDataFromAPI();
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                });
        } else {
            const dataEndpoint = "/student/admission/preference/city_campus";
            axiosServerInstance()
                .post(dataEndpoint, requestData)
                .then((response) => {
                    setSelectedCity(null);
                    setSelectedCampus(null);
                    fetchAndParseDataFromAPI();
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                });
        }
    };

    const computeDataAndSendToServer = async () => {
        let status = true;
        let endpoint = "/student/admission/information";
        if (profile?.account_data?.admission_application_stage < 6) {
            await axiosServerInstance()
                .patch(endpoint, {
                    admission_application_stage: currentStep.current + 1,
                })
                .then((response) => {
                    status = true;
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                    status = false;
                });
        }
        return status;
    };

    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            setAvailableCampuses([]);
            fetchAvailableCampuses();
        }
    }, [selectedCity]);

    useImperativeHandle(ref, () => ({
        async isValidated() {
            const {status, message} = multiStepFormValidator(dataFieldsMapping, data[0]);
            if (!status) {
                notifyFailure(message);
            } else {
                return await computeDataAndSendToServer();
            }
            return status;
        },
    }));

    useEffect(() => {
        setIsDisclaimerConfirmed(false)
    }, [inEditMode])

    useEffect(() => {
        if (profile) {
            APIUtils.fetchItemData({
                endpoint: '/student/admission/degree',
                item: degreeData,
                setItem: setDegreeData
            })
        }
    }, [profile])


    useEffect(() => {
        if (degreeData?.isFetching === false) {
            if (degreeData?.data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL && profile?.account_data?.is_existing === true) {
                APIUtils.fetchItemData({
                    endpoint: APIUtils.parseEndpointForQueryParams({
                            endpoint: '/shared/population_data/campuses/feeder/',
                            params: {
                                "branch_id__in": (degreeData?.data?.institutions || []).map(item => item?.identifier).join(',')
                            }
                        }
                    ),
                    item: selectedFeederCampuses,
                    setItem: setSelectedFeederCampuses
                })
            } else {
                fetchAvailableCities();
            }
        }
    }, [degreeData])


    useEffect(() => {
        if (selectedFeederCampuses?.isFetching === false) {
            let tempData = []
            for (let item of selectedFeederCampuses?.data) {
                if (!tempData.includes(item?.main_branch?.branch_id)) {
                    tempData.push(item?.main_branch)
                }
            }
            setSelectedFeederCampusesMainCampuses(tempData)
        }

    }, [selectedFeederCampuses])

    useEffect(() => {
        if (selectedFeederCampusesMainCampuses) {
            fetchAvailableCities();
        }
    }, [selectedFeederCampusesMainCampuses])
    return (
        <StyledComponent inEditMode={inEditMode}>
            {!!!data?.length || (inEditMode && data?.length) ? (
                <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                    {
                        (!isDisclaimerConfirmed && (degreeData?.data?.degree_name !== STUDENT_DEGREE_TYPES.O_LEVEL && profile?.account_data?.is_existing)) ? (
                                <div className="formfieldInnerDisclaimerText">
                                    <div className="inputOuter w-38">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isDisclaimerConfirmed}
                                                onChange={(e) => {
                                                    setIsDisclaimerConfirmed(!isDisclaimerConfirmed)
                                                }}
                                            />
                                            BSS student can only apply to their designated BCP Campus
                                        </label>
                                    </div>
                                </div>
                            ) :
                            <>
                                <div className="formfieldInner">

                                    <div className="inputOuter w-38">
                                        <label>City</label>
                                        {(isFetchingCity || degreeData?.isFetching === true || selectedFeederCampuses?.isFetching === true) ? (
                                            <div className={"spinnerWrapper"}>
                                                <Spinner size={1}/>
                                            </div>
                                        ) : (
                                            <select
                                                value={selectedCity}
                                                onChange={(e) => {
                                                    setSelectedCity(e.target.value && Number.parseInt(e.target.value));
                                                }}
                                            >
                                                {(availableCities || []).map((city) => (
                                                    <option
                                                        key={city?.id}
                                                        selected={city?.id === selectedCity}
                                                        value={city?.id}
                                                    >
                                                        {city?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>

                                    <div className="inputOuter w-38">
                                        <label>Campus</label>

                                        {isFetchingCampus ? (
                                            <div className={"spinnerWrapper"}>
                                                <Spinner size={1}/>
                                            </div>
                                        ) : (
                                            <select
                                                value={selectedCampus}
                                                onChange={(e) => {
                                                    setSelectedCampus(e.target.value && Number.parseInt(e.target.value));
                                                }}
                                            >
                                                {(availableCampuses || []).map((campus) => (
                                                    <option
                                                        key={campus?.id}
                                                        selected={campus?.id === selectedCampus}
                                                        value={campus?.id}
                                                    >
                                                        {campus?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                    <div className="actionButtonWrapper">
                                        <Button
                                            disabled={!(selectedCampus && selectedCity)}
                                            fontSize={1}
                                            borderRadius={2.4}
                                            paddingTopBottom={0.6}
                                            paddingLeftRight={3.5}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleAddCityCampus();
                                            }}
                                        >
                                            {data?.length ? "SAVE" : "+ ADD"}
                                        </Button>
                                    </div>
                                </div>
                            </>
                    }
                </FormFieldsWrapper>
            ) : null}
            {data?.length && !inEditMode ? (
                <CityCampusTable
                    data={data}
                    callBackFetchData={fetchAndParseDataFromAPI}
                    setEditMode={setInEditMode}
                />
            ) : null}
        </StyledComponent>
    );
});

export default StepThreeContainer;
