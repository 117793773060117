import { AuthMainContainer } from "../components/AuthMainContainer";
import { CNICVerificationForm } from "./Form";

export const CNICVerificationContainer = () => {
    return (
        <AuthMainContainer mode={"cnic_verification"}>
            <CNICVerificationForm />
        </AuthMainContainer>
    );
};
