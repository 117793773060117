export const ActionPrint = ({ actionsPrint, dataID }) => {
  return (
    <>
      {actionsPrint && (
        <div className="view">
          {Object.keys(actionsPrint).map((actionName) => (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionsPrint[actionName](dataID)
              }}
            >
              {actionName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
