import styled from 'styled-components'

const StyledComponent = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0rem 0 0rem;
`

export const AlignItemsEnd = props => {
    return <StyledComponent {...props}>{props.children}</StyledComponent>
}
