import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {HorizontalRule} from "../../../../Global/HorizontalRule";
import {Spinner} from "../../../../Global/graphical/Spinner";
import {Button} from "../../../../Global/Button";
import {InputComponent} from "../../../../Authentication/components/InputELement";
import {SizedBox} from "../../../../Global/SizedBox";
import {ModalComponent} from "../../../../Global/graphical/Modal";
import {CancleProcess} from "./CancleProcess";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {STUDENT_ADMISSION_STATUS_TYPES} from "../../../../../config/store/constants";
import {handleOnInputChange} from "../../../../../helper/form_inputs/handleOnInputChange";
import {StudentStatusDetailsContext} from "./Container";
import moment from "moment";
import {notifySuccess} from "../../../../../helper/notifications/notifySuccess";

const StyledComponent = styled.div`
  margin-top: 3rem;

  .statusOuter {
    padding: 2rem;
    box-shadow: 0 10px 10px -3px #cecece;
    border-radius: 20px;
  }

  .searchActions {
    display: flex;
    margin-bottom: 4rem;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 810px) {
      flex-direction: column;
    }
    @media (max-width: 500px) {
      margin-bottom: 3rem;
    }

    .helperText {
      font-size: 20px;
    }

    .statusSelectOuter {
      display: flex;
      flex-direction: column;

      .StatusBar {
        display: flex;
        @media (max-width: 450px) {
          flex-direction: column;
        }

        .label {
          margin-right: 3rem;
          display: flex;
        }

        .badgeShowStatus {
          border-radius: 5px;
          border: 0px;
        }
      }

      .CancelReason {
        display: flex;
        flex-direction: column;
      }

      .statusBadge {
        /* background-color: ; */
      }
    }

    select {
      min-width: 120px;
      height: 2.5rem;
      text-indent: 10px;
      background: red;
      border-radius: 5px;
      outline: none;
      background-image: none;
      overflow: hidden;
      color: var(--custom-white);

      option {
        border-radius: 25px;
        width: 32%;
        background: var(--custom-input-bg);
      }
    }
  }

  .actionButtonWrapper {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;

    button {
      @media (max-width: 500px) {
        height: 2.3rem;
        max-width: 10rem;
      }
    }
  }

  .statusBtnOuter {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (min-width: 1400px) {
      justify-content: space-between;
    }

    .statusbtn {
      display: flex;
      justify-content: center;
      flex-direction: column;

      input {
        border: 0px;
        padding: 0.2rem 1rem;
        cursor: pointer;
        font-size: 0.9rem;
      }

      input:disabled {
        cursor: not-allowed;
      }

      .statusbtninfo {
        font-size: 0.77rem;
      }
    }
  }

  .badgeShowStatus {
    padding: 0.2rem 0.3rem;
    text-align: center;
    color: #fff;
    min-width: 120px;
    max-width: 140px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }

    @media (max-width: 500px) {
      padding: 0.4rem 0.8rem;
    }
  }

  .statusbtnbadge {
    border-radius: 5px;
    padding: 0.2rem 0.3rem;
    text-align: center;
    color: #fff;
    @media (max-width: 500px) {
      padding: 0.4rem 0.8rem;
    }
  }

  .warning {
    background-color: #c5ba1b;
  }

  .default {
    background-color: #c5c5c5;
  }

  .success {
    background-color: #0eab38;
  }

  .danger {
    background-color: #dc3545;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    @media (max-width: 1010px) {
      justify-content: flex-end;
    }

    button {
      color: var(--custom-btn-bg);
      border: 1px solid var(--custom-btn-bg);
    }
  }

  .inputOuter {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .inputOuter.w-84 {
    width: 82%;
    @media (max-width: 1400px) {
      width: 80%;
    }
    @media (max-width: 1250px) {
      width: 78%;
    }
    @media (max-width: 1150px) {
      width: 70%;
    }
    @media (max-width: 1010px) {
      width: 100%;
    }
  }

  .inputOuter.w-28 {
    width: 34.9%;
    @media (max-width: 1250px) {
      width: 48%;
    }
    @media (max-width: 960px) {
      width: 100%;
    }
  }

  .ShowFinalData {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;

    .badgeOuter {
      display: flex;
      width: 26%;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 1250px) {
        width: 48%;
      }
      @media (max-width: 900px) {
        width: 100%;
      }

      .Badge {
        width: 10rem;
        height: 2rem;
        text-align: center;
        padding: 0.6rem;
        height: fit-content;
        color: #fff;
        border-radius: 5px;
      }
    }

    .inputOuter {
      display: flex;
      align-items: center;
      flex-direction: row;

      label {
        width: 70%;
      }
    }

    .inputOuter.w-22 {
      width: 28%;
      @media (max-width: 1250px) {
        width: 48%;
      }

      @media (max-width: 900px) {
        width: 100%;
      }
    }

    button {
      @media (max-width: 500px) {
        padding: 0.5rem 3rem;
      }
    }
  }

  input[type="datetime-local"] {
    font-size: 0.85rem;
  }

  input[type="datetime-local" i] {
    font-size: 1rem;

    padding-right: 1rem;
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StatusDetailBadges = () => {
    const [studentCurrentStatusData, setStudentCurrentStatusData] =
        useState(null);
    const [studentStatusDetails, setStudentStatusDetails] = useState([]);
    const [newStatusData, setNewStatusData] = useState(null);
    const statusList = useRef([
        "Applied",
        "Schedule Interview",
        "Interviewed",
        "Reserved",
        "Confirmed",
        "Enrolled",
        "Cancelled",
    ]);
    const statusWithDateRequired = useRef(["Schedule Interview"]);
    const statusWithDescriptionMapping = useRef({
        Applied: (data) => "The student has applied",
        "Schedule Interview": (data) => `An interview has been scheduled for the student on ${moment(data?.date).format('dddd, MMMM Do, h:mm a')}`,
        Interviewed: (data) => "The student has been interviewed",
        Reserved: (data) => "The student admission seat has been reserved",
        Confirmed: (data) => "The student admission has been confirmed",
        Enrolled: (data) => "The student has been enrolled",
        Cancelled: (data) => "The student admission has been cancelled",
    });
    const {id: studentID} = useParams();
    const [nextAvailableAdmissionStatuses, setNextAvailableAdmissionStatuses] =
        useState([]);
    const currentTempAdmissionStatus =
        newStatusData?.admission_status ||
        studentCurrentStatusData?.admission_status;
    const [showCancelAdmissionReasonModal, setShowCancelAdmissionReasonModal] =
        useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const {fetchAdmissionHistoryDataFromAPI} = useContext(
        StudentStatusDetailsContext
    );
    const fetchStudentAllStatusDetails = async () => {
        const endpoint = `/pro/students/${studentID}/admission/status/all`;
        return await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setStudentStatusDetails(response.data?.results || []);
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
    };
    const fetchStudentCurrentStatus = async () => {
        const endpoint = `/pro/students/${studentID}/admission/status/current`;
        return await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setStudentCurrentStatusData(response.data);
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
    };

    const handleChangeAdmissionStatus = async (data) => {
        const requestData = data || newStatusData;
        if (studentStatusDetails.find(
            (item) =>
                item.admission_status ===
                requestData?.admission_status
        )) {
            updateAdmissionStatus(requestData)
        } else {
            const endpoint = `/pro/students/${studentID}/admission/status/current`;
            if (requestData?.date) {
                requestData["date"] = moment(requestData?.date).utc().toDate()
            }
            setIsProcessing(true);
            await axiosServerInstance()
                .patch(endpoint, requestData)
                .then((response) => {
                    setNewStatusData(null);
                    fetchAdmissionHistoryDataFromAPI();
                    fetchStudentCurrentStatus();
                    fetchStudentAllStatusDetails();
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                });
            setIsProcessing(false);
        }
    };


    const updateAdmissionStatus = async (data) => {
        const endpoint = `/pro/students/${studentID}/admission/status/${data?.id}/`;
        const requestData = data;
        if (requestData?.date) {
            requestData["date"] = moment(requestData?.date).utc().toDate()
        }
        setIsProcessing(true);
        await axiosServerInstance()
            .patch(endpoint, requestData)
            .then((response) => {
                fetchAdmissionHistoryDataFromAPI();
                fetchStudentCurrentStatus();
                fetchStudentAllStatusDetails();
                notifySuccess("Operation Successful")
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
        setIsProcessing(false);
    };

    const handleCancelAdmissionProceed = (reason) => {
        handleChangeAdmissionStatus({
            admission_status: STUDENT_ADMISSION_STATUS_TYPES.CANCELLED,
            description_or_reason: reason,
        });
    };


    useEffect(() => {
        fetchStudentCurrentStatus();
        fetchStudentAllStatusDetails();
    }, [studentID]);

    useEffect(() => {
        if (studentCurrentStatusData) {
            const availableStatuses = [];
            if (
                ![
                    STUDENT_ADMISSION_STATUS_TYPES.ENROLLED,
                    STUDENT_ADMISSION_STATUS_TYPES.CANCELLED,
                    STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED,
                ].includes(studentCurrentStatusData?.admission_status)
            ) {
                let currentStatusIndex = statusList.current.findIndex(
                    (item) =>
                        item === studentCurrentStatusData?.admission_status
                );
                let nextStatusIndex = currentStatusIndex + 1;
                if (
                    !studentStatusDetails.find(
                        (item) =>
                            item?.admission_status ===
                            statusList.current[nextStatusIndex]
                    )
                ) {
                    availableStatuses.push(statusList.current[nextStatusIndex]);
                }
            }
            if (
                ![
                    STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED,
                    STUDENT_ADMISSION_STATUS_TYPES.CANCELLED,
                ].includes(studentCurrentStatusData?.admission_status)
            ) {
                availableStatuses.push(
                    STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                );
            }
            setNextAvailableAdmissionStatuses(availableStatuses);
        }
    }, [studentCurrentStatusData, studentStatusDetails]);

    return (
        <>
            <StyledComponent>
                {studentCurrentStatusData ?
                    <div className="statusOuter">
                        <div className="searchActions">
                            <p className="helperText">
                                <strong>STATUS DETAILS</strong>
                            </p>
                            <div className="statusSelectOuter">
                                <div className="StatusBar">
                                    <span className="label">Overall Status:</span>
                                    <input
                                        disabled={!newStatusData}
                                        onClick={() => {
                                            setNewStatusData(null);
                                        }}
                                        className={`badgeShowStatus ${
                                            newStatusData?.admission_status
                                                ? newStatusData?.admission_status ===
                                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                                                ? "danger"
                                                : "warning"
                                                : studentCurrentStatusData?.admission_status ===
                                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                                                ? "danger"
                                                : "success"
                                        }`}
                                        value={currentTempAdmissionStatus === STUDENT_ADMISSION_STATUS_TYPES.INTERVIEW_SCHEDULED ? 'Interview Scheduled' : currentTempAdmissionStatus}
                                    />
                                </div>
                                {!newStatusData &&
                                studentCurrentStatusData?.admission_status ===
                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED && (
                                    <div className="CancelReason">
                                    <span className="label">
                                        <b>Reasons:</b>
                                    </span>
                                        <span className="detail">
                                        {
                                            studentCurrentStatusData?.description_or_reason
                                        }
                                    </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="statusBtnOuter">
                            {statusList.current.map((status, i) => (
                                <div className="statusbtn" key={status}>
                                    <input
                                        type="button"
                                        value={status}
                                        disabled={(!(nextAvailableAdmissionStatuses.includes(
                                            status) ||
                                            ((status === STUDENT_ADMISSION_STATUS_TYPES.INTERVIEW_SCHEDULED) ? (
                                                    studentStatusDetails.find(
                                                        (item) =>
                                                            item.admission_status ===
                                                            status
                                                    ))
                                                :
                                                false)))
                                        }
                                        onClick={(e) => {
                                            let prevData = studentStatusDetails.find(
                                                (item) =>
                                                    item.admission_status ===
                                                    e.target.value
                                            )
                                            setNewStatusData({
                                                ...(prevData || {}),
                                                admission_status: e.target.value,
                                            });
                                            if (
                                                e.target.value ===
                                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                                            ) {
                                                setShowCancelAdmissionReasonModal(true);
                                            }
                                        }}
                                        className={`statusbtnbadge ${
                                            newStatusData &&
                                            newStatusData?.admission_status === status
                                                ? status ===
                                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                                                ? "danger"
                                                : "warning"
                                                : studentStatusDetails.find(
                                                (item) =>
                                                    item.admission_status ===
                                                    status
                                                )
                                                ? status ===
                                                STUDENT_ADMISSION_STATUS_TYPES.CANCELLED
                                                    ? "danger"
                                                    : "success"
                                                : "default"
                                        }`}
                                    />

                                    {studentStatusDetails.find(
                                        (item) => item.admission_status === status
                                    ) ? (
                                        ""
                                    ) : (
                                        <span className="statusbtninfo">
                                    Not Initialized
                                </span>
                                    )}
                                </div>
                            ))}
                        </div>
                        <SizedBox height={2}/>
                        <HorizontalRule/>
                        <SizedBox height={2}/>
                        {newStatusData &&
                        newStatusData?.admission_status !==
                        STUDENT_ADMISSION_STATUS_TYPES.CANCELLED ? (
                            isProcessing ? (
                                <div className="loaderWrapper">
                                    <Spinner size={1.5}/>
                                </div>
                            ) : (
                                <div className="formFieldWrapper">
                                    <div
                                        className={`inputOuter ${
                                            statusWithDateRequired.current.includes(
                                                newStatusData?.admission_status
                                            )
                                                ? "w-28"
                                                : "w-84"
                                        }`}
                                    >
                                        <label>Remarks</label>
                                        <InputComponent
                                            placeholder={""}
                                            type="text"
                                            fontSize={0.7}
                                            value={newStatusData?.description_or_reason}
                                            onChange={(e) => {
                                                handleOnInputChange(
                                                    e,
                                                    "description_or_reason",
                                                    setNewStatusData,
                                                    newStatusData
                                                );
                                            }}
                                        />
                                    </div>
                                    {statusWithDateRequired.current.includes(
                                        newStatusData?.admission_status
                                    ) ? (
                                        <div className="inputOuter w-28">
                                            <label>Time/Date</label>
                                            <InputComponent
                                                placeholder={"HH:mm PM/AM , DD/MM/YYYY"}
                                                type="datetime-local"
                                                name="date"
                                                min="1997-01-01"
                                                max="2030-12-31"
                                                fontSize={0.7}
                                                value={moment(newStatusData?.date).format('YYYY-MM-DDTHH:mm')}
                                                onChange={(e) => {
                                                    handleOnInputChange(
                                                        e,
                                                        "date",
                                                        setNewStatusData,
                                                        newStatusData
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <Button
                                        fontSize={0.9}
                                        maxWidth={10}
                                        height={3}
                                        borderRadius={2.4}
                                        BgColor="transparent"
                                        paddingTopBottom={0.2}
                                        paddingLeftRight={1}
                                        onClick={(e) => {
                                            handleChangeAdmissionStatus();
                                        }}
                                    >
                                        SET
                                    </Button>
                                </div>
                            )
                        ) : (
                            <span>
                        {
                            (statusWithDescriptionMapping.current[studentCurrentStatusData?.admission_status](studentCurrentStatusData))
                        }
                    </span>
                        )}

                    </div>
                    :
                    <div className="spinnerWrapper">
                        <Spinner size={1.5}/>
                    </div>
                }
                <ModalComponent
                    modalLabel={"Cancel Admission"}
                    isOpen={showCancelAdmissionReasonModal}
                    callbackCloseModal={() => {
                        setShowCancelAdmissionReasonModal(false);
                    }}
                >
                    <CancleProcess
                        callbackCancel={() => {
                            setShowCancelAdmissionReasonModal(false);
                            setNewStatusData(null);
                        }}
                        callbackProceed={(reason) => {
                            setShowCancelAdmissionReasonModal(false);
                            handleCancelAdmissionProceed(reason);
                        }}
                    />
                </ModalComponent>
            </StyledComponent>
        </>
    );
};
