import React, {useEffect, useState} from "react";
import {CircleProgressBarContainer} from "../../../Global/graphical/progressbar/circleBar/Container";
import {LinearProgressBarContainer} from "../../../Global/graphical/progressbar/linearBar/Container";
import {HorizontalRule} from "../../../Global/HorizontalRule";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {SizedBox} from "../../../Global/SizedBox";
import {Spinner} from "../../../Global/graphical/Spinner";

const ProAnalytics = () => {
    const [isFetchingSuccessfulApplicationsSubjectWise, setIsFetchingSuccessfulApplicationsSubjectWise] = useState(false);
    const [successfulApplicationsSubjectWise, setSuccessfulApplicationsSubjectWise] = useState(null)
    const [isFetchingCampusCapacityStats, setIsFetchingCampusCapacityStats] = useState(false);
    const [totalCampusCapacityStats, setTotalCampusCapacityStats] = useState(null)
    const [isFetchingCampusCapacitySubjectWiseStats, setIsFetchingCampusCapacitySubjectWiseStats] = useState(false);
    const [totalCampusCapacitySubjectWiseStats, setTotalCampusCapacitySubjectWiseStats] = useState(null)

    const fetchSuccessfulApplicationsSubjectWise = async () => {
        let endpoint = "/shared/campuses/my_campus/subjects/stats/successful_applications/";
        setIsFetchingSuccessfulApplicationsSubjectWise(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setSuccessfulApplicationsSubjectWise(response?.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching successful application analytics per subjects");
            });
        setIsFetchingSuccessfulApplicationsSubjectWise(false)
    };

    const fetchTotalCampusCapacityStats = async () => {
        let endpoint = "/shared/campuses/my_campus/total_stats/";
        setIsFetchingCampusCapacityStats(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setTotalCampusCapacityStats(response?.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching campus capacity stats");
            });
        setIsFetchingCampusCapacityStats(false)
    };

    const fetchTotalCampusCapacitySubjectWiseStats = async () => {
        let endpoint = "/shared/campuses/my_campus/subjects/stats/capacity/";
        setIsFetchingCampusCapacityStats(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setTotalCampusCapacitySubjectWiseStats(response?.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching campus capacity stats per subject");
            });
        setIsFetchingCampusCapacityStats(false)
    };

    useEffect(() => {
        fetchSuccessfulApplicationsSubjectWise()
        fetchTotalCampusCapacityStats()
        fetchTotalCampusCapacitySubjectWiseStats()
    }, [])
    return (
        <>
            <h2>Submitted Applications Subject Wise</h2>
            {!isFetchingSuccessfulApplicationsSubjectWise ?
                (successfulApplicationsSubjectWise && (
                        successfulApplicationsSubjectWise?.subjects ?
                            <div className="circleBarsOuter">
                                {Object.keys(successfulApplicationsSubjectWise?.subjects).map((item, index) => (
                                    <div className="progressbar" key={index}>
                                        <CircleProgressBarContainer
                                            detailSize="1.2"
                                            counterSize="2.9"
                                            counterColor="btn-bg"
                                            color2="#DFDFDF"
                                            color1="#3CB4E5"
                                            detail={item}
                                            value={successfulApplicationsSubjectWise.subjects[item] || 0}
                                            total={successfulApplicationsSubjectWise?.total_successful_applications_count || 0}
                                        />
                                    </div>
                                ))}
                            </div> :
                            <div className="spinnerWrapper">
                                <SizedBox height={1}/>
                                <div>No subjects</div>
                                <SizedBox height={1}/>
                            </div>
                    )
                )
                :
                (
                    <div className="spinnerWrapper">
                        <SizedBox height={1}/>
                        <Spinner size={2}/>
                        <SizedBox height={1}/>
                    </div>
                )
            }
            <HorizontalRule/>
            {!isFetchingCampusCapacityStats ?
                (
                    <div className="LinearBarsOuter">
                        <div className="progressbar">
                            <LinearProgressBarContainer
                                detailSize="1.4"
                                counterSize="2"
                                counterColor="#fff"
                                strokeBg="#DFDFDF"
                                TrailBg="#1C4489"
                                label="Total Capacity"
                                percentage={100}
                                TrailText={totalCampusCapacityStats?.total_capacity || 0}
                            />
                        </div>
                        <div className="progressbar">
                            <LinearProgressBarContainer
                                detailSize="1.4"
                                counterSize="2"
                                counterColor="#fff"
                                strokeBg="#DFDFDF"
                                TrailBg="#1C4489"
                                label="Capacity Used"
                                percentage={totalCampusCapacityStats?.total_capacity ? (totalCampusCapacityStats?.capacity_used / totalCampusCapacityStats?.total_capacity) * 100 : 0}
                                TrailText={totalCampusCapacityStats?.capacity_used || 0}
                            />
                        </div>
                        <div className="progressbar">
                            <LinearProgressBarContainer
                                detailSize="1.4"
                                counterSize="2"
                                counterColor="#fff"
                                strokeBg="#DFDFDF"
                                TrailBg="#1C4489"
                                label="Capacity Remaining"
                                percentage={totalCampusCapacityStats?.total_capacity ? (totalCampusCapacityStats?.remaining_capacity / totalCampusCapacityStats?.total_capacity) * 100 : 0}
                                TrailText={totalCampusCapacityStats?.remaining_capacity || 0}
                            />
                        </div>
                    </div>
                ) :
                (
                    <div className="spinnerWrapper">
                        <SizedBox height={1}/>
                        <Spinner size={2}/>
                        <SizedBox height={1}/>
                    </div>
                )
            }
            <HorizontalRule/>
            {!isFetchingCampusCapacitySubjectWiseStats ?
                (totalCampusCapacitySubjectWiseStats && (
                    <div className="LinearBarsCombineOuter">
                        <div className="progressOuter LinearBarsOuter">
                            {
                                Object.keys(totalCampusCapacitySubjectWiseStats).map(
                                    (subject, index) => (
                                        <div className="progressbar" key={index}>
                                            <h3 className="headingProgressbar">{subject}</h3>
                                            <LinearProgressBarContainer
                                                detailSize="1.4"
                                                counterSize="2"
                                                counterColor="#fff"
                                                strokeBg="#DFDFDF"
                                                TrailBg="#1C4489"
                                                label="Total Capacity"
                                                percentage={100}
                                                TrailText={totalCampusCapacitySubjectWiseStats[subject]?.total_capacity || 0}
                                            />
                                            <LinearProgressBarContainer
                                                detailSize="1.4"
                                                counterSize="2"
                                                counterColor="#fff"
                                                strokeBg="#DFDFDF"
                                                TrailBg="#3CB4E5"
                                                label="Capacity Used"
                                                percentage={(totalCampusCapacitySubjectWiseStats[subject]?.total_capacity) ? ((totalCampusCapacitySubjectWiseStats[subject]?.used_capacity || 0) / totalCampusCapacitySubjectWiseStats[subject]?.total_capacity) * 100 : 0}
                                                TrailText={totalCampusCapacitySubjectWiseStats[subject]?.used_capacity || 0}
                                            />
                                            <LinearProgressBarContainer
                                                detailSize="1.4"
                                                counterSize="2"
                                                counterColor="#fff"
                                                strokeBg="#DFDFDF"
                                                TrailBg="#349DC8"
                                                label="Capacity Remaining"
                                                percentage={(totalCampusCapacitySubjectWiseStats[subject]?.total_capacity) ? ((totalCampusCapacitySubjectWiseStats[subject]?.remaining_capacity || 0) / totalCampusCapacitySubjectWiseStats[subject]?.total_capacity) * 100 : 0}
                                                TrailText={totalCampusCapacitySubjectWiseStats[subject]?.remaining_capacity || 0}
                                            />
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                )) :
                (
                    <div className="spinnerWrapper">
                        <SizedBox height={1}/>
                        <Spinner size={2}/>
                        <SizedBox height={1}/>
                    </div>
                )
            }
        </>
    )
};

export default ProAnalytics;
