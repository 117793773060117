import React, {useContext, useEffect, useRef, useState} from "react";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {Button} from "../../Global/Button";
import {Spinner} from "../../Global/graphical/Spinner";
import {GlobalUserProfileContext} from "../../../App";

export const ListFilterComponentByCityCampus = ({setterCallback}) => {
    const [cityList, setCityList] = useState([])
    const [isFetchingCity, setIsFetchingCity] = useState(false)
    const [campusList, setCampusList] = useState([])
    const [isFetchingCampus, setIsFetchingCampus] = useState(false)
    const {profile} = useContext(GlobalUserProfileContext)
    const selectedRegion = profile?.regional_head_account_data?.region_id
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedCampus, setSelectedCampus] = useState(null)


    const fetchAllCitiesInARegion = async () => {
        setIsFetchingCity(true)
        const endpoint = `/shared/global/fetch_all_city_in_a_region/?region_id=${selectedRegion}`
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setCityList(response?.data)
            })
            .catch((err) => {
                notifyFailure("Error in fetching all cities in the selected region");
            });
        setIsFetchingCity(false)
    };


    const fetchAllCampusesInACity = async () => {
        setIsFetchingCampus(true)
        const endpoint = `/shared/global/fetch_all_campus_in_a_city/?city_id=${selectedCity}`
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setCampusList(response?.data)
            })
            .catch((err) => {
                notifyFailure("Error in fetching all campuses in the selected city");
            });
        setIsFetchingCampus(false)
    };

    useEffect(() => {
        if (selectedRegion) {
            fetchAllCitiesInARegion()
        } else {
            setSelectedCampus('')
            setSelectedCity('')
            setCityList([])
            setCampusList([])
        }
    }, [selectedRegion])


    useEffect(() => {
        if (selectedCity) {
            fetchAllCampusesInACity()
        } else {
            setSelectedCampus('')
            setCampusList([])
        }
    }, [selectedCity])


    return (
        <div className="selectwrapper">
            <div className="selectOuter">
                {
                    !isFetchingCity ?
                        <select value={selectedCity} onChange={(e) => {
                            setSelectedCity(e.target.value)
                        }}>
                            <option value={''} selected={selectedCity === ''}>
                                CITY WISE
                            </option>
                            {cityList.map((item, i) => (
                                <option value={item?.id} key={item?.id} selected={selectedCity === item?.id}>
                                    {item?.name}
                                </option>
                            ))}
                        </select>
                        : <div className='spinnerWrapper'>
                            <Spinner size={2}/>
                        </div>
                }
            </div>
            <div className="selectOuter">
                {!isFetchingCampus ?
                    <select value={selectedCampus} onChange={(e) => {
                        setSelectedCampus(e.target.value)
                    }}>
                        <option value={''} selected={selectedCampus === ''}>
                            PER CAMPUS
                        </option>
                        {campusList.map((item, i) => (
                            <option value={item?.id} key={item?.id}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                    : <div className='spinnerWrapper'>
                        <Spinner size={2}/>
                    </div>
                }
            </div>
            <div className="actionButtonWrapper">
                <Button
                    fontSize={1}
                    borderRadius={2.5}
                    paddingTopBottom={0.5}
                    paddingLeftRight={1.5}
                    onClick={() => {
                        setterCallback({
                            selectedCity,
                            selectedCampus
                        })
                    }}
                >
                    SHOW RESULTS
                </Button>
            </div>
        </div>
    )

}