export const extractDataFromObject = (data, fieldSpecification) => {
    const fields = fieldSpecification.split(".")
    let tempData = {...data};

    for (let field of fields) {
        tempData = tempData[field]
    }
    return tempData;
}


export const setDataInObject = (sourceData, dataToSet, fieldSpecification) => {
    const fields = fieldSpecification.split(".")
    sourceData = {...sourceData}
    if (fields.length === 2) {
        sourceData[fields[0]][fields[1]] = dataToSet
    } else if (fields.length === 1) {
        sourceData[fields[0]] = dataToSet
    }
    return sourceData
}