import styled from "styled-components";
import { TableBody } from "./components/TableBody";
import { TableHeader } from "./components/TableHeader";
import { SizedBox } from "../../SizedBox";

const StyledComponent = styled.div`
    padding: 1rem;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0 10px 20px -3px #ddd;
    * {
        font-size: 1rem;
    }
    h4 {
        margin-bottom: 1.5rem;
    }
    .tableOuter {
        background-color: var(--custom-secondry-bg);
        padding: 1rem;
        overflow: auto;
        max-height: 500px;
        @media (min-width: 1100px) {
            overflow-x: auto !important;
        }
    }
    .tablevms {
        border-collapse: collapse;
        width: 100%;
        max-width: 100vw;
        position: relative;
    }
    .tablevms thead tr {
        color: var(--custom-primary-bg);
        background-color: var(--custom-secondary-bg);
    }
    .tablevms thead th {
        background-color: var(--custom-secondary-bg);
        padding: 0.4rem;
        font-size: 0.95rem;
        font-weight: 600;
    }
    .tablevms thead th:first-child {
        border-radius: 2.5rem 0px 0px 2.5rem;
    }
    .tablevms thead th:last-child {
        border-radius: 0px 2.5rem 2.5rem 0px;
    }
    .tablevms tbody td {
        border: 1px solid var(--custom-table-border);
        border-top: 0px;
        padding: 0.5rem;
        padding-left: 0.8rem;
        line-height: 1.2rem;
        font-size: 1rem;
        color: var(--custom-Black);
        text-align: center;
    }

    .tablevms tbody td .actions {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
    .tablevms tbody td .view {
        display: flex;
        justify-content: center;
    }

    .tablevms tbody td .list {
        display: flex;
        align-items: left;
        flex-direction: column;
        gap: 1rem;
    }

    .tablevms tbody td .file {
        a {
            text-decoration: none !important;
        }
    }

    .tablevms tbody td .view button,
    .tablevms tbody td .Update button {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0.4rem 1.5rem;
        background: var(--custom-btn-bg);
        color: var(--custom-white);
        border: none;
        border-radius: 5px;
        outline: none;
        transition: background-color 0.3s ease-in-out;
    }

    .tablevms tbody td .view button:hover,
    .tablevms tbody td .Update button:hover {
        background-color: var(--custom-btn-bg-hover);
        color: var(--custom-white);
        transition: background-color 0.3s ease-in-out;
    }

    .tablevms tbody td .actions button {
        img {
            filter: invert(0);
            width: 1rem;
            height: 1rem;
            object-fit: contain;
        }
        ::focus-visible {
            border: none;
            outline: none;
        }
    }
    .tablevms tbody tr:last-child td {
        border-bottom: 0px !important;
    }
    .tablevms tbody td:last-child {
        border-right: 0px !important;
    }
    .tablevms tbody td:first-child {
        border-left: 0px !important;
    }

    .editBtn {
        text-align: center;
        button {
            border: none;
            outline: none;
            color: var(--custom-btn-bg);
            transition: color 0.3s ease-in-out;
            border-radius: 10px;
            background: white;
            cursor: pointer;
            img {
                filter: invert(0);
                width: 1.2rem;
                height: 1.2rem;
                object-fit: cover;
            }
            ::focus-visible {
                border: none;
                outline: none;
            }
        }
    }
    .editBtn:hover {
        button {
            color: var(--custom-btn-bg-hover);
            transition: color 0.3s ease-in-out;
        }
    }
    .tablevms tbody td,
    .tablevms thead th {
        ${"" /* min-width: 100px; */}
    }
    .tablevms tbody td:first-child,
    .tablevms thead th:first-child {
        min-width: 60px;
    }
`;

export const TableComponent = ({
    heading,
    headerData,
    bodyData,
    actionsView1,
    actionsView2,
    actionsView3,
    actionsUpdate,
    actionDelete,
    actionEdit,
}) => {
    return (
        <StyledComponent>
            <div className="tableOuter">
                <h4 className="heading">{heading}</h4>
                <table className="tablevms">
                    <thead>
                        <TableHeader HeaderData={headerData} />
                        <SizedBox height={1} />
                    </thead>
                    <tbody>
                        <TableBody
                            bodyData={bodyData}
                            actionsView1={actionsView1}
                            actionsView2={actionsView2}
                            actionsUpdate={actionsUpdate}
                            actionsView3={actionsView3}
                            actionEdit={actionEdit}
                            actionDelete={actionDelete}
                        />
                    </tbody>
                </table>
            </div>
        </StyledComponent>
    );
};
