import { MainWrapper } from "../../Global/MainWrapper";
import { RemindersListComponents } from "./components/RemindersList";

export const RemindersPROContainer = () => {
    return (
        <MainWrapper pageTitle={"Reminders"}>
            <RemindersListComponents />
        </MainWrapper>
    );
};
