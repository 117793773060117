import React from "react";
import { MainWrapper } from "../../Global/MainWrapper";
import { FinanceListComponents } from "./components/FinanceList";

export const FinancePROContainer = () => {
    return (
        <MainWrapper pageTitle={"Finance"}>
            <FinanceListComponents />
        </MainWrapper>
    );
};
