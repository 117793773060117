import { GlobalRoutes } from './routes/global/Container'
import { StudentRoutes } from './routes/students/Routes'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createContext, useEffect } from 'react'
import { fetchUserProfile } from './config/store/actions'
import { PRORoutes } from './routes/pro/Routes'
import { SuperAdminRoutes } from './routes/superadmin/Routes'
import { RegionalHeadRoutes } from './routes/regionalHead/Routes'
import { SchoolHeadRoutes } from './routes/schoolHead/Routes'

export const GlobalUserProfileContext = createContext({
    isFetchingProfile: null,
    profile: null,
    lastTimeFetched: null,
})

const App = () => {
    const { isFetchingProfile, profile, lastTimeFetched } = useSelector(
        (state) => state.userProfileReducer
    )
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUserProfile)
    }, [])
    console.warn = () => {}

    return (
        <GlobalUserProfileContext.Provider
            value={{
                profile,
                isFetchingProfile,
                lastTimeFetched,
            }}
        >
            <BrowserRouter>
                <GlobalRoutes />
                <StudentRoutes />
                <PRORoutes />
                <RegionalHeadRoutes />
                <SchoolHeadRoutes />
                <SuperAdminRoutes />
            </BrowserRouter>
        </GlobalUserProfileContext.Provider>
    )
}

export default App
