import styled from "styled-components";
import logo from "../../static/images/logo/bcplogowhite2.svg";

const Logo = styled.div`
  font-family: var(--logo-font);
  ${"" /* border: 1px solid var(--custom-border-color); */}
  padding: 2rem 3rem 0 1rem;
  border-radius: 5px;
  color: var(--custom-txt-white);
  font-size: ${({ size }) => size}rem;
  font-weight: 700;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CompanyLogo = ({ size }) => (
  <Logo size={size}>
    <img src={logo} />
  </Logo>
);
