import {TableComponent} from "../../../../graphical/table/Container";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {notifySuccess} from "../../../../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";

export const SubjectsTable = ({data, callBackFetchData}) => {
    const headerData = ["Subject", ""]

    const onClickDelete = (id) => {
        const endpoint = `/student/admission/preference/subjects/${id}/`
        axiosServerInstance()
            .delete(endpoint)
            .then((response) => {
                notifySuccess("Success")
                callBackFetchData()
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const onClickEdit = (id) => {
    }
    const fetchTableData = () => {
        return (
            (data).map((item, i) => {
                return {
                    id: item.id,
                    data: [
                        {
                            type: "text",
                            value: item.subject_name,
                        },
                        {
                            type: "delete"
                        },
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchTableData()}
            actionDelete={{
                delete: onClickDelete,
            }}
        />
    )
}