export const ActionsView1 = ({ actionsView1, dataID }) => {
  return (
    <>
      {actionsView1 && (
        <div className="view">
          {Object.keys(actionsView1).map((actionName, i) => (
            <button
              key={i}
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionsView1[actionName](dataID)
              }}
            >
              {actionName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
