import styled from 'styled-components'
import { CircleProgressBarContainer } from '../../Global/graphical/progressbar/circleBar/Container'
import React, { useEffect, useState } from 'react'
import { axiosServerInstance } from '../../../config/api/axios'
import { notifyFailure } from '../../../helper/notifications/notifyFailure'
import { MainWrapper } from '../../Global/MainWrapper'
import { HorizontalRule } from '../../Global/HorizontalRule'
import { GlobalUserProfileContext } from '../../../App'
import { useContext } from 'react'
import FilterByBatch from '../../Global/filters/FilterByBatch'
import { AlignItemsEnd } from '../../Global/AlignItemsEnd'

const Wrapper = styled.div`
    .circleBarsOuter {
        display: flex;
        gap: 2rem;
        margin-bottom: 1rem;
        justify-content: start;
        @media (max-width: 1080px) {
            justify-content: center;
            row-gap: 0rem;
            flex-wrap: wrap;
        }

        .progressbar {
            width: 25%;
            padding: 1rem;
            border-radius: 2.5rem;
            @media (max-width: 1080px) {
                width: 40%;
                padding: 2rem;
            }
            @media (max-width: 530px) {
                padding: 1rem;
                width: 50%;
            }
        }
    }

    .capacityDetailsOuter {
        .heading {
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.2rem;
        }

        .countWrapper {
            display: flex;
            gap: 2rem;
            @media (max-width: 900px) {
                flex-wrap: wrap;
                gap: 1rem;
            }
            @media (max-width: 500px) {
                margin-top: 1rem;
            }
            @media (max-width: 410px) {
                gap: 0.5rem;
            }

            .counter {
                padding: 2rem;
                @media (max-width: 970px) {
                    padding: 1rem;
                }
                @media (max-width: 500px) {
                    padding: 0.3rem;
                }

                .title {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.2rem;
                    @media (max-width: 970px) {
                        font-size: 1rem;
                    }
                }

                .digits {
                    font-size: 2.4rem;
                    font-weight: 600;
                    line-height: 1.8rem;
                    color: #1c4489;
                    @media (max-width: 1060px) {
                        font-size: 2rem;
                    }
                    @media (max-width: 500px) {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    .circleContent {
        .detail {
            font-size: 1vw;
            @media (max-width: 1320px) {
                font-size: 1rem !important;
                line-height: 1rem;
            }
        }

        @media (max-width: 1320px) {
            .counter {
                font-size: 1.4rem;
                line-height: 1.4rem;
            }
        }
    }
`
export const DashboardPROContainer = () => {
    const { profile } = useContext(GlobalUserProfileContext)
    const [campusTotalStats, setCampusTotalStats] = useState()
    const [campusAdmissionStatusStats, setCampusAdmissionStatusStats] =
        useState()
    const [batchFilter, setBatchFilter] = useState({ batch: '' })

    const fetchCampusTotalStats = async () => {
        // let endpoint = `/shared/campuses/my_campus/total_stats/`
        let endpoint = `/shared/campuses/my_campus/total_stats/${
            batchFilter?.batch && `?batch=${batchFilter.batch}`
        }`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setCampusTotalStats(response?.data)
            })
            .catch(err => {
                notifyFailure('Error in fetching campus capacity information')
            })
    }

    const fetchCampusAdmissionStatusStats = async () => {
        let endpoint = `/shared/campuses/my_campus/admission_status_stats/${
            batchFilter?.batch && `?batch=${batchFilter.batch}`
        }`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setCampusAdmissionStatusStats(response?.data)
            })
            .catch(err => {
                notifyFailure('Error in fetching campus admission statistics')
            })
    }

    useEffect(() => {
        fetchCampusAdmissionStatusStats()
    }, [batchFilter])

    // useEffect(() => {
    //     fetchCampusTotalStats()
    // }, [])
    useEffect(() => {
        fetchCampusTotalStats()
    }, [batchFilter])

    const counterArr = [
        {
            title: 'Total',
            Digits: campusTotalStats?.total_capacity || 0,
        },
        {
            title: 'Used',
            Digits: campusTotalStats?.capacity_used || 0,
        },
        {
            title: 'Remaining',
            Digits: campusTotalStats?.remaining_capacity || 0,
        },
    ]
    return (
        <MainWrapper pageTitle={`Welcome  ${profile?.full_name || ''}`}>
            <Wrapper>
                <AlignItemsEnd>
                    <div className='searchActions'>
                        <div className='selectOuter'>
                            <FilterByBatch setBatchFilter={setBatchFilter} />
                        </div>
                    </div>
                </AlignItemsEnd>
                {/* <div className='searchActions'>
                    <div className='selectOuter'>
                        <FilterByBatch setBatchFilter={setBatchFilter} />
                    </div>
                </div> */}
                <div className='capacityDetailsOuter'>
                    <h3 className='heading'>CAPACITY DETAILS</h3>
                    <div className='countWrapper'>
                        {counterArr.map((item, i) => {
                            return (
                                <div className='counter' key={i}>
                                    <span className='title'>
                                        {item.title} :{' '}
                                    </span>
                                    <span className='digits'>
                                        {item.Digits}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <HorizontalRule />
                <div style={{ marginTop: '13px' }}></div>

                <div className='circleBarsOuter'>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Submitted Applications'
                            value={
                                campusAdmissionStatusStats?.successful_applications_count ||
                                0
                            }
                            total={
                                campusAdmissionStatusStats?.total_student_count ||
                                0
                            }
                        />
                    </div>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Successful Interviews'
                            value={
                                campusAdmissionStatusStats?.successful_interview_count ||
                                0
                            }
                            total={
                                campusAdmissionStatusStats?.total_student_count ||
                                0
                            }
                        />
                    </div>

                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Seat Confirmation'
                            value={
                                campusAdmissionStatusStats?.seat_confirmation_count ||
                                0
                            }
                            total={
                                campusAdmissionStatusStats?.total_student_count ||
                                0
                            }
                        />
                    </div>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Fee Submission'
                            value={
                                campusAdmissionStatusStats?.fee_submission_count ||
                                0
                            }
                            total={
                                campusAdmissionStatusStats?.total_student_count ||
                                0
                            }
                        />
                    </div>
                </div>
            </Wrapper>
        </MainWrapper>
    )
}
