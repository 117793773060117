import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { FormFieldsWrapper } from './../../../../../Global/graphical/data_input/FormFieldsWrapper'
import { Button } from './../../../../../Global/Button'
import { InputComponent } from './../../../../../Authentication/components/InputELement'
import { axiosServerInstance } from '../../../../../../config/api/axios'
import { notifyApiErrorMessage } from '../../../../../../helper/notifications/notifyApiErrorMessage'
import { useParams } from 'react-router-dom'
import { ModalComponent } from '../../../../../Global/graphical/Modal'
import { AddSubjectPreference } from './AddSubjectPreference'
import { EditSubjectPreference } from './EditSubjectPreference'
import { PROStudentPreferencesContext } from '../Container'
import { SizedBox } from '../../../../../Global/SizedBox'
import { Spinner } from '../../../../../Global/graphical/Spinner'
import Delete from '../../../../../../static/images/table/Path 39.png'
import Edit from '../../../../../../static/images/table/Group 46.svg'
import { formatBatch } from '../../../../../../helper/formatters/formatBatch'
import { EditBatchPreference } from './EditBatchPreference'

const StyledComponent = styled.div`
    .formFieldWrapper {
        border-radius: 20px;
        box-shadow: 0 10px 10px -3px #cfcfcf;
        padding: 3rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media screen and (max-width: 500px) {
            padding: 2rem 1rem;
        }

        .headinginfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 1100px) {
                flex-wrap: wrap;
            }
        }

        .formTitle {
            font-size: 1.2rem;
            margin-left: 1rem;
            color: var(--custom-Black);
            margin: 1rem 0;
        }

        .subjectsWrapper {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-top: 5rem;
        }

        .formfieldInner {
            img {
                cursor: pointer;
            }
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            gap: 0.5rem;
            @media (max-width: 1500px) {
                width: 100%;
            }

            .inputOuter.w-32 {
                width: 29%;

                @media (max-width: 1350px) {
                    width: 24%;
                }
                @media (max-width: 1100px) {
                    width: 48%;
                }
                @media (max-width: 790px) {
                    width: 100%;
                }
            }

            .inputOuter.w-21 {
                width: 19%;
                input {
                    margin-top: 0.5rem;
                }
                @media (max-width: 1350px) {
                    width: 16%;
                }
                @media (max-width: 1100px) {
                    width: 20%;
                }
                @media (max-width: 790px) {
                    width: 100%;
                }
            }
        }

        .actionButtonWrapper {
            align-self: end;
            justify-self: end;
        }
    }

    select {
        min-width: 105px;
        height: 2.9rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        border: 1px solid var(--custom-input-border);
        background-image: none;
        overflow: hidden;
        color: var(--custom-Black);

        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }

    .selected {
        label {
            display: block;
        }
    }

    .actionButtonWrapper {
        margin-top: 3rem;
        display: flex;
        justify-content: flex-end;
    }

    .helperText {
        font-size: 14px;
        margin: 3rem 0 2rem;
    }

    .AvailableSeatsOuter {
        font-size: 1rem;
        font-weight: 500;
        padding: 0px 10px;
        text-align: center;

        span {
            font-weight: 700;
            margin-left: 0.2rem;
            font-size: 1.2rem;
            color: var(--custom-btn-bg);
        }
        @media (max-width: 1350px) {
        }
        @media (max-width: 1100px) {
            font-size: 0.8rem;
            p {
                font-size: 0.8rem;
            }
        }
        @media (max-width: 790px) {
        }
    }
`

export const Preference = ({ callbackAction }) => {
    const [citiesCampuses, setCitiesCampuses] = useState([])
    const [studentInfo, setStudentInfo] = useState({})
    const [subjects, setSubjects] = useState([])
    const [formattedSubjectsWithCapacity, setFormattedSubjectsWithCapacity] =
        useState([])
    const { id: studentID } = useParams()
    const [addPreferencesModal, setAddPreferencesModal] = useState(false)
    const [editPreferencesModal, setEditPreferencesModal] = useState(false)
    const [editPreferencesBatchModal, setEditPreferencesBatchModal] =
        useState(false)
    const [preferenceToEdit, setPreferenceToEdit] = useState(null)
    const { getAvailableSeat, setCanConfirmSubjects } = useContext(
        PROStudentPreferencesContext
    )

    const callBackFilterNonCustomSubjectsOutFromFormattedSubjectsWithCapacity =
        useCallback(() => {
            return formattedSubjectsWithCapacity.filter(
                item => !(item?.subject_id || '').startsWith('CUSTOM-SUBJECT-')
            )
        }, [formattedSubjectsWithCapacity])

    const callBackFilterCustomSubjectsOutFromFormattedSubjectsWithCapacity =
        useCallback(() => {
            return formattedSubjectsWithCapacity.filter(item =>
                (item?.subject_id || '').startsWith('CUSTOM-SUBJECT-')
            )
        }, [formattedSubjectsWithCapacity])

    useEffect(() => {
        const execute = async () => {
            if (subjects) {
                let tempData = []
                for (let subject of subjects) {
                    if (
                        subject?.subject_id &&
                        subject?.teacher_id &&
                        subject?.class_id &&
                        !subject.subject_id.startsWith('CUSTOM-SUBJECT-')
                    ) {
                        let value = await getAvailableSeat(
                            subject?.subject_id,
                            subject?.teacher_id,
                            subject?.class_id
                        )
                        tempData.push({ ...subject, available_seats: value })
                    } else {
                        tempData.push(subject)
                    }
                }
                setFormattedSubjectsWithCapacity(tempData)
            }
        }
        execute()
    }, [subjects])

    const fetchSubjectsFromServer = async () => {
        let endpoint = `/pro/students/${studentID}/admission/preference/subjects`
        axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setSubjects(response.data?.results)
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
    }

    const fetchCityCampusFromServer = async () => {
        let endpoint = `/pro/students/${studentID}/admission/preference/city_campus`
        axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setCitiesCampuses(response.data?.results)
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
    }

    const fetchStudentInfo = () => {
        let enpoint = `/pro/students/${studentID}`
        axiosServerInstance()
            .get(enpoint)
            .then(resp => {
                setStudentInfo(resp.data)
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
    }

    useEffect(() => {
        fetchCityCampusFromServer()
        fetchSubjectsFromServer()
        fetchStudentInfo()
    }, [])

    const onEdit = () => {
        fetchStudentInfo()
    }
    const [deleteLoading, setDeleteLoading] = useState(null)

    const removeSubject = id => {
        let endpoint = `/pro/students/${studentID}/admission/preference/subjects/${id}/`
        setDeleteLoading(id)
        axiosServerInstance()
            .delete(endpoint)
            .then(response => {
                setDeleteLoading(null)

                setSubjects(subjects.filter(item => item.id !== id))
            })
            .catch(err => {
                setDeleteLoading(null)

                notifyApiErrorMessage(err)
            })
    }

    useEffect(() => {
        const nonCustomSubjects =
            callBackFilterNonCustomSubjectsOutFromFormattedSubjectsWithCapacity()
        const canProceedToSaveToDB =
            !!nonCustomSubjects.length &&
            nonCustomSubjects
                .map(item => item?.subject_id)
                .every(item => !!item) &&
            nonCustomSubjects
                .map(item => item?.teacher_id)
                .every(item => !!item) &&
            nonCustomSubjects.map(item => item?.class_id).every(item => !!item)
        setCanConfirmSubjects(canProceedToSaveToDB)
    }, [formattedSubjectsWithCapacity])

    return (
        <StyledComponent>
            <div className='formFieldWrapper' autocomplete='off'>
                <div className='headinginfo'>
                    <h2 className='formTitle'>PREFERENCES</h2>
                    <div className='updatBtn'>
                        <Button
                            fontSize={1}
                            borderRadius={2.4}
                            maxWidth={12}
                            paddingTopBottom={0.6}
                            paddingLeftRight={2}
                            onClick={e => {
                                e.preventDefault()
                                setAddPreferencesModal(true)
                            }}
                        >
                            ADD SUBJECT
                        </Button>
                    </div>
                </div>
                <FormFieldsWrapper>
                    <div className='formfieldInner'>
                        <div className='inputOuter w-32'>
                            <label>City</label>
                            <InputComponent
                                type='text'
                                fontSize={1}
                                value={citiesCampuses[0]?.city_name}
                            />
                        </div>
                        <div className='inputOuter w-32'>
                            <label>Campus</label>
                            <InputComponent
                                type='text'
                                fontSize={1}
                                value={citiesCampuses[0]?.campus_name}
                            />
                        </div>
                    </div>
                </FormFieldsWrapper>

                {studentInfo?.account_data && (
                    <div className='subjectsWrapper'>
                        <div className='formfieldInner'>
                            <div className='inputOuter w-21'>
                                <label>Batch</label>
                                <InputComponent
                                    type='text'
                                    fontSize={0.7}
                                    value={
                                        studentInfo?.account_data?.batch
                                            ? formatBatch(
                                                  studentInfo?.account_data
                                                      ?.batch?.first_year
                                              )
                                            : 'nill'
                                    }
                                />
                            </div>

                            <img
                                src={Edit}
                                alt=''
                                style={{ width: '30px' }}
                                onClick={e => {
                                    e.preventDefault()
                                    setEditPreferencesBatchModal(true)
                                }}
                            />
                        </div>
                    </div>
                )}

                <div className='subjectsWrapper'>
                    {callBackFilterNonCustomSubjectsOutFromFormattedSubjectsWithCapacity().map(
                        (config, i) => (
                            <div className='formfieldInner' key={i}>
                                <div className='inputOuter w-21'>
                                    <label>Subject</label>
                                    <InputComponent
                                        type='text'
                                        fontSize={0.7}
                                        value={config.subject_name}
                                    />
                                </div>
                                <div className='inputOuter w-21'>
                                    <label>Teacher</label>
                                    <InputComponent
                                        type='text'
                                        fontSize={0.7}
                                        value={config?.teacher_name}
                                    />
                                </div>
                                <div className='inputOuter w-21'>
                                    <label>Class</label>
                                    <InputComponent
                                        type='text'
                                        fontSize={0.7}
                                        value={config?.class_name}
                                    />
                                </div>
                                <div className='AvailableSeatsOuter'>
                                    <p>Available</p>
                                    <p>seats</p>
                                    <span>
                                        {config?.available_seats || 'Nil'}
                                    </span>
                                </div>
                                <img
                                    src={Edit}
                                    style={{ width: '30px' }}
                                    onClick={e => {
                                        e.preventDefault()
                                        setPreferenceToEdit(config.id)
                                        setEditPreferencesModal(true)
                                    }}
                                />
                                {/* <Button
                                    fontSize={0.5}
                                    borderRadius={2.4}
                                    maxWidth={5}
                                    height={2}
                                    paddingTopBottom={0.6}
                                    paddingLeftRight={1}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setPreferenceToEdit(config.id)
                                        setEditPreferencesModal(true)
                                    }}
                                >
                                    UPDATE
                                </Button> */}
                                {deleteLoading === config.id ? (
                                    <Spinner />
                                ) : (
                                    <img
                                        src={Delete}
                                        onClick={() => removeSubject(config.id)}
                                    />
                                )}
                            </div>
                        )
                    )}
                </div>
                {callBackFilterCustomSubjectsOutFromFormattedSubjectsWithCapacity()
                    .length ? (
                    <>
                        <SizedBox height={2} />
                        <h3 className='formTitle'>Other Subjects</h3>
                        <SizedBox height={1} />
                        <div className=''>
                            {formattedSubjectsWithCapacity
                                .filter(item =>
                                    (item?.subject_id || '').startsWith(
                                        'CUSTOM-SUBJECT-'
                                    )
                                )
                                .map((config, i) => (
                                    <div className='formfieldInner' key={i}>
                                        <div className='inputOuter w-21'>
                                            <InputComponent
                                                type='text'
                                                fontSize={0.7}
                                                value={config.subject_name}
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                ) : null}
            </div>
            <ModalComponent
                modalLabel={'Add reminder'}
                isOpen={addPreferencesModal}
                callbackCloseModal={() => {
                    setAddPreferencesModal(false)
                }}
            >
                <AddSubjectPreference
                    callbackCancel={() => {
                        setAddPreferencesModal(false)
                    }}
                    callbackProceed={() => {
                        setAddPreferencesModal(false)
                        fetchSubjectsFromServer()
                    }}
                />
            </ModalComponent>
            <ModalComponent
                modalLabel={'Add reminder'}
                isOpen={editPreferencesModal}
                callbackCloseModal={() => {
                    setEditPreferencesModal(false)
                }}
            >
                <EditSubjectPreference
                    callbackCancel={() => {
                        setEditPreferencesModal(false)
                    }}
                    callbackProceed={() => {
                        setEditPreferencesModal(false)
                        fetchSubjectsFromServer()
                    }}
                    subjectPreferenceID={preferenceToEdit}
                />
            </ModalComponent>
            <ModalComponent
                modalLabel={'Add reminder'}
                isOpen={editPreferencesBatchModal}
                callbackCloseModal={() => {
                    setEditPreferencesBatchModal(false)
                }}
            >
                <EditBatchPreference
                    callbackCancel={() => {
                        setEditPreferencesBatchModal(false)
                    }}
                    callbackProceed={() => {
                        setEditPreferencesBatchModal(false)
                    }}
                    batchInfo={studentInfo?.account_data?.batch}
                    onEdit={onEdit}
                />
            </ModalComponent>
        </StyledComponent>
    )
}
