import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import React, {useState} from "react";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import {Spinner} from "../../Global/graphical/Spinner";
import {useDispatch} from "react-redux";

export const ForgotPasswordForm = () => {
    const [data, setData] = useState({})
    const [showSpinner, setShowSpinner] = useState(false)

    const handleOnClickProceedButton = (e) => {
        e.preventDefault()
        handleInitiateForgotPassword()
    }

    const handleInitiateForgotPassword = async () => {
        const endpoint = "/auth/initiate_reset_password_email/"
        setShowSpinner(true)
        axiosServerInstance()
            .post(endpoint, data)
            .then((response) => {
                notifySuccess(`A password reset link has been sent to ${data?.email}`)
                setShowSpinner(false)
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
                setShowSpinner(false)
            });
    };

    return (
        <FormComponent>
            <label>Enter your email</label>
            <InputComponent
                placeholder={""}
                type="email"
                value={data?.email}
                onChange={(e) => handleOnInputChange(e, "email", setData, data)}
            />
            <SizedBox height={2.5}/>

            <AuthButtonContainer>
                <>
                    {showSpinner ?
                        <Spinner size={2}/>
                        :
                        <Button
                            fontWeight={700}
                            fontSize={1}
                            maxWidth={15}
                            borderRadius={1.5}
                            paddingTopBottom={0.8}
                            onClick={handleOnClickProceedButton}
                        >
                            Send Verification Code
                        </Button>
                    }
                </>
            </AuthButtonContainer>
        </FormComponent>
    );
};
