import {GlobalNoAuthRoutes} from "./NoAuthRoutes";
import {GlobalAuthRoutes} from "./AuthRoutes";

export const GlobalRoutes = () => {
    return (
        <>
            <GlobalAuthRoutes/>
            <GlobalNoAuthRoutes/>
        </>
    )
}