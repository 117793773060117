import React, {useEffect, useRef, useState} from "react";
import {FormFieldsWrapper} from "../../../../graphical/data_input/FormFieldsWrapper";
import {InputComponent} from "../../../../../Authentication/components/InputELement";
import {Spinner} from "../../../../graphical/Spinner";
import {Button} from "../../../../Button";
import styled from "styled-components";
import {handleOnInputChange} from "../../../../../../helper/form_inputs/handleOnInputChange";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {
    extractDataFromObject,
    setDataInObject,
} from "../../../../../../helper/misc";
import {notifyFailure} from "../../../../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";

const StyledComponent = styled.div`
    margin: 1rem 1.5rem;
    .formTitle {
        font-size: 2rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
    }
    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        .inputOuter.w-38 {
            width: 100%;
        }
    }
    .formFieldWrapperSubject {
        align-items: flex-start;
        .helperText {
            font-size: 12px;
            margin-left: 1rem;
            line-height: 1.5rem;
        }
    }
    .actionButtonWrapper {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        & > button:first-child {
            background-color: transparent;
            color: var(--custom-btn-bg);
            border: 1px solid var(--custom-btn-bg);
        }
        @media (max-width: 900px) {
            margin-top: 2rem;
        }
    }
    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);
        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }
`;

export const EditInstitution = ({
                                    callbackCancel,
                                    callbackProceed,
                                    institutionID,
                                }) => {

    const [data, setData] = useState({});
    const neededDataFields = useRef(["name"]);

    const fetchAndParseDataFromAPI = () => {
        const dataEndpoint = `/student/admission/degree/institutions/${institutionID}/`;
        axiosServerInstance()
            .get(dataEndpoint)
            .then((response) => {
                const tempData = {};
                for (let field of neededDataFields.current) {
                    tempData[field] = response.data[field];
                }
                setData(tempData);
            })
            .catch((err) => {
                notifyFailure("Error in fetching institution data");
                notifyApiErrorMessage(err);
            });
    };

    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);

    return (
        <StyledComponent>
            <h2 className="formTitle">Edit Institution</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="inputOuter w-38">
                    <label>Institution Name</label>
                    <InputComponent
                        placeholder={""}
                        type="text"
                        fontSize={1}
                        value={data?.name}
                        onChange={(e) => {
                            handleOnInputChange(e, "name", setData, data);
                        }}
                    />
                </div>
            </FormFieldsWrapper>

            <div className="actionButtonWrapper">
                <>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        Bg_color="transparent"
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={callbackCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        paddingTopBottom={0.6}
                        paddingLeftRight={1.7}
                        onClick={() => callbackProceed(data)}
                    >
                        Save
                    </Button>
                </>
            </div>
        </StyledComponent>
    );
};
