import styled from 'styled-components'
import { MainWrapper } from '../Global/MainWrapper'

import { UpdatePersonalinformation } from './Components/UpdatePersonalinformation'

const StyledWrapper = styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: stretch;
    flex-grow: 1;

    @media screen and (max-width: 56rem) {
        flex-direction: column;
    }

    @media screen and (max-width: 35rem) {
        align-items: stretch;
    }

    .wrapper {
        border-radius: 0.5rem;
        padding: 1.3rem 1.5rem;
    }

    .profileWrapper {
        width: 100%;
        flex-grow: 0;
        align-self: flex-start;

        @media screen and (max-width: 35rem) {
            width: initial;
            align-self: stretch;
        }
    }

    .activityWrapper {
        align-self: stretch;
        flex-grow: 1;
    }
`

export const PersonalInfoUpdateContainer = ({ customTitle }) => {
    return (
        <MainWrapper pageTitle="Personal Information">
            <StyledWrapper>
                <div className="profileWrapper wrapper">
                    <UpdatePersonalinformation />
                </div>
            </StyledWrapper>
        </MainWrapper>
    )
}
