import { Route, Routes } from 'react-router-dom'
import { ProtectedRouteComponent } from '../../components/Global/ProtectedRouteComponent'
import {
    authPagesConditions,
    isAuthenticated,
    isEmailVerified,
    isPhoneNumberVerified,
} from '../../conditions'
import HomeboardingContainer from '../../components/HomeBoarding/Container'
import LogoutContainer from '../../components/Authentication/logout/Container'
import { CNICVerificationContainer } from '../../components/Authentication/cnic_verification/Container'
import { DashboardPROContainer } from '../../components/PRO/dashboard/Dashboard'
import { DashboardRegionalHeadContainer } from '../../components/regional_head/dashboard/Dashboard'
import { DashboardSchoolHeadContainer } from '../../components/school_head/dashboard/Dashboard'
import { DashboardSuperAdminContainer } from '../../components/superadmin/dashboard/Dashboard'

const roleBasedDashboardRoutesMapping = {
    PRO: <DashboardPROContainer />,
    Student: <HomeboardingContainer />,
    'Regional Head': <DashboardRegionalHeadContainer />,
    'School Head': <DashboardSchoolHeadContainer />,
    'Super Admin': <DashboardSuperAdminContainer />,
}

export const GlobalAuthRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        roleBasedRouting={true}
                        roleBasedRoutingFieldName={'account_type'}
                        elementToRenderOnPass={<HomeboardingContainer />}
                        roleBasedRoutingElementMapping={
                            roleBasedDashboardRoutesMapping
                        }
                    />
                }
            />
            <Route
                path="/verifycnic"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{
                            '/login': isAuthenticated,
                            '/verifyphonenumber': isPhoneNumberVerified,
                            '/verifyemail': isEmailVerified,
                        }}
                        elementToRenderOnPass={<CNICVerificationContainer />}
                    />
                }
            />
            <Route
                path="/logout"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{ '/login': isAuthenticated }}
                        elementToRenderOnPass={<LogoutContainer />}
                    />
                }
            />
        </Routes>
    )
}
