import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HorizontalRule } from './HorizontalRule'
import { axiosServerInstance } from '../../config/api/axios'
import { notifyApiErrorMessage } from '../../helper/notifications/notifyApiErrorMessage'
import { notifyFailure } from '../../helper/notifications/notifyFailure'

const StyledComponent = styled.div`
    position: absolute;
    top: 3rem;
    right: 10%;
    max-height: 500px;
    overflow: auto;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    border-radius: 20px;
    box-shadow: 0 10px 20px -3px #ddd;
    margin-top: 1rem;
    z-index: 2;
    background-color: #fff;
    @media (max-width: 500px) {
        padding: 1.5rem 1rem;
        width: 100%;
        /* top: 10%; */
        right: 10%;
    }
    @media (max-width: 500px) {
        min-width: 80vw;
    }

    .heading {
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }

    .activity {
        .activityContent {
            display: flex;
            justify-content: left;
            align-items: center;

            .content {
                font-size: 1.2rem;
            }

            .dot {
                min-width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #3cb4e5;
                margin: 0 2rem;
            }
        }

        hr {
            margin: 0.7rem 0;
        }
    }
`
export const BellNotification = () => {
    const [data, setData] = useState([])
    const fetchDataFromServer = async () => {
        const endpoint = `/user/notifications?ordering=-date_added`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setData(response.data.results)
            })
            .catch(err => {
                notifyFailure('Error in fetching your notifications')
            })
    }

    useEffect(() => {
        fetchDataFromServer()
    }, [])
    return (
        <StyledComponent>
            {data.length ? (
                data.map((item, i) => (
                    <div className='activity' key={i}>
                        <div className='activityContent'>
                            <div className='dot'></div>
                            <div className='content'>{item.content}</div>
                        </div>
                        <HorizontalRule />
                    </div>
                ))
            ) : (
                <>You currently have no notification</>
            )}
        </StyledComponent>
    )
}
