import React, {useEffect, useRef, useState} from "react";
import {FormFieldsWrapper} from "../../../Global/graphical/data_input/FormFieldsWrapper";
import {InputComponent} from "../../../Authentication/components/InputELement";
import {Button} from "../../../Global/Button";
import styled from "styled-components";
import {handleOnInputChange} from "../../../../helper/form_inputs/handleOnInputChange";
import {axiosServerInstance} from "../../../../config/api/axios";
import {notifyFailure} from "../../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../../helper/notifications/notifyApiErrorMessage";
import {notifySuccess} from "../../../../helper/notifications/notifySuccess";

const StyledComponent = styled.div`
  margin: 1rem 1.5rem;

  .formTitle {
    font-size: 2rem;
    margin-left: 1rem;
    color: var(--custom-btn-bg);
    margin-bottom: 2rem;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    @media (max-width: 700px) {
      flex-direction: column;
    }

    .inputOuter.w-38 {
      width: 100%;
    }
  }

  .formFieldWrapperSubject {
    align-items: flex-start;

    .helperText {
      font-size: 12px;
      margin-left: 1rem;
      line-height: 1.5rem;
    }
  }

  .actionButtonWrapper {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    & > button:first-child {
      background-color: transparent;
      color: var(--custom-btn-bg);
      border: 1px solid var(--custom-btn-bg);
    }

    @media (max-width: 900px) {
      margin-top: 2rem;
    }
  }

  select {
    width: 100%;
    height: 3rem;
    text-indent: 10px;
    background: var(--custom-input-bg);
    border-radius: 25px;
    outline: none;
    background-image: none;
    overflow: hidden;
    border: 1px solid var(--custom-input-border);

    option {
      border-radius: 25px;
      width: 32%;
      background: var(--custom-input-bg);
    }
  }
`;

export const EditOlevel = ({callbackCancel, callbackProceed, criteriaID}) => {
    const [data, setData] = useState({})

    const fetchAndParseDataFromAPI = async () => {
        const dataEndpoint = `/shared/scholarship_criterias/${criteriaID}/`;
        await axiosServerInstance()
            .get(dataEndpoint)
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => {
                notifyFailure("Error in fetching criteria data");
            });
    };

    const handleUpdateData = async () => {
        const dataEndpoint = `/shared/scholarship_criterias/${criteriaID}/`;
        return await axiosServerInstance()
            .patch(dataEndpoint, data)
            .then((response) => {
                notifySuccess('Operation success')
                return true;
            })
            .catch((err) => {
                notifyFailure("Error in updating scholarship criteria");
                notifyApiErrorMessage(err)
                return false;
            });
    };


    useEffect(() => {
        fetchAndParseDataFromAPI();
    }, []);

    return (
        <StyledComponent>
            <h2 className="formTitle">Edit Scholarship Criteria</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="inputOuter w-38">
                    <label>Total Grades(A/A+):</label>
                    <InputComponent
                        placeholder={""}
                        type="text"
                        fontSize={1}
                        value={data?.total_distinction_count}
                        onChange={(e) => {
                            handleOnInputChange(e, "total_distinction_count", setData, data);
                        }}
                    />
                </div>
                <div className="inputOuter w-38">
                    <label>Scholarship(%)</label>
                    <InputComponent
                        placeholder={""}
                        type="text"
                        fontSize={1}
                        value={data?.percentage}
                        onChange={(e) => {
                            handleOnInputChange(e, "percentage", setData, data);
                        }}
                    />
                </div>
            </FormFieldsWrapper>

            <div className="actionButtonWrapper">
                <>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        Bg_color="transparent"
                        paddingTopBottom={0.5}
                        paddingLeftRight={1.7}
                        onClick={callbackCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        fontSize={0.9}
                        maxWidth={10}
                        borderRadius={2.4}
                        paddingTopBottom={0.5}
                        paddingLeftRight={1.7}
                        onClick={async () => {
                            if (await handleUpdateData()) {
                                callbackProceed()
                            }
                        }
                        }
                    >
                        Save
                    </Button>
                </>
            </div>
        </StyledComponent>
    );
};
