import {Route, Routes} from "react-router-dom";
import {ProtectedRouteComponent} from "../../components/Global/ProtectedRouteComponent";
import {isGuest} from "../../conditions";
import LoginContainer from "../../components/Authentication/login/Container";
import ResetPasswordContainer from "../../components/Authentication/reset_password/Container";
import ForgotPasswordContainer from "../../components/Authentication/forgot_password/Container";
import RegisterContainer from "../../components/Authentication/signup/Container";
import {PhoneVerificationContainer} from "../../components/Authentication/phone_number_verification/Container";
import {EmailVerificationContainer} from "../../components/Authentication/email_verification/Container";
import GoogleSignUpContainer from "../../components/Authentication/google_signup/Container";
import LoginRolesContainer from "../../components/Authentication/loginRoles/Container";
import CreatePasswordContainer from "../../components/Authentication/create_password/Container";

export const GlobalNoAuthRoutes = () => {
    return (
        <Routes>
            <Route
                path="/login"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<LoginContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/create_password"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<CreatePasswordContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/loginroles"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<LoginRolesContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/reset_password"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{}}
                        elementToRenderOnPass={<ResetPasswordContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/forgot_password"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<ForgotPasswordContainer/>}
                        profileRequired={false}
                    />
                }
            />

            <Route
                path="/signup"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<RegisterContainer/>}
                        profileRequired={false}
                    />
                }
            />

            <Route
                path="/googlesignup"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{"/": isGuest}}
                        elementToRenderOnPass={<GoogleSignUpContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/verifyemail"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{}}
                        elementToRenderOnPass={<EmailVerificationContainer/>}
                        profileRequired={false}
                    />
                }
            />
            <Route
                path="/verifyphonenumber"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={{}}
                        elementToRenderOnPass={<PhoneVerificationContainer/>}
                    />
                }
            />
        </Routes>
    );
};
