import React, {useEffect, useState} from "react";
import {Button} from "../../../Global/Button";
import {FormFieldsWrapper} from "../../../Global/graphical/data_input/FormFieldsWrapper";
import styled from "styled-components";
import {InputComponent} from "../../../Authentication/components/InputELement";
import {SizedBox} from "../../../Global/SizedBox";

const StyledComponent = styled.div`
  margin: 1rem 1.5rem;

  .formTitle {
    font-size: 2rem;
    margin-left: 1rem;
    color: var(--custom-btn-bg);
    margin-bottom: 2rem;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .inputOuter {
      width: 100%;
      min-width: 30rem;
      @media (max-width: 900px) {
        min-width: 100%;
      }
    }
  }

  .actionButtonWrapper {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 900px) {
      margin-top: 2rem;
    }
  }
`;

export const EditMaxCapacity = ({callbackCancel, callbackProceed, label, inputVal}) => {
    const [value, setValue] = useState(inputVal);

    return (
        <StyledComponent>
            <h2 className="formTitle">{label}</h2>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="inputOuter ">
                    <label>Capacity</label>
                    <InputComponent
                        placeholder={""}
                        type="number"
                        fontSize={1}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                </div>
            </FormFieldsWrapper>
            <SizedBox height={2}/>
            <div className="actionButtonWrapper">
                <Button
                    fontSize={1}
                    maxWidth={15}
                    borderRadius={2.4}
                    paddingTopBottom={0.6}
                    paddingLeftRight={1.5}
                    onClick={() => {
                        callbackProceed(value)
                    }}
                >
                    UPDATE
                </Button>
            </div>
        </StyledComponent>
    );
};
