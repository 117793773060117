import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FormFieldsWrapper } from '../../../../../Global/graphical/data_input/FormFieldsWrapper'

import { useParams } from 'react-router-dom'
import { axiosServerInstance } from '../../../../../../config/api/axios'
import { notifyFailure } from '../../../../../../helper/notifications/notifyFailure'
import { notifyApiErrorMessage } from '../../../../../../helper/notifications/notifyApiErrorMessage'
import { notifySuccess } from '../../../../../../helper/notifications/notifySuccess'

import moment from 'moment'
import { formatBatch } from '../../../../../../helper/formatters/formatBatch'

const StyledComponent = styled.div`
    margin: 1rem 1.5rem;

    .formTitle {
        font-size: 1.4rem;
        margin-left: 1rem;
        color: var(--custom-btn-bg);
        margin-bottom: 2rem;
    }

    .formFieldWrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
            gap: 1rem;

            .inputOuter {
                flex: auto;
                width: 32%;
            }

            label {
                display: inline-block;
            }

            .Seats {
                display: inline-block;
                color: var(--custom-btn-bg);
                margin-left: 1rem;
                font-weight: 700;
                font-size: 18px;
            }

            .ResultRadioMode {
                width: 100%;

                input {
                    margin: 0 1rem;
                }
            }

            .inputOuter.w-38 {
                width: 300px;
                max-width: 100%;
                @media screen and (max-width: 45rem) {
                    width: 100%;
                }
            }
        }

        .actionButtonWrapper {
            button {
                @media (max-width: 500px) {
                    padding: 0.7rem 2rem;
                }
            }
        }
    }

    .spinnerWrapper {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    select {
        width: 100%;
        height: 3rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);

        option {
            border-radius: 25px;
            width: 32%;
            background: var(--custom-input-bg);
        }
    }
`
export const EditBatchPreference = ({
    callbackCancel,
    callbackProceed,
    batchInfo,
    onEdit,
}) => {
    const { id: studentID } = useParams()

    const [data, setData] = useState(
        batchInfo === '' ? { batch: '' } : { batch: batchInfo?.id }
    )
    const selectArray = ['Batch']
    const [selectedOptions, setSelectedOptions] = useState({
        Batch: batchInfo?.id,
    })

    const [optionData, setOptionData] = useState({
        Batch: {
            data: [
                {
                    academic_id: batchInfo?.id,
                    academicYear: formatBatch(batchInfo?.first_year),
                },
            ],
            currentPage: 1,
            hasMore: true,
        },
    })

    const fetchAndParseDataFromAPI = async (optionType, page = 1) => {
        // Update the API endpoint to include pagination parameters
        if (optionData[optionType].hasMore) {
            let endpoint = ''
            let pagination = `limit=${20}&offset=${(page - 1) * 20}`
            if (optionType === 'Batch') {
                endpoint = `/student/batch?${pagination}`
            }

            const response = await axiosServerInstance().get(endpoint)
            const mappedData = response.data.results.map(item => ({
                academic_id: item?.id,
                academicYear: formatBatch(item?.first_year),
            }))

            setOptionData(prevData => {
                // Check for duplicates before adding data
                const newData = mappedData.filter(
                    newItem =>
                        !prevData[optionType].data.some(
                            prevItem =>
                                prevItem.academic_id === newItem.academic_id
                        )
                )
                return {
                    [optionType]: {
                        data: [...prevData[optionType].data, ...newData],
                        currentPage: page,
                        hasMore: response.data.next !== null,
                    },
                }
            })
        }
    }

    const computeDataAndSendToServer = async () => {
        const accountDataEndpoint = `/pro/students/${studentID}/admission/information`
        let accountDataUpdateStatus = false

        await axiosServerInstance()
            .patch(accountDataEndpoint, data)
            .then(response => {
                accountDataUpdateStatus = true
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })

        return accountDataUpdateStatus
    }

    const isEdit = async () => {
        if (selectedOptions['Batch'] === batchInfo?.id) {
            notifyFailure('Please update batch')
            return false
        } else if (selectedOptions['Batch'] === '') {
            notifyFailure('Batch is required')
        } else {
            const success = await computeDataAndSendToServer()
            if (success) {
                notifySuccess('Batch updated')
                onEdit()
            } else {
                notifyFailure('Failed to update data')
                return false
            }
        }
    }

    useEffect(() => {
        if (selectedOptions['Batch'] === batchInfo?.id) {
            return
        } else {
            isEdit()
        }
    }, [selectedOptions['Batch']])

    useEffect(() => {
        fetchAndParseDataFromAPI('Batch')
    }, [])

    const handleOptionChange = (optionType, value) => {
        if (value !== 'loadMore') {
            setSelectedOptions(prevData => ({
                ...prevData,
                Batch: value,
            }))
            setData(value === '' ? { batch: '' } : { batch: parseInt(value) })
        } else {
            handleScroll(optionType)
        }
    }

    const handleScroll = optionType => {
        // Load the next page of data
        fetchAndParseDataFromAPI(
            optionType,
            selectedOptions.Batch,
            optionData[optionType].currentPage + 1
        )
    }

    return (
        <StyledComponent>
            <h2 className='formTitle'>UPDATE BATCH</h2>
            <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
                <div className='formfieldInner'>
                    <div className='inputOuter w-38'>
                        <label>Batch</label>
                        {selectArray.map((optionType, index) => (
                            <select
                                key={index}
                                value={selectedOptions[optionType]}
                                onChange={e =>
                                    handleOptionChange(
                                        optionType,
                                        e.target.value
                                    )
                                }
                            >
                                <option value=''>{optionType}</option>
                                {optionData[optionType].data.map(
                                    (item, index) => (
                                        <option
                                            key={index}
                                            value={item?.academic_id}
                                        >
                                            {item.academicYear}
                                        </option>
                                    )
                                )}
                                {optionData[optionType].hasMore &&
                                    optionData[optionType].data.length > 0 && (
                                        <option value='loadMore'>
                                            load more
                                        </option>
                                    )}
                            </select>
                        ))}
                    </div>
                </div>
            </FormFieldsWrapper>
        </StyledComponent>
    )
}
