import { MainWrapper } from "../../Global/MainWrapper";
import { FeeManagementListComponents } from "./components/FeeManagementList";

export const FeeManagementContainer = () => {
    return (
        <MainWrapper pageTitle={"FEE MANAGEMENT"}>
                <FeeManagementListComponents />
        </MainWrapper>
    );
};
