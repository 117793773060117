import React, {forwardRef, useContext, useImperativeHandle, useRef} from "react";
import styled from "styled-components";
import {FormFieldsWrapper} from "../../../graphical/data_input/FormFieldsWrapper";
import {useEffect, useState} from "react";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {multiStepFormValidator} from "../../../../../helper/form_validators/multiStepFormValidator";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";
import {Button} from "../../../Button";
import {SubjectsTable} from "./components/SubjectsTable";
import {SizedBox} from "../../../SizedBox";
import {GlobalUserProfileContext} from "../../../../../App";
import {Spinner} from "../../../graphical/Spinner";
import {parseArrayToSelectValues} from "../../../../../helper/form_inputs/parseEnumToSelectValues";
import {STUDENT_DEGREE_TYPES} from "../../../../../config/store/constants";
import {InputComponent} from "../../../../Authentication/components/InputELement";
import {handleOnInputChange} from "../../../../../helper/form_inputs/handleOnInputChange";
import * as randomstring from "randomstring";

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: 0 10px 10px -3px #cfcfcf;
    padding: 3rem;
    @media (max-width: 500px) {
        padding: 3rem 1rem;
    }

    .formFieldWrapperSubjectEdit {
        width: 100%;

        > div {
            padding: 0rem;
            border-radius: 0px;
            box-shadow: none;
        }

        .tableOuter {
            padding: 0rem;
            border-radius: 2rem;
            border: 1px solid var(--custom-input-border);

            thead {
                display: none;
            }
            .heading {
                display: none;
            }
            tbody {
                padding: 0.5rem;
                background-color: var(--custom-input-bg);

                tr {
                    border-bottom: 0.1px solid var(--custom-border-color);
                    td {
                        padding: 0.5rem 0rem;

                        button {
                            width: 5rem;
                            font-weight: 600;
                            font-size: 1rem;
                            background-color: transparent;
                            letter-spacing: 0.5px;
                            border: none;
                            img {
                                filter: invert(0);
                                width: 1.2rem;
                                height: 1.2rem;
                                object-fit: cover;
                            }
                            ::focus-visible {
                                border: none;
                                outline: none;
                            }
                        }
                    }
                    :last-child {
                        border-bottom: none;
                    }
                }

                td:last-child {
                    width: 7vw;
                }
            }
        }
    }

    .formFieldWrapper {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
            @media (max-width: 1095px) {
                justify-content: flex-end;
            }

            .inputOuter.w-38 {
                width: 82%;
                @media (max-width: 1420px) {
                    width: 80%;
                }
                @media (max-width: 1328px) {
                    width: 78%;
                }
                @media (max-width: 1251px) {
                    width: 76%;
                }
                @media (max-width: 1188px) {
                    width: 72%;
                }
                @media (max-width: 1095px) {
                    width: 100%;
                }

                .spinnerWrapper {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                }
            }

            .textarea {
                width: 100%;
            }
        }

        select {
            width: 100%;
            height: 3rem;
            text-indent: 10px;
            background: var(--custom-input-bg);
            border-radius: 25px;
            outline: none;
            background-image: none;
            overflow: hidden;
            border: 1px solid var(--custom-input-border);

            option {
                border-radius: 25px;
                width: 32%;
                background: var(--custom-input-bg);
            }
        }

        .actionButtonWrapper {
            align-self: end;
            justify-self: end;

            button {
                @media (max-width: 500px) {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }
`;

const dataFieldsMapping = {
    subjects: (data) => {
        const message = "You need to add at least 3 subjects";
        return {
            status: data?.length >= 3,
            message,
        };
    },
};

const StepFourContainer = forwardRef(({jumpToStep}, ref) => {
    const currentStep = useRef(4);
    const [data, setData] = useState([]);
    const [customSubjectName, setCustomSubjectName] = useState('')
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [availableSubjects, setAvailableSubjects] = useState([]);
    const {profile} = useContext(GlobalUserProfileContext);
    const [isFetchingSubjects, setIsFetchingSubjects] = useState(false);

    const fetchAndParseDataFromAPI = () => {
        const endpoint = "/student/admission/preference/subjects";
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data?.results);
            })
            .catch((err) => {
                notifyFailure("Error in fetching available subjects");
                notifyApiErrorMessage(err);
            });
    };

    const fetchAvailableSubjects = async () => {
        const endpoint = "/student/admission/preference/city_campus";
        setIsFetchingSubjects(true);
        await axiosServerInstance()
            .get(endpoint)
            .then(async (response) => {
                let selectedCampus = response.data?.results[0]?.campus_id;
                if (selectedCampus) {
                    let endpoint = `/utils/student/preference/fetch_subjects_available_in_campus/?campus_id=${selectedCampus}`;
                    await axiosServerInstance()
                        .get(endpoint)
                        .then((response) => {
                            setAvailableSubjects([...parseArrayToSelectValues(response.data), {
                                id: "others",
                                name: "Others"
                            }]);
                            if (!response?.data?.length) {
                                setSelectedSubject(response.data[0].id.toString());
                            }
                        })
                        .catch((err) => {
                            notifyFailure("Error in fetching available subjects");
                            notifyApiErrorMessage(err);
                        });
                }
            })
            .catch((err) => {
                notifyFailure("Error in fetching available subjects");
                notifyApiErrorMessage(err);
            });
        setIsFetchingSubjects(false);
    };

    const handleAddSubject = () => {
        if (!selectedSubject) {
            notifyFailure("Kindly select a subject");
            return;
        }
        let requestData;
        if (selectedSubject !== "others") {
            requestData = {
                subject_name: availableSubjects.find(
                    (data) => data?.id === Number.parseInt(selectedSubject)
                )?.name,
                subject_id: selectedSubject.toString(),
            };
        } else {
            if (!customSubjectName) {
                return;
            }
            requestData = {
                subject_name: customSubjectName,
                subject_id: "CUSTOM-SUBJECT-" + randomstring.generate(12),
            };
        }
        const endPoint = "/student/admission/preference/subjects";
        axiosServerInstance()
            .post(endPoint, requestData)
            .then((response) => {
                setSelectedSubject(null);
                setCustomSubjectName('')
                fetchAndParseDataFromAPI();
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
    };

    const computeDataAndSendToServer = async () => {
        let status = true;
        if (profile?.account_data?.admission_application_stage < 6) {
            let endpoint = "/student/admission/information";
            await axiosServerInstance()
                .patch(endpoint, {
                    admission_application_stage: currentStep.current + 1,
                })
                .then((response) => {
                    status = true;
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                    status = false;
                });
        }
        return status;
    };

    useEffect(() => {
        fetchAndParseDataFromAPI();
        fetchAvailableSubjects();
    }, []);

    useImperativeHandle(ref, () => ({
        async isValidated() {
            const {status, message} = multiStepFormValidator(dataFieldsMapping, data);
            if (!status) {
                notifyFailure(message);
            } else {
                return await computeDataAndSendToServer();
            }
            return status;
        },
    }));

    return (
        <StyledComponent>
            <FormFieldsWrapper className="formFieldWrapper" autocomplete="off">
                <div className="formfieldInner">
                    <div className="inputOuter w-38">
                        <label>Subject</label>
                        {isFetchingSubjects ? (
                            <div className={"spinnerWrapper"}>
                                <Spinner size={1}/>
                            </div>
                        ) : (
                            <>
                                <select
                                    value={selectedSubject}
                                    onChange={(e) => {
                                        setSelectedSubject(e.target.value);
                                    }}
                                >
                                    {availableSubjects.map((subject) => (
                                        <option
                                            key={subject?.id}
                                            selected={subject?.id === selectedSubject}
                                            value={subject?.id}
                                        >
                                            {subject?.name}
                                        </option>
                                    ))}
                                </select>
                                {selectedSubject === "others" &&
                                <div
                                    className={`inputOuter w-22`}
                                >
                                    <label>Subject Name</label>
                                    <InputComponent
                                        placeholder={""}
                                        type="text"
                                        fontSize={1}
                                        value={customSubjectName}
                                        onChange={(e) => {
                                            setCustomSubjectName(e.target.value)
                                        }}
                                    />
                                </div>
                                }
                            </>

                        )}
                    </div>
                    <div className="actionButtonWrapper">
                        <Button
                            disabled={(!selectedSubject || (selectedSubject === "others" & !customSubjectName))}
                            fontSize={1}
                            borderRadius={2.4}
                            paddingTopBottom={0.6}
                            paddingLeftRight={3.5}
                            onClick={(e) => {
                                e.preventDefault();
                                handleAddSubject();
                            }}
                        >
                            + ADD
                        </Button>
                    </div>
                </div>
            </FormFieldsWrapper>
            <SizedBox height={1}/>
            {data?.length ? (
                <div className="formFieldWrapperSubjectEdit">
                    <SubjectsTable data={data} callBackFetchData={fetchAndParseDataFromAPI}/>
                </div>
            ) : null}
        </StyledComponent>
    );
});

export default StepFourContainer;
