import React, {createContext, useEffect, useState} from "react";
import {StatusDetailHistory} from "./StatusDetailHistory";
import {StatusDetailBadges} from "./StatusDetailsBadges";
import {useParams} from "react-router-dom";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../helper/notifications/notifyApiErrorMessage";

export const StudentStatusDetailsContext = createContext({})
export const StatusDetailContainer = () => {
    const [studentAdmissionStatusHistoryData, setStudentAdmissionStatusHistoryData] = useState()
    const {id: studentID} = useParams()

    const fetchAdmissionHistoryDataFromAPI = async () => {
        const endpoint = `/pro/students/${studentID}/admission/status/history/all`;
        return await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setStudentAdmissionStatusHistoryData(response.data.results)
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
            });
    }

    useEffect(() => {
        fetchAdmissionHistoryDataFromAPI()
    }, [studentID])


    return (
        <StudentStatusDetailsContext.Provider value={{
            studentAdmissionStatusHistoryData,
            fetchAdmissionHistoryDataFromAPI
        }}>
            <StatusDetailBadges/>
            <StatusDetailHistory/>
        </StudentStatusDetailsContext.Provider>
    );
};
