import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import {useLocation, useNavigate} from "react-router-dom";

import {Spinner} from "../../Global/graphical/Spinner";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {axiosServerInstance} from "../../../config/api/axios";
import {fetchUserProfile} from "../../../config/store/actions";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";

const fields = {
    employee_id: "Employee ID",
    password: "Password"
}
export const LoginRolesForm = ({renderPage, setRenderPage}) => {
    const [data, setData] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const validateFields = () => {
        let state = true;
        for (let field in fields) {
            if (!data[field]) {
                notifyFailure(`${fields[field]} is required`);
                state = false;
            }
        }
        return state;
    };

    const handleProceedLogin = async () => {
        if (!validateFields()) {
            return;
        }
        setShowSpinner(true);
        axiosServerInstance()
            .post("/auth/login_as_an_employee/", data)
            .then((response) => {
                const {refresh, access} = response.data?.token;
                window.localStorage.setItem("access_token", access);
                window.localStorage.setItem("refresh_token", refresh);
                dispatch(fetchUserProfile);
                navigate("/", {replace: true});
            })
            .catch((err) => {
                setShowSpinner(false);
                notifyApiErrorMessage(err);
            });
    };

    return (
        <FormComponent>
            <label>Enter Your Employee ID</label>
            <InputComponent
                placeholder={""}
                type="text"
                height={2.5}
                onChange={(e) => handleOnInputChange(e, "employee_id", setData, data)}
            />
            <SizedBox height={1.5}/>
            <label>Enter Password</label>
            <InputComponent
                placeholder={""}
                type="password"
                height={2.5}
                onChange={(e) => handleOnInputChange(e, "password", setData, data)}
            />
            <SizedBox height={1.5}/>
            <AuthButtonContainer>
                {!showSpinner ? (
                    <>
                        <Button
                            fontWeight={700}
                            fontSize={1}
                            maxWidth={15}
                            borderRadius={1.5}
                            paddingTopBottom={0.6}
                            onClick={(e) => {
                                e.preventDefault();
                                handleProceedLogin();
                            }}
                        >
                            LOGIN
                        </Button>
                    </>
                ) : (
                    <Spinner size={1.5}/>
                )}
            </AuthButtonContainer>
        </FormComponent>
    );
};
