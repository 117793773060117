import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { MainWrapper } from "../../../Global/MainWrapper";
import { Button } from "../../../Global/Button";
import { axiosServerInstance } from "../../../../config/api/axios";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { Spinner } from "../../../Global/graphical/Spinner";

const StyledComponent = styled.div`
  .searchActions {
    @media (max-width: 1250px) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .backbutton {
    position: absolute;
    top: 90%;

    button {
      background-color: transparent;
      border: 1px solid var(--custom-btn-bg);
      color: var(--custom-btn-bg);
      padding: 0.6rem 4rem;
    }
  }
`;

const headerData = ["SUBJECT", "TEACHER"];
export const ViewSubject = () => {
  const [tableBodyData, setTableBodyData] = useState([]);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { id: studentID } = useParams();
  const [showSpinnerFetchingData, setShowSpinnerFetchingData] = useState(false);

  const fetchAndParseDataFromAPI = async () => {
    setShowSpinnerFetchingData(true);
    const endpoint = `/super_admin/students/${studentID}/detail/`;
    await axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setData(response?.data);
      })
      .catch((err) => {
        notifyFailure("Error in fetching student personal info");
      });
    setShowSpinnerFetchingData(false);
  };

  const handleOnClickBack = () => {
    navigate("/superadmin/scholarship", { replace: true });
  };
  useEffect(() => {
    if (data) {
      setTableBodyData(
        (data?.subjects_teachers || []).map((item) => {
          return {
            data: [
              {
                type: "text",
                value: item?.subject_name,
              },
              {
                type: "text",
                value: item?.teacher_name,
              },
            ],
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    fetchAndParseDataFromAPI();
  }, [studentID]);
  return (
    <MainWrapper pageTitle={"SUBJECT/TEACHER"}>
      {!showSpinnerFetchingData ? (
        tableBodyData && (
          <StyledComponent>
            <TableComponent headerData={headerData} bodyData={tableBodyData} />
            <div className='backbutton'>
              <Button
                fontSize={1}
                borderRadius={2.4}
                maxWidth={15}
                paddingTopBottom={0.6}
                onClick={handleOnClickBack}
              >
                BACK
              </Button>
            </div>
          </StyledComponent>
        )
      ) : (
        <div className={"spinnerWrapper"}>
          <Spinner size={2} />
        </div>
      )}
    </MainWrapper>
  );
};
