import React from "react";
import styled from "styled-components";
import { AcademicInformationForm } from "./components/AcademicInformationForm";

const StyledComponent = styled.div`
    .actionButtonWrapper {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
    }
`;
export const AcamedinInformationContainer = () => {
    return (
        <StyledComponent>
            <div>
                <AcademicInformationForm />
            </div>
        </StyledComponent>
    );
};
