import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {GlobalUserProfileContext} from "../../../App";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {Spinner} from "../../Global/graphical/Spinner";
import {useNavigate} from "react-router-dom";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {useDispatch} from "react-redux";
import {fetchUserProfile} from "../../../config/store/actions";

export const CNICVerificationForm = () => {
    const [data, setData] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProceed = async () => {
        setIsProcessing(true);
        let status = true;
        await axiosServerInstance()
            .post("/utils/student/check_if_exists_by_cnic/", data)
            .then((response) => {
                if (response.data.status === false) {
                    status = true;
                } else {
                    notifyFailure("The CNIC is already registered with an account");
                    status = false;
                }
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                status = false;
            });
        if (status) {
            let endpoint = "/student/admission/information";
            await axiosServerInstance()
                .patch(endpoint, data)
                .then((response) => {
                    notifySuccess("Success");
                    dispatch(fetchUserProfile);
                    navigate("/", {replace: true});
                })
                .catch((err) => {
                    notifyApiErrorMessage(err);
                });
        }
        setIsProcessing(false);
    };

    return (
        <FormComponent>
            <div>
                <label>CNIC/CRC/B-Form</label>
                <InputComponent
                    placeholder={""}
                    type="text"
                    value={data?.cnic}
                    onChange={(e) => handleOnInputChange(e, "cnic", setData, data)}
                />
                <SizedBox height={2.5}/>
            </div>
            <AuthButtonContainer>
                {isProcessing ? (
                    <Spinner size={1.5}/>
                ) : (
                    <>
                        <Button
                            fontWeight={700}
                            fontSize={1}
                            maxWidth={15}
                            borderRadius={1.5}
                            paddingTopBottom={0.8}
                            onClick={(e) => {
                                e.preventDefault();
                                handleProceed();
                            }}
                        >
                            VERIFY
                        </Button>
                    </>
                )}
            </AuthButtonContainer>
        </FormComponent>
    );
};
