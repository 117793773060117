import { AuthMainContainer } from "../components/AuthMainContainer";
import { GoogleSignUpForm } from "./Form";

const GoogleSignUpContainer = () => {
  return (
    <AuthMainContainer mode={"google_signup"}>
      <GoogleSignUpForm />
    </AuthMainContainer>
  );
};

export default GoogleSignUpContainer;
