import {TableComponent} from "../../../../graphical/table/Container";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {notifySuccess} from "../../../../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";

export const DegreeInstitutionsTable = ({data, callBackFetchData, handleEdit}) => {
    const headerData = ['institution', '', '']

    const onClickDelete = (id) => {
        const endpoint = `/student/admission/degree/institutions/${id}/`
        axiosServerInstance()
            .delete(endpoint)
            .then((response) => {
                notifySuccess("Success")
                callBackFetchData()
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const onClickEdit = (id) => {
        handleEdit(id)
    }
    const fetchTableData = () => {
        return (
            (data?.institutions).map((institution, i) => {
                return {
                    id: institution.id,
                    data: [
                        {
                            type: "text",
                            value: institution.name,
                        },
                        {
                            type: "edit"
                        },
                        {
                            type: "delete"
                        },
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchTableData()}
            actionEdit={{
                edit: onClickEdit,
            }}
            actionDelete={{
                delete: onClickDelete,
            }}
        />
    )
}