import {TableComponent} from "../../../../graphical/table/Container";
import {axiosServerInstance} from "../../../../../../config/api/axios";
import {notifySuccess} from "../../../../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../../../../helper/notifications/notifyApiErrorMessage";
import {STUDENT_ADMISSION_STATUS_TYPES, STUDENT_RESULT_TYPES} from "../../../../../../config/store/constants";
import {useContext} from "react";
import {GlobalUserProfileContext} from "../../../../../../App";

export const ResultAnwaitedSubjectsTable = ({data, callBackFetchData, handleEdit}) => {
    const headerData = ['subject', '', '']
    const {profile} = useContext(GlobalUserProfileContext);
    const onClickDelete = (id) => {
        const endpoint = `/student/admission/degree/result_awaited/subjects/${id}/`
        axiosServerInstance()
            .delete(endpoint)
            .then((response) => {
                notifySuccess("Success")
                callBackFetchData()
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const onClickEdit = (id) => {
        handleEdit(id, STUDENT_RESULT_TYPES.AWAITED)
    }

    const fetchResultAnnouncedSubjectTableData = () => {
        return (
            (data?.result_awaited?.subjects).filter(subject => subject.grade === 'Result Awaited').map((subject, i) => {
                return {
                    id: subject.id,
                    data: [
                        {
                            type: "text",
                            value: subject.name,
                        },
                        ...((![STUDENT_ADMISSION_STATUS_TYPES.ENROLLED].includes(profile?.account_data?.admission_status?.admission_status)) ? [{
                            type: "edit"
                        },
                            {
                                type: "delete"
                            },
                        ] : [])
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchResultAnnouncedSubjectTableData()}
            actionEdit={{
                edit: onClickEdit,
            }}
            actionDelete={{
                delete: onClickDelete,
            }}
        />
    )
}