import styled from "styled-components";
import { MainContainer } from "./MainContainer";
import { createContext, useContext, useEffect, useState } from "react";
import { NavigationContainer } from "./navigation/Container";
import SideNavBar from "./sideNavBar/SideNavBar";
import { MainContentContainer } from "./content/MainContentContainer";

const StyledContainer = styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    min-height: 100vh;
    align-items: stretch;
    width: 100vw;
    @media (max-width:800px){
        flex-direction:  column;
    }
`;
export const NavigationContext = createContext({});
export const MainWrapper = ({ ...props }) => {
    const [mobileSubNavEnabled, setMobileSubNavEnabled] = useState(false);
    const { pageTitle } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <StyledContainer >
            <NavigationContext.Provider
                value={{
                    mobileSubNavEnabled,
                    setMobileSubNavEnabled,
                }}
            >
                <SideNavBar />
                <MainContainer>
                    <NavigationContainer pageTitle={pageTitle} />
                    <MainContentContainer>{props.children}</MainContentContainer>
                </MainContainer>
            </NavigationContext.Provider>
        </StyledContainer>
    );
};
