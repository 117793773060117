export const StudentData = [
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    view1: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    Personal_Info: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    Personal_Info: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    Personal_Info: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    Personal_Info: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
  {
    Edit: "edit",
    Student_ID: "1234",
    Student_Name: "Tayeb Jaffer",
    Gender: "Male",
    Personal_Info: "view1",
    City: "Lahore",
    Branch_Campus: "Johar town",
    Subjects: "view2",
  },
];
export const OneStudentData = [
  {
    Edit: "edit",
    FULL_NAME: "Tayyab Jaffar",
    CNIC: "35202-333333-3",
    Gender: "Male",
    City: "Lahore",
    Father_Name: "Jaffar Khan",
    MOBILE_NO: "03333333333",
    Address: "Skindar Block",
  },
];
export const OneSubject = [
  {
    Edit: "edit",
    SUBJECT: "English",
    subject:["English","English","English"],
    TEACHER: "Sir Fakhir",
  },
];
