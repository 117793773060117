import React from "react";
import WidgetHome from "./components/WidgetHome";

export const HomeWidgetContainer = ({
  handleOnClickButton,
  WidgetButtonName,
  widgetTitle,
  WidgetBgColor,
  widgetTitleColor,
  WidgetButtonColor
}) => {
  return (
    <WidgetHome
      widgetTitle={widgetTitle}
      WidgetButtonName={WidgetButtonName}
      handleOnClickButton={handleOnClickButton}
      WidgetButtonColor={WidgetButtonColor}
      WidgetBgColor={WidgetBgColor}
      widgetTitleColor={widgetTitleColor}
    />
  );
};
