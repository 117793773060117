import React from "react";

export const StudentsCount = ({ head, count }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.5rem",
        // border: "1px solid blue",
        minWidth: "10%",
        height: "3rem",
        // borderRadius: "30px",
        // backgroundColor: "#1c4489",
        color: "black",
      }}
    >
      <div className="" style={{ textAlign: "center", margin: "0.5rem" }}>
        <span style={{ fontSize: "20px" }}>{head}: </span>
        <span
          style={{ fontWeight: "bold", color: "#1c4489", fontSize: "20px" }}
        >
          {count ? count : 0}
        </span>
      </div>
    </div>
  );
};
