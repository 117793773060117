import { Route, Routes } from "react-router-dom";
import { ProtectedRouteComponent } from "../../components/Global/ProtectedRouteComponent";
import { authPagesConditions } from "../../conditions";
import { AnalyticsContainer } from "../../components/school_head/analytics/Container";
import { LogsContainer } from "../../components/school_head/logs/Container";
import { ScholarshipContainer } from "../../components/school_head/scholarship/Container";
import { ViewSubject } from "../../components/school_head/scholarship/viewSubject/ViewSubject";
import { StudentsSchoolHeadContainer } from "../../components/school_head/students/Container";
import { ViewStudentSubject } from "../../components/school_head/students/viewSubject/ViewSubject";
import { ViewPersonalInfo } from "../../components/school_head/students/viewPersonalInfo/ViewPersonalInfo";
import { FeeManagementContainer } from "../../components/school_head/feeManagement/Container";
import { FacultyContainer } from "../../components/school_head/faculity/Container";

export const SchoolHeadRoutes = () => {
    return (
        <Routes>
            <Route
                path="/schoolhead/students"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<StudentsSchoolHeadContainer />}
                    />
                }
            />
            <Route
                path="/schoolhead/students/:id/subject/"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ViewStudentSubject />}
                    />
                }
            />
            <Route
                path="/schoolhead/students/:id/personalinfo/"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ViewPersonalInfo />}
                    />
                }
            />
            <Route
                path="/schoolhead/analytics"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<AnalyticsContainer />}
                    />
                }
            />

            <Route
                path="/schoolhead/logs"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<LogsContainer />}
                    />
                }
            />
            <Route
                path="/schoolhead/feemanagement"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<FeeManagementContainer />}
                    />
                }
            />
            <Route
                path="/schoolhead/scholarship"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ScholarshipContainer />}
                    />
                }
            />
            <Route
                path="/schoolhead/scholarship/:id"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<ViewSubject />}
                    />
                }
            />
            <Route
                path="/schoolhead/faculty"
                exact={true}
                element={
                    <ProtectedRouteComponent
                        conditions={authPagesConditions}
                        elementToRenderOnPass={<FacultyContainer />}
                    />
                }
            />
        </Routes>
    );
};
