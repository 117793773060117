import styled from 'styled-components'
import { TableComponent } from '../../../Global/graphical/table/Container'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import search from '../../../../static/images/table/Path 123.svg'
import Edit from '../../../../static/images/table/Group 46.svg'
import { ModalComponent } from '../../../Global/graphical/Modal'
import { EditMaxCapacity } from './EditMaxCapacity'
import { SearchBar } from '../../../Global/searchBar/SearchBar'
import { GlobalUserProfileContext } from '../../../../App'
import { axiosServerInstance } from '../../../../config/api/axios'
import { notifyFailure } from '../../../../helper/notifications/notifyFailure'
import { SizedBox } from '../../../Global/SizedBox'
import { Spinner } from '../../../Global/graphical/Spinner'
import { notifySuccess } from '../../../../helper/notifications/notifySuccess'
import { notifyApiErrorMessage } from '../../../../helper/notifications/notifyApiErrorMessage'
import { EditRemarks } from './EditRemarks'
import { fa } from 'faker/lib/locales'
import { formatQueryDictToQueryParams } from '../../../../helper/formatters/formatDictToParams'
import { ListFilterComponentByRegionCityCampus } from '../../global/ListFilterComponentByRegionCityCampus'
import { RefreshAuthUserAffiliatedObjectsButtonComponent } from '../../../Global/utils/RefreshAuthUserAffiliatedObjectsButton'
import FilterByBatch from '../../../Global/filters/FilterByBatch'

const StyledComponent = styled.div`
    .tablevms tbody td,
    .tablevms thead th {
        min-width: 60px;
    }

    .searchActions {
        @media (max-width: 1380px) {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    .capacityedit {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 1rem;
        }
    }
`
const headerData = [
    'ID',
    'NAME',
    'REGION',
    'CITY',
    'CAMPUS',
    'CLASS',
    'SECTION',
    'Subject',
    'MINIMUM CAPACITY',
    'MAXIMUM CAPACITY',
    'DATE / TIME',
    'REMARKS',
    '',
]
export const FaculityListComponents = () => {
    const [showEditMaxCapacityModal, setShowEditMaxCapacityModal] =
        useState(false)
    const [showEditRemarksModal, setShowEditRemarksModal] = useState(false)
    const [tableBodyData, setTableBodyData] = useState([])
    const [searchParam, setSearchParam] = useState('')
    const { profile } = useContext(GlobalUserProfileContext)
    const isMax = useRef(false)
    const inputValCapacity = useRef(null)
    const inputValRemarks = useRef(null)
    const itemInQuestion = useRef(null)
    const [facultyListData, setFacultyListData] = useState([])
    const [showSpinner, setShowSpinner] = useState(false)
    const [filterData, setFilterData] = useState({})
    const [batchFilter, setBatchFilter] = useState({ batch: '' })

    const fetchData = async () => {
        setShowSpinner(true)
        const formattedFilterData = {}
        if (filterData['selectedRegion']) {
            formattedFilterData['region_id'] = filterData['selectedRegion']
        }

        if (filterData['selectedCity']) {
            formattedFilterData['city_id'] = filterData['selectedCity']
        }

        if (filterData['selectedCampus']) {
            formattedFilterData['campus_id'] = filterData['selectedCampus']
        }
        // const endpoint = `/super_admin/faculty/all/?${formatQueryDictToQueryParams(
        //     { search: searchParam, ...formattedFilterData }
        // )}`

        const endpoint = `/super_admin/faculty/all/?${formatQueryDictToQueryParams(
            { search: searchParam, ...formattedFilterData }
        )}${batchFilter?.batch && `&batch=${batchFilter.batch}`}`

        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setFacultyListData(response.data?.results)
            })
            .catch(err => {
                notifyFailure('Error in fetching facility data')
            })
        setShowSpinner(false)
    }

    const handleCancelEditCapacity = close => {
        setShowEditMaxCapacityModal(false)
    }

    const handleSaveEditCapacity = value => {
        const endpoint = `/shared/campuses/subjects_teachers/${itemInQuestion.current}/`
        let requestData
        if (isMax.current) {
            requestData = { max_capacity: value }
        } else {
            requestData = { min_capacity: value }
        }
        axiosServerInstance()
            .patch(endpoint, requestData)
            .then(response => {
                setShowEditMaxCapacityModal(false)
                fetchData()
                notifySuccess('Operation Success')
            })
            .catch(err => {
                setShowEditMaxCapacityModal(false)
                notifyApiErrorMessage(err)
            })
    }

    const handleSaveEditRemarks = async value => {
        const endpoint = `/shared/campuses/subjects_teachers/${itemInQuestion.current}/`
        let requestData = { remarks: value }
        await axiosServerInstance()
            .patch(endpoint, requestData)
            .then(response => {
                fetchData()
                notifySuccess('Operation Success')
            })
            .catch(err => {
                notifyApiErrorMessage(err)
            })
        setShowEditRemarksModal(false)
    }

    const onClickEdit = async id => {}

    useEffect(() => {
        fetchData()
    }, [batchFilter])

    useEffect(() => {
        if (Object.values(filterData).length) {
            fetchData()
        }
    }, [filterData])

    useEffect(() => {
        if (facultyListData) {
            setTableBodyData(
                facultyListData.map((data, i) => {
                    return {
                        id: data.id,
                        data: [
                            {
                                type: 'text',
                                value: i + 1,
                            },
                            {
                                type: 'text',
                                value: data.teacher_name,
                            },
                            {
                                type: 'text',
                                value: data.region_name,
                            },
                            {
                                type: 'text',
                                value: data.city_name,
                            },
                            {
                                type: 'text',
                                value: data.campus_name,
                            },
                            {
                                type: 'text',
                                value: data.class_name,
                            },
                            {
                                type: 'text',
                                value: '',
                            },
                            {
                                type: 'text',
                                value: data.subject_name,
                            },

                            {
                                type: 'text',
                                value: (
                                    <div className='capacityedit'>
                                        <span>
                                            {data.min_capacity || 'Nil'}
                                        </span>
                                        <img
                                            onClick={() => {
                                                itemInQuestion.current = data.id
                                                isMax.current = false
                                                inputValCapacity.current =
                                                    data.min_capacity
                                                setShowEditMaxCapacityModal(
                                                    true
                                                )
                                            }}
                                            src={Edit}
                                            alt='edit'
                                        />
                                    </div>
                                ),
                            },
                            {
                                type: 'text',
                                value: (
                                    <div className='capacityedit'>
                                        <span>
                                            {data.max_capacity || 'Nil'}
                                        </span>
                                        <img
                                            onClick={() => {
                                                itemInQuestion.current = data.id
                                                isMax.current = true
                                                inputValCapacity.current =
                                                    data.max_capacity
                                                setShowEditMaxCapacityModal(
                                                    true
                                                )
                                            }}
                                            src={Edit}
                                            alt='edit'
                                        />
                                    </div>
                                ),
                            },
                            {
                                type: 'text',
                                value: '',
                            },
                            {
                                type: 'text',
                                value: data.remarks || 'Nil',
                            },
                            {
                                type: 'text',
                                value: (
                                    <div className='capacityedit'>
                                        <img
                                            onClick={() => {
                                                itemInQuestion.current = data.id
                                                inputValRemarks.current =
                                                    data.remarks
                                                setShowEditRemarksModal(true)
                                            }}
                                            src={Edit}
                                            alt='edit'
                                        />
                                    </div>
                                ),
                            },
                        ],
                    }
                })
            )
        }
    }, [facultyListData])

    return (
        <StyledComponent>
            <div className='searchActions'>
                <div className='selectOuter'>
                    <FilterByBatch setBatchFilter={setBatchFilter} />
                </div>
            </div>
            <div className='searchActions'>
                <ListFilterComponentByRegionCityCampus
                    setterCallback={setFilterData}
                />
                <div className='searchOuter'>
                    <input
                        type='search'
                        placeholder='Search by teacher name, subject name, city, region...'
                        value={searchParam}
                        onChange={e => {
                            setSearchParam(e.target.value)
                        }}
                    />
                    <img
                        src={search}
                        alt='search icon'
                        className={'searchIcon'}
                        onClick={() => {
                            fetchData()
                        }}
                    />
                </div>
            </div>
            <RefreshAuthUserAffiliatedObjectsButtonComponent
                callBackOnComplete={fetchData}
            />
            <SizedBox height={1} />
            {!showSpinner ? (
                <TableComponent
                    headerData={headerData}
                    bodyData={tableBodyData}
                    actionEdit={{
                        edit: onClickEdit,
                    }}
                />
            ) : (
                <div className={'spinnerWrapper'}>
                    <SizedBox height={1} />
                    <Spinner size={1.5} />
                    <SizedBox height={1} />
                </div>
            )}

            <ModalComponent
                modalLabel={
                    isMax.current ? 'MAXIMUM CAPACITY' : 'MINIMUM CAPACITY'
                }
                isOpen={showEditMaxCapacityModal}
                callbackCloseModal={() => {
                    setShowEditMaxCapacityModal(false)
                }}
            >
                <EditMaxCapacity
                    label={
                        isMax.current ? 'MAXIMUM CAPACITY' : 'MINIMUM CAPACITY'
                    }
                    inputVal={inputValCapacity.current}
                    callbackCancel={handleCancelEditCapacity}
                    callbackProceed={handleSaveEditCapacity}
                />
            </ModalComponent>
            <ModalComponent
                modalLabel={'REMARKS'}
                isOpen={showEditRemarksModal}
                callbackCloseModal={() => {
                    setShowEditRemarksModal(false)
                }}
            >
                <EditRemarks
                    label={''}
                    inputVal={inputValRemarks.current}
                    callbackCancel={() => {
                        setShowEditRemarksModal(false)
                    }}
                    callbackProceed={handleSaveEditRemarks}
                />
            </ModalComponent>
        </StyledComponent>
    )
}
