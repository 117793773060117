import { useParams } from 'react-router-dom'
import { notifyFailure } from '../../../../../../helper/notifications/notifyFailure'
import { notifyApiErrorMessage } from '../../../../../../helper/notifications/notifyApiErrorMessage'
import {
    extractDataFromObject,
    setDataInObject,
} from '../../../../../../helper/misc'
import { axiosServerInstance } from '../../../../../../config/api/axios'
import React, { useEffect, useRef, useState } from 'react'
import {
    FILE_UPLOAD_PURPOSE,
    STUDENT_DEGREE_TYPES,
    STUDENT_RESULT_TYPES,
    STUDENT_SUBJECT_GRADES_TYPES,
} from '../../../../../../config/store/constants'
import { Button } from '../../../../../Global/Button'
import { multiStepFormValidator } from '../../../../../../helper/form_validators/multiStepFormValidator'
import { FormFieldsWrapper } from '../../../../../Global/graphical/data_input/FormFieldsWrapper'
import { handleOnInputChange } from '../../../../../../helper/form_inputs/handleOnInputChange'
import { parseEnumToSelectValues } from '../../../../../../helper/form_inputs/parseEnumToSelectValues'
import { InputComponent } from '../../../../../Authentication/components/InputELement'
import { ModalComponent } from '../../../../../Global/graphical/Modal'
import { EditSubject } from './components/EditSubjectModal'
import { EditInstitution } from './components/EditInstitutionModal'
import styled from 'styled-components'
import { handleFileUploadSingle } from '../../../../../../helper/fileManagement/handler'
import { DegreeInstitutionsTable } from './components/DegreeInstitutionsTable'
import { ResultAnnouncedSubjectsTable } from './components/ResultAnouncedSubjectsTable'
import { ResultAnwaitedSubjectsTable } from './components/ResultAwaitedSubjectsTable'
import { toast } from 'react-toastify'
import { notifySuccess } from '../../../../../../helper/notifications/notifySuccess'

const StyledComponent = styled.div`
    border-radius: 20px;
    box-shadow: none;

    .formFieldWrapperSubjectEdit {
        width: 100%;
        > div {
            padding: 0rem;
            border-radius: 0px;
            box-shadow: none;
        }
        .tableOuter {
            padding: 0rem;
            border-radius: 2.5rem;
            border: 1px solid var(--custom-input-border);
            thead {
                display: none;
            }
            .heading {
                display: none;
            }
            tbody {
                padding: 0.5rem;
                background-color: var(--custom-input-bg);

                tr {
                    td {
                        padding: 0.4rem 0 0 1rem;

                        button {
                            width: 4rem;
                            font-weight: 600;
                            font-size: 1rem;
                            background-color: transparent;
                            letter-spacing: 0.5px;
                        }
                    }
                }

                td:nth-last-child(2) {
                    width: 7vw !important;
                    padding: 0.4rem 0;
                }
                td:last-child {
                    padding: 0.4rem 0;
                    width: 7vw;
                }
            }
        }
    }
    .formFieldWrapper {
        border-radius: 20px;
        box-shadow: 0 10px 10px -3px #cfcfcf;
        padding: 3rem;

        @media screen and (max-width: 45rem) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media (max-width: 500px) {
            padding: 2rem 1rem;
        }
        .headinginfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 915px) {
                flex-wrap: wrap;
            }
            .updatBtn {
                margin-bottom: 1rem;
            }
        }
        .formTitle {
            font-size: 1.2rem;
            margin-left: 1rem;
            color: var(--custom-Black);
            margin: 1rem 0;
        }

        .formfieldInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .formFieldWrapperSubject {
                width: 100%;
                gap: 1rem;
                display: flex;
                flex-wrap: wrap;
                @media screen and (max-width: 1000px) {
                    justify-content: flex-end;
                }
            }
            .inputOuter.w-50 {
                width: 49.1%;
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-60 {
                width: 64%;
                @media screen and (max-width: 1170px) {
                    width: 48%;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-90 {
                width: 83%;
                @media screen and (max-width: 1390px) {
                    width: 80%;
                }
                @media screen and (max-width: 1242px) {
                    width: 78%;
                }
                @media screen and (max-width: 1167px) {
                    width: 76%;
                }
                @media screen and (max-width: 1105px) {
                    width: 100%;
                }
            }
            .inputOuter.w-32 {
                width: 32%;
                @media screen and (max-width: 1170px) {
                    width: 48%;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-22 {
                width: 22%;
                @media screen and (max-width: 1405px) {
                    width: 48%;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-34 {
                width: 34%;
                @media screen and (max-width: 1170px) {
                    width: 48%;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .inputOuter.w-38 {
                width: 41%;
                @media screen and (max-width: 1470px) {
                    width: 40%;
                }
                @media screen and (max-width: 1417px) {
                    width: 39%;
                }
                @media screen and (max-width: 1236px) {
                    width: 38%;
                }
                @media screen and (max-width: 1170px) {
                    width: 48%;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
            .ResultRadioMode {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                input {
                    display: none;
                    margin: 0 1rem;
                }
                label {
                    border: 2px solid var(--custom-secondary-bg);
                    border-radius: 2.5rem;
                    padding: 0.3rem 1.5rem;
                    @media (max-width: 500px) {
                        padding: 0.2rem 2rem;
                    }
                }
                label.active {
                    background-color: var(--custom-secondary-bg);
                    color: #fff;
                }
            }

            .textarea {
                width: 100%;
            }
            .helperText {
                font-size: 0.8rem;
            }
        }
        select {
            width: 100%;
            height: 3rem;
            text-indent: 10px;
            background: var(--custom-input-bg);
            border-radius: 25px;
            outline: none;
            background-image: none;
            overflow: hidden;
            border: 1px solid var(--custom-input-border);
            option {
                border-radius: 25px;
                width: 32%;
                background: var(--custom-input-bg);
            }
        }
        .inputOuter.profilePicturePickerWrapper {
            position: relative;
            margin-bottom: 2rem;
            input {
                position: absolute;
                opacity: 0;
                left: 0;
                bottom: -2.5rem;
            }
            .inputFile {
                position: absolute;
                bottom: -2.5rem;
                left: 0;
                width: 100%;
                height: 50px;
                border: 1px solid var(--custom-input-border);
                background-color: var(--custom-input-bg);
                border-radius: 2.5rem;

                :after {
                    content: 'Choose File';
                    position: absolute;
                    border: 1px solid var(--custom-btn-bg);
                    right: 10px;
                    color: var(--custom-btn-bg);
                    background-color: #f3f3f2;
                    padding: 0.4rem 1rem;
                    width: 90px;
                    bottom: 14%;
                    font-size: 1rem;
                    border-radius: 2.5rem;
                }
            }
        }
        .profilePicturePickerWrapper {
            height: 3rem;
            margin-top: 0.24rem;
            width: 100%;
        }
        .profilePicturePickerWrapper > div {
            justify-content: space-around;
            gap: 0.5rem;
            .controlsWrapperImage .text {
                color: var(--custom-txt-color);
                font-size: 1rem;
                font-weight: 400;
            }
            .controlsWrapperImage {
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 0.7rem;
            }
            .chooseImageButtonWrapper {
                width: 100%;
                border-radius: 2.5rem;
                padding: 0.2rem;
                border: 1px solid var(--custom-input-border);
                justify-content: flex-end;
                display: flex;
                background-color: var(--custom-input-bg);
            }
            .chooseImageButtonWrapper input {
                height: 46px;
            }
            .chooseImageButtonWrapper button {
                background-color: #f3f3f2;
                color: black;
                width: 150px;
                font-size: 1rem;
                border-radius: 2.5rem;
            }
            .imageWrapper {
                display: none;
            }
        }
        .actionButtonWrapper.degree {
            align-self: center;
        }
        .actionButtonWrapper {
            align-self: end;
            justify-self: end;
            button {
                @media (max-width: 500px) {
                    padding: 0.5rem 2rem;
                }
            }
        }
    }
`

const dataFieldsMapping = {
    BASE: {
        degree_name: 'Degree Type',
        institutions: (data) => {
            const message = 'You need to add at least one institution'
            return {
                status: !!data?.institutions?.length,
                message,
            }
        },
        institution_matriculation: (data) => {
            const message =
                "'Matriculation' degree type supports only one institution"
            return {
                status:
                    (data?.institutions?.length > 1 &&
                        data?.degree_name !==
                            STUDENT_DEGREE_TYPES.MATRICULATION) ||
                    data?.institutions?.length === 1,
                message,
            }
        },
        total_marks_mark_obtained_1: (data) => {
            const message =
                "Total Marks and marks obtained are both needed for 'Matriculation' degree type"
            return {
                status:
                    data?.degree_name !== STUDENT_DEGREE_TYPES.MATRICULATION ||
                    (data?.degree_name === STUDENT_DEGREE_TYPES.MATRICULATION &&
                        data?.total_marks &&
                        data?.marks_obtained),
                message,
            }
        },
        total_marks_mark_obtained_2: (data) => {
            const message =
                "Marks obtained cannot be greater than total marks for 'Matriculation' degree type"
            return {
                status:
                    data?.degree_name !== STUDENT_DEGREE_TYPES.MATRICULATION ||
                    data?.total_marks >= data?.marks_obtained,
                message,
            }
        },
        result_subjects: (data) => {
            const message = 'You need to add at least 8 subjects'
            if (data?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL) {
                const result_awaited_subjects =
                    data?.result_awaited?.subjects || []
                const result_announced_subjects =
                    data?.result_announced?.subjects || []
                const total_subjects_count =
                    result_awaited_subjects.length +
                    result_announced_subjects.length
                return {
                    status: total_subjects_count >= 8,
                    message,
                }
            } else {
                return {
                    status: true,
                    message: null,
                }
            }
        },
    },
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.MATRICULATION] = {
    ...dataFieldsMapping.BASE,
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.O_LEVEL] = {
    ...dataFieldsMapping.BASE,
}

dataFieldsMapping[STUDENT_DEGREE_TYPES.OTHERS] = {
    ...dataFieldsMapping.BASE,
    degree_label: 'Degree Name',
}

export const AcademicInformationForm = () => {
    const [data, setData] = useState({})
    const [files, setFiles] = useState({})
    const [currentDegreeResultType, setCurrentDegreeResultType] = useState(
        STUDENT_RESULT_TYPES.ANNOUNCED
    )
    const [showModalEditInstitution, setShowModalEditInstitution] =
        useState(false)
    const institutionToEdit = useRef(null)
    const [showModalEditSubject, setShowModalEditSubject] = useState(false)
    const subjectToEdit = useRef(null)
    const resultTypeSubjectToEdit = useRef(null)
    const { id: studentID } = useParams()

    const fetchAndParseDataFromAPI = (fieldSpecified) => {
        const dataEndpoint = `/pro/students/${studentID}/admission/degree`
        axiosServerInstance()
            .get(dataEndpoint)
            .then((response) => {
                if (fieldSpecified) {
                    let tempData = {
                        ...data,
                    }
                    const extractedData = extractDataFromObject(
                        response.data,
                        fieldSpecified
                    )
                    const mutatedData = setDataInObject(
                        tempData,
                        extractedData,
                        fieldSpecified
                    )
                    setData(mutatedData)
                } else {
                    const tempData = {
                        ...data,
                        ...response.data,
                    }
                    tempData.total_marks =
                        tempData?.result_announced?.total_marks
                    tempData.marks_obtained =
                        tempData?.result_announced?.marks_obtained
                    tempData.percentage = tempData?.result_announced?.percentage
                    setData(tempData)
                }
                const resultAnnouncedUploadResultData = {
                    url_on_server:
                        response.data?.result_announced?.uploaded_result?.file,
                }
                setFiles({
                    ...files,
                    resultAnnouncedUploadedResult:
                        resultAnnouncedUploadResultData,
                })
            })
            .catch((err) => {
                notifyFailure('Error in fetching data')
                notifyApiErrorMessage(err)
            })
    }

    const handleAddInstitution = async ({ name }) => {
        const requestData = {
            name,
        }
        const endpoint = `pro/students/${studentID}/admission/degree/institutions`
        return await axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                return response?.data
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            })
    }

    const handleAddSubject = async ({ name, grade, owner }) => {
        const requestData = {
            name,
            grade,
        }
        let endpoint
        if (owner === STUDENT_RESULT_TYPES.ANNOUNCED) {
            endpoint =
                data.degree_name === 'O Level'
                    ? `/pro/students/${studentID}/admission/degree/result_announced/subjects`
                    : `/pro/students/${studentID}/admission/degree/result_announced/subjects?remove_subject_validator=True`
        } else {
            endpoint =
                data.degree_name === 'O Level'
                    ? `/pro/students/${studentID}/admission/degree/result_awaited/subjects`
                    : `/pro/students/${studentID}/admission/degree/result_awaited/subjects?remove_subject_validator=True`
        }
        return await axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                notifySuccess('Subject added.')
                return response?.data
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            })
    }

    const callAddSubject = async () => {
        let status = true
        if (data[`subject_name_${currentDegreeResultType.toLowerCase()}`]) {
            if (
                currentDegreeResultType === STUDENT_RESULT_TYPES.ANNOUNCED &&
                !data?.subject_grade
            ) {
                notifyFailure(
                    "Please 'Enter the subject grade for the result announced'"
                )
                status = false
            } else {
                status = !!(await handleAddSubject({
                    name: data[
                        `subject_name_${currentDegreeResultType.toLowerCase()}`
                    ],
                    owner: currentDegreeResultType,
                    grade:
                        currentDegreeResultType === STUDENT_RESULT_TYPES.AWAITED
                            ? 'Result Awaited'
                            : data?.subject_grade,
                }))
                if (status) {
                    const tempData = { ...data }
                    tempData[
                        `subject_name_${currentDegreeResultType.toLowerCase()}`
                    ] = null
                    tempData['subject_grade'] = null
                    setData({
                        ...tempData,
                    })
                }
            }
        }
        return status
    }

    const callAddInstitution = async () => {
        let status = true
        if (data?.institution) {
            status = !!(await handleAddInstitution({
                name: data?.institution,
            }))
            if (status) {
                const tempData = { ...data }
                tempData['institution'] = null
                setData(tempData)
            }
        }
        return status
    }

    const initiateEditInstitution = (id) => {
        institutionToEdit.current = id
        setShowModalEditInstitution(true)
    }

    const finalizeEditInstitution = (data) => {
        const endpoint = `/pro/students/${studentID}/admission/degree/institutions/${institutionToEdit.current}/`
        axiosServerInstance()
            .patch(endpoint, data)
            .then((response) => {
                fetchAndParseDataFromAPI('institutions')
                setShowModalEditInstitution(false)
            })
            .catch((err) => {
                notifyFailure('Error in editing institution')
                notifyApiErrorMessage(err)
            })
    }

    const initiateEditSubject = (id, type) => {
        subjectToEdit.current = id
        resultTypeSubjectToEdit.current = type
        setShowModalEditSubject(true)
    }

    const finalizeEditSubject = (data) => {
        let endpoint
        if (
            resultTypeSubjectToEdit.current === STUDENT_RESULT_TYPES.ANNOUNCED
        ) {
            endpoint =
                data.degree_name === 'O Level'
                    ? `/pro/students/${studentID}/admission/degree/result_announced/subjects/${subjectToEdit.current}/`
                    : `/pro/students/${studentID}/admission/degree/result_announced/subjects/${subjectToEdit.current}/?remove_subject_validator=True`
        } else {
            endpoint =
                data.degree_name === 'O Level'
                    ? `/pro/students/${studentID}/admission/degree/result_awaited/subjects/${subjectToEdit.current}/`
                    : `/pro/students/${studentID}/admission/degree/result_awaited/subjects/${subjectToEdit.current}/?remove_subject_validator=True`
        }
        axiosServerInstance()
            .patch(endpoint, data)
            .then((response) => {
                if (
                    resultTypeSubjectToEdit.current ===
                    STUDENT_RESULT_TYPES.ANNOUNCED
                ) {
                    fetchAndParseDataFromAPI('result_announced.subjects')
                } else {
                    fetchAndParseDataFromAPI('result_awaited.subjects')
                }
                setShowModalEditSubject(false)
            })
            .catch((err) => {
                notifyFailure('Error in editing subject')
                notifyApiErrorMessage(err)
            })
    }

    const computeDataAndSendToServer = async () => {
        let status = true
        const requestConfig = [
            {
                requestData: {
                    degree_name: data?.degree_name,
                    degree_label: data?.degree_label,
                },
                endpoint: `/pro/students/${studentID}/admission/degree`,
            },
            {
                requestData: {
                    total_marks:
                        data?.degree_name ===
                            STUDENT_DEGREE_TYPES.MATRICULATION &&
                        data?.total_marks,
                    marks_obtained:
                        data?.degree_name ===
                            STUDENT_DEGREE_TYPES.MATRICULATION &&
                        data?.marks_obtained,
                    percentage:
                        data?.degree_name ===
                            STUDENT_DEGREE_TYPES.MATRICULATION &&
                        data?.percentage,
                },
                endpoint: `/pro/students/${studentID}/admission/degree/result_announced`,
            },
        ]
        for (let config of requestConfig) {
            if (status) {
                const { requestData, endpoint } = config
                await axiosServerInstance()
                    .patch(endpoint, requestData)
                    .then((response) => {
                        status = true
                    })
                    .catch((err) => {
                        notifyApiErrorMessage(err)
                        status = false
                    })
            }
        }
        if (status) {
            if (files?.resultAnnouncedUploadedResult) {
                const temp = {
                    ...files?.resultAnnouncedUploadedResult,
                    to_be_deleted_file_id:
                        data?.result_announced?.uploaded_result?.id,
                }
                if (temp?.to_be_uploaded_buffer) {
                    const uploadedFileData = await handleFileUploadSingle(temp)
                    console.log(uploadedFileData)
                    const endpoint = `/pro/students/${studentID}/admission/degree/result_announced`
                    await axiosServerInstance()
                        .patch(endpoint, {
                            uploaded_result: uploadedFileData?.id,
                        })
                        .then((response) => {
                            status = true
                        })
                        .catch((err) => {
                            notifyApiErrorMessage(err)
                            status = false
                        })
                }
            }
        }

        fetchAndParseDataFromAPI()
        return status
    }

    const performValidationAndSave = async () => {
        const { status, message } = multiStepFormValidator(
            data?.degree_name
                ? dataFieldsMapping[data?.degree_name]
                : dataFieldsMapping.BASE,
            data
        )
        let tempStatus = status
        tempStatus === 0 ? (tempStatus = false) : (tempStatus = true)

        console.log(tempStatus)

        if (!tempStatus) {
            notifyFailure(message)
        } else {
            return await computeDataAndSendToServer()
        }
        return tempStatus
    }

    const UpdateEverything = async () => {
        if (await performValidationAndSave()) {
            toast.success('Result Updated.')
        }
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [currentDegreeResultType])

    const [selectedFileName, setSelectedFileName] = useState('')
    return (
        <StyledComponent tableLength={data?.institutions?.length}>
            <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
                <div className='headinginfo'>
                    <h2 className='formTitle'>ACADEMIC INFORMATION</h2>
                    {/*<div className="updatBtn">*/}
                    {/*    <Button*/}
                    {/*        fontSize={1}*/}
                    {/*        borderRadius={2.4}*/}
                    {/*        maxWidth={10}*/}
                    {/*        paddingTopBottom={0.6}*/}
                    {/*        paddingLeftRight={3}*/}
                    {/*        onClick={()=>{}}*/}
                    {/*    >*/}
                    {/*        UPDATE*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
                <div className='formfieldInner'>
                    <div
                        className='formFieldWrapperSubject degree'
                        autocomplete='off'
                    >
                        <div className='inputOuter w-34'>
                            <label>Degree Name</label>
                            <select
                                value={data?.degree_name}
                                onChange={(e) => {
                                    handleOnInputChange(
                                        e,
                                        'degree_name',
                                        setData,
                                        data
                                    )
                                }}
                            >
                                {parseEnumToSelectValues(
                                    STUDENT_DEGREE_TYPES
                                ).map((type) => (
                                    <option
                                        selected={type === data?.gender}
                                        value={type}
                                    >
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {data?.degree_name && (
                            <>
                                {data?.degree_name ===
                                    STUDENT_DEGREE_TYPES.OTHERS && (
                                    <div className='inputOuter w-22'>
                                        <label>Degree Name</label>
                                        <InputComponent
                                            placeholder={''}
                                            type='text'
                                            fontSize={0.7}
                                            value={data?.degree_label}
                                            onChange={(e) => {
                                                handleOnInputChange(
                                                    e,
                                                    'degree_label',
                                                    setData,
                                                    data
                                                )
                                            }}
                                        />
                                    </div>
                                )}
                                <div
                                    className={`inputOuter ${
                                        data?.degree_name ===
                                        STUDENT_DEGREE_TYPES.OTHERS
                                            ? 'w-22'
                                            : data?.degree_name ===
                                              STUDENT_DEGREE_TYPES.O_LEVEL
                                            ? 'w-38'
                                            : 'w-60'
                                    }`}
                                >
                                    <label>Institution</label>
                                    <InputComponent
                                        placeholder={''}
                                        type='text'
                                        fontSize={0.7}
                                        value={data?.institution}
                                        onChange={(e) => {
                                            handleOnInputChange(
                                                e,
                                                'institution',
                                                setData,
                                                data
                                            )
                                        }}
                                    />
                                    {data?.degree_name !==
                                        STUDENT_DEGREE_TYPES.MATRICULATION && (
                                        <p className='helperText'>
                                            *If you have multiple institutions,
                                            please provide all.
                                        </p>
                                    )}
                                </div>
                                {data?.degree_name ===
                                    STUDENT_DEGREE_TYPES.MATRICULATION &&
                                data?.institutions?.length ? null : (
                                    <div className='actionButtonWrapper degree'>
                                        <Button
                                            fontSize={0.9}
                                            borderRadius={2.4}
                                            paddingTopBottom={0.6}
                                            paddingLeftRight={1.5}
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                if (
                                                    await callAddInstitution()
                                                ) {
                                                    fetchAndParseDataFromAPI(
                                                        'institutions'
                                                    )
                                                }
                                            }}
                                        >
                                            + ADD INSTITUTION
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    {data?.institutions?.length ? (
                        <div className='formFieldWrapperSubjectEdit'>
                            <DegreeInstitutionsTable
                                data={data}
                                callBackFetchData={() =>
                                    fetchAndParseDataFromAPI('institutions')
                                }
                                handleEdit={initiateEditInstitution}
                            />
                        </div>
                    ) : null}
                    {data?.degree_name && (
                        <div className='ResultRadioMode '>
                            <input
                                type='radio'
                                id='ResultAnnounced'
                                value={STUDENT_RESULT_TYPES.ANNOUNCED}
                                name='degreeResultTypes'
                                checked={
                                    currentDegreeResultType ===
                                    STUDENT_RESULT_TYPES.ANNOUNCED
                                }
                                onChange={(e) =>
                                    setCurrentDegreeResultType(
                                        e.currentTarget.value
                                    )
                                }
                            />
                            <label
                                htmlFor='ResultAnnounced'
                                className={`${
                                    currentDegreeResultType ===
                                    STUDENT_RESULT_TYPES.ANNOUNCED
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Result Announced
                            </label>
                            <input
                                type='radio'
                                id='ResultAwaited'
                                value={STUDENT_RESULT_TYPES.AWAITED}
                                name='degreeResultTypes'
                                checked={
                                    currentDegreeResultType ===
                                    STUDENT_RESULT_TYPES.AWAITED
                                }
                                onChange={(e) =>
                                    setCurrentDegreeResultType(
                                        e.currentTarget.value
                                    )
                                }
                            />
                            <label
                                htmlFor='ResultAwaited'
                                className={`${
                                    currentDegreeResultType ===
                                    STUDENT_RESULT_TYPES.AWAITED
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                Result Awaited
                            </label>
                        </div>
                    )}
                    {data?.degree_name && (
                        <>
                            {currentDegreeResultType ===
                                STUDENT_RESULT_TYPES.ANNOUNCED &&
                                data?.degree_name !==
                                    STUDENT_DEGREE_TYPES.O_LEVEL && (
                                    <div className='formFieldWrapperSubject'>
                                        <div className='inputOuter w-32'>
                                            <label>Total Marks</label>
                                            <InputComponent
                                                placeholder={''}
                                                type='text'
                                                fontSize={0.7}
                                                value={data?.total_marks}
                                                onChange={(e) => {
                                                    handleOnInputChange(
                                                        e,
                                                        'total_marks',
                                                        setData,
                                                        data
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className='inputOuter w-32'>
                                            <label>Marks Obtained</label>
                                            <InputComponent
                                                placeholder={''}
                                                type='text'
                                                fontSize={0.7}
                                                value={data?.marks_obtained}
                                                onChange={(e) => {
                                                    handleOnInputChange(
                                                        e,
                                                        'marks_obtained',
                                                        setData,
                                                        data
                                                    )
                                                }}
                                            />
                                        </div>
                                        <div className='inputOuter w-32'>
                                            <label>Percentage</label>
                                            <InputComponent
                                                placeholder={''}
                                                type='text'
                                                fontSize={0.7}
                                                value={data?.percentage}
                                                onChange={(e) => {
                                                    handleOnInputChange(
                                                        e,
                                                        'percentage',
                                                        setData,
                                                        data
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            <div className='formFieldWrapperSubject'>
                                <div
                                    className={`inputOuter ${
                                        currentDegreeResultType ===
                                            STUDENT_RESULT_TYPES.ANNOUNCED &&
                                        data?.degree_name !==
                                            STUDENT_DEGREE_TYPES.MATRICULATION
                                            ? 'w-38'
                                            : 'w-90'
                                    } `}
                                >
                                    <label>Subject Name</label>
                                    <InputComponent
                                        placeholder={''}
                                        type='text'
                                        fontSize={0.7}
                                        value={
                                            data[
                                                `subject_name_${currentDegreeResultType.toLowerCase()}`
                                            ]
                                        }
                                        onChange={(e) => {
                                            handleOnInputChange(
                                                e,
                                                `subject_name_${currentDegreeResultType.toLowerCase()}`,
                                                setData,
                                                data
                                            )
                                        }}
                                    />
                                </div>
                                {currentDegreeResultType ===
                                    STUDENT_RESULT_TYPES.ANNOUNCED && (
                                    <div
                                        className={`inputOuter ${
                                            currentDegreeResultType ===
                                                STUDENT_RESULT_TYPES.ANNOUNCED &&
                                            data?.degree_name !==
                                                STUDENT_DEGREE_TYPES.MATRICULATION
                                                ? 'w-38'
                                                : 'w-34'
                                        } `}
                                    >
                                        <label>Grade</label>
                                        <select
                                            value={data?.subject_grade}
                                            onChange={(e) => {
                                                handleOnInputChange(
                                                    e,
                                                    'subject_grade',
                                                    setData,
                                                    data
                                                )
                                            }}
                                        >
                                            {parseEnumToSelectValues(
                                                STUDENT_SUBJECT_GRADES_TYPES
                                            ).map((type) => (
                                                <option
                                                    selected={
                                                        type === data?.grade
                                                    }
                                                    value={type}
                                                >
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                <div className='actionButtonWrapper'>
                                    <Button
                                        fontSize={0.9}
                                        borderRadius={2.4}
                                        paddingTopBottom={0.6}
                                        paddingLeftRight={1.5}
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            if (await callAddSubject()) {
                                                if (
                                                    currentDegreeResultType ===
                                                    STUDENT_RESULT_TYPES.ANNOUNCED
                                                ) {
                                                    fetchAndParseDataFromAPI(
                                                        'result_announced.subjects'
                                                    )
                                                } else {
                                                    fetchAndParseDataFromAPI(
                                                        'result_awaited.subjects'
                                                    )
                                                }
                                            }
                                        }}
                                    >
                                        + ADD SUBJECT
                                    </Button>
                                </div>
                            </div>

                            {currentDegreeResultType ===
                                STUDENT_RESULT_TYPES.ANNOUNCED &&
                            data?.result_announced?.subjects?.length ? (
                                <div className='formFieldWrapperSubjectEdit'>
                                    <ResultAnnouncedSubjectsTable
                                        data={data}
                                        callBackFetchData={() =>
                                            fetchAndParseDataFromAPI(
                                                'result_announced.subjects'
                                            )
                                        }
                                        handleEdit={initiateEditSubject}
                                    />
                                </div>
                            ) : null}
                            {currentDegreeResultType ===
                                STUDENT_RESULT_TYPES.AWAITED &&
                            data?.result_awaited?.subjects?.length ? (
                                <div className='formFieldWrapperSubjectEdit'>
                                    <ResultAnwaitedSubjectsTable
                                        data={data}
                                        callBackFetchData={() =>
                                            fetchAndParseDataFromAPI(
                                                'result_awaited.subjects'
                                            )
                                        }
                                        handleEdit={initiateEditSubject}
                                    />
                                </div>
                            ) : null}
                            {currentDegreeResultType ===
                                STUDENT_RESULT_TYPES.ANNOUNCED && (
                                <div className='inputOuter profilePicturePickerWrapper'>
                                    <label>Upload Degree/Result</label>
                                    {files?.resultAnnouncedUploadedResult
                                        ?.url_on_server && (
                                        <a
                                            href={
                                                files
                                                    ?.resultAnnouncedUploadedResult
                                                    ?.url_on_server
                                            }
                                            target={'_blank'}
                                        >
                                            {}
                                            View
                                        </a>
                                    )}
                                    <div className='inputFile'></div>
                                    <InputComponent
                                        placeholder={''}
                                        type='file'
                                        fontSize={0.7}
                                        onChange={(e) => {
                                            setSelectedFileName(
                                                e.target.files[0] &&
                                                    e.target.files[0].name
                                            )
                                            setFiles((files) => {
                                                const temp = { ...files }
                                                temp[
                                                    'resultAnnouncedUploadedResult'
                                                ] = {
                                                    ...(temp[
                                                        'resultAnnouncedUploadedResult'
                                                    ] || {}),
                                                    to_be_uploaded_buffer:
                                                        e.target.files[0],
                                                    purpose:
                                                        FILE_UPLOAD_PURPOSE.RESULT_ANNOUNCED,
                                                    owner: data.owner.id,
                                                }
                                                return temp
                                            })
                                        }}
                                    />
                                    {/* Display the selected file name */}
                                    <div
                                        style={{
                                            marginTop: '3.6rem',
                                            marginLeft: '1rem',
                                            fontStyle: 'italic',
                                            color: 'blue',
                                        }}
                                    >
                                        {selectedFileName}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </FormFieldsWrapper>
            <ModalComponent
                modalLabel={'Edit Institution'}
                isOpen={showModalEditInstitution}
                callbackCloseModal={() => {
                    setShowModalEditInstitution(false)
                }}
            >
                <EditInstitution
                    callbackCancel={() => {
                        setShowModalEditInstitution(false)
                    }}
                    callbackProceed={finalizeEditInstitution}
                    institutionID={institutionToEdit.current}
                />
            </ModalComponent>
            <ModalComponent
                modalLabel={'Edit Subject'}
                isOpen={showModalEditSubject}
                callbackCloseModal={() => {
                    setShowModalEditSubject(false)
                }}
            >
                <EditSubject
                    callbackCancel={() => {
                        setShowModalEditSubject(false)
                    }}
                    callbackProceed={finalizeEditSubject}
                    subjectID={subjectToEdit.current}
                    resultType={resultTypeSubjectToEdit.current}
                    degree_name={data.degree_name}
                />
            </ModalComponent>
            <div className='actionButtonWrapper'>
                <Button
                    fontSize={1}
                    borderRadius={2.4}
                    maxWidth={10}
                    paddingTopBottom={0.6}
                    paddingLeftRight={3}
                    onClick={() => UpdateEverything()}
                >
                    SAVE
                </Button>
            </div>
        </StyledComponent>
    )
}
