export const FaculityData = [
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: "100",
        MAXIMUM_CAPACITY: "500",
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
];
export const OneStudentData = [
    {
        Edit: "edit",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "Lahore",
        FULL_NAME: "Tayyab Jaffar",
        CNIC: "35202-333333-3",
        Gender: "Male",
        Father_Name: "Jaffar Khan",
        MOBILE_NO: "03333333333",
        Address: "Skindar Block",
    },
];
export const OneSubject = [
    {
        Edit: "edit",
        SUBJECT: "English",
        TEACHER: "Sir Fakhir",
    },
];
export const Faculity2Data = [
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
    {
        Edit: "edit",
        CAMPUS: "Johar town",
        City: "Lahore",
        CLASS: "Lorem Ipsum",
        SECTION: "C",
        Subject: "Math",
        TEACHER_FACULTY: "Lorem Ipsum",
        MINIMUM_CAPACITY: [{ value: "100", edit: "" }],
        MAXIMUM_CAPACITY: [{ value: "500", edit: "" }],
        DATE_TIME: "10-6-2022 8:13PM",
        REMARKS: "12345",
    },
];
