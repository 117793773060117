import styled from "styled-components";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TableComponent} from "../../Global/graphical/table/Container";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifyFailure} from "../../../helper/notifications/notifyFailure";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";

const StyledComponent = styled.div`
  .extra-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const CampusList = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const headerData = ["CITY", "CAMPUS"];

    const [data, setData] = useState([]);

    const fetchAndParseDataFromAPI = () => {
        const endpoint = "/student/admission/preference/city_campus"
        axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setData(response.data?.results)
            })
            .catch((err) => {
                notifyFailure("Error in fetching preferred cities and campuses")
                notifyApiErrorMessage(err)
            });
    }

    useEffect(() => {
        fetchAndParseDataFromAPI()
    }, [])

    useEffect(() => {
        if (data) {
            setTableBodyData(
                data.map((item) => {
                    return {
                        data: [
                            {
                                type: "text",
                                value: item.city_name,
                            },
                            {
                                type: "text",
                                value: item.campus_name,
                            },
                        ],
                    };
                })
            );
        }
    }, [data]);

    return (
        <StyledComponent>
            <TableComponent headerData={headerData} bodyData={tableBodyData}/>
        </StyledComponent>
    );
};
