import PasswordValidator from "password-validator";

export const validatePhoneNumber = (phoneNumber) => {
    const regexPhone = /92\d{10}$/g;
    let matches = phoneNumber.match(regexPhone);
    if (!matches) {
        return [
            false,
            ["Phone Number: Kindly type in a phone number of this format '92xxxxxxxxxx'"],
        ];
    }
    return [true, null];
};

const passwordValidationSchema = new PasswordValidator();
passwordValidationSchema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(16) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces(); // Should not have spaces

export const validatePassword = (password) => {
    if (!passwordValidationSchema.validate(password)) {
        return [
            false,
            passwordValidationSchema
                .validate(password, { details: true })
                .map((item) => "Password: " + item.message.replace("The string", "")),
        ];
    }
    return [true, null];
};
