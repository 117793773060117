import {
    PRO_CLEAR_STUDENT_CURRENT_PROFILE_STATE,
    PRO_SET_STUDENT_CURRENT_PROFILE_STATE,
} from "../constants";

export const PROStudentCurrentProfileStateReducer = (
    state = {states: {}},
    {type, payload}
) => {
    switch (type) {
        case PRO_SET_STUDENT_CURRENT_PROFILE_STATE:
            return {
                states: {...(state?.states || {}), ...payload}
            };
        case PRO_CLEAR_STUDENT_CURRENT_PROFILE_STATE:
            return {
                states: {}
            };
        default:
            return state;
    }
};