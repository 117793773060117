import {HomeboardingHeader} from "./component/Header";
import {MainWrapper} from "../Global/MainWrapper";

const HomeboardingContainer = () => {
    return (
        <>
            <MainWrapper>
                <HomeboardingHeader/>
            </MainWrapper>
        </>
    );
};

export default HomeboardingContainer;
