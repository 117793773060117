import React from 'react'
import ReactDOM from 'react-dom/client'
import './static/css/index.css'
import './static/css/App.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import {
    globalNavigationReducer,
    phoneNumberVerificationReducer,
    userProfileReducer,
} from './config/store/reducers/reducers'
import { PROStudentCurrentProfileStateReducer } from './config/store/reducers/PROReducers'

const reducer = combineReducers({
    userProfileReducer,
    globalNavigationReducer,
    PROStudentCurrentProfileStateReducer,
    phone_number: phoneNumberVerificationReducer,
})

const middleware = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middleware))
)

// Sentry.init({
//     dsn: 'https://cae00b11d01904c86d89a21d3b7d4352@o4505232523853824.ingest.us.sentry.io/4507214338064384',
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

//     tracePropagationTargets: [
//         'localhost',
//         /^https:\/\/(www\.)?dev\.bcp\.net\.pk/,
//     ],

//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
            <ToastContainer />
        </React.StrictMode>
    </Provider>
)
