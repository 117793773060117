import {useParams} from "react-router-dom";
import {axiosServerInstance} from "../../../../../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../../../../../helper/notifications/notifyApiErrorMessage";
import {notifySuccess} from "../../../../../../../helper/notifications/notifySuccess";
import {STUDENT_RESULT_TYPES} from "../../../../../../../config/store/constants";
import {TableComponent} from "../../../../../../Global/graphical/table/Container";

export const ResultAnwaitedSubjectsTable = ({data, callBackFetchData, handleEdit}) => {
    const headerData = ['subject', '', '']
    const {id: studentID} = useParams()


    const onClickDelete = (id) => {
        const endpoint = `/pro/students/${studentID}/admission/degree/result_awaited/subjects/${id}/`
        axiosServerInstance()
            .delete(endpoint)
            .then((response) => {
                notifySuccess("Success")
                callBackFetchData()
            })
            .catch((err) => {
                notifyApiErrorMessage(err)
            });
    }

    const onClickEdit = (id) => {
        handleEdit(id, STUDENT_RESULT_TYPES.AWAITED)
    }

    const fetchResultAnnouncedSubjectTableData = () => {
        return (
            (data?.result_awaited?.subjects).map((subject, i) => {
                return {
                    id: subject.id,
                    data: [
                        {
                            type: "text",
                            value: subject.name,
                        },
                        {
                            type: "edit"
                        },
                        {
                            type: "delete"
                        },
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchResultAnnouncedSubjectTableData()}
            actionEdit={{
                edit: onClickEdit,
            }}
            actionDelete={{
                delete: onClickDelete,
            }}
        />
    )
}