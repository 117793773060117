export const ActionsView3 = ({ actionsView3, dataID }) => {
  return (
    <>
      {actionsView3 && (
        <div className="view">
          {Object.keys(actionsView3).map((actionName) => (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionsView3[actionName](dataID)
              }}
            >
              {actionName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
