import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {TableComponent} from "./../../../../Global/graphical/table/Container";
import {axiosServerInstance} from "../../../../../config/api/axios";
import {notifyFailure} from "../../../../../helper/notifications/notifyFailure";
import {useParams} from "react-router-dom";
import {ChallanFormContext} from "./Container";
import {Spinner} from "../../../../Global/graphical/Spinner";
import {SizedBox} from "../../../../Global/SizedBox";
import {STUDENT_DEGREE_TYPES} from "../../../../../config/store/constants";

const StyledComponent = styled.div`
  width: inherit;

  .formTitle {
    font-size: 1.2rem;
    margin-left: 1rem;
    color: var(--custom-Black);
    margin: 1rem 0;
  }

  .feeManagement {
    display: flex;
    justify-content: left;

    .ColoredCard {
      border-radius: 2.5rem 0rem 0rem 2.5rem;
      padding: 1rem 0 1rem 1rem;
      border: 1px solid var(--custom-border-color);
      background-color: var(--custom-secondary-bg);

      p {
        font-weight: 500;
      }
    }

    .ColoredCard,
    .PlaneCard {
      width: 46%;

      p {
        padding-left: 1rem;
        color: var(--custom-white);
        line-height: 2.5rem;
        border-bottom: 1px solid var(--custom-border-color);
      }
    }

    .PlaneCard {
      border: 1px solid var(--custom-border-color);
      border-left: 0px;
      border-radius: 0rem 2.5rem 2.5rem 0rem;
      padding: 1rem 1rem 1rem 0rem;

      p {
        color: var(--custom-Black);

        padding-left: 2rem;
      }
    }
  }
`;


const headerData = [
    "ID",
    "BSS ID",
    "Name",
    "SCHOLARSHIP PERCENTAGE",
];

export const AfterResults = () => {
    const [tableBodyData, setTableBodyData] = useState([]);
    const {id: studentID} = useParams();
    const {studentAccountData} = useContext(ChallanFormContext)
    const [afterResultData, setAfterResultData] = useState([])
    // const [isFetchingTotalFee, setIsFetchingTotalFee] = useState(false)
    const [isFetchingStudentDegreeData, setIsFetchingStudentDegreeData] = useState(false)
    const [isFetchingStudentScholarshipData, setIsFetchingStudenScholarshipData] = useState(false)
    // const [totalFee, setTotalFee] = useState(null)
    const [studentDegreeData, setStudentDegreeData] = useState(null)
    const [studentScholarshipData, setStudentScholarshipData] = useState(null)


    // const fetchStudentTotalFee = async () => {
    //     const endpoint = `/pro/students/action/${studentID}/fetch_after_result_fees_status`
    //     setIsFetchingTotalFee(true)
    //     await axiosServerInstance()
    //         .get(endpoint)
    //         .then((response) => {
    //             setTotalFee(response.data?.total_fee)
    //         })
    //         .catch((err) => {
    //             notifyFailure("Error in getting student total fee")
    //         });
    //     setIsFetchingTotalFee(false)
    // }

    const fetchStudentDegreeTypeFromServer = async () => {
        const endpoint = `/pro/students/${studentID}/admission/degree`;
        setIsFetchingStudentDegreeData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setStudentDegreeData(response.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching student account data from server")
            });

        setIsFetchingStudentDegreeData(false)
    };

    const fetchStudentScholarshipCriteria = async () => {
        const endpoint = `/shared/student/${studentID}/fee_scholarship/record/`;
        setIsFetchingStudenScholarshipData(true)
        await axiosServerInstance()
            .get(endpoint)
            .then((response) => {
                setStudentScholarshipData(response.data);
            })
            .catch((err) => {
                notifyFailure("Error in fetching student scholarship data from server")
            });
        setIsFetchingStudenScholarshipData(false)
    };

    useEffect(() => {
        if (studentID) {
            // fetchStudentTotalFee()
            fetchStudentDegreeTypeFromServer()
        }
    }, [studentID])

    useEffect(() => {
        // if (studentAccountData && studentDegreeData?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL && totalFee) {
        //     fetchStudentScholarshipCriteria()
        // }
        if (studentAccountData && studentDegreeData?.degree_name === STUDENT_DEGREE_TYPES.O_LEVEL) {
            fetchStudentScholarshipCriteria()
        }
    }, [studentDegreeData])

    useEffect(() => {
        setAfterResultData(
            {
                id: studentAccountData?.id,
                Bss_id: studentAccountData?.account_data?.system_id_on_bss_portal || studentAccountData?.account_data?.registration_id_on_bss_portal || studentAccountData?.account_data?.visitor_id_on_bss_portal,
                name: studentAccountData?.full_name,
                // totalFee: totalFee,
                scholarshipPercentage: studentDegreeData?.degree_name !== STUDENT_DEGREE_TYPES.O_LEVEL ? 'Calculate Manually' : (studentScholarshipData?.scholarship_percentage && (studentScholarshipData?.scholarship_percentage + "%")),
                // remainingFee: studentDegreeData?.degree_name !== STUDENT_DEGREE_TYPES.O_LEVEL ? 'Calculate Manually' : studentScholarshipData?.remaining_fee,
            },
        )
    }, [studentAccountData, studentDegreeData, studentScholarshipData])

    useEffect(() => {
        if (afterResultData) {
            setTableBodyData(
                [{
                    id: afterResultData.id,
                    data: [
                        {
                            type: "text",
                            value: afterResultData.id,
                        },
                        {
                            type: "text",
                            value: afterResultData.Bss_id,
                        },
                        {
                            type: "text",
                            value: afterResultData.name,
                        },
                        // {
                        //     type: "text",
                        //     value: afterResultData.totalFee,
                        // },
                        {
                            type: "text",
                            value: afterResultData.scholarshipPercentage,
                        },
                        // {
                        //     type: "text",
                        //     value: afterResultData.remainingFee,
                        // }
                    ]
                }
                ]
            )
        }
    }, [afterResultData]);
    return (
        <StyledComponent>
            {(isFetchingStudentDegreeData || isFetchingStudentScholarshipData) ?
                <div className="spinnerWrapper">
                    <SizedBox height={3}/>
                    <Spinner size={1.5}/>
                    <SizedBox height={1}/>
                </div>
                : (
                    <TableComponent
                        heading="AFTER RESULT"
                        headerData={headerData}
                        bodyData={tableBodyData}
                    />
                )
            }
        </StyledComponent>
    );
};
