import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { options } from "./component/Option";
import { data } from "./component/Data";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const Wrapper = styled.div`
    min-width:300px;
    overflow: auto;
    canvas {
      min-width:800px;
    overflow: auto;
    min-height: 400px;
    @media (max-width: 1300px) {
      min-height: 100% !important;
    }
  }
`;

export const LineChartContainer = () => {
  return (
    <Wrapper>
      <Line options={options} data={data} />
    </Wrapper>
  );
};
