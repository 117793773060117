import {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button} from "../../Global/Button";
import {useNavigate} from "react-router";
import {Spinner} from "../../Global/graphical/Spinner";
import {axiosServerInstance} from "../../../config/api/axios";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {BlankCenterCanvas} from "../../Global/graphical/BlankCenterCanvas";
import {useDispatch} from "react-redux";
import {
    ACCOUNT_TYPES,
    CLEAR_USER_PROFILE,
    PRO_SET_STUDENT_CURRENT_PROFILE_STATE,
} from "../../../config/store/constants";
import {GlobalUserProfileContext} from "../../../App";

const LogoutContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {profile} = useContext(GlobalUserProfileContext)

    const handleOnClickLogoutButton = () => {
        axiosServerInstance()
            .post("/auth/logout/")
            .then((response) => {
                window.localStorage.clear();
                dispatch({type: CLEAR_USER_PROFILE, payload: null});
                dispatch({type: PRO_SET_STUDENT_CURRENT_PROFILE_STATE, payload: null});
                if (profile?.account_type !== ACCOUNT_TYPES.STUDENT){
                    navigate("/loginroles", {replace: true});
                }
                else{
                    navigate("/login", {replace: true});
                }
            })
            .catch((err) => {
                window.localStorage.clear();
                dispatch({type: CLEAR_USER_PROFILE, payload: null});
                dispatch({type: PRO_SET_STUDENT_CURRENT_PROFILE_STATE, payload: null});
                if (profile?.account_type !== ACCOUNT_TYPES.STUDENT){
                    navigate("/loginroles", {replace: true});
                }
                else{
                    navigate("/login", {replace: true});
                }
            });
    };

    useEffect(() => {
        handleOnClickLogoutButton();
    }, []);

    return (
        <BlankCenterCanvas>
            <Spinner size={2}/>
        </BlankCenterCanvas>
    );
};

export default LogoutContainer;
