import styled from "styled-components";
import { CompanyLogo } from "../../CompanyLogo";
import { Link } from "react-router-dom";

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || "100%"};

  a {
    text-decoration: none;
  }

  .brandLogo {
    font-size: 1.5rem;
    color: var(--custom-txt-logo);
    font-weight: 700;
  }
`;

export const NavigationBrandContainer = ({ ...props }) => {
  return (
    <StyledComponent {...props}>
      <Link to={"/"} className="brandLogo">
        <CompanyLogo size={1.5} />
      </Link>
    </StyledComponent>
  );
};
