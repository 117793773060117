import { StudentsListComponents } from "./components/StudentsList";
import {MainWrapper} from "../../Global/MainWrapper";


export const StudentsPROContainer = () => {
  return (
    <MainWrapper pageTitle={"STUDENTS"}>
        <StudentsListComponents />
    </MainWrapper>
  );
};
