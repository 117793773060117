export const ActionUpdate = ({ actionsUpdate, dataID }) => {
  return (
    <>
      {actionsUpdate && (
        <div className="Update">
          {Object.keys(actionsUpdate).map((actionName) => (
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionsUpdate[actionName](dataID)
              }}
            >
              {actionName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
