import {createContext} from "react";
import {MainWrapper} from "../../Global/MainWrapper";
import {FaculityListComponents} from "./components/FaculityList";

export const SchoolHeadFacultyContext = createContext({});

export const FacultyContainer = () => {
    return (
        <MainWrapper pageTitle={"Faculty"}>
            <FaculityListComponents/>
        </MainWrapper>
    );
};
