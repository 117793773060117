import styled from "styled-components";

const InputElement = styled.input`
  background: var(--custom-input-bg);
  border-radius: 25px;
  outline: none;
  border: 1px solid var(--custom-input-border);
  width: ${({ width }) => width || 100}%;
  height: ${({ height }) => height || 3}rem;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  color: var(--custom-Black) !important;
  font-size: ${({ fontSize }) => fontSize || 1}rem;
  text-indent: 10px;
`;

export const InputComponent = ({
  children,
  fontSize,
  paddingX,
  height,
  width,
  paddingY,
  ...props
}) => (
  <InputElement
    fontSize={fontSize}
    paddingX={paddingX}
    height={height}
    width={width}
    paddingY={paddingY}
    {...props}
  >
    {children}
  </InputElement>
);
