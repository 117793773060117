export const multiStepFormValidator = (stepMappings, stepData) => {
    for (let field of Object.keys(stepMappings)) {
        if (typeof stepMappings[field] === "function") {
            const response = stepMappings[field](stepData)
            if (!response.status) {
                return response
            }
        } else {
            if (!stepData[field]) {
                return {
                    status: false,
                    message: `'${stepMappings[field]}' is required`
                }
            }
        }
    }
    return {
        status: true
    }
}