import labels from "./Labels";
import faker from "faker";

export const data = {
  labels,
  datasets: [
    {
      label: " My Third dataset",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "rgba(28, 68, 137, 1)",
      backgroundColor: "rgba(28, 68, 137, 1)",
      pointHoverBackgroundColor: "",
      fill: true,
      borderWidth: 2,
      datalabels: {
        color: "#FFCE56",
        anchor: "end",
        align: "end",
        labels: {
          value: {
            color: "black",
          },
          font: {
            size: 14
        }
        },
      },
    },
  ],
};
