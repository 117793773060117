import { AuthMainContainer } from "../components/AuthMainContainer";
import {ForgotPasswordForm} from "./Form";

const ForgotPasswordContainer = () => {
  return (
    <AuthMainContainer mode={"forgot_password"}>
        <ForgotPasswordForm />
    </AuthMainContainer>
  );
};

export default ForgotPasswordContainer;
