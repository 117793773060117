import { StudentsListComponents } from "./components/StudentsList";
import { MainWrapper } from "../../Global/MainWrapper";
import { createContext } from "react";

export const StudentsContext = createContext({});
export const StudentsRegionalHeadContainer = () => {
    return (
        <MainWrapper pageTitle={"STUDENTS"}>
            <StudentsListComponents />
        </MainWrapper>
    );
};
