import React, { createContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  MatricData,
  OLevelData,
} from "../../../config/DataList/scholarship/ScholarshipData";
import { SCHOLARSHIP_TYPES } from "../../../config/store/constants";
import { Button } from "../../Global/Button";
import { MainWrapper } from "../../Global/MainWrapper";
import { DetailTable } from "./components/DetailTable";
import { OLevel } from "./components/OLevel";
import { axiosServerInstance } from "../../../config/api/axios";
import { notifyFailure } from "../../../helper/notifications/notifyFailure";

export const StudentsContext = createContext({});
const StyledComponent = styled.div`
  width: 100%;

  .component {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 20px 10px -3px #ddd;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    @media (max-width: 1400px) {
      flex-direction: column;
    }

    > div > div {
      box-shadow: none;
      padding-bottom: 0;
    }
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      gap: 1rem;

      .inputOuter.w-32 {
        width: 39.2%;
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }

      .inputOuter.w-22 {
        width: 25%;
        @media screen and (max-width: 1545px) {
          width: 30%;
        }
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }
    }

    .actionButtonWrapper {
      align-self: end;
      justify-self: end;
    }
  }

  .ActionButtons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  .actionButtonSave {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 0rem;
  }

  .table {
    margin: 1rem 0;

    .tableOuter {
      padding: 1rem 0px;
    }
  }

  .table > div > div {
    box-shadow: none;
    padding: 1rem 0px;
  }

  .title {
    margin-bottom: 3rem;
  }

  .ResultRadioMode {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    input {
      display: none;
      margin: 0 1rem;
    }

    label {
      border: 2px solid var(--custom-secondary-bg);
      border-radius: 2.5rem;
      padding: 0.25rem 1.5rem;
      @media (max-width: 500px) {
        padding: 0.2rem 2rem;
      }
    }

    label.active {
      background-color: var(--custom-secondary-bg);
      color: #fff;
    }
  }
`;

export const ScholarshipContainer = () => {
  const [matric, setMatric] = useState([]);
  const [oLevel, setOlevel] = useState([]);
  const [changeCriteriaStatus, setChangeCriteriaStatus] = useState(false);
  const [currentScholarshipType, setCurrentScholarshipType] = useState("");

  useEffect(() => {
    setMatric(MatricData);
    setOlevel(OLevelData);
    setCurrentScholarshipType(SCHOLARSHIP_TYPES.CRITERIA);
  }, []);

  useEffect(() => {
    changeCriteriaStatus === true &&
      setCurrentScholarshipType(SCHOLARSHIP_TYPES.DETAILS);
  }, [changeCriteriaStatus]);

  const handleOnClickDownload = async () => {
    const campusLists = await axiosServerInstance()
      .get(`/shared/global/fetch_all_campus/`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        notifyFailure("Error in fetching all registered campuses");
      });
    if (!campusLists) {
      return;
    }
    for (let campus of campusLists) {
      await axiosServerInstance()
        .get(
          `/shared/campuses/scholarship/export_record/?campus_id=${campus.id}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Super Admin (${campus.name}) Scholarship Export.xlsx`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  };
  return (
    <MainWrapper pageTitle={"SCHOLARSHIP"}>
      <StudentsContext.Provider
        value={{
          matric,
          oLevel,
          changeCriteriaStatus,
          setChangeCriteriaStatus,
        }}
      >
        <StyledComponent>
          <div className='ResultRadioMode '>
            <input
              type='radio'
              id='CRITERIA'
              value={SCHOLARSHIP_TYPES.CRITERIA}
              name='degreeResultTypes'
              checked={currentScholarshipType === SCHOLARSHIP_TYPES.CRITERIA}
              onChange={(e) => setCurrentScholarshipType(e.currentTarget.value)}
            />
            <label
              htmlFor='CRITERIA'
              className={`${
                currentScholarshipType === SCHOLARSHIP_TYPES.CRITERIA
                  ? "active"
                  : ""
              }`}
            >
              CRITERIA
            </label>
            <input
              type='radio'
              id='DETAILS'
              value={SCHOLARSHIP_TYPES.DETAILS}
              name='degreeResultTypes'
              checked={currentScholarshipType === SCHOLARSHIP_TYPES.DETAILS}
              onChange={(e) => setCurrentScholarshipType(e.currentTarget.value)}
            />
            <label
              htmlFor='DETAILS'
              className={`${
                currentScholarshipType === SCHOLARSHIP_TYPES.DETAILS
                  ? "active"
                  : ""
              }`}
            >
              DETAILS
            </label>
          </div>
          {currentScholarshipType !== SCHOLARSHIP_TYPES.CRITERIA ? (
            <>
              <div className='actionButtonSave'>
                <Button
                  fontSize={0.9}
                  borderRadius={2.4}
                  maxWidth={10}
                  paddingTopBottom={0.8}
                  paddingLeftRight={1.5}
                  onClick={handleOnClickDownload}
                >
                  DOWNLOAD
                </Button>
              </div>
              <div className='component'>
                <DetailTable />
              </div>
            </>
          ) : (
            <div className='component'>
              <OLevel />
            </div>
          )}
        </StyledComponent>
      </StudentsContext.Provider>
    </MainWrapper>
  );
};
