import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  .container {
    text-align: center;
  }

  .toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
  }

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }

  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 25px;
    padding: 0;
    line-height: 25px;
    border: 1px solid #000;
    border-radius: 20px;
    color: #fff;
    box-sizing: border-box;
  }

  .inner:before {
    content: "";
    padding-left: 10px;
    background-color: var(--custom-btn-bg);
    border: 1px solid #000;
    border-radius: 20px;
    color: #fff;
  }

  .inner:after {
    content: "";
    padding-right: 10px;
  ${"" /* background-color: #060; */}
    color: #fff;
    border: 2px solid var(--custom-btn-bg);
    border-radius: 20px;
    text-align: right;
  }

  .switch {
    display: block;
    width: 16px;
    margin: 5px;
    background: var(--custom-btn-bg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 48px;
    border: 1px solid var(--custom-btn-bg);
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  .checkbox:checked + .label .inner {
    margin-left: 0;
  }

  .checkbox:checked + .label .switch {
    background: var(--custom-white);
    right: 0px;
  }
`;
export const ToggleButton = ({label, checked, callBackOnChange}) => {
    return (
        <Wrapper>
            <div className="container">
                {label}:{" "}
                <div className="toggle-switch">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => {
                            callBackOnChange(e.target.checked)
                        }}
                        className="checkbox"
                        name="label"
                        id="label"
                    />
                    <label className="label" htmlFor="label">
                        <span className="inner"/>
                        <span className="switch"/>
                    </label>
                </div>
            </div>
        </Wrapper>
    );
};
