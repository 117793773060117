import {AuthMainContainer} from "../components/AuthMainContainer";
import {ResetPasswordForm} from "./Form";

const ResetPasswordContainer = () => {
    return (
        <AuthMainContainer mode={"reset_password"}>
            <ResetPasswordForm/>
        </AuthMainContainer>
    );
};
export default ResetPasswordContainer;
