import styled from 'styled-components'
import { CircleProgressBarContainer } from '../../Global/graphical/progressbar/circleBar/Container'
import { useEffect, useState } from 'react'
import { axiosServerInstance } from '../../../config/api/axios'
import { notifyFailure } from '../../../helper/notifications/notifyFailure'
import { MainWrapper } from '../../Global/MainWrapper'
import FilterByBatch from '../../Global/filters/FilterByBatch'

const Wrapper = styled.div`
    .circleBarsOuter {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;
        justify-content: start;

        .progressbar {
            width: 35%;
            padding: 1rem 5.5rem;
            border-radius: 2.5rem;
            box-shadow: 0 10px 13px -3px #ddd;
            @media (max-width: 1200px) {
                width: 47%;
            }
            @media (max-width: 1030px) {
                padding: 1rem 6.5vw;
            }
        }
    }

    .circleContent {
        .detail {
            line-height: 1.4rem;

            @media (max-width: 1030px) {
                font-size: 1rem !important;
                line-height: 1rem;
            }
            @media (max-width: 800px) {
                font-size: 1rem !important;
                line-height: 1rem;
            }
            @media (max-width: 370px) {
                font-size: 0.8rem !important;
                line-height: 0.8rem;
            }
        }

        .counter {
            text-align: center;
            line-height: 4rem;
            margin-top: 5px;
            @media (max-width: 1030px) {
                font-size: 1.4rem !important;
                line-height: 1.4rem;
            }
            @media (max-width: 800px) {
                line-height: 1rem;
                font-size: 1.4rem !important;
            }
        }
    }
`

export const DashboardRegionalHeadContainer = () => {
    const [data, setData] = useState(null)
    const [batchFilter, setBatchFilter] = useState({ batch: '' })

    const fetchPageAnalyticsCount = async () => {
        const endpoint = `/regional_head/analytics/admission_status_stats/${
            batchFilter?.batch && `?batch=${batchFilter.batch}`
        }`
        await axiosServerInstance()
            .get(endpoint)
            .then(response => {
                setData(response.data)
            })
            .catch(err => {
                notifyFailure('Error in fetching admission status statistics')
            })
    }

    useEffect(() => {
        fetchPageAnalyticsCount()
    }, [batchFilter])

    return (
        <MainWrapper pageTitle={'DASHBOARD'}>
            <Wrapper>
                <div className='searchActions'>
                    <div className='selectOuter'>
                        <FilterByBatch setBatchFilter={setBatchFilter} />
                    </div>
                </div>
                <div className='circleBarsOuter'>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Submitted Applications'
                            strokeWidth={9}
                            value={data?.successful_applications_count}
                            percentage={
                                data
                                    ? Math.floor(
                                          (data?.successful_applications_count /
                                              data?.total_student_count) *
                                              100
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Successful Interviews'
                            strokeWidth={9}
                            value={data?.successful_interview_count}
                            percentage={
                                data
                                    ? Math.floor(
                                          (data?.successful_interview_count /
                                              data?.total_student_count) *
                                              100
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
                <div className='circleBarsOuter'>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Seat Confirmation'
                            strokeWidth={9}
                            value={data?.seat_confirmation_count}
                            percentage={
                                data
                                    ? Math.floor(
                                          (data?.seat_confirmation_count /
                                              data?.total_student_count) *
                                              100
                                      )
                                    : 0
                            }
                        />
                    </div>
                    <div className='progressbar'>
                        <CircleProgressBarContainer
                            counterSize='2.9'
                            detailSize='1.2'
                            counterColor='btn-bg'
                            color2='#DFDFDF'
                            color1='#3CB4E5'
                            detail='Fee Submission'
                            strokeWidth={9}
                            value={data?.fee_submission_count}
                            percentage={
                                data
                                    ? Math.floor(
                                          (data?.fee_submission_count /
                                              data?.total_student_count) *
                                              100
                                      )
                                    : 0
                            }
                        />
                    </div>
                </div>
            </Wrapper>
        </MainWrapper>
    )
}
