import {TableComponent} from "../../../../graphical/table/Container";

export const CityCampusTable = ({data, callBackFetchData, setEditMode}) => {
    const headerData = ["CITY", "Campus", ""]

    // const onClickDelete = (id) => {
    //     const endpoint = `/student/admission/preference/city_campus/${id}/`
    //     axiosServerInstance()
    //         .delete(endpoint)
    //         .then((response) => {
    //             notifySuccess("Success")
    //             callBackFetchData()
    //         })
    //         .catch((err) => {
    //             notifyApiErrorMessage(err)
    //         });
    // }

    const onClickEdit = (id) => {
        setEditMode(true)
    }
    const fetchTableData = () => {
        return (
            (data).map((item, i) => {
                return {
                    id: item.id,
                    data: [
                        {
                            type: "text",
                            value: item.city_name,
                        },
                        {
                            type: "text",
                            value: item.campus_name,
                        },
                        {
                            type: "edit"
                        }
                    ],
                };
            })
        )
    }


    return (
        <TableComponent
            headerData={headerData}
            bodyData={fetchTableData()}
            actionEdit={{
                edit: onClickEdit,
            }}
        />
    )
}