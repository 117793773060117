import React from 'react'
import { MainWrapper } from '../Global/MainWrapper'
import { CampusList } from './components/CampusList'
import { SubjectList } from './components/SubjectList'
import styled from 'styled-components'
import { SizedBox } from '../Global/SizedBox'
const StyledComponent = styled.div`
    max-width: 100%;
`
export const PreferenceContainer = () => {
    return (
        <MainWrapper pageTitle={'PREFERENCES'}>
            <StyledComponent>
                <CampusList />
                <SizedBox height={2} />
                <SubjectList />
            </StyledComponent>
        </MainWrapper>
    )
}
