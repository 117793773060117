import Edit from "../../../../../../static/images/table/Group 46.svg";

export const ActionsEdit = ({ actionEdit, dataID }) => {
  return (
    <>
      {actionEdit && (
        <div className="editBtn">
          {Object.keys(actionEdit).map((actionName, i) => (
            <button
              key={i}
              className="button"
              onClick={(e) => {
                e.preventDefault()
                actionEdit[actionName](dataID)
              }}
            >
              <img src={Edit} alt="" />
            </button>
          ))}
        </div>
      )}
    </>
  );
};
