import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../Global/Button";
import {SizedBox} from "../../Global/SizedBox";
import {AuthButtonContainer} from "../components/AuthButtonContainer";
import {FormComponent} from "../components/FormElement";
import {InputComponent} from "../components/InputELement";
import {useLocation, useNavigate} from "react-router-dom";
import {axiosServerInstance} from "../../../config/api/axios";
import {handleOnInputChange} from "../../../helper/form_inputs/handleOnInputChange";
import {notifySuccess} from "../../../helper/notifications/notifySuccess";
import {notifyApiErrorMessage} from "../../../helper/notifications/notifyApiErrorMessage";
import {Spinner} from "../../Global/graphical/Spinner";

export const ResetPasswordForm = () => {
    const [data, setData] = useState({});
    const [resetPasswordToken, setResetPasswordToken] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnClickButton = (e) => {
        e.preventDefault();
        handleProceedResetPassword();
    };

    const handleProceedResetPassword = () => {
        const endpoint = "/auth/finalize_reset_password_email/";
        const requestData = {
            ...data,
            token: resetPasswordToken,
        };
        setShowSpinner(true);
        axiosServerInstance()
            .post(endpoint, requestData)
            .then((response) => {
                notifySuccess("Password reset successfully");
                navigate("/", {replace: true});
            })
            .catch((err) => {
                notifyApiErrorMessage(err);
                setShowSpinner(false);
            });
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("token")) {
            setResetPasswordToken(query.get("token"));
        }
    }, []);

    return (
        <FormComponent>
            <label>New Password</label>
            <InputComponent
                placeholder={""}
                type="password"
                value={data?.password}
                onChange={(e) => handleOnInputChange(e, "password", setData, data)}
            />
            <SizedBox height={1.0}/>
            <label>Confirm Password</label>
            <InputComponent
                placeholder={""}
                type="password"
                value={data?.confirm_password}
                onChange={(e) => handleOnInputChange(e, "confirm_password", setData, data)}
            />
            <SizedBox height={1.5}/>
            <AuthButtonContainer>
                {!showSpinner ? (
                    <>
                        <Button
                            fontWeight={700}
                            fontSize={1}
                            maxWidth={15}
                            borderRadius={1.5}
                            paddingTopBottom={0.6}
                            onClick={handleOnClickButton}
                        >
                            SAVE/CONTINUE
                        </Button>
                    </>
                ) : (
                    <Spinner size={2}/>
                )}
            </AuthButtonContainer>
        </FormComponent>
    );
};
