import React, { useRef, useState } from "react";
import styled from "styled-components";
import { MainWrapper } from "../../Global/MainWrapper";
import { LogsList } from "./components/LogsList";
const Wrapper = styled.div`
    .searchActions {
        @media (max-width: 1250px) {
            justify-content: flex-end;
            flex-wrap: wrap;
        }
    }
`;
export const LogsContainer = () => {
    return (
        <MainWrapper pageTitle={"LOGS"}>
            <Wrapper>
                <LogsList />
            </Wrapper>
        </MainWrapper>
    );
};
